import React, { useState } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Book, Feather } from 'lucide-react';
import { useSaintMeditation } from '../../hooks/useSaintMeditation';
import LoadingSpinner from '../common/LoadingSpinner';
import SaintStory from './SaintStory';
import SaintMeditation from './SaintMeditation';
import '../../styles/saintMeditation.css';
import Sharing from '../common/Sharing';
import { PrayerTrackingProvider } from '../../contexts/PrayerTrackingContext';
import ContentHeader from '../common/ContentHeader';

const SaintMeditationContainer = ({ seriesContext, saintId: propSaintId }) => {
  const { saintId: paramSaintId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Use propSaintId if available, otherwise fall back to paramSaintId
  const activeSaintId = propSaintId || paramSaintId;
  
  const { saintMeditation, saintData, loading, error } = useSaintMeditation(activeSaintId);
  const [activeSection, setActiveSection] = useState('story');

  const handleSectionChange = (section) => {
    setActiveSection(section);
    navigate(`?section=${section}`, { replace: true });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error-message">{error}</div>;
  if (!saintMeditation || !saintData) return <div>No data available</div>;

  const today = new Date().toISOString().split('T')[0];
  
  const trackingData = {
    title: `${saintData.name} - ${activeSection === 'story' ? 'Life Story' : 'Meditation'}`,
    type: 'saints-meditation',
    date: today,
    metadata: {
      saintId: activeSaintId,
      section: activeSection
    }
  };

  return (
    <PrayerTrackingProvider 
      prayerMethod={{
        id: `saint-${activeSaintId}`,
        type: 'saints-meditation',
        name: saintData.name
      }}
      prayerData={trackingData}
      seriesContext={seriesContext && {
        seriesId: seriesContext.seriesId,
        itemId: seriesContext.itemId,
        sectionId: seriesContext.sectionId
      }}
    >
      <div className="saint-meditation-container px-1 px-md-4">
        <ContentHeader
          backLink="/saints"
          backText="Back to Saints"
          title={`${saintData.name} - Saintly Whispers`}
          description={`Explore the story and meditate with ${saintData.name} on Saintly Whispers.`}
          hideBackLink={seriesContext?.hideBackLink}
          className="mb-3"
        />
        <div className="saint-banner" style={{ backgroundImage: `url(${saintData.imageUrl || ''})` }}>
          <h1 className="saint-title">
            <span className="title-background">{saintData.name}</span>
          </h1>
        </div>
        
        <div className="saint-tabs">
          <button 
            className={`tab-button ${activeSection === 'story' ? 'active' : ''}`}
            onClick={() => handleSectionChange('story')}
          >
            <Book size={20} className="tab-icon" />
            <span>Life and Spirituality</span>
          </button>
          <button 
            className={`tab-button ${activeSection === 'meditation' ? 'active' : ''}`}
            onClick={() => handleSectionChange('meditation')}
          >
            <Feather size={20} className="tab-icon" />
            <span>Meditate on {saintData.name}</span>
          </button>
        </div>

        <div className="saint-content">
          {activeSection === 'story' && (
            <div className="saint-story">
              <SaintStory saintData={saintData} backgroundData={saintMeditation.background} />
            </div>
          )}

          {activeSection === 'meditation' && (
            <div className="saint-meditation">
              <SaintMeditation saintData={saintData} meditationData={saintMeditation.meditation} />
            </div>
          )}
        </div>
      </div>
    </PrayerTrackingProvider>
  );
};

export default SaintMeditationContainer;
