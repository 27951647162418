import { useState, useCallback, useEffect } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import api from '../utils/api';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { RosaryMystery } from '@shared/types/rosaryMystery';

interface AudioScript {
  text: string;
  break: string;
}

export const useRosaryPlayer = (
  prayerMethod: PrayerMethod,
  mysteryMeditationData: RosaryMystery['mysteries'][0],
  allMeditationData: RosaryMystery
) => {
  const [script, setScript] = useState<AudioScript[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchScript = useCallback(async (): Promise<AudioScript[] | null> => {
    if (script) return script;

    setIsLoading(true);
    setError(null);

    try {
      if (mysteryMeditationData.audioScript) {
        setScript(mysteryMeditationData.audioScript);
        return mysteryMeditationData.audioScript;
      }

      const db = getFirestore();
      const scriptRef = doc(db, FIRESTORE_PATHS.ROSARY_MEDITATIONS.path(allMeditationData.id, mysteryMeditationData.id));
      const scriptDoc = await getDoc(scriptRef);
      
      if (scriptDoc.exists()) {
        const fetchedScript = scriptDoc.data().script as AudioScript[];
        setScript(fetchedScript);
        return fetchedScript;
      } else {
        throw new Error("Rosary meditation script not found");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      setError(`Error fetching script: ${errorMessage}`);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [script, mysteryMeditationData, allMeditationData]);

  const generateAudio = useCallback(async (params: {
    prayerMethod: PrayerMethod;
    prayerData: RosaryMystery['mysteries'][0];
    script?: AudioScript[];
  }) => {
    console.log('GENERATE AUDIO PARAMS', params);
    const { prayerMethod, prayerData, script } = params;
    
    const audioScript = script || prayerData.audioScript || [];

    return api.generateRosaryAudio({
      prayerMethod: prayerMethod,
      prayerData: prayerData,
      script: audioScript,
      currentMysteryId: prayerData.id,
      rosaryMeditationId: allMeditationData.id
    });
  }, [allMeditationData]);

  useEffect(() => {
    fetchScript().catch(console.error);
  }, [fetchScript]);

  return { script, isLoading, error, fetchScript, generateAudio };
};

