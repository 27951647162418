import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../utils/api';
import { CustomScriptureMeditationHook, MeditationData, PrayerMethod } from '@shared/types/customScripture';

export const useCustomScriptureMeditation = (): CustomScriptureMeditationHook => {
  const [meditationData, setMeditationData] = useState<MeditationData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchMeditation = useCallback(async (scripture: string, prayerMethodId: string, scriptureText: string) => {
    if (!scripture || !prayerMethodId || !scriptureText) {
      console.log('Missing required parameters', { scripture, prayerMethodId, scriptureText });
      setError('Missing required parameters');
      return;
    }

    if (meditationData) {
      return; // Don't fetch if we already have data
    }

    setLoading(true);
    try {
      const response = await api.generateCustomScriptureMeditation({ 
        scriptureData: {
          citation: scripture,
          text: scriptureText
        },
        prayerMethodId: prayerMethodId 
      });
      //console.log('API response:', response);
      setMeditationData(response.data);
    } catch (err: any) {
      console.error('Error in fetchMeditation:', err);
      setError(err.response?.data?.error || 'An error occurred while fetching the meditation');
    } finally {
      setLoading(false);
    }
  }, [meditationData]);

  const generateMeditation = useCallback(async (scripture: string, prayerMethod: PrayerMethod) => {
    if (scripture && prayerMethod) {
      setLoading(true);
      navigate(`/scripture/meditation?scripture=${encodeURIComponent(scripture)}&prayerMethod=${encodeURIComponent(prayerMethod.id)}`);
      await fetchMeditation(scripture, prayerMethod.id, scripture);
    } else {
      console.log('Missing scripture or prayerMethod', { scripture, prayerMethod });
      setError('Missing scripture or prayer method');
    }
  }, [navigate, fetchMeditation]);

  return { meditationData, loading, error, fetchMeditation, generateMeditation };
};
