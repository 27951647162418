import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Sharing from './Sharing';
import styles from '../../styles/ContentHeader.module.css';

interface ContentHeaderProps {
  backLink: string;
  backText: string;
  title: string;
  description: string;
  hideBackLink?: boolean;
  className?: string;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
  backLink,
  backText,
  title,
  description,
  hideBackLink,
  className
}) => {
  if (hideBackLink) return null;

  return (
    <div className={`${styles.header} ${className || ''}`}>
      <div className={styles.headerContent}>
        <Link to={backLink} className={styles.backLink}>
          <ArrowLeft size={18} className={styles.backIcon} />
          {backText}
        </Link>
        <Sharing 
          url={window.location.href}
          title={title}
          description={description}
        />
      </div>
    </div>
  );
};

export default ContentHeader;
