import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../../styles/about.css';
import { Link } from 'react-router-dom';

interface PrayerMethod {
  name: string;
  description: string;
  link: string;
}

interface ReleaseNote {
  date: string;
  features: {
    mainPoints: string[];
    subPoints?: {
      [key: string]: string[];
    };
  };
}

const AboutPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const prayerMethods: PrayerMethod[] = [
    { 
      name: 'Franciscan Prayer', 
      description: 'Inspired by St. Francis of Assisi, connecting with God through nature and simplicity.',
      link: '/daily/franciscan-prayer'
    },
    {
      name: 'Theresian Prayer',
      description: 'Based on St. Thérèse of Lisieux\'s "Little Way," embracing childlike trust and love.',
      link: '/daily/therese-prayer'
    },
    {
      name: 'Carmelite Mystical Prayer',
      description: 'Inspired by St. John of the Cross, focusing on contemplation and union with God.',
      link: '/daily/stjohncross-prayer'
    },
    {
      name: 'Ignatian Contemplation',
      description: 'Based on St. Ignatius of Loyola\'s Spiritual Exercises, using imagination to enter Gospel scenes.',
      link: '/daily/ignatius-prayer'
    },
    {
      name: 'Custom Scripture Meditations',
      description: 'Select any scripture passage for personalized meditation experiences.',
      link: '/scripture'
    },
    {
      name: 'Saints Meditations',
      description: 'Learn about and reflect on the lives and teachings of various saints.',
      link: '/saints'
    },
    {
      name: 'Contemplative Inspired Meditations',
      description: 'Experience practices like Centering Prayer, Christian Mindfulness, and more.',
      link: '/prayers?filters=contemplative-meditation'
    },
    {
      name: 'Rosary Meditations',
      description: 'Multiple styles to deepen your engagement with the mysteries of the rosary.',
      link: '/rosary'
    },
    {
      name: 'Relaxation Prayers',
      description: 'Combine spiritual reflection with relaxation techniques for peace and wellbeing.',
      link: '/prayers?filters=relaxation-prayer'
    }
  ];

  return (
    <Container className="about-container">
      <div className="d-flex align-items-center justify-content-center mb-4">
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/lofh-dbbbb.appspot.com/o/img%2Fsite-images%2Flogo-square.jpg?alt=media" 
          alt="Logo" 
          className="about-logo me-3" 
        />
        <h1 className="text-center my-3">About Saintly Whispers</h1>
      </div>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Our Mission</h2>
          <p>
            Saintly Whispers is dedicated to enriching your spiritual journey through the wisdom of Catholic saints, time-honored prayer traditions, and diverse meditation practices. We aim to make daily scripture meditation, saint-inspired reflections, and contemplative prayer accessible, meaningful, and transformative for Catholics of all backgrounds.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">What Makes Us Unique</h2>
          <p>
            Saintly Whispers offers a distinctive approach to Catholic spirituality by combining:
          </p>
          <ul>
            <li>Daily gospel readings and custom scripture meditations</li>
            <li>Diverse prayer methods inspired by beloved saints and contemplative traditions</li>
            <li>Guided, step-by-step prayer experiences</li>
            <li>Historical and spiritual context to deepen your understanding</li>
            <li>Flexibility to customize your prayer experience</li>
          </ul>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Our Approach</h2>
          <p>
            At Saintly Whispers, we combine the timeless wisdom of Catholic tradition with modern technology to provide a unique and personalized prayer experience. We utilize generative artificial intelligence, carefully grounded in the teachings and traditions of Saints and Catholicism, to generate much of our content.
          </p>
          <p>
            This innovative approach allows us to:
          </p>
          <ul>
            <li>Offer a wide variety of prayer methods and reflections</li>
            <li>Customize content to individual user needs and preferences</li>
            <li>Provide fresh, relevant insights while staying true to Catholic doctrine</li>
            <li>Continuously expand our offerings without compromising quality</li>
          </ul>
          <p>
            This blend of tradition and technology allows us to offer a rich, diverse, and personalized prayer experience that adapts to your spiritual journey. We try very hard to be true to the teachings of the Saints and Catholicism, but it is not always possible to be 100% accurate. If you find any inaccuracies, please let us know so we can correct them.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Our Prayer Methods and Features</h2>
          <p>Explore a variety of prayer approaches and features, each designed to enrich your spiritual life:</p>
          <Row xs={1} md={2} lg={3} className="g-4">
            {prayerMethods.map((method, index) => (
              <Col key={index}>
                <Card as={Link} to={method.link} className="h-100 prayer-method-card">
                  <Card.Body>
                    <Card.Title>{method.name}</Card.Title>
                    <Card.Text>{method.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Our Vision</h2>
          <p>
            At Saintly Whispers, we believe that the wisdom of the saints and the richness of Catholic contemplative traditions can profoundly impact our modern spiritual lives. Our app is designed to be a daily companion in your faith journey, helping you to:
          </p>
          <ul>
            <li>Deepen your connection with scripture</li>
            <li>Explore diverse spiritual traditions within the Catholic faith</li>
            <li>Cultivate a consistent and varied prayer practice</li>
            <li>Find moments of peace, reflection, and relaxation in your busy day</li>
            <li>Grow in your understanding of saints and their spirituality</li>
          </ul>
          <p>
            Whether you're a seasoned practitioner or new to Catholic meditation, Saintly Whispers is here to support and enrich your spiritual life, one whisper at a time.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Support Saintly Whispers</h2>
          <p>
            Saintly Whispers is a labor of love, created to help people deepen their faith and prayer life. While the app is free to use, maintaining and improving it requires time, effort, and resources. If you've found value in Saintly Whispers and would like to support its continued development, please consider making a donation.
          </p>
          <p>Your contribution, no matter the size, helps us:</p>
          <ul>
            <li>Develop new features and prayer methods</li>
            <li>Maintain and improve existing functionality</li>
            <li>Cover hosting and operational costs</li>
          </ul>
        </Col>
      </Row>

      {/* Creator Section */}
      <Row className="mb-5">
        <Col className="creator-section">
          <h2 className="mb-4">Meet the Creator</h2>
          <p>Hey there! I'm <Link to="https://www.linkedin.com/in/chris-heather-40335146/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Chris Heather</Link>, the creator of Saintly Whispers.</p>
          <p>I'll cut to the chase: I made this app because I struggled with prayer. Like many of you, I heard countless recommendations to pray with scripture, but I always felt lost when I tried. It was frustrating, to say the least.</p>
          <p>So, I decided to do something about it. I created Saintly Whispers to be the guide I wished I had when I started my prayer journey. This app is designed to help you explore different ways of praying with scripture, inspired by the wisdom of saints and spiritual masters.</p>
          <p>I'm not a theology or prayer expert. I'm just a regular Catholic who wants to make rich, meaningful prayer more accessible to everyone. My goal is simple: to help you deepen your relationship with God, one prayer at a time.</p>
          <p>Saintly Whispers is constantly evolving, and your feedback is crucial to its growth. If you find the app helpful, please share it with others who might benefit.</p>
          <p>Thanks for joining me on this journey. Let's grow in faith together!</p>
          <p>God bless,<br />Chris</p>
        </Col>
      </Row>

      {/* Release Notes Section */}
      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Release Notes</h2>
          <ul className="list-unstyled">
            <li className="mb-4">
              <h5>November 12, 2024</h5>
              <ul>
                <li>Launched Spiritual Journey Series at <a href="/series" className="release-note-link">Series Catalog</a></li>
                <li>New features include:
                  <ul>
                    <li>Multi-day guided spiritual journeys</li>
                    <li>Progress tracking for each series</li>
                    <li>Various series options including:
                      <ul>
                        <li>Preparing the Way: An Advent Journey</li>
                        <li>The Spiritual Exercises of St. Ignatius</li>
                        <li>The Little Way of Spiritual Childhood</li>
                        <li>Through Darkness to Light: Mystical Wisdom of St. John of the Cross</li>
                        <li>Brother Sun, Sister Moon: The Path of St. Francis</li>
                        <li>Ocean of Mercy: St. Faustina's Path of Trust</li>
                      </ul>
                    </li>
                    <li>Each series features:
                      <ul>
                        <li>Structured daily meditations</li>
                        <li>Integration with various prayer methods</li>
                        <li>Scripture passages for reflection</li>
                        <li>Detailed introductions and guidance</li>
                        <li>Section-based organization with themes and graces</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mb-4">
              <h5>November 1, 2024</h5>
              <ul>
                <li>Launched Prayer Advisor at <a href="/advisor" className="release-note-link">Prayer Advisor</a></li>
                <li>New features include:
                  <ul>
                    <li>Personalized prayer recommendations based on your spiritual needs</li>
                    <li>Interactive prayer planning system with drag-and-drop organization</li>
                    <li>Prayer history tracking and statistics</li>
                    <li>Customizable prayer journey with:
                      <ul>
                        <li>Multiple scripture options for each recommendation</li>
                        <li>Alternative prayer methods tailored to your preferences</li>
                        <li>Guided spiritual insights for your journey</li>
                      </ul>
                    </li>
                    <li>Enhanced user authentication with:
                      <ul>
                        <li>Google sign-in integration</li>
                        <li>Guest access options</li>
                        <li>Password reset functionality</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mb-4">
              <h5>October 18, 2024</h5>
              <ul>
                <li>You can now create custom scripture meditations for any scripture passage at <a href="/scripture" className="release-note-link">Custom Scripture Meditations</a></li>
                <li>Includes recommended scripture passages for each prayer method</li>
                <li>Organizes recommended scripture passages by themes</li>
              </ul>
            </li>
            <li className="mb-4">
              <h5>October 15, 2024</h5>
              <ul>
                <li>Added Saints Meditations at <a href="/saints" className="release-note-link">Saints Catalog</a></li>
                <li>Learn about saints' lives and spirituality through discussion podcasts</li>
                <li>Experience meditations inspired by saints' lives and teachings</li>
              </ul>
            </li>
            <li className="mb-4">
              <h5>September 28, 2024</h5>
              <ul>
                <li>Introduced contemplative inspired meditations at <a href="/prayers?filters=contemplative-meditation" className="release-note-link">Contemplative Meditations</a></li>
                <li>New meditation methods include:
                  <ul>
                    <li><a href="/contemplation/centering-prayer" className="release-note-link">Centering Prayer</a></li>
                    <li><a href="/contemplation/christian-mindfulness" className="release-note-link">Christian Mindfulness</a></li>
                    <li><a href="/contemplation/cloud-unknowing" className="release-note-link">Cloud of Unknowing</a></li>
                    <li><a href="/contemplation/merton-contemplation" className="release-note-link">Thomas Merton Contemplative Prayer</a></li>
                    <li><a href="/contemplation/sacrament-of-present" className="release-note-link">Sacrament of the Present Moment</a></li>
                    <li><a href="/contemplation/desert-fathers-contemplation" className="release-note-link">Desert Fathers Contemplation</a></li>
                    <li><a href="/contemplation/sacred-footsteps" className="release-note-link">Walking Meditation with Christ</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mb-4">
              <h5>September 15, 2024</h5>
              <ul>
                <li>Added Rosary Meditations at <a href="/rosary/joyful" className="release-note-link">Rosary Meditations</a></li>
                <li>Multiple styles of rosary meditations available</li>
                <li>Includes audio-guided options</li>
              </ul>
            </li>
            <li className="mb-4">
              <h5>August 30, 2024</h5>
              <ul>
                <li>Launched Saintly Whispers with initial features:
                  <ul>
                    <li>Daily Gospel Readings</li>
                    <li>Guided Prayer Methods:
                      <ul>
                        <li><a href="/daily/ignatius-prayer" className="release-note-link">Ignatian Contemplation</a></li>
                        <li><a href="/daily/lectio-divina" className="release-note-link">Lectio Divina</a></li>
                        <li><a href="/daily/franciscan-prayer" className="release-note-link">Franciscan Prayer</a></li>
                        <li><a href="/daily/stjohncross-prayer" className="release-note-link">St. John of the Cross Method</a></li>
                        <li><a href="/daily/therese-prayer" className="release-note-link">St. Thérèse's Little Way</a></li>
                      </ul>
                    </li>
                    <li>Customizable meditation lengths</li>
                    <li>Progress tracking</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </Col>
      </Row>

      {/* Get in Touch Section */}
      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Get in Touch</h2>
          <p>
            We value your feedback and would love to hear about your experience with Saintly Whispers. Please reach out with any questions, suggestions, or testimonials.
          </p>
          <p>
            Thank you for choosing Saintly Whispers as your companion in prayer and meditation. May your journey be blessed with the wisdom and grace of the saints.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutPage; 