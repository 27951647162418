import React from 'react';
import DiscussionPlayer from './audio/DiscussionPlayer';
import { PrayerTrackingProvider } from '../contexts/PrayerTrackingContext';

const DiscussionContainer = ({ gospelContent, date }) => {
  const prayerMethod = {
    id: 'contemplativeDiscussion',
    name: 'Contemplative Discussion',
    type: 'daily-discussion'
  };

  return (
    <PrayerTrackingProvider 
      prayerMethod={prayerMethod}
      prayerData={gospelContent}
    >
      <div className="discussion-player-wrapper">
        <h3>Today's Gospel Discussion</h3>
        <DiscussionPlayer
          prayerMethod={prayerMethod}
          gospelContent={gospelContent}
          date={date}
        />
      </div>
    </PrayerTrackingProvider>
  );
};

export default DiscussionContainer;