import { useState, useEffect, useRef, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import api from '../utils/api';
import { API_ENDPOINTS } from '../utils/apiConfig';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { DailyPrayer } from '@shared/types/dailyPrayer';

export const useDailyPrayer = (prayerMethod: PrayerMethod | null, date: string) => {
  const [prayerData, setPrayerData] = useState<DailyPrayer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const apiCallMadeRef = useRef<boolean>(false);

  const fetchPrayer = useCallback(async () => {
    if (!prayerMethod || (prayerMethod.type !== 'daily-prayer' && prayerMethod.type !== 'daily-meditation')) {
      console.log('Skipping fetchPrayer due to invalid prayerMethod');
      return;
    }
    if (apiCallMadeRef.current) {
      console.log('API call already made, skipping');
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const formattedDate = format(parseISO(date), 'yyyy-MM-dd');
      
      // First, try to fetch from Firestore
      const prayerDataRef = doc(firestore, 'dailyPrayers', `${prayerMethod.id}_${formattedDate}`);
      const prayerDataDoc = await getDoc(prayerDataRef);
      
      if (prayerDataDoc.exists()) {
        setPrayerData({ id: prayerDataDoc.id, ...prayerDataDoc.data() } as DailyPrayer);
      } else {
        // If not in Firestore, fetch from API
        const response = await api.get<{ prayer: DailyPrayer }>(API_ENDPOINTS.GENERIC_PRAYER(prayerMethod['api-link'], formattedDate));
        setPrayerData(response.data.prayer);
      }
      
      apiCallMadeRef.current = true;
    } catch (err) {
      console.error('Error in fetchPrayer:', err);
      setError(`Error fetching prayer data: ${err instanceof Error ? err.message : 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  }, [prayerMethod, date]);

  useEffect(() => {
    if (prayerMethod && prayerMethod['api-link'] && date && !apiCallMadeRef.current) {
      fetchPrayer();
    }
    return () => {
      apiCallMadeRef.current = false;
    };
  }, [prayerMethod, date, fetchPrayer]);

  return { prayerData, loading, error };
};
