import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2 className="auth-title">Logged Out Successfully</h2>
        <p>You have been successfully logged out. Redirecting to home page...</p>
      </div>
    </div>
  );
};

export default LogoutSuccess;

