import React, { useState, useCallback, useEffect } from 'react';
import { parseISO, isValid, format, addDays } from 'date-fns';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import api from '../../utils/api';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import { useAudioPrayer } from '../../hooks/useAudioPrayer';

const DailyPrayerDataGeneration = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedMethods, setSelectedMethods] = useState({});
  const [status, setStatus] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [generateScripts, setGenerateScripts] = useState(false);

  const { prayerMethods, loading: methodsLoading, error: methodsError } = usePrayerMethods();
  const { fetchScript } = useAudioPrayer(null, null, null);

  useEffect(() => {
    if (prayerMethods.length > 0) {
      const initialSelectedMethods = prayerMethods
        .filter(method => method.status === 'published' && (method.type === 'daily-meditation' || method.type === 'daily-prayer'))
        .reduce((acc, method) => {
          acc[method.id] = true;
          return acc;
        }, {});
      setSelectedMethods(initialSelectedMethods);
    }
  }, [prayerMethods]);

  const checkAll = () => {
    const updatedMethods = Object.keys(selectedMethods).reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    setSelectedMethods(updatedMethods);
  };
  
  const uncheckAll = () => {
    const updatedMethods = Object.keys(selectedMethods).reduce((acc, id) => {
      acc[id] = false;
      return acc;
    }, {});
    setSelectedMethods(updatedMethods);
  };

  const generateData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setStatus('Processing...');
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    if (!isValid(start) || !isValid(end)) {
      setError('Invalid date range. Please check your input.');
      setIsLoading(false);
      return;
    }

    const selectedMethodIds = Object.entries(selectedMethods)
      .filter(([_, isSelected]) => isSelected)
      .map(([id, _]) => id);

    if (selectedMethodIds.length === 0) {
      setError('Please select at least one prayer method.');
      setIsLoading(false);
      return;
    }

    for (let date = start; date <= end; date = addDays(date, 1)) {
      const formattedDate = format(date, 'yyyy-MM-dd');

      for (const methodId of selectedMethodIds) {
        const method = prayerMethods.find(m => m.id === methodId);
        try {
          setStatus(prevStatus => `${prevStatus}\nGenerating data for ${method.name} on ${formattedDate}...`);
          const response = await api.get(API_ENDPOINTS.GENERIC_PRAYER(method['api-link'], formattedDate));
          setStatus(prevStatus => `${prevStatus}\nSuccessfully generated data for ${method.name} on ${formattedDate}`);
          
          if (generateScripts) {
            setStatus(prevStatus => `${prevStatus}\nGenerating audio script for ${method.name} on ${formattedDate}...`);
            try {
              await fetchScript(method, response.data.prayer, formattedDate);
              setStatus(prevStatus => `${prevStatus}\nSuccessfully generated audio script for ${method.name} on ${formattedDate}`);
            } catch (scriptError) {
              console.error(`Error generating audio script for ${method.name} on ${formattedDate}:`, scriptError);
              setError(prevError => `${prevError ? prevError + '\n' : ''}Error generating audio script for ${method.name} on ${formattedDate}: ${scriptError.message}`);
            }
          }

          // Add a delay to avoid overwhelming the API
          await new Promise(resolve => setTimeout(resolve, 2000));
        } catch (error) {
          console.error(`Error generating data for ${method.name} on ${formattedDate}:`, error);
          setError(prevError => `${prevError ? prevError + '\n' : ''}Error generating data for ${method.name} on ${formattedDate}: ${error.message}`);
        }
      }
    }

    setStatus(prevStatus => `${prevStatus}\nProcessing complete.`);
    setIsLoading(false);
  }, [startDate, endDate, selectedMethods, prayerMethods, generateScripts, fetchScript]);

  if (methodsLoading) return <div>Loading prayer methods...</div>;
  if (methodsError) return <div>Error loading prayer methods: {methodsError}</div>;

  return (
    <div className="container mt-5">
      <h2>Generate Daily Prayer Data</h2>
      <div className="mb-3">
        <label className="form-label">Start Date:
          <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
      </div>
      <div className="mb-3">
        <label className="form-label">End Date:
          <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
      </div>
      <div className="mb-3">
        <h3>Select Prayer Methods:</h3>
        <div className="mb-2">
          <button className="btn btn-sm btn-outline-primary me-2" onClick={checkAll}>Check All</button>
          <button className="btn btn-sm btn-outline-secondary" onClick={uncheckAll}>Uncheck All</button>
        </div>
        {prayerMethods
          .filter(method => method.status === 'published' && (method.type === 'daily-meditation' || method.type === 'daily-prayer'))
          .map((method) => (
            <div key={method.id} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={method.id}
                checked={selectedMethods[method.id] || false}
                onChange={() => setSelectedMethods(prev => ({ ...prev, [method.id]: !prev[method.id] }))}
              />
              <label className="form-check-label" htmlFor={method.id}>{method.name}</label>
            </div>
          ))}
      </div>
      <p>Extra Options</p>
      <div className="mb-3 form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="generateScripts"
          checked={generateScripts}
          onChange={(e) => setGenerateScripts(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="generateScripts">Generate Audio Scripts</label>
      </div>
      <button className="btn btn-primary" onClick={generateData} disabled={isLoading || !startDate || !endDate}>
        {isLoading ? 'Generating...' : 'Generate Data'}
      </button>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
      <pre className="mt-3">{status}</pre>
    </div>
  );
};

export default DailyPrayerDataGeneration;