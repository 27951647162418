export const estimateSpeakingDuration = (text) => {
	const wordsPerMinute = 150; // Average speaking rate
	const wordCount = text.split(/\s+/).length;
	return (wordCount / wordsPerMinute) * 60; // Convert to seconds
};

export const adjustBreakDurations = (script, targetDuration) => {
	const MIN_BREAK_DURATION = 1; // Minimum break duration in seconds

	const parseBreakToSeconds = (breakValue) => {
		if (typeof breakValue === 'number') return breakValue;
		if (!breakValue) return 0;
		
		// If it's just a number string without units
		if (!isNaN(breakValue)) return parseFloat(breakValue);
		
		const match = breakValue.match(/(\d+)(\w)/);
		if (!match) return 0;
		const [value, unit] = match.slice(1);
		const multiplier = { s: 1, m: 60 }[unit.toLowerCase()];
		return parseInt(value) * multiplier;
	};

	const totalSpeakingDuration = script.reduce((sum, item) => {
		return sum + (item.speakingDuration || estimateSpeakingDuration(item.text));
	}, 0);

	const totalBreakDuration = script.reduce((sum, item) => sum + parseBreakToSeconds(item.break), 0);

	const availableBreakTime = targetDuration - totalSpeakingDuration;
	const adjustmentFactor = availableBreakTime / totalBreakDuration;

	const adjustedScript = script.map((item) => {
		const currentBreakSeconds = parseBreakToSeconds(item.break);
		let adjustedBreakSeconds = Math.max(MIN_BREAK_DURATION, currentBreakSeconds * adjustmentFactor);
		
		return {
			...item,
			break: Math.round(adjustedBreakSeconds).toString() + 's'
		};
	});

	const breakDurations = adjustedScript.map(item => parseBreakToSeconds(item.break));
	const totalAdjustedDuration = totalSpeakingDuration + breakDurations.reduce((a, b) => a + b, 0);

	// console.log('Target duration:', targetDuration);
	// console.log('Total speaking duration:', totalSpeakingDuration);
	// console.log('Total adjusted duration:', totalAdjustedDuration);

	// console.group('Break Duration Adjustment');
	// script.forEach((item, index) => {
	// 	console.log(`Item ${index}:`, {
	// 		text: item.text.substring(0, 30) + '...',
	// 		originalBreak: item.break,
	// 		parsedBreak: parseBreakToSeconds(item.break)
	// 	});
	// });
	// console.groupEnd();

	return { adjustedScript, breakDurations, totalAdjustedDuration };
};

export const adjustContemplationBreakDurations = (script, targetDuration) => {
	const MIN_BREAK_DURATION = 1;

	const parseBreakToSeconds = (breakString) => {
		if (typeof breakString === 'number') return breakString;
		if (!breakString) return 0;
		const match = breakString.match(/(\d+)(\w)/);
		if (!match) return 0;
		const [value, unit] = match.slice(1);
		const multiplier = { s: 1, m: 60 }[unit];
		return parseInt(value) * multiplier;
	};

	const totalSpeakingDuration = script.reduce((sum, item) => {
		if (item.speakingDuration) {
			return sum + item.speakingDuration;
		} else {
			const estimatedDuration = estimateSpeakingDuration(item.text);
			console.warn(`Missing speakingDuration for item. Estimated duration: ${estimatedDuration.toFixed(2)} seconds`);
			return sum + estimatedDuration;
		}
	}, 0);

	const totalBaseBreakDuration = script.reduce((sum, item) => sum + parseBreakToSeconds(item.baseBreak), 0);
	const totalExpandableBreakDuration = script.reduce((sum, item) => sum + parseBreakToSeconds(item.expandableBreak), 0);

	const availableBreakTime = targetDuration - totalSpeakingDuration;
	const excessTime = availableBreakTime - totalBaseBreakDuration - totalExpandableBreakDuration;

	const adjustedScript = script.map((item) => {
		const baseBreakSeconds = parseBreakToSeconds(item.baseBreak);
		const expandableBreakSeconds = parseBreakToSeconds(item.expandableBreak);
		
		let adjustedBreakSeconds;
		if (excessTime > 0) {
			const proportion = expandableBreakSeconds / totalExpandableBreakDuration;
			adjustedBreakSeconds = baseBreakSeconds + expandableBreakSeconds + (excessTime * proportion);
		} else {
			const reductionProportion = Math.abs(excessTime) / (totalBaseBreakDuration + totalExpandableBreakDuration);
			adjustedBreakSeconds = Math.max(
				MIN_BREAK_DURATION,
				baseBreakSeconds + expandableBreakSeconds - ((baseBreakSeconds + expandableBreakSeconds) * reductionProportion)
			);
		}

		return {
			...item,
			break: Math.round(adjustedBreakSeconds).toString() + 's'
		};
	});

	const breakDurations = adjustedScript.map(item => parseFloat(item.break));
	const totalAdjustedDuration = totalSpeakingDuration + breakDurations.reduce((a, b) => a + b, 0);

	return { adjustedScript, breakDurations };
};

