import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { RosaryMystery } from '@shared/types/rosaryMystery';

export const useRosaryMystery = (mysteryType: string) => {
  const [mysteryData, setMysteryData] = useState<RosaryMystery | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMysteryData = async () => {
      try {
        const docRef = doc(firestore, 'prayerMethods', mysteryType);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setMysteryData(docSnap.data() as RosaryMystery);
        } else {
          throw new Error('Mystery data not found');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchMysteryData();
  }, [mysteryType]);

  return { mysteryData, loading, error };
};

