import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Tab } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import RecommendationForm from './RecommendationForm';
import RecommendationHistory from './RecommendationHistory';
import { useUserData } from '../../hooks/useUserData';
import styles from '../../styles/recommendationContainer.module.css';
import PrayerPlan from '../plan/PrayerPlan';
import PrayerHistory from '../activity/PrayerHistory';
import RecommendationDisplay from './RecommendationDisplay';
import { useRecommendation } from '../../hooks/useRecommendation';
import SignUpPrompt from '../authentication/SignUpPrompt';
import TabNavigation, { TabItem } from '../common/TabNavigation';

const RecommendationContainer: React.FC = () => {
  const { userData } = useUserData();
  const { recommendation, recommendationHistory, setSelectedRecommendation } = useRecommendation();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || 'advisor';
  const recommendationId = searchParams.get('recommendationId');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (recommendationId && recommendationHistory.length > 0) {
      const historyItem = recommendationHistory.find(item => item.id === recommendationId);
      if (historyItem && !recommendation) {
        setSelectedRecommendation({
          recommendation: historyItem.recommendation
        });
      }
    }
  }, [recommendationId, recommendationHistory, setSelectedRecommendation, recommendation]);

  const handleTabSelect = (key: string | null) => {
    if (key) {
      setSearchParams({ tab: key });
    }
  };

  if (!userData || userData.isAnonymous) {
    return (
      <Container className={styles.container}>
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className={styles.welcomeCard}>
              <Card.Body>
                <h1 className={styles.title}>Personalized Prayer Journey</h1>
                <p className={styles.description}>
                  Get customized scripture recommendations and create your own prayer plan
                </p>
                <div className={styles.signupPrompt}>
                  <SignUpPrompt 
                    message="Sign up to save your recommendations and access your personal prayer plan"
                    variant="button"
                  />
                </div> 
              </Card.Body>
            </Card>
            <RecommendationForm />
          </Col>
        </Row>
      </Container>
    );
  }

  const tabs: TabItem[] = [
    { key: 'advisor', label: 'Prayer Advisor' },
    { key: 'plan', label: 'Prayer Plan' },
    { key: 'history', label: 'Prayer History' }
  ];

  return (
    <Container className={styles.container}>
      <Row>
        <Col lg={8} md={12}>
          <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
            <TabNavigation 
              tabs={tabs}
              activeKey={activeTab}
              onSelect={handleTabSelect}
            />
            <Tab.Content>
              <Tab.Pane eventKey="advisor">
                {recommendation ? (
                  <RecommendationDisplay 
                    recommendation={recommendation}
                    onRetake={() => window.location.href = '/advisor'}
                  />
                ) : (
                  <div>
                    <RecommendationForm />            
                    <Card className="mt-4">
                      <Card.Body>
                    <RecommendationHistory variant="full" />
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="plan">
                <PrayerPlan variant="full" />
              </Tab.Pane>
              <Tab.Pane eventKey="history">
                <PrayerHistory variant="full" />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
        <Col lg={4} md={12}>
          <div className={styles.sidePanel}>
            {activeTab !== 'plan' && (
              <Card className={`mt-3 mt-lg-0 ${styles.sidePanelCard}`}>
                <Card.Body className="p-2 p-sm-3">
                  <PrayerPlan variant="compact" />
                </Card.Body>
              </Card>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RecommendationContainer;
