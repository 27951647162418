import React, { useState, useCallback, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { useSaintsData } from '../../hooks/useSaintsData';
import FilterChips from '../common/FilterChips';
import styles from '../../styles/saintsCatalog.module.css';

const SaintsCatalog = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { saints: allSaints, loading, error } = useSaintsData();
  const [filteredSaints, setFilteredSaints] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    const filters = searchParams.get('filters');
    setActiveFilters(filters ? filters.split(',') : []);
  }, [searchParams]);

  const toggleFilter = useCallback((category) => {
    setActiveFilters(prev => {
      const newFilters = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];
      return newFilters;
    });
  }, []);

  useEffect(() => {
    if (activeFilters.length > 0) {
      setSearchParams({ filters: activeFilters.join(',') });
    } else {
      setSearchParams({});
    }
  }, [activeFilters, setSearchParams]);

  useEffect(() => {
    if (allSaints.length > 0) {
      const publishedSaints = allSaints
        .filter(saint => saint.status === 'published')
        .sort((a, b) => (a.displayOrder || Infinity) - (b.displayOrder || Infinity));
      setFilteredSaints(publishedSaints);
    }
  }, [allSaints]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  const categories = [...new Set(filteredSaints.flatMap(saint => saint.category))];

  const displayedSaints = activeFilters.length > 0
    ? filteredSaints.filter(saint => saint.category.some(cat => activeFilters.includes(cat)))
    : filteredSaints;

  return (
    <div className={styles.saintsCatalog}>
      <Link to="/" className={styles.backHomeLink}>
        <ArrowLeft size={18} className={styles.backIcon} />
        Back to Home
      </Link>
      <h1 className={styles.catalogTitle}>Saints & Religious Figures</h1>
      <FilterChips items={categories} activeItems={activeFilters} onToggle={toggleFilter} />
      <div className={styles.saintsGrid}>
        {displayedSaints.map(saint => (
          <Link 
            key={saint.id} 
            to={`/saints/${saint.id}`}
            className={styles.saintCard}
          >
            <img src={saint.imageUrl} alt={saint.name} className={styles.saintImage} />
            <div className={styles.saintDetails}>
              <h3 className={styles.saintName}>{saint.name}</h3>
              <p className={styles.saintDescription}>{saint.description}</p>
              <span className={styles.saintLink}>
                Learn More <ArrowRight size={16} className={styles.saintIcon} />
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SaintsCatalog;
