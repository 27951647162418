import React from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import api from '../../utils/api';
import { useRelaxationPrayer } from '../../hooks/useRelaxationPlayer';
import LoadingSpinner from '../common/LoadingSpinner';

const RelaxationPlayer = ({ prayerMethod, relaxationPrayer }) => {
  const { script, isLoading, error, fetchScript } = useRelaxationPrayer(prayerMethod, relaxationPrayer);

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
    />
  );

  const title = `Guided Relaxation: ${prayerMethod.name}`;

  if (isLoading) return <LoadingSpinner message="Preparing your relaxation audio..." />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <BaseAudioPlayer
        fetchScript={fetchScript}
        generateAudioEndpoint={api.generateRelaxationAudio}
        renderTranscript={renderTranscript}
        title={title}
        prayerMethod={prayerMethod}
        prayerData={{
          title: title,
          completionType: 'listen',
          prayerType: 'relaxation-prayer'
        }}
        storagePath={STORAGE_PATHS.RELAXATION_AUDIO_PRAYERS(prayerMethod.id)}
      />
    </div>
  );
};

export default RelaxationPlayer;