import { usePrayerMethods } from './usePrayerMethods';
import { useSeriesData } from './useSeriesData';
import { useSaintsData } from './useSaintsData';
import { Saint } from '@shared/types/saints';

// Define section order and metadata
export type SectionConfigKey = 
  | 'daily-meditation'
  | 'daily-prayer'
  | 'series'
  | 'saints'
  | 'relaxation-prayer'
  | 'rosary-meditation'
  | 'daily-reflection'
  | 'contemplative-meditation'
  | 'saints-meditation';

export const SECTION_CONFIG: Record<SectionConfigKey, { order: number; title: string }> = {
  'daily-meditation': {
    order: 1,
    title: 'Daily Gospel Meditations'
  },
  'daily-prayer': {
    order: 2,
    title: 'Daily Gospel Prayers'
  },
  'series': {
    order: 3,
    title: 'Prayer Series & Journeys'
  },
  'saints': {
    order: 4,
    title: 'Saints & Religious Figures'
  },
  'relaxation-prayer': {
    order: 5,
    title: 'Relaxation Prayers'
  },
  'rosary-meditation': {
    order: 6,
    title: 'Rosary Meditations'
  },
  'daily-reflection': {
    order: 7,
    title: 'Daily Reflections'
  },
  'contemplative-meditation': {
    order: 8,
    title: 'Contemplative Meditations'
  },
  'saints-meditation': {
    order: 9,
    title: 'Saints Meditations'
  }
} as const;

export const usePrayerCatalogData = () => {
  const { prayerMethods, loading: methodsLoading, error: methodsError } = usePrayerMethods();
  const { seriesCatalog, loading: seriesLoading, error: seriesError } = useSeriesData();
  const { saints, loading: saintsLoading, error: saintsError } = useSaintsData();

  const loading = methodsLoading || seriesLoading || saintsLoading;
  const error = methodsError || seriesError || saintsError;

  // Filter published saints and sort by display order
  const publishedSaints = saints
    .filter((saint: Saint) => saint.status === 'published')
    .sort((a: Saint, b: Saint) => (a.displayOrder || Infinity) - (b.displayOrder || Infinity));

  return {
    prayerMethods,
    series: seriesCatalog,
    saints: publishedSaints,
    sectionConfig: SECTION_CONFIG,
    loading,
    error
  };
};
