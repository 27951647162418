import React, { useEffect, useState, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Book, Headphones, ChevronDown, ChevronUp, ArrowLeft } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { useSearchParams, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import '../../styles/prayerComponent.css';
import AudioPrayerWithDuration from '../audio/AudioPrayerWithDuration';
import PrayerInstructionComponent from './PrayerInstructionComponent';
import SinglePrayerInstructionComponent from './SinglePrayerInstructionComponent';
import LoadingSpinner from '../common/LoadingSpinner';
import Sharing from '../common/Sharing';
import ErrorMessage from '../common/ErrorMessage';
import { useAudioPrayer } from '../../hooks/useAudioPrayer';
import PrayerMethodsCarousel from '../catalog/PrayerMethodsCarousel';
import DateNavigation from '../common/DateNavigation';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';

const PrayerComponent = ({ 
  prayerData, 
  prayerMethod, 
  loading, 
  error, 
  onDateChange, 
  currentDate, 
  isCustomScripture, 
  allPrayerMethods, 
  prayerMethodsLoading, 
  prayerMethodsError 
}) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'listen');
  const [isGospelExpanded, setIsGospelExpanded] = useState(true);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audioPosition, setAudioPosition] = useState(0);
  const { fetchScript } = useAudioPrayer(prayerMethod, prayerData, currentDate);
  const { logCompletion } = usePrayerTracking();

  const handleCompletion = useCallback(async (completionType) => {
    if (!prayerData || !prayerMethod) return;
    
    try {
      await logCompletion({
        prayerMethod,
        prayerData: {
          title: prayerData.title,
          scriptureReference: prayerData.gospelReference || prayerData.scriptureReference,
          gospelDate: prayerData.date || currentDate,
          completionType,
          prayerType: prayerMethod.type
        },
        completionType,
      });
    } catch (error) {
      console.error('[PrayerComponent] Completion error:', error);
    }
  }, [prayerMethod, prayerData, currentDate, logCompletion]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const toggleGospelExpansion = () => {
    setIsGospelExpanded(!isGospelExpanded);
  };

  const handleDateChangeWrapper = useCallback((direction) => {
    onDateChange(direction);
  }, [onDateChange]);

  useEffect(() => {
    if (prayerData?.date) {
      const newDate = parseISO(prayerData.date);
      setSelectedDate(newDate);
    }
  }, [prayerData]);

  useEffect(() => {
    setSearchParams({ tab: activeTab });
  }, [activeTab, setSearchParams]);

  useEffect(() => {
    setIsGospelExpanded(!isMobile);
  }, [isMobile]);

  console.log('prayerData', prayerData);
  console.log('prayerMethod', prayerMethod);
  console.log('Date values:', {
    currentDate,
    prayerDataDate: prayerData?.date,
    selectedDate: selectedDate.toISOString(),
  });
  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (!prayerData || !prayerMethod) return <div className="d-flex justify-content-center align-items-center vh-100">Loading...</div>;

  const formattedSelectedDate = format(selectedDate, "EEE. MMMM do, yyyy");

  const durationOptions = [
    { value: '10', label: '10 minutes' },
    { value: '15', label: '15 minutes' },
    { value: '20', label: '20 minutes' },
    { value: '30', label: '30 minutes' }
  ];

  const defaultDuration = '10';

  return (
    <div className="prayer-container mt-2 mt-md-3">
      <div className="container-fluid px-1 px-md-4 py-1 py-md-3">
        <div className="row mb-3 mb-md-4">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Link to={`/${format(selectedDate, 'yyyy-MM-dd')}`} className="btn btn-link text-muted d-inline-flex align-items-center">
                <ArrowLeft size={18} className="me-1" />
                Back to Home
              </Link>
              <Sharing 
                url={window.location.href}
                title={`${prayerData.title} - ${prayerMethod.name} - Saintly Whispers`}
                description={`Experience ${prayerMethod.name} with today's meditation: ${prayerData.title} on Saintly Whispers.`}
              />
            </div>
            <div className="d-flex align-items-center mb-3">
              <img src={prayerMethod.imageUrl} alt={prayerMethod.name} className="prayer-method-image me-3" />
              <div>
                <h1 className="h2 mb-1">{prayerData.title}</h1>
                <p className="text-muted mb-0">{prayerMethod.name}</p>
              </div>
            </div>
            <DateNavigation
              selectedDate={selectedDate}
              onPreviousDay={() => handleDateChangeWrapper('prev')}
              onNextDay={() => handleDateChangeWrapper('next')}
              align="left"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 mb-4 mb-lg-0 order-lg-1">
            {prayerMethod.type === 'daily-prayer' && (
              <div className="card mb-4">
                <div className="todays-prayer-banner">
                  <h3 className="todays-prayer-title">Today's Prayer</h3>
                </div>
                <div className="card-body p-2 p-md-4">
                  <p className="daily-prayer-text" style={{ whiteSpace: 'pre-wrap' }}>{prayerData.prayer.replace(/\\n/g, '\n')}</p>
                  <h4 className="affirmation-title">Affirmation</h4>
                  <p className="affirmation-text">{prayerData.affirmation}</p>
                </div>
              </div>
            )}
            <div className="card">
              <div className="guided-meditation-banner">
                <h3 className="guided-meditation-title">Guided Meditation</h3>
              </div>
              <div className="card-header bg-white">
                <ul className="nav nav-tabs card-header-tabs mt-2">
                  <li className="nav-item">
                    <button
                      className={`nav-link ${activeTab === 'listen' ? 'active' : ''}`}
                      onClick={() => handleTabChange('listen')}
                    >
                      <Headphones size={18} className="me-2" />
                      Listen
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${activeTab === 'read' ? 'active' : ''}`}
                      onClick={() => handleTabChange('read')}
                    >
                      <Book size={18} className="me-2" />
                      Read
                    </button>
                  </li>
                </ul>
              </div>
              <div className="card-body px-2 px-md-4 py-sm-0">
                {activeTab === 'listen' && (
                  <div className="audio-prayer-container">
                    <AudioPrayerWithDuration
                      fetchScript={fetchScript}
                      prayerMethod={prayerMethod}
                      prayerData={{
                        ...prayerData,
                        date: prayerData.date || currentDate,
                        gospelDate: prayerData.date || currentDate
                      }}
                      date={prayerData.date || currentDate}
                      durationOptions={durationOptions}
                      defaultDuration={defaultDuration}
                      isCustomScripture={isCustomScripture}
                      completionThreshold={5}
                      onComplete={() => handleCompletion('listen')}
                    />
                  </div>
                )}
                {activeTab === 'read' && (
                  prayerMethod.type === 'daily-prayer' ? (
                    <SinglePrayerInstructionComponent 
                      prayerData={{
                        ...prayerData,
                        gospelDate: prayerData.date || currentDate,
                        scriptureReference: prayerData.gospelReference || prayerData.scriptureReference,
                        title: prayerData.title,
                        prayerType: prayerMethod.type
                      }}
                      prayerMethod={prayerMethod}
                      isCustomScripture={isCustomScripture}
                      onComplete={() => handleCompletion('read')}
                    />
                  ) : (
                    <PrayerInstructionComponent 
                      steps={prayerData.steps}
                      prayerMethod={prayerMethod}
                      prayerData={{
                        ...prayerData,
                        gospelDate: prayerData.date || currentDate,
                        scriptureReference: prayerData.gospelReference || prayerData.scriptureReference,
                        title: prayerData.title,
                        prayerType: prayerMethod.type
                      }}
                      isCustomScripture={isCustomScripture}
                      onComplete={() => handleCompletion('read')}
                    />
                  )
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-5 order-lg-2">
            <div className="card gospel-panel">
              <div className="card-header bg-white d-flex justify-content-between align-items-center" onClick={toggleGospelExpansion}>
                <h3 className="my-1">Today's Gospel: {prayerData.gospelReference}</h3>
                <button className="btn btn-link p-0">
                  {isGospelExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                </button>
              </div>
              {isGospelExpanded && (
                <div className="card-body p-3 p-md-4 gospel-content">
                  <p className="card-text" style={{ whiteSpace: 'pre-wrap' }}>{prayerData.gospelText.replace(/<br\s*\/?>/gi, '\n')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!prayerMethodsLoading && !prayerMethodsError && (
        <div className="prayer-container px-1 px-md-4 mt-4">
          <div className="card">
            <div className="card-body">
              <PrayerMethodsCarousel 
                prayerMethods={allPrayerMethods.filter(method => 
                  (method.type === 'daily-meditation' || method.type === 'daily-prayer') && 
                  method.id !== prayerMethod.id
                )}
                selectedDate={new Date(currentDate)}
                types={['daily-meditation', 'daily-prayer']}
                showAllSections={false}
                title="Other Gospel Meditations"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrayerComponent;
