import { useState, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where, orderBy } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Series } from '@shared/types/series';

export const useSeriesData = () => {
  const [seriesCatalog, setSeriesCatalog] = useState<Series[]>([]);
  const [currentSeries, setCurrentSeries] = useState<Series | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSeriesCatalog = async () => {
      try {
        const q = query(
          collection(firestore, 'seriesCatalog'),
          where('status', '==', 'published'),
          orderBy('displayOrder')
        );
        
        const snapshot = await getDocs(q);
        const series = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Series));
        setSeriesCatalog(series);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Error fetching series catalog');
      } finally {
        setLoading(false);
      }
    };

    fetchSeriesCatalog();
  }, []);

  const fetchSeriesDetails = async (seriesId: string) => {
    try {
      setLoading(true);
      const [catalogDoc, detailsDoc] = await Promise.all([
        getDoc(doc(firestore, 'seriesCatalog', seriesId)),
        getDoc(doc(firestore, 'seriesDetails', seriesId))
      ]);
      
      if (!catalogDoc.exists() || !detailsDoc.exists()) {
        throw new Error(`Series ${seriesId} not found`);
      }
      
      setCurrentSeries({
        ...catalogDoc.data(),
        ...detailsDoc.data(),
        id: seriesId,
      } as Series);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error fetching series details');
    } finally {
      setLoading(false);
    }
  };

  return {
    seriesCatalog,
    currentSeries,
    loading,
    error,
    fetchSeriesDetails
  };
};