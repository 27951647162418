import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { ChevronDown, ChevronUp, BookOpen, HelpCircle, MessageCircle } from 'lucide-react';
import DiscussionContainer from '../DiscussionContainer';
import '../../styles/expandableContainer.css';
import { useNavigate, useLocation } from 'react-router-dom';

interface ExpandableSectionProps {
  title: string;
  icon: ReactNode;
  children?: ReactNode;
  expandedSection?: string;
  sectionId?: string;
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({ 
  title, 
  icon, 
  children, 
  expandedSection, 
  sectionId 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (expandedSection && sectionId && expandedSection === sectionId) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [expandedSection, sectionId]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    if (expandedSection !== undefined && sectionId !== undefined) {
      const searchParams = new URLSearchParams(location.search);
      if (!isExpanded) {
        searchParams.set('expand', sectionId);
      } else {
        searchParams.delete('expand');
      }
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  return (
    <div className="expandable-section mb-3" ref={sectionRef}>
      <button 
        className="expand-button d-flex align-items-center justify-content-between w-100"
        onClick={handleExpand}
      >
        <span className="d-flex align-items-center">
          {icon}
          <span className="ms-2">{title}</span>
        </span>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isExpanded && (
        <div className="expandable-content">
          {children}
        </div>
      )}
    </div>
  );
};

interface AnalysisSection {
  section: string;
  content: string;
  reflectionQuestion: string;
}

interface Analysis {
  analysis: AnalysisSection[];
  questions?: Array<{
    question: string;
    answer: string;
  }>;
}

interface ExpandableContainerProps {
  gospelContent?: string;
  date?: string;
  analysis?: Analysis;
  expandedSection?: string;
}

export const ExpandableContainer = React.forwardRef<HTMLDivElement, ExpandableContainerProps>(
  ({ gospelContent, date, analysis, expandedSection }, ref) => {
    if (!analysis || !analysis.analysis) {
      return null;
    }

    const hasQuestions = analysis.questions && analysis.questions.length > 0;

    return (
      <div className="expandable-discussion-container" ref={ref}>
        <ExpandableSection 
          title="Show Commentary" 
          icon={<BookOpen size={20} />} 
          expandedSection={expandedSection}
          sectionId="commentary"
        >
          <h3>Gospel Commentary</h3>
          {analysis.analysis.map((section, index) => (
            <div key={index} className="analysis-section">
              <h4>{section.section}</h4>
              {section.section === "Key Terms and Concepts" ? (
                <div className="key-terms">
                  {section.content.split('\n').map((line, i) => {
                    if (line.trim() === '') return null;
                    return (
                      <React.Fragment key={i}>
                        <p>
                          {line.split('**').map((part, j) => (
                            j % 2 === 0 ? (
                              <span key={j}>{part}</span>
                            ) : (
                              <strong key={j}>{part}</strong>
                            )
                          ))}
                        </p>
                        {i < section.content.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <p>{section.content}</p>
              )}
              <p className="reflection-question">
                <em>Reflection Question:</em> {section.reflectionQuestion}
              </p>
            </div>
          ))}
        </ExpandableSection>

        {hasQuestions && (
          <ExpandableSection 
            title="People Often Ask" 
            icon={<HelpCircle size={20} />} 
            expandedSection={expandedSection}
            sectionId="questions"
          >
            {analysis.questions?.map((item, index) => (
              <div key={index} className="question-item">
                <h4>{item.question}</h4>
                <p>{item.answer}</p>
              </div>
            ))}
          </ExpandableSection>
        )}

        <ExpandableSection 
          title="Gospel Discussion" 
          icon={<MessageCircle size={20} />} 
          expandedSection={expandedSection}
          sectionId="discussion"
        >
          <DiscussionContainer
            gospelContent={gospelContent}
            date={date}
          />
        </ExpandableSection>
      </div>
    );
  }
);

ExpandableContainer.displayName = 'ExpandableContainer';