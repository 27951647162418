import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Sharing from './Sharing';
import styles from '../../styles/PrayerBanner.module.css';

interface PrayerBannerProps {
  title: string;
  imageUrl: string;
}

const PrayerBanner: React.FC<PrayerBannerProps> = ({
  title,
  imageUrl,
}) => {
  return (
    <>
      <div 
        className={styles.banner} 
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <h1 className={styles.title}>
          <span className={styles.titleBackground}>{title}</span>
        </h1>
      </div>
    </>
  );
};

export default PrayerBanner;