import React from 'react';
import { useParams } from 'react-router-dom';
import { usePrayerMethod } from '../../hooks/usePrayerMethod';
import { useRelaxationPrayer } from '../../hooks/useRelaxationPrayer';
import RelaxationPrayerComponent from './RelaxationPrayerComponent';
import LoadingSpinner from '../common/LoadingSpinner';
import { PrayerTrackingProvider } from '../../contexts/PrayerTrackingContext';
import { PrayerMethod } from '@shared/types/prayerMethod';

interface RelaxationPrayerContainerProps {
  methodLink?: string;
  prayerMethod?: PrayerMethod;
  seriesContext?: {
    seriesId: string;
    itemId: string;
    sectionId: string;
    hideBackLink: boolean;
  };
}

const RelaxationPrayerContainer: React.FC<RelaxationPrayerContainerProps> = ({
  methodLink: propMethodLink,
  prayerMethod: propPrayerMethod,
  seriesContext
}) => {
  console.log('[RelaxationPrayerContainer] seriesContext:', seriesContext);
  const { methodLink: routeMethodLink } = useParams<{ methodLink: string }>();
  const methodLinkToUse = propMethodLink || routeMethodLink || '';
  
  const { prayerMethod, loading: methodLoading, error: methodError } = 
    usePrayerMethod(propPrayerMethod ? null : methodLinkToUse);
  
  const methodToUse = propPrayerMethod || prayerMethod;
  const { relaxationPrayer, loading: prayerLoading, error: prayerError } = useRelaxationPrayer(methodToUse);

  if (methodLoading || prayerLoading) return <LoadingSpinner />;
  if (methodError || prayerError) return <div>Error: {methodError || prayerError}</div>;
  if (!methodToUse || !relaxationPrayer) return <div>No data available</div>;

  const today = new Date().toISOString().split('T')[0];
  
  const trackingData = {
    id: `${methodToUse.id}-${today}`,
    title: `Guided Relaxation: ${methodToUse.name}`,
    type: 'relaxation-prayer',
    gospelReference: '',
    gospelText: '',
    gospelId: '',
    prayerMethodId: methodToUse.id,
    steps: [],
    script: relaxationPrayer.script,
    date: today
  };

  return (
    <PrayerTrackingProvider 
      prayerMethod={methodToUse}
      prayerData={trackingData}
      seriesContext={seriesContext}
    >
      <RelaxationPrayerComponent 
        prayerMethod={methodToUse} 
        relaxationPrayer={relaxationPrayer}
        seriesContext={seriesContext}
      />
    </PrayerTrackingProvider>
  );
};

export default RelaxationPrayerContainer;
