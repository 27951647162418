import { 
  User,
  UserCredential,
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInAnonymously, 
  signOut as firebaseSignOut, 
  linkWithCredential, 
  EmailAuthProvider, 
  sendPasswordResetEmail,
  AuthError,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { doc, setDoc, Timestamp } from 'firebase/firestore';
import { firestore } from '../firebase';
import { AuthResult, AuthSuccessResult, AuthErrorResult } from '@shared/types/auth';
import { format } from 'date-fns';
import { checkRateLimit, RateLimitError } from './rateLimiting';

export { EmailAuthProvider, linkWithCredential } from 'firebase/auth';

export const auth = getAuth();

export const signOut = (): Promise<void> => firebaseSignOut(auth);

export const signUp = async (email: string, password: string): Promise<AuthResult> => {
  try {
    const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
    await createUserDocument(userCredential.user);
    return { 
      success: true, 
      user: userCredential.user,
      error: null,
      message: null
    };
  } catch (error) {
    let errorMessage = "An error occurred during sign up.";
    if ((error as AuthError).code === 'auth/email-already-in-use') {
      errorMessage = "This email is already in use. Please try logging in or use a different email.";
    } else if ((error as AuthError).code === 'auth/weak-password') {
      errorMessage = "Password is too weak. Please use a stronger password.";
    }
    return { 
      success: false, 
      error: errorMessage,
      message: null 
    };
  }
};

export const logIn = async (email: string, password: string): Promise<AuthResult> => {
  try {
    checkRateLimit(email);
    const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
    return { 
      success: true, 
      user: userCredential.user,
      error: null,
      message: null
    };
  } catch (error: unknown) {
    let errorMessage = "Invalid email or password. ";
    
    if (error instanceof RateLimitError) {
      errorMessage = "Too many login attempts. Please try again in 15 minutes or use 'Forgot Password'.";
    } else if (error instanceof Error && (error as AuthError).code === 'auth/user-not-found' || 
        (error as AuthError).code === 'auth/wrong-password') {
      errorMessage += "Please try again or click 'Sign Up' if you need to create an account.";
    }
    
    return { 
      success: false, 
      error: errorMessage,
      message: null
    };
  }
};

export const resetPassword = async (email: string): Promise<AuthResult> => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { 
      success: true, 
      message: "Password reset email sent. Check your inbox.",
      error: null
    };
  } catch (error) {
    let errorMessage = "An error occurred while sending the password reset email.";
    if ((error as AuthError).code === 'auth/user-not-found') {
      errorMessage = "No user found with this email address.";
    }
    return { 
      success: false, 
      error: errorMessage,
      message: null
    };
  }
};

export const signInAnonymouslyUser = async (): Promise<User> => {
  try {
    const userCredential = await signInAnonymously(auth);
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

export const getAuthToken = async (): Promise<string | null> => {
  const user = auth.currentUser;
  if (user) {
    return user.getIdToken();
  }
  return null;
};

export const linkAnonymousAccount = async (user: User, email: string, password: string): Promise<void> => {
  const credential = EmailAuthProvider.credential(email, password);
  try {
    await linkWithCredential(user, credential);
  } catch (error) {
    throw error;
  }
};

export const createUserDocument = async (user: User): Promise<void> => {
  if (!user) return;

  const userRef = doc(firestore, 'users', user.uid);
  const now = Timestamp.now();
  
  try {
    await setDoc(userRef, {
      email: user.email,
      isAnonymous: user.isAnonymous,
      uid: user.uid,
      createdAt: now,
      lastLogin: now,
    }, { merge: true });
  } catch (error) {
    console.error("Error creating user document:", error);
    throw error;
  }
};

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async (): Promise<AuthResult> => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;
    await createUserDocument(user);
    
    return {
      success: true,
      user,
      message: null,
      error: null
    };
  } catch (error) {
    let errorMessage = "Failed to sign in with Google. Please try again.";
    if ((error as AuthError).code === 'auth/popup-closed-by-user') {
      errorMessage = "Sign in was cancelled. Please try again.";
    }
    
    return {
      success: false,
      error: errorMessage,
      message: null
    };
  }
};
