import React from 'react';
import { Book, Heart, Globe, Star, Quote } from 'lucide-react';
import SaintsPlayer from '../audio/SaintsPlayer';

const SaintStory = ({ saintData, backgroundData }) => {
  const {
    lifeStory,
    spiritualityAndTeachings,
    historicalContext,
    impactAndLegacy,
    sayingsAndQuotes,
    famousPrayer
  } = backgroundData;

  return (
    <div>
      <h2 className="saint-story-title">The Story of {saintData.name}</h2>

      <SaintsPlayer
        saintData={saintData}
        saintMeditationData={backgroundData}
        isDiscussion={true}
      />
      <hr className="divider" />
      <section className="life-story-section">
        <h3><Book size={24} className="section-icon" /> Life Story</h3>
        <div className="timeline">
          {lifeStory.map((event, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <h4 className="timeline-year">{event.year}</h4>
                <p>{event.event}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="historical-context-section">
        <h3><Globe size={24} className="section-icon" /> Historical Context</h3>
        <p>{historicalContext}</p>
      </section>

      <section className="spirituality-section">
        <h3><Heart size={24} className="section-icon" /> Spirituality & Teachings</h3>
        <div className="spirituality-grid">
          <div className="spirituality-card">
            <h4>Core Beliefs</h4>
            <ul>
              {spiritualityAndTeachings.coreBeliefs.map((belief, index) => (
                <li key={index}>{belief}</li>
              ))}
            </ul>
          </div>
          <div className="spirituality-card">
            <h4>Key Teachings</h4>
            <ul>
              {spiritualityAndTeachings.keyTeachings.map((teaching, index) => (
                <li key={index}>{teaching}</li>
              ))}
            </ul>
          </div>
          <div className="spirituality-card">
            <h4>Spiritual Practices</h4>
            <ul>
              {spiritualityAndTeachings.spiritualPractices.map((practice, index) => (
                <li key={index}>{practice}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="impact-legacy-section">
        <h3><Star size={24} className="section-icon" /> Impact & Legacy</h3>
        <p>{impactAndLegacy}</p>
      </section>

      <section className="quotes-section">
        <h3><Quote size={24} className="section-icon" /> Sayings & Quotes</h3>
        <div className="quotes-grid">
          {sayingsAndQuotes.map((quote, index) => (
            <blockquote key={index} className="saint-quote">
              "{quote}"
            </blockquote>
          ))}
        </div>
      </section>

      {famousPrayer && (
        <section className="famous-prayer-section">
          <h3>{famousPrayer.title}</h3>
          <pre className="prayer-text">{famousPrayer.text}</pre>
        </section>
      )}
    </div>
  );
};

export default SaintStory;