import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  signUp, 
  logIn, 
  signInAnonymouslyUser, 
  signOut, 
  auth, 
  linkWithCredential, 
  EmailAuthProvider, 
  resetPassword, 
  signInWithGoogle 
} from '../../utils/authentication';
import { useUserData } from '../../hooks/useUserData';
import '../../styles/authentication.css';
import { AuthResult, UserData } from '@shared/types/auth';
import LoadingSpinner from '../common/LoadingSpinner';
import { PASSWORD_REQUIREMENTS, PasswordValidationResult, validatePassword } from '../../utils/validation';

interface AuthComponentProps {
  isModal?: boolean;
  returnUrl?: string;
  onSuccess?: () => void;
}

const AuthComponent: React.FC<AuthComponentProps> = ({ isModal, returnUrl, onSuccess }) => {
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidationResult>({
    isValid: false,
    errors: [],
    strength: 'weak'
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useUserData() as { userData: UserData | null };

  useEffect(() => {
    // Check if the return URL is an admin route
    const isAdminRoute = returnUrl?.startsWith('/admin');
    if (userData && !userData.isAnonymous) {
      if (isAdminRoute && !userData.isAdmin) {
        // Don't show success message for non-admin users trying to access admin routes
        navigate('/', { replace: true });
        return;
      }
      
      if (isModal) {
        setTimeout(() => {
          onSuccess?.();
        }, 1500);
      } else {
        setTimeout(() => {
          navigate(returnUrl || '/', { replace: true });
        }, 1500);
      }
    }
  }, [userData, isModal, returnUrl, navigate, onSuccess]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setLoading(true);
    try {
      let result: AuthResult;
      if (isResetPassword) {
        result = await resetPassword(email);
      } else if (isLogin) {
        result = await logIn(email, password);
      } else {
        result = await signUp(email, password);
      }

      if (result.success) {
        if (isResetPassword) {
          setSuccessMessage(result.message);
        } else {
          if (auth.currentUser && auth.currentUser.isAnonymous) {
            const credential = EmailAuthProvider.credential(email, password);
            await linkWithCredential(auth.currentUser, credential);
          }
          await auth.currentUser?.getIdToken(true);
          
          setSuccessMessage('Successfully logged in!');
          
          if (isModal) {
            setTimeout(() => {
              onSuccess?.();
            }, 1500);
          } else {
            setTimeout(() => {
              navigate(returnUrl || '/');
            }, 1500);
          }
        }
      } else {
        setError(result.error || 'An error occurred');
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const handleAnonymousSignIn = async (): Promise<void> => {
    try {
      await signInAnonymouslyUser();
      
      if (isModal) {
        setTimeout(() => {
          onSuccess?.();
        }, 100);
      } else {
        navigate('/');
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const handleSignOut = async (): Promise<void> => {
    try {
      await signOut();
      await signInAnonymouslyUser();
      navigate('/logout-success');
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const toggleAuthMode = (): void => {
    setIsLogin(!isLogin);
    setIsResetPassword(false);
    setError(null);
    setSuccessMessage(null);
  };

  const toggleResetPassword = (): void => {
    setIsResetPassword(!isResetPassword);
    setError(null);
    setSuccessMessage(null);
  };

  const handleGoogleSignIn = async (): Promise<void> => {
    try {
      const result = await signInWithGoogle();
      if (result.success) {
        navigate('/');
      } else {
        setError(result.error);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!isLogin) {
      setPasswordValidation(validatePassword(newPassword));
    }
  };

  const LoadingOverlay = () => (
    <div className="auth-loading-overlay">
      <LoadingSpinner message="Processing authentication..." />
    </div>
  );

  if (userData && !userData.isAnonymous) {
    return (
      <div className="auth-container">
        <div className="auth-box">
          <h2 className="auth-title">Success!</h2>
          <p>You are now signed in as {userData.email}</p>
          <button 
            onClick={onSuccess} 
            className="auth-button"
          >
            OK
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2 className="auth-title">
          {isResetPassword ? 'Reset Password' : (isLogin ? 'Login' : 'Sign Up')}
        </h2>
        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {!isResetPassword && (
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <div className="password-input-wrapper">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  aria-describedby="password-requirements"
                />
                <button
                  type="button"
                  className="password-toggle"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  aria-label={passwordVisible ? "Hide password" : "Show password"}
                >
                  {passwordVisible ? "Hide" : "Show"}
                </button>
              </div>
              {!isLogin && (
                <div className="password-strength-indicator">
                  <div className={`strength-bar ${passwordValidation.strength}`}>
                    <div className="strength-fill"></div>
                  </div>
                  <span className="strength-text">{passwordValidation.strength}</span>
                  {passwordValidation.errors.length > 0 && (
                    <ul className="password-requirements" id="password-requirements">
                      {passwordValidation.errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          )}
          <button type="submit" className="auth-button" disabled={loading}>
            {loading ? 'Processing...' : (isResetPassword ? 'Reset Password' : (isLogin ? 'Login' : 'Sign Up'))}
          </button>
        </form>
        <button 
          onClick={handleGoogleSignIn} 
          className="gsi-material-button mt-3"
          type="button"
          style={{width: '100%'}}
        >
          <div className="gsi-material-button-state"></div>
          <div className="gsi-material-button-content-wrapper">
            <div className="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{display: 'block'}}>
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span className="gsi-material-button-contents">Sign in with Google</span>
            <span style={{display: 'none'}}>Sign in with Google</span>
          </div>
        </button>
        {!isResetPassword && (
          <button onClick={handleAnonymousSignIn} className="auth-button anonymous">
            Continue as Guest
          </button>
        )}

        <p className="auth-switch">
          {isResetPassword ? (
            <button onClick={toggleResetPassword} className="switch-button">
              Back to Login
            </button>
          ) : (
            <>
              {isLogin ? "Don't have an account? " : "Already have an account? "}
              <button onClick={toggleAuthMode} className="switch-button">
                {isLogin ? 'Sign Up' : 'Login'}
              </button>
            </>
          )}
        </p>
        {isLogin && !isResetPassword && (
          <p className="auth-switch">
            <button onClick={toggleResetPassword} className="switch-button">
              Forgot Password?
            </button>
          </p>
        )}
      </div>
    </div>
  );
};

export default AuthComponent;