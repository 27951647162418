import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useSeriesData } from '../../hooks/useSeriesData';
import CustomScriptureMeditationContainer from '../scripture/CustomScriptureMeditationContainer';
import RelaxationPrayerContainer from '../relaxation/RelaxationPrayerContainer';
import ContemplationMeditationContainer from '../contemplation/ContemplationMeditationContainer';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorMessage from '../common/ErrorMessage';
import styles from '../../styles/SeriesItem.module.css';
import { SeriesItem } from '@shared/types/series';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { 
  CustomScriptureMeditationContainerProps, 
  ScriptureData 
} from '@shared/types/customScripture';
import { RelaxationPrayer, RelaxationPrayerComponentProps } from '@shared/types/relaxationPrayer';
import { DailyReflectionData } from '@shared/types/dailyReflection';
import { usePrayerMethod } from '../../hooks/usePrayerMethod';
import { useCustomScripture } from '../../hooks/useCustomScripture';
import DailyReflectionContainer from '../daily-reflections/DailyReflectionContainer';
import RosaryMeditationContainer from '../rosary/RosaryMeditationContainer';
import SaintMeditationContainer from '../saints/SaintMeditationContainer';
import SeriesIntroduction from './SeriesIntroduction';
import SeriesNavigation from './SeriesNavigation';

interface SeriesContext {
  seriesId: string;
  itemId: string;
  sectionId: string;
  hideBackLink: boolean;
}

const SeriesItemContainer = () => {
  const { seriesId } = useParams();
  
  return <SeriesItemContent />;
};

const SeriesItemContent = () => {
  const { seriesId, itemId } = useParams();
  const navigate = useNavigate();
  const { currentSeries, loading: seriesLoading, error: seriesError, fetchSeriesDetails } = useSeriesData();
  const currentSection = currentSeries?.sections?.find(section => 
    section.items.some(item => item.id === itemId)
  );
  const currentItem = currentSection?.items.find(item => item.id === itemId);
  
  const { prayerMethod: fetchedPrayerMethod, loading: methodLoading, error: methodError } = 
    usePrayerMethod(currentItem?.prayerMethodId || null);

  const { fetchScripturePassage } = useCustomScripture();
  const [completeScriptureData, setCompleteScriptureData] = useState<ScriptureData | null>(null);
  const [scriptureLoading, setScriptureLoading] = useState(true);

  useEffect(() => {
    if (seriesId) {
      fetchSeriesDetails(seriesId);
    }
  }, [seriesId]);

  useEffect(() => {
    const loadCompleteScripture = async () => {
      if (!currentItem?.additionalData?.scripturePassage?.reference) {
        console.log('No scripture reference available');
        setScriptureLoading(false);
        return;
      }

      const reference = currentItem.additionalData.scripturePassage.reference;
      setScriptureLoading(true);
      
      try {
        console.log('Fetching scripture for reference:', reference);
        const fullScripture = await fetchScripturePassage(reference);
        
        if (fullScripture && fullScripture.readings?.[0]) {
          if (fullScripture.text) {
            setCompleteScriptureData({
              citation: reference,
              text: fullScripture.text,
              bookContent: fullScripture.bookContent || null,
              selectedChapter: fullScripture.selectedChapter || '',
              startVerse: fullScripture.startVerse || 0,
              endVerse: fullScripture.endVerse || 0
            } as ScriptureData);
            return;
          }

          const chapterData = fullScripture.bookContent?.data?.find(
            (c: any) => c.number === fullScripture.selectedChapter
          );

          let scriptureText = '';
          if (chapterData && typeof fullScripture.startVerse === 'number' && typeof fullScripture.endVerse === 'number') {
            const verses = chapterData.content.split(/\[(\d+)\]/).filter(Boolean);
            const startIdx = (fullScripture.startVerse * 2) - 2;
            const endIdx = (fullScripture.endVerse * 2);
            scriptureText = verses.slice(startIdx, endIdx).join('').trim();
          }

          setCompleteScriptureData({
            citation: reference,
            text: scriptureText,
            bookContent: fullScripture.bookContent || null,
            selectedChapter: fullScripture.selectedChapter || '',
            startVerse: fullScripture.startVerse || 0,
            endVerse: fullScripture.endVerse || 0
          } as ScriptureData);
        } else {
          console.error('Invalid scripture data received:', fullScripture);
        }
      } catch (error) {
        console.error('Error loading scripture:', error);
      } finally {
        setScriptureLoading(false);
      }
    };

    loadCompleteScripture();
  }, [currentItem?.additionalData?.scripturePassage?.reference, fetchScripturePassage]);

  if (seriesLoading || methodLoading || scriptureLoading) {
    return <LoadingSpinner />;
  }

  if (seriesError) {
    return <ErrorMessage message={seriesError} />;
  }

  if (methodError) {
    return <ErrorMessage message={methodError} />;
  }

  if (!currentSeries?.sections) {
    return <ErrorMessage message="Series data not found" />;
  }

  if (!currentItem) {
    return <ErrorMessage message="Series item not found" />;
  }

  if (!fetchedPrayerMethod) {
    return <ErrorMessage message={`Prayer method not found: ${currentItem.prayerMethodId}`} />;
  }

  const renderPrayerComponent = () => {
    if (!currentItem || !fetchedPrayerMethod) return null;

    const seriesContext = {
      seriesId: seriesId!,
      itemId: itemId!,
      sectionId: currentSection?.id || '',
      hideBackLink: true
    };

    switch (fetchedPrayerMethod.type) {
      case 'rosary-meditation':
        if (!currentItem.additionalData?.rosary) {
          console.error('Missing rosary configuration for rosary meditation');
          return null;
        }
        
        return (
          <RosaryMeditationContainer
            seriesContext={seriesContext}
            mysteryType={fetchedPrayerMethod.id}
            styleId={currentItem.additionalData.rosary.styleId}
          />
        );
      
      case 'relaxation-prayer':
        return (
          <RelaxationPrayerContainer
            prayerMethod={fetchedPrayerMethod}
            seriesContext={seriesContext}
          />
        );

      case 'daily-meditation':
      case 'daily-prayer':
        if (currentItem.additionalData?.scripturePassage) {
          if (!completeScriptureData || scriptureLoading) {
            return <LoadingSpinner />;
          }
          return (
            <CustomScriptureMeditationContainer
              prayerMethod={fetchedPrayerMethod}
              scripturePassage={completeScriptureData}
              customTitle={currentItem.customTitle}
              seriesContext={seriesContext}
            />
          );
        }
        break;

      case 'contemplative-meditation':
        return (
          <ContemplationMeditationContainer
            methodLink={currentItem.prayerMethodId}
            seriesContext={seriesContext}
          />
        );

      case 'daily-reflection':
        return (
          <DailyReflectionContainer
            prayerMethod={fetchedPrayerMethod}
            seriesContext={seriesContext}
          />
        );

      case 'saints-meditation':
        return (
          <SaintMeditationContainer 
            seriesContext={seriesContext}
            saintId={currentItem.additionalData?.saint?.id}
          />
        );

      default:
        console.warn(`Unknown prayer method type: ${fetchedPrayerMethod.type}`);
        return null;
    }
  };

  const findAdjacentItems = () => {
    if (!currentSeries?.sections) return { prevItem: null, nextItem: null };
    
    let prevItem: SeriesItem | null = null;
    let nextItem: SeriesItem | null = null;
    
    const sections = currentSeries.sections;
    
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const itemIndex = section.items.findIndex(item => item.id === itemId);
      
      if (itemIndex !== -1) {
        if (itemIndex > 0) {
          prevItem = section.items[itemIndex - 1];
        } else if (i > 0) {
          const prevSection = sections[i - 1];
          prevItem = prevSection.items[prevSection.items.length - 1];
        }
        
        if (itemIndex < section.items.length - 1) {
          nextItem = section.items[itemIndex + 1];
        } else if (i < sections.length - 1) {
          nextItem = sections[i + 1].items[0];
        }
        
        break;
      }
    }
    
    return { prevItem, nextItem };
  };

  const { prevItem, nextItem } = findAdjacentItems();

  return (
    <div className={styles.container}>
      <SeriesNavigation
        seriesId={seriesId!}
        seriesTitle={currentSeries.title}
        sectionTitle={currentSection?.title}
        prevItem={prevItem}
        nextItem={nextItem}
      />
      <div className={styles.content}>
        <SeriesIntroduction
          title={currentItem.customTitle}
          description={currentItem.customDescription}
          introduction={currentItem.introduction}
        />
        {renderPrayerComponent()}
      </div>
    </div>
  );
};

export default SeriesItemContainer;