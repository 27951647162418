import React from 'react';
import { Nav } from 'react-bootstrap';
import styles from '../../styles/TabNavigation.module.css';

export interface TabItem {
  key: string;
  label: string;
}

interface TabNavigationProps {
  tabs: TabItem[];
  activeKey: string;
  onSelect: (key: string | null) => void;
  className?: string;
}

const TabNavigation: React.FC<TabNavigationProps> = ({
  tabs,
  activeKey,
  onSelect,
  className
}) => {
  return (
    <Nav 
      variant="tabs" 
      className={`${styles.navTabs} ${className || ''}`}
      activeKey={activeKey}
      onSelect={onSelect}
    >
      {tabs.map((tab) => (
        <Nav.Item key={tab.key}>
          <Nav.Link eventKey={tab.key}>{tab.label}</Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default TabNavigation;