import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { useSaintsData } from '../../hooks/useSaintsData';
import { useSaintMeditation } from '../../hooks/useSaintMeditation';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from '../../styles/adminPage.module.css';

const GenerateSaintsData = () => {
  const [saintData, setSaintData] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [step, setStep] = useState(1);
  const { saints, loading, error } = useSaintsData();
  const [editingSaint, setEditingSaint] = useState(null);
  const [displayOrder, setDisplayOrder] = useState(99);
  const [status, setStatus] = useState('draft');
  const [editingSaintId, setEditingSaintId] = useState(null);
  const { meditationExists, loading: meditationLoading } = useSaintMeditation(editingSaintId);

  const parseSaintData = (data) => {
    try {
      return eval(`(${data})`);
    } catch (error) {
      throw new Error(`Invalid saint data format: ${error.message}`);
    }
  };

  const handleGenerateImage = async () => {
    setIsLoading(true);
    setMessage(null);
    try {
      const parsedData = parseSaintData(saintData);
      const response = await api.generateSaintImage({ saint: parsedData });
      setImageUrl(response.data.imageUrl);
      setStep(2);
    } catch (error) {
      setMessage({ type: 'error', content: `Error generating image: ${error.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveImage = async () => {
    setIsLoading(true);
    setMessage(null);
    try {
      const parsedData = parseSaintData(saintData);
      parsedData.displayOrder = displayOrder;
      parsedData.status = status;
      const response = await api.saveSaintData({ saint: parsedData, imageUrl });
      setImageUrl(response.data.imageUrl);
      setMessage({ type: 'success', content: `Saint data saved successfully. Saint ID: ${response.data.saintId}` });
      setStep(3);
    } catch (error) {
      setMessage({ type: 'error', content: `Error saving saint data: ${error.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateMeditation = async () => {
    setIsLoading(true);
    setMessage(null);
    try {
      const parsedData = parseSaintData(saintData);
      const response = await api.generateSaintMeditation({ saint: parsedData });
      setMessage({ type: 'success', content: 'Saint meditation generated successfully' });
    } catch (error) {
      setMessage({ type: 'error', content: `Error generating meditation: ${error.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditSaint = (saint) => {
    setEditingSaint(saint);
    setEditingSaintId(saint.id);
    setSaintData(JSON.stringify(saint, null, 2));
    setDisplayOrder(saint.displayOrder || 99); // Update display order
    setStatus(saint.status || 'draft'); // Update status
    setStep(1);
  };

  const handleUpdateSaint = async () => {
    setIsLoading(true);
    setMessage(null);
    try {
      const parsedData = JSON.parse(saintData);
      parsedData.displayOrder = displayOrder;
      parsedData.status = status;
      const response = await api.updateSaintData({ saint: parsedData });
      setMessage({ type: 'success', content: `Saint data updated successfully. Saint ID: ${response.data.saintId}` });
      setEditingSaint(null);
      setStep(1);
    } catch (error) {
      setMessage({ type: 'error', content: `Error updating saint data: ${error.response?.data?.error || error.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Generate Saint Data and Meditation</h2>
      <div className="card">
        <div className="card-body">
          <form>
            <div className="mb-3">
              <label className="form-label">Saint Data (JavaScript object format)</label>
              <textarea
                className="form-control"
                placeholder="Enter saint data in JavaScript object format"
                value={saintData}
                onChange={(e) => setSaintData(e.target.value)}
                rows={10}
              />
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Display Order</label>
                <input
                  type="number"
                  className="form-control"
                  value={displayOrder}
                  onChange={(e) => setDisplayOrder(parseInt(e.target.value) || 99)}
                  min="1"
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Status</label>
                <select
                  className="form-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="draft">Draft</option>
                  <option value="published">Published</option>
                </select>
              </div>
            </div>
            {step === 1 && (
              <button type="button" className="btn btn-primary btn-lg w-100" onClick={handleGenerateImage} disabled={isLoading}>
                {isLoading ? <><i className="fas fa-spinner fa-spin"></i> Generating...</> : <><i className="fas fa-image"></i> Generate Saint Image</>}
              </button>
            )}
            {step === 2 && (
              <>
                <img src={imageUrl} alt="Generated Saint Image" className="mt-3 img-fluid rounded" />
                <div className="mt-3">
                  <button type="button" className="btn btn-success btn-lg me-2" onClick={handleApproveImage} disabled={isLoading}>
                    <i className="fas fa-check"></i> Approve and Save
                  </button>
                  <button type="button" className="btn btn-secondary btn-lg" onClick={handleGenerateImage} disabled={isLoading}>
                    <i className="fas fa-redo"></i> Try Again
                  </button>
                </div>
              </>
            )}
            {step === 3 && (
              <button type="button" className="btn btn-primary btn-lg w-100" onClick={handleGenerateMeditation} disabled={isLoading}>
                <i className="fas fa-book-reader"></i> Generate Saint Meditation
              </button>
            )}
            {editingSaint && (
              <div className="mt-3">
                <button 
                  type="button" 
                  className="btn btn-primary btn-lg w-100" 
                  onClick={handleUpdateSaint} 
                  disabled={isLoading}
                >
                  <i className="fas fa-save"></i> Update Saint Data
                </button>
                <div className="mt-3 p-3 bg-light rounded">
                  <h5>Meditation Status:</h5>
                  {meditationLoading ? (
                    <p><i className="fas fa-spinner fa-spin"></i> Checking meditation status...</p>
                  ) : (
                    <p>{meditationExists ? <><i className="fas fa-check text-success"></i> Meditation exists</> : <><i className="fas fa-times text-danger"></i> No meditation found</>}</p>
                  )}
                  {!meditationExists && !meditationLoading && (
                    <button 
                      type="button" 
                      className="btn btn-primary btn-lg w-100" 
                      onClick={handleGenerateMeditation} 
                      disabled={isLoading}
                    >
                      <i className="fas fa-plus"></i> Generate Saint Meditation
                    </button>
                  )}
                </div>
              </div>
            )}
            {isLoading && <LoadingSpinner />}
          </form>
        </div>
      </div>
      {message && (
        <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'} mt-3`}>
          {message.content}
        </div>
      )}

      <h3 className="mt-5 mb-3">Existing Saints</h3>
      {loading ? (
        <p><i className="fas fa-spinner fa-spin"></i> Loading saints data...</p>
      ) : error ? (
        <p className="text-danger"><i className="fas fa-exclamation-triangle"></i> {error}</p>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className="table-light">
              <tr>
                <th>Name</th>
                <th>Category</th>
                <th>Feast Day</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {saints.map((saint) => (
                <tr key={saint.id}>
                  <td>{saint.name}</td>
                  <td>{saint.category.join(', ')}</td>
                  <td>{saint.feast_day}</td>
                  <td>
                    <span className={`badge ${saint.status === 'published' ? 'bg-success' : 'bg-secondary'}`}>
                      {saint.status || 'draft'}
                    </span>
                  </td>
                  <td>
                    <button className="btn btn-sm btn-outline-primary" onClick={() => handleEditSaint(saint)}>
                      <i className="fas fa-edit"></i> Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default GenerateSaintsData;
