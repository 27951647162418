import React, { useState } from 'react';
import styles from '../../styles/HomilyInspiration.module.css';
import { HomilyInspiration as HomilyInspirationType } from '@shared/types/homily';

interface HomilyInspirationProps {
  inspiration: HomilyInspirationType;
}

const HomilyInspiration: React.FC<HomilyInspirationProps> = ({ inspiration }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!inspiration) return null;

  return (
    <div className={styles.inspirationSection}>
      <button 
        className={styles.inspirationToggle}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? 'Hide' : 'Show'} Homily Inspiration Guide
      </button>
      
      {isExpanded && (
        <div className={styles.inspirationContent}>
          {/* Key Themes Section */}
          <div className={styles.themeSection}>
            <h4>Key Themes</h4>
            {inspiration.keyThemes.mainThemes.map((theme, index) => (
              <div key={index} className={styles.themeBlock}>
                <h5>{theme.theme}</h5>
                <h6>Modern Parallels</h6>
                <ul>
                  {theme.modernParallels.map((parallel, i) => (
                    <li key={i}>{parallel}</li>
                  ))}
                </ul>
                <h6>Universal Experiences</h6>
                <ul>
                  {theme.universalExperiences.map((exp, i) => (
                    <li key={i}>{exp}</li>
                  ))}
                </ul>
                <h6>Homiletic Angles</h6>
                <ul>
                  {theme.homileticAngles.map((angle, i) => (
                    <li key={i}>{angle}</li>
                  ))}
                </ul>
              </div>
            ))}

            {/* Memorable Quotes */}
            <h4>Memorable Quotes</h4>
            {inspiration.keyThemes.memorableQuotes.map((quote, index) => (
              <div key={index} className={styles.quoteBlock}>
                <blockquote>"{quote.quote}"</blockquote>
                <p><strong>Modern Relevance:</strong> {quote.modernRelevance}</p>
                <h6>Situations</h6>
                <ul>
                  {quote.situations.map((situation, i) => (
                    <li key={i}>{situation}</li>
                  ))}
                </ul>
                <h6>Use in Homily</h6>
                <ul>
                  <li>Opening: {quote.useInHomily.openingHook}</li>
                  <li>Midpoint: {quote.useInHomily.midpointReturn}</li>
                  <li>Conclusion: {quote.useInHomily.conclusion}</li>
                </ul>
              </div>
            ))}
          </div>

          {/* Story Seeds Section */}
          <div className={styles.storySection}>
            <h4>Story Seeds</h4>
            <h5>Personal Experiences</h5>
            {inspiration.storySeeds.personalExperiences.map((exp, index) => (
              <div key={index} className={styles.experienceBlock}>
                <h6>{exp.situation}</h6>
                <p><strong>Development Points:</strong></p>
                <ul>
                  {exp.developmentPoints.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
                <p><strong>Transitions:</strong></p>
                <ul>
                  {exp.transitions.map((transition, i) => (
                    <li key={i}>{transition}</li>
                  ))}
                </ul>
              </div>
            ))}

            <h5>Local Connections</h5>
            <div className={styles.localConnectionsBlock}>
              <h6>Community Examples</h6>
              {inspiration.storySeeds.localConnections.communityExamples.map((example, index) => (
                <div key={index}>
                  <p><strong>{example.example}</strong></p>
                  <p>Relevance: {example.relevance}</p>
                  <p>Use in Homily: {example.useInHomily}</p>
                </div>
              ))}

              <h6>Parish Life</h6>
              {inspiration.storySeeds.localConnections.parishLife.map((item, index) => (
                <div key={index}>
                  <p><strong>{item.situation}</strong></p>
                  <p>Connection: {item.connection}</p>
                  <p>Application: {item.application}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Narrative Structures Section */}
          <div className={styles.narrativeSection}>
            <h4>Narrative Structures</h4>
            <h5>Opening Options</h5>
            {inspiration.narrativeStructures.openingOptions.map((option, index) => (
              <div key={index}>
                <p><strong>Approach:</strong> {option.approach}</p>
                <p><strong>Development:</strong> {option.development}</p>
                <p><strong>Gospel Connection:</strong> {option.gospelConnection}</p>
              </div>
            ))}

            <h5>Conclusions</h5>
            {inspiration.narrativeStructures.conclusions.map((conclusion, index) => (
              <div key={index}>
                <p><strong>Type:</strong> {conclusion.type}</p>
                <h6>Elements:</h6>
                <ul>
                  {conclusion.elements.map((element, i) => (
                    <li key={i}>{element}</li>
                  ))}
                </ul>
                <p><strong>Call to Action:</strong> {conclusion.callToAction}</p>
              </div>
            ))}
          </div>

          {/* Intellectual Connections Section */}
          <div className={styles.intellectualSection}>
            <h4>Intellectual Connections</h4>
            
            <h5>Books</h5>
            {inspiration.intellectualConnections?.books.map((book, index) => (
              <div key={index} className={styles.bookBlock}>
                <h6>{book.title} by {book.author}</h6>
                <p><strong>Relevance:</strong> {book.relevance}</p>
                <blockquote>{book.keyQuote}</blockquote>
                <div className={styles.homileticUse}>
                  <p><strong>Setup:</strong> {book.homileticUse.setup}</p>
                  <p><strong>Development:</strong> {book.homileticUse.development}</p>
                  <p><strong>Application:</strong> {book.homileticUse.application}</p>
                </div>
              </div>
            ))}

            <h5>Thinkers</h5>
            {inspiration.intellectualConnections?.thinkers.map((thinker, index) => (
              <div key={index} className={styles.thinkerBlock}>
                <h6>{thinker.name}</h6>
                <p><strong>Idea:</strong> {thinker.idea}</p>
                <p><strong>Connection:</strong> {thinker.connection}</p>
                <div className={styles.homileticApplication}>
                  <p><strong>Simplification:</strong> {thinker.homileticApplication.simplification}</p>
                  <p><strong>Modernization:</strong> {thinker.homileticApplication.modernization}</p>
                  <p><strong>Practical Use:</strong> {thinker.homileticApplication.practicalUse}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomilyInspiration;