import { useState, useCallback, useEffect, useRef } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useSeriesProgress } from './useSeriesProgress';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { PrayerActivityData, BasePrayerActivityData } from '@shared/types/prayerHistory';
import { logPrayerActivity as logPrayerActivityApi } from '../../utils/api';
import { LogPrayerActivityApiResponse } from '@shared/types/api';
import { 
  PrayerMethodType,
  isCustomScripturePrayer,
  isRosaryPrayer,
  isGospelPrayer,
  DailyGospelRequirements,
  CustomScriptureRequirements,
  RosaryMeditationRequirements,
  DailyReflectionRequirements,
  SimpleTrackingRequirements
} from '../../types/prayerMethodTracking';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';

interface TrackPrayerProgressProps {
  prayerMethod: PrayerMethod;
  prayerData: BasePrayerActivityData;
  completionType: 'read' | 'listen' | 'reflection';
  seriesContext?: {
    seriesId: string;
    itemId: string;
  };
  trigger?: {
    currentStep?: number;
    totalSteps?: number;
    currentTime?: number;
    threshold?: number;
  };
  onComplete?: (prayerHistoryId: string) => void;
  disabled?: boolean;
}

export const usePrayerProgress = ({
  prayerMethod,
  prayerData,
  completionType,
  seriesContext,
  trigger,
  onComplete,
  disabled = false
}: TrackPrayerProgressProps) => {
  const { userData, loading, isAuthChecked } = useUser();
  const [hasCompleted, setHasCompleted] = useState(false);
  const isCompletingRef = useRef(false);
  const { logCompletion } = usePrayerTracking();

  const handleCompletion = useCallback(async () => {
    if (!userData?.uid || !prayerMethod || isCompletingRef.current || hasCompleted) return;
    
    isCompletingRef.current = true;
    
    try {
      const prayerHistoryId = await logCompletion({
        prayerMethod,
        prayerData,
        completionType,
        seriesContext
      });

      if (onComplete) {
        onComplete(prayerHistoryId);
      }

      setHasCompleted(true);
      return prayerHistoryId;
    } catch (error) {
      console.error('Error during completion:', error);
      throw error;
    } finally {
      isCompletingRef.current = false;
    }
  }, [userData?.uid, prayerMethod, prayerData, seriesContext, completionType, hasCompleted, onComplete]);

  useEffect(() => {
    if (loading || disabled || !completionType || hasCompleted) return;

    const shouldComplete = trigger && (
      (trigger.currentStep !== undefined && 
       trigger.totalSteps !== undefined && 
       trigger.currentStep === trigger.totalSteps - 1) ||
      (trigger.currentTime !== undefined && 
       trigger.threshold !== undefined && 
       trigger.currentTime >= trigger.threshold)
    );

    if (shouldComplete && userData?.uid) {
      handleCompletion();
    }
  }, [trigger, hasCompleted, disabled, completionType, userData?.uid, handleCompletion]);

  return {
    hasCompleted,
    markComplete: handleCompletion,
    isAuthChecked,
    isReady: !loading && isAuthChecked && !!userData?.uid
  };
};


