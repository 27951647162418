import React from 'react';
import AuthComponent from './AuthComponent';
import Modal from 'react-bootstrap/Modal';

interface AuthModalProps {
  show: boolean;
  onHide: () => void;
  returnUrl?: string;
}

const AuthModal: React.FC<AuthModalProps> = ({ show, onHide, returnUrl }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      backdrop="static"
      className="auth-modal"
      dialogClassName="modal-dialog-centered"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AuthComponent isModal={true} returnUrl={returnUrl} onSuccess={onHide} />
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
