import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import '../../styles/prayerMethodsCarousel.css';
import { getPrayerMethodRoute } from '../../utils/routeHelpers';
import { PrayerMethod } from '@shared/types/prayerMethod';

interface CarouselSectionProps {
  methods: PrayerMethod[];
  title: string;
  formattedDate: string;
  handlePrayerMethodClick: (method: PrayerMethod, e: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface PrayerMethodsCarouselProps {
  prayerMethods: PrayerMethod[];
  selectedDate: Date;
  types: string[];
  showAllSections?: boolean;
  title?: string;
}

const CarouselSection: React.FC<CarouselSectionProps> = ({ 
  methods, 
  title, 
  formattedDate, 
  handlePrayerMethodClick 
}) => {
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const updateArrows = () => {
    if (carouselRef.current) {
      setShowLeftArrow(carouselRef.current.scrollLeft > 0);
      setShowRightArrow(
        carouselRef.current.scrollLeft < carouselRef.current.scrollWidth - carouselRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    const currentRef = carouselRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', updateArrows);
      const resizeObserver = new ResizeObserver(() => {
        updateArrows();
      });
      resizeObserver.observe(currentRef);

      setTimeout(updateArrows, 100);

      return () => {
        if (currentRef) {
          currentRef.removeEventListener('scroll', updateArrows);
        }
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const container = carouselRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const targetScroll = direction === 'left' 
        ? container.scrollLeft - scrollAmount 
        : container.scrollLeft + scrollAmount;
      
      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="prayer-methods-carousel">
      <h3 className="prayer-methods-carousel-title">{title}</h3>
      <div className="prayer-methods-carousel-container">
        {showLeftArrow && (
          <button className="carousel-arrow left" onClick={() => scroll('left')}>
            <ChevronLeft size={24} />
          </button>
        )}
        <div className="prayer-methods-carousel-list" ref={carouselRef}>
          {methods.map((method, index) => (
            <div key={method.id || index} className="prayer-method-carousel-item">
              <a 
                href={method.type === 'relaxation-prayer' || method.type === 'rosary-meditation' ? `/${method.link}` : `/${method.link}/${formattedDate}`}
                className="prayer-method-carousel-card-link"
                onClick={(e) => handlePrayerMethodClick(method, e)}
              >
                <div className="prayer-method-carousel-card">
                  <img src={method.imageUrl} alt={method.name} className="prayer-method-carousel-image" />
                  <div className="prayer-methods-carousel-details">
                    <h4 className="prayer-method-carousel-name">{method.name}</h4>
                    <p className="prayer-method-carousel-description">{method.description}</p>
                    <span className="prayer-method-carousel-link">
                      Start Prayer <ArrowRight size={16} className="prayer-method-carousel-icon" />
                    </span>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <button className="carousel-arrow right" onClick={() => scroll('right')}>
            <ChevronRight size={24} />
          </button>
        )}
      </div>
    </div>
  );
};

const PrayerMethodsCarousel: React.FC<PrayerMethodsCarouselProps> = ({ 
  prayerMethods, 
  selectedDate, 
  types, 
  showAllSections = true, 
  title = "Other Meditations and Prayers" 
}) => {
  const navigate = useNavigate();
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  const [filteredMethods, setFilteredMethods] = useState<PrayerMethod[]>([]);
  const [rosaryMethods, setRosaryMethods] = useState<PrayerMethod[]>([]);
  const [dailyReflectionMethods, setDailyReflectionMethods] = useState<PrayerMethod[]>([]);
  const [contemplativeMethods, setContemplativeMethods] = useState<PrayerMethod[]>([]);

  useEffect(() => {
    const filtered = prayerMethods.filter(
      method => types.includes(method.type) && method.status === 'published'
    );
    
    if (showAllSections) {
      const sortedNonRosary = filtered
        .filter(method => method.type !== 'rosary-meditation' && method.type !== 'daily-reflection' && method.type !== 'contemplative-meditation')
        .sort((a, b) => a.displayOrder - b.displayOrder);
      
      setFilteredMethods(sortedNonRosary);

      const rosary = filtered.filter(method => method.type === 'rosary-meditation');
      const sortedRosary = sortRosaryMethods(rosary, selectedDate);
      setRosaryMethods(sortedRosary);

      const dailyReflection = filtered.filter(method => method.type === 'daily-reflection');
      setDailyReflectionMethods(dailyReflection);

      const contemplative = filtered.filter(method => method.type === 'contemplative-meditation');
      setContemplativeMethods(contemplative);
    } else {
      setFilteredMethods(filtered.sort((a, b) => a.displayOrder - b.displayOrder));
    }
  }, [prayerMethods, types, selectedDate, showAllSections]);

  const sortRosaryMethods = (methods: PrayerMethod[], date: Date): PrayerMethod[] => {
    const dayOfWeek = format(date, 'EEEE').toLowerCase();
    const orderMap: Record<string, string[]> = {
      monday: ['joyful', 'luminous', 'sorrowful', 'glorious'],
      tuesday: ['sorrowful', 'joyful', 'luminous', 'glorious'],
      wednesday: ['glorious', 'joyful', 'luminous', 'sorrowful'],
      thursday: ['luminous', 'joyful', 'sorrowful', 'glorious'],
      friday: ['sorrowful', 'joyful', 'luminous', 'glorious'],
      saturday: ['joyful', 'luminous', 'sorrowful', 'glorious'],
      sunday: ['glorious', 'joyful', 'luminous', 'sorrowful'],
    };

    const order = orderMap[dayOfWeek];
    return methods.sort((a, b) => {
      const aIndex = order.findIndex(mystery => a.id.toLowerCase().includes(mystery));
      const bIndex = order.findIndex(mystery => b.id.toLowerCase().includes(mystery));
      return aIndex - bIndex;
    });
  };

  const handlePrayerMethodClick = (method: PrayerMethod, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(getPrayerMethodRoute(method, selectedDate));
  };

  return (
    <>
      {showAllSections ? (
        <>
          <CarouselSection 
            methods={filteredMethods} 
            title="Relaxation Prayers and Meditations" 
            formattedDate={formattedDate}
            handlePrayerMethodClick={handlePrayerMethodClick}
          />
          <CarouselSection 
            methods={rosaryMethods} 
            title="Rosary Meditations" 
            formattedDate={formattedDate}
            handlePrayerMethodClick={handlePrayerMethodClick}
          />
          <CarouselSection 
            methods={dailyReflectionMethods} 
            title="Reflect on Your Day" 
            formattedDate={formattedDate}
            handlePrayerMethodClick={handlePrayerMethodClick}
          />
          <CarouselSection 
            methods={contemplativeMethods} 
            title="Contemplative Meditations" 
            formattedDate={formattedDate}
            handlePrayerMethodClick={handlePrayerMethodClick}
          />
        </>
      ) : (
        <CarouselSection 
          methods={filteredMethods} 
          title={title} 
          formattedDate={formattedDate}
          handlePrayerMethodClick={handlePrayerMethodClick}
        />
      )}
    </>
  );
};

export default PrayerMethodsCarousel;
