import React, { useEffect, useRef } from 'react';
import styles from '../../styles/StepperProgress.module.css';

interface StepperProgressProps {
  steps: Array<{ title: string }>;
  currentStep: number;
  onStepClick: (index: number) => void;
}

const StepperProgress: React.FC<StepperProgressProps> = ({ 
  steps, 
  currentStep, 
  onStepClick 
}) => {
  const stepsWrapperRef = useRef<HTMLDivElement>(null);
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (stepRefs.current[currentStep] && stepsWrapperRef.current) {
      const wrapper = stepsWrapperRef.current;
      const activeStep = stepRefs.current[currentStep];
      
      if (activeStep) {
        const scrollLeft = activeStep.offsetLeft - (wrapper.offsetWidth / 2) + (activeStep.offsetWidth / 2);
        wrapper.scrollTo({
          left: scrollLeft,
          behavior: 'smooth'
        });
      }
    }
  }, [currentStep]);

  return (
    <div className={`${styles.stepperProgressContainer} mb-4`}>
      <div className={styles.stepperPreviewBar}>
        <div className={styles.progressLine}></div>
        <div className={styles.stepsWrapper} ref={stepsWrapperRef}>
          {steps.map((step, index) => (
            <div 
              key={index} 
              ref={el => stepRefs.current[index] = el}
              className={`${styles.stepperStep} ${index === currentStep ? styles.active : ''} ${index < currentStep ? styles.completed : ''}`}
              onClick={() => onStepClick(index)}
            >
              <div className={styles.stepDot}></div>
              <div className={styles.stepTitleContainer}>
                <span className={styles.stepTitle}>{step.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepperProgress;
