import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import { useDailyPrayer } from '../../hooks/useDailyPrayer';
import api from '../../utils/api';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import GeneratePostingPlan from './GeneratePostingPlan';

const SocialMediaPosts = () => {
  const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [selectedMethodId, setSelectedMethodId] = useState('');
  const [status, setStatus] = useState('');
  const [publishedMethods, setPublishedMethods] = useState([]);
  const { prayerMethods, loading: methodsLoading, error: methodsError } = usePrayerMethods();
  const selectedMethod = prayerMethods.find(method => method.id === selectedMethodId);
  const { prayerData, loading: prayerLoading, error: prayerError } = useDailyPrayer(selectedMethod, selectedDate);
  const [delayUntil, setDelayUntil] = useState('');

  useEffect(() => {
    if (prayerMethods.length > 0) {
      const filteredMethods = prayerMethods.filter(method => 
        method.status === 'published' && 
        (method.type === 'daily-meditation' || method.type === 'daily-prayer')
      );
      setPublishedMethods(filteredMethods);
    }
  }, [prayerMethods]);

  const handleGeneratePost = async () => {
    if (!selectedMethod || !prayerData) {
      setStatus('Please select a prayer method and ensure prayer data is loaded.');
      return;
    }

    try {
      setStatus('Generating post...');
      const response = await api.post(API_ENDPOINTS.GENERATE_SOCIAL_MEDIA_POST, {
        prayerMethod: selectedMethod,
        prayerData: prayerData
      });
      setStatus(`Post generated and sent to Zapier: ${response.data.message}`);
    } catch (error) {
      setStatus(`Error generating post: ${error.message}`);
    }
  };

  if (methodsLoading) return <div>Loading prayer methods...</div>;
  if (methodsError) return <div>Error loading prayer methods: {methodsError}</div>;

  return (
    <div className="container mt-5">
      <h2>Social Media Post Generation</h2>
      
      <div className="row mt-4">
        <div className="col-md-6">
          <h3>Generate Single Post</h3>
          <div className="mb-3">
            <label className="form-label">
              Date:
              <input
                type="date"
                className="form-control"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Prayer Method:
              <select
                className="form-select"
                value={selectedMethodId}
                onChange={(e) => setSelectedMethodId(e.target.value)}
              >
                <option value="">Select a method</option>
                {publishedMethods.map(method => (
                  <option key={method.id} value={method.id}>{method.name}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="mb-3">
            <label className="form-label">
              Delay Until (optional):
              <input
                type="datetime-local"
                className="form-control"
                value={delayUntil}
                onChange={(e) => setDelayUntil(e.target.value)}
              />
            </label>
          </div>
          <button 
            className="btn btn-primary" 
            onClick={handleGeneratePost} 
            disabled={prayerLoading || !prayerData}
          >
            Generate Single Post
          </button>
        </div>
        
        <div className="col-md-6">
          <GeneratePostingPlan publishedMethods={publishedMethods} setStatus={setStatus} />
        </div>
      </div>

      {prayerLoading && <div className="alert alert-info mt-3">Loading prayer data...</div>}
      {prayerError && <div className="alert alert-danger mt-3">Error loading prayer data: {prayerError}</div>}
      {prayerData && (
        <div className="mt-3">
          <h3>Raw Prayer Data:</h3>
          <pre className="bg-light p-3 rounded">{JSON.stringify(prayerData, null, 2)}</pre>
        </div>
      )}
      
      {status && <div className="alert alert-info mt-3">{status}</div>}
    </div>
  );
};

export default SocialMediaPosts;