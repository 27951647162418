import React from 'react';
import styles from '../../styles/SeriesItem.module.css';

interface SeriesIntroductionProps {
  title: string;
  description?: string;
  introduction?: string;
}

const SeriesIntroduction: React.FC<SeriesIntroductionProps> = ({
  title,
  description,
  introduction
}) => {
  if (!introduction) return null;

  return (
    <div>
    <div className={styles.introduction}>
      <h1 className={styles.introTitle}>{title}</h1>
      {/* {description && <p className={styles.introDescription}>{description}</p>} */}
      <div className={styles.introContent}>
        {introduction.split('\n\n').map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
{/* <hr className="divider mb-0" /> */}
</div>
  );
};

export default SeriesIntroduction;