import React, { useState } from 'react';
import { Share2, MessageCircle, Send, Link, Mail } from 'lucide-react';
import '../../styles/sharing.css';

interface ShareLink {
  name: string;
  icon: React.ReactNode;
  url: string;
}

interface SharingProps {
  url: string;
  title: string;
  description: string;
}

const Sharing: React.FC<SharingProps> = ({ url, title, description }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);

  const shareLinks: ShareLink[] = [
    {
      name: 'Facebook',
      icon: <MessageCircle size={18} />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    },
    {
      name: 'Twitter',
      icon: <Send size={18} />,
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
    },
    {
      name: 'LinkedIn',
      icon: <Link size={18} />,
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}&summary=${encodedDescription}`,
    },
    {
      name: 'Email',
      icon: <Mail size={18} />,
      url: `mailto:?subject=${encodedTitle}&body=${encodedDescription}%0A%0A${encodedUrl}`,
    },
  ];

  const toggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sharing-container">
      <button className="sharing-toggle" onClick={toggleDropdown}>
        <Share2 size={18} />
        <span>Share</span>
      </button>
      {isOpen && (
        <div className="sharing-dropdown">
          {shareLinks.map((link) => (
            <a
              key={link.name}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="sharing-link"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                window.open(link.url, '_blank', 'width=600,height=400');
              }}
            >
              {link.icon}
              <span>{link.name}</span>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sharing;