import { useState, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { useUser } from '../../contexts/UserContext';
import api from '../../utils/api';
import { SeriesProgressData, SeriesProgressUpdateData, SeriesProgress } from '@shared/types/series';
import { AxiosError } from 'axios';

export const useSeriesProgress = (seriesId?: string) => {
  const { userData } = useUser();
  const [progress, setProgress] = useState<SeriesProgress | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProgress = useCallback(async () => {
    if (!userData?.uid || !seriesId) return;
    
    setLoading(true);
    try {
      const progressRef = doc(firestore, 'userActivity', userData.uid, 'seriesProgress', seriesId);
      const progressSnap = await getDoc(progressRef);
      
      if (progressSnap.exists()) {
        setProgress({
          ...progressSnap.data(),
          startedAt: progressSnap.data().startedAt?.toDate(),
          lastActivityAt: progressSnap.data().lastActivityAt?.toDate(),
          completedAt: progressSnap.data().completedAt?.toDate(),
        } as SeriesProgress);
      } else {
        setProgress(null);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error fetching series progress');
    } finally {
      setLoading(false);
    }
  }, [userData?.uid, seriesId]);

  const updateProgress = useCallback(async (data: SeriesProgressUpdateData) => {
    if (!userData?.uid) {
      console.error('[useSeriesProgress] No user ID available');
      return;
    }

    setLoading(true);
    try {
      const progressData: SeriesProgressData = {
        userId: userData.uid,
        ...data
      };
      const response = await api.updateSeriesProgress(progressData);
      await fetchProgress(); // Refresh progress after update
      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        setError(error.response.data?.message || 'Error updating progress');
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }, [userData?.uid, fetchProgress]);

  return {
    progress,
    loading,
    error,
    fetchProgress,
    updateProgress
  };
};
