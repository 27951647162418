import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { usePrayerMethod } from '../../hooks/usePrayerMethod';
import { useDailyReflectionData } from '../../hooks/useDailyReflectionData';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorMessage from '../common/ErrorMessage';
import { ArrowLeft, Calendar, Info } from 'lucide-react';
import styles from '../../styles/dailyReflection.module.css';
import { ExpandableSection } from '../common/ExpandableContainer';
import DailyReflectionIntro from './DailyReflectionIntro';
import DailyReflectionSteps from './DailyReflectionSteps';
import StepNavigation from '../common/StepNavigation';
import '../../styles/stepNavigation.css';
import Sharing from '../common/Sharing';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';
import { DailyReflectionData } from '@shared/types/dailyReflection';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { DailyReflectionTrackingData } from '@shared/types/dailyReflection';
import { PrayerTrackingProvider } from '../../contexts/PrayerTrackingContext';
import ContentHeader from '../common/ContentHeader';

interface DailyReflectionContainerProps {
  methodLink?: string;
  prayerMethod?: PrayerMethod;
  seriesContext?: {
    seriesId: string;
    itemId: string;
    sectionId: string;
    hideBackLink: boolean;
  };
}

const DailyReflectionContainer: React.FC<DailyReflectionContainerProps> = ({ 
  methodLink: propMethodLink,
  prayerMethod: propPrayerMethod,
  seriesContext 
}) => {
  const { methodLink: routeMethodLink } = useParams();
  const methodLinkToUse = propMethodLink || routeMethodLink || '';
  
  const { prayerMethod, loading: methodLoading, error: methodError } = 
    usePrayerMethod(propPrayerMethod ? null : methodLinkToUse);
  
  const methodToUse = propPrayerMethod || prayerMethod;
  const { reflectionData, loading: dataLoading, error: dataError } = useDailyReflectionData(methodToUse);
  
  const [currentDate] = useState<Date>(new Date());
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  if (methodLoading || dataLoading || !methodToUse || !reflectionData) {
    return <LoadingSpinner message="Loading reflection..." />;
  }

  if (methodError || dataError) {
    return <ErrorMessage message={(methodError || dataError || 'An error occurred')} />;
  }

  const trackingData: DailyReflectionTrackingData = {
    id: `${methodToUse.id}-${currentDate.toISOString().split('T')[0]}`,
    title: `${methodToUse.name} Reflection`,
    type: 'daily-reflection',
    prayerMethodId: methodToUse.id,
    prayerMethodTypeId: methodToUse.type,
    date: currentDate.toISOString().split('T')[0],
    steps: reflectionData.steps || [],
    gospelReference: '',
    gospelText: '',
    gospelId: ''
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-US', { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  return (
    <PrayerTrackingProvider
      prayerMethod={methodToUse}
      prayerData={trackingData}
      seriesContext={seriesContext && {
        seriesId: seriesContext.seriesId,
        itemId: seriesContext.itemId,
        sectionId: seriesContext.sectionId
      }}
    >
      <div className={styles.dailyReflectionContainer}>
        <ContentHeader
          backLink="/"
          backText="Back to Home"
          title={`${methodToUse?.name} - Saintly Whispers`}
          description={`Reflect on your day with the ${methodToUse?.name} prayer method on Saintly Whispers.`}
          hideBackLink={seriesContext?.hideBackLink}
          // className="mb-3"
        />
        <div 
          className={styles.dailyReflectionBanner} 
          style={{ backgroundImage: `url(${methodToUse.imageUrl})` }}
        >
          <h1 className={styles.dailyReflectionTitle}>
            <span className={styles.titleBackground}>{methodToUse.name}</span>
          </h1>
        </div>
        <div className={`${styles.dateNavigation} d-flex justify-content-center align-items-center mb-4`}>
          <div className="d-flex align-items-center">
            <Calendar size={24} className="me-2" />
            <span className={styles.selectedDate}>{formatDate(currentDate)}</span>
          </div>
        </div>

        <ExpandableSection title={`Introduction to ${methodToUse.name}`} icon={<Info size={20} />}>
          <DailyReflectionIntro reflectionData={reflectionData} />
        </ExpandableSection>
        <hr className={styles.dailyReflectionDivider} />
        <h2 className={styles.dailyReflectionStepsTitle}>{methodToUse.name} Steps</h2>
        <StepNavigation
          currentIndex={currentStepIndex}
          totalSteps={reflectionData.steps?.length || 0}
          onPrevious={() => setCurrentStepIndex(prev => Math.max(prev - 1, 0))}
          onNext={() => setCurrentStepIndex(prev => Math.min(prev + 1, (reflectionData.steps?.length || 0) - 1))}
          onStepClick={setCurrentStepIndex}
          stepTitle={reflectionData.steps?.[currentStepIndex]?.title || ''}
        />
        <DailyReflectionSteps 
          steps={reflectionData.steps || []}
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          prayerMethod={methodToUse}
        />
      </div>
    </PrayerTrackingProvider>
  );
};

export default DailyReflectionContainer;
