import React from 'react';
import { Timestamp } from 'firebase/firestore';
import { isSameDay, differenceInDays, format } from 'date-fns';
import { PrayerHistoryItem } from '@shared/types/prayerHistory';
import styles from '../../styles/statsOverview.module.css';

interface StatsOverviewProps {
  history: PrayerHistoryItem[];
}

const StatsOverview: React.FC<StatsOverviewProps> = ({ history }) => {
  const getDateFromTimestamp = (timestamp: string | Timestamp): Date => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate();
    }
    return new Date(timestamp);
  };

  const calculateStats = () => {
    if (!history?.length) return { 
      totalPrayers: 0, 
      currentStreak: 0, 
      audioCount: 0, 
      readingCount: 0,
      uniqueDaysPrayed: 0 
    };

    const sortedHistory = [...history].sort((a, b) => {
      const dateA = getDateFromTimestamp(a.timestamp);
      const dateB = getDateFromTimestamp(b.timestamp);
      return dateB.getTime() - dateA.getTime();
    });

    const audioCount = history.filter(prayer => prayer.completionType === 'listen').length;
    const readingCount = history.filter(prayer => prayer.completionType === 'read').length;

    // Get unique dates from history
    const uniqueDates = new Set(
      sortedHistory.map(item => 
        format(getDateFromTimestamp(item.timestamp), 'yyyy-MM-dd')
      )
    );

    const dates = Array.from(uniqueDates).map(date => new Date(date));
    dates.sort((a, b) => b.getTime() - a.getTime()); // Sort descending

    let currentStreak = 0;
    const today = new Date();

    // Check if the most recent prayer was today or yesterday to continue the streak
    if (dates.length > 0) {
      const mostRecentDate = dates[0];
      const daysSinceLastPrayer = differenceInDays(today, mostRecentDate);
      
      if (daysSinceLastPrayer <= 1) {
        currentStreak = 1;
        
        // Count consecutive days
        for (let i = 1; i < dates.length; i++) {
          const currentDate = dates[i];
          const previousDate = dates[i - 1];
          const dayDiff = differenceInDays(previousDate, currentDate);
          
          if (dayDiff === 1) {
            currentStreak++;
          } else {
            break;
          }
        }
      }
    }

    return {
      totalPrayers: history.length,
      currentStreak,
      audioCount,
      readingCount,
      uniqueDaysPrayed: uniqueDates.size
    };
  };

  const stats = calculateStats();

  return (
    <div className={styles.statsContainer}>
      <div className={styles.statCard}>
        <span className={styles.statValue}>{stats.totalPrayers}</span>
        <span className={styles.statLabel}>Total Prayers</span>
      </div>
      <div className={styles.statCard}>
        <span className={styles.statValue}>{stats.uniqueDaysPrayed}</span>
        <span className={styles.statLabel}>Days Prayed</span>
      </div>
      {/* <div className={styles.statCard}>
        <span className={styles.statValue}>{stats.currentStreak}</span>
        <span className={styles.statLabel}>Day Streak</span>
      </div> */}
      <div className={styles.statCard}>
        <span className={styles.statValue}>{stats.audioCount}</span>
        <span className={styles.statLabel}>Audio Prayers</span>
      </div>
      <div className={styles.statCard}>
        <span className={styles.statValue}>{stats.readingCount}</span>
        <span className={styles.statLabel}>Reading Prayers</span>
      </div>
    </div>
  );
};

export default StatsOverview;
