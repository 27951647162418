import React, { useEffect } from 'react';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';
import { RosaryMystery } from '@shared/types/rosaryMystery';
import { RosaryTrackingData } from '@shared/types/rosary';
import { PrayerActivityData } from '@shared/types/prayerHistory';

interface RosaryMeditationProgressProps {
  currentMysteryIndex: number;
  meditationData: RosaryMystery;
}

const RosaryMeditationProgress: React.FC<RosaryMeditationProgressProps> = ({
  currentMysteryIndex,
  meditationData
}) => {
  const { hasCompleted, markComplete } = usePrayerProgress({
    prayerMethod: {
      id: meditationData.prayerMethodId,
      type: 'rosary-meditation',
      name: `${meditationData.mysteryType} Mysteries - ${meditationData.style}`,
      description: '',
      long_description: '',
      imageUrl: '',
      link: '',
      status: 'active',
      'api-link': '',
      promptFile: '',
      schemaFile: '',
      displayOrder: 0
    },
    prayerData: {
      title: `${meditationData.mysteryType} Mysteries - ${meditationData.style} Style`,
      date: new Date().toISOString().split('T')[0],
      metadata: {
        styleId: meditationData.styleId,
        mysteryType: meditationData.mysteryType as 'Joyful' | 'Luminous' | 'Sorrowful' | 'Glorious'
      }
    },
    completionType: 'read',
    trigger: {
      currentStep: currentMysteryIndex + 1,
      totalSteps: meditationData.mysteries.length + 2
    }
  });

  useEffect(() => {
    if (currentMysteryIndex === meditationData.mysteries.length && !hasCompleted) {
      markComplete();
    }
  }, [currentMysteryIndex, meditationData.mysteries.length, hasCompleted, markComplete]);

  return null;
};

export default RosaryMeditationProgress;
