import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ExpandableTextProps } from '@shared/types/expandableText';
import styles from '../../styles/ExpandableText.module.css';
import LoadingSpinner from './LoadingSpinner';
import { formatScriptureVerses } from '../../utils/gospelUtils';

const ExpandableText: React.FC<ExpandableTextProps> = ({
  title,
  content,
  isExpanded: externalIsExpanded,
  onToggleExpand: externalOnToggleExpand,
  maxHeight = '300px',
  className = '',
  variant = 'verses',
  startVerse = 1,
  endVerse,
  isLoading = false
}) => {
  const [internalIsExpanded, setInternalIsExpanded] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  
  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight;
      setContentHeight(height);
    }
  }, [content]);

  const isExpanded = externalIsExpanded ?? internalIsExpanded;
  const onToggleExpand = externalOnToggleExpand ?? (() => setInternalIsExpanded(!internalIsExpanded));

  const formatVersesContent = useCallback((text: string): JSX.Element[] => {
    const formattedVerses = formatScriptureVerses(text, startVerse, endVerse);
    
    return formattedVerses.map(verse => (
      <div key={verse.number} className={styles.verse}>
        <span className={styles.verseNumber}>{verse.number}</span>
        <span className={styles.verseContent}>{verse.content}</span>
      </div>
    ));
  }, [startVerse, endVerse]);

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner message="Loading content..." />;
    }

    if (!content) return null;

    // Handle JSX.Element[] content
    if (Array.isArray(content)) {
      return content;
    }

    // Handle string content
    if (variant === 'verses') {
      return formatVersesContent(content);
    }
    
    // Handle plain text with newlines
    return content.split('\n').map((paragraph: string, index: number) => (
      <p key={index} className={styles.paragraph}>
        {paragraph.trim()}
      </p>
    ));
  };

  const shouldShowButton = content && !isLoading && (
    Array.isArray(content) ? content.length > 3 :
    variant === 'verses' ? content.length > 300 : 
    (content as string).split('\n').length > 3
  );

  console.log('ExpandableText props:', {
    title,
    contentLength: content?.length,
    startVerse,
    endVerse,
    variant,
    isLoading
  });

  return (
    <div className={`${styles.container} ${className}`}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.contentWrapper}>
        <div 
          ref={contentRef}
          className={`${styles.content} ${!isExpanded ? styles.collapsed : ''}`}
          style={{
            '--content-height': `${contentHeight}px`,
            '--collapsed-height': maxHeight,
          } as React.CSSProperties}
        >
          {renderContent()}
        </div>
        {shouldShowButton && !isExpanded && (
          <div className={styles.gradientOverlay}>
            <button 
              className={styles.toggleButton}
              onClick={onToggleExpand}
            >
              Show More
            </button>
          </div>
        )}
        {shouldShowButton && isExpanded && (
          <button 
            className={styles.toggleButton}
            onClick={onToggleExpand}
          >
            Show Less
          </button>
        )}
      </div>
    </div>
  );
};

export default ExpandableText;