import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import LoadingSpinner from '../common/LoadingSpinner';
import { useRosaryMeditationStyles } from '../../hooks/useRosaryMeditationStyles';
import { useRosaryMystery } from '../../hooks/useRosaryMystery';
import { RosaryMeditationStyle } from '@shared/types/rosaryMeditationStyle';
import { RosaryMystery } from '@shared/types/rosaryMystery';
import '../../styles/rosaryMeditationStyleSelector.css';

interface RouteParams extends Record<string, string | undefined> {
  mysteryType: string;
}

const RosaryMeditationStyleSelector: React.FC = () => {
  const { mysteryType } = useParams<RouteParams>();
  const navigate = useNavigate();

  const { 
    styles, 
    loading: stylesLoading, 
    error: stylesError 
  } = useRosaryMeditationStyles();
  
  const { 
    mysteryData, 
    loading: mysteryLoading, 
    error: mysteryError 
  } = useRosaryMystery(mysteryType ?? '');

  const handleStyleSelect = (styleId: string): void => {
    navigate(`/rosary/${mysteryType}/${styleId}`);
  };

  if (stylesLoading || mysteryLoading) return <LoadingSpinner />;
  if (stylesError || mysteryError) return <div className="error-message">Error: {stylesError || mysteryError}</div>;

  return (
    <div className="rosary-style-selector container">
      <Link to="/" className="btn btn-link text-muted mb-3 d-inline-flex align-items-center">
        <ArrowLeft size={18} className="me-1" />
        Back to Home
      </Link>
      {mysteryData && (
        <div className="row mb-4">
          <div className="col-md-12">
            <h2 className="mystery-title">{mysteryData.name}</h2>
            <div className="mystery-image-container">
              <img 
                src={mysteryData.imageUrl} 
                alt={mysteryData.name} 
                className="mystery-image img-fluid" 
              />
            </div>
            <p className="mystery-description mb-4">{mysteryData.long_description}</p>
            <h3 className="style-selection-title">Select a Meditation Style</h3>
            <div className="style-grid">
              {styles?.map((style: RosaryMeditationStyle) => (
                <div
                  key={style.id}
                  className="style-card"
                  onClick={() => handleStyleSelect(style.id)}
                >
                  <img src={style.imageURL} alt={style.name} className="style-image" />
                  <div className="style-info">
                    <h3 className="style-name">{style.name}</h3>
                    <p className="style-description">{style.description}</p>
                    <span className="prayer-method-link">
                      Start Prayer <ArrowRight size={16} className="prayer-method-icon" />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RosaryMeditationStyleSelector;
