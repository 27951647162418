import React from 'react';
import RelaxationPlayer from '../audio/RelaxationPlayer';
import ContentHeader from '../common/ContentHeader';
import '../../styles/prayerComponent.css';
import { RelaxationPrayer } from '@shared/types/relaxationPrayer';
import { PrayerMethod } from '@shared/types/prayerMethod';
import PrayerBanner from '../common/PrayerBanner';

interface RelaxationPrayerComponentProps {
  prayerMethod: PrayerMethod;
  relaxationPrayer: RelaxationPrayer;
  seriesContext?: {
    hideBackLink?: boolean;
  };
}

const RelaxationPrayerComponent: React.FC<RelaxationPrayerComponentProps> = ({ 
  prayerMethod, 
  relaxationPrayer,
  seriesContext
}) => {
  return (
    <div className="relaxation-prayer-container">
      <div className="container-fluid px-1 px-md-4 py-1 py-md-3">
        <ContentHeader
          backLink="/"
          backText="Back to Home"
          title={`${prayerMethod.name} - Saintly Whispers`}
          description={`Experience ${prayerMethod.name}, a relaxation prayer method on Saintly Whispers.`}
          hideBackLink={seriesContext?.hideBackLink}
          className="mb-3"
        />
        <div className="card mb-4">
          <div className="card-body px-1 px-md-4">
            <PrayerBanner
              title={prayerMethod.name}
              imageUrl={prayerMethod.imageUrl}
            />
            <div className="relaxation-prayer-description mb-4">
              <h3 className="h5 d-inline me-2">About this Prayer Method:</h3>
              <p className="d-inline">{prayerMethod.description}</p>
            </div>
            <div className="card">
              <div className="card-body px-1 px-md-4">
                <RelaxationPlayer 
                  prayerMethod={prayerMethod} 
                  relaxationPrayer={relaxationPrayer}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelaxationPrayerComponent;

