import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { format } from 'date-fns';
import { Link, useSearchParams } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import '../../styles/prayersCatalog.css';
import { getPrayerMethodRoute } from '../../utils/routeHelpers';
import FilterChips from '../common/FilterChips';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { Saint } from '@shared/types/saints';
import { usePrayerCatalogData } from '../../hooks/usePrayerCatalogData';
import { SECTION_CONFIG, SectionConfigKey } from '../../hooks/usePrayerCatalogData';

interface TypeHeaders {
  [key: string]: string;
}

interface GroupedMethods {
  [key: string]: PrayerMethod[];
}

const PrayersCatalog: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { prayerMethods, series, saints, loading, error } = usePrayerCatalogData() as {
    prayerMethods: PrayerMethod[];
    series: any[];
    saints: Saint[];
    loading: boolean;
    error: string | null;
  };
  const [activeFilters, setActiveFilters] = useState<string[]>(() => {
    const filters = searchParams.get('filters');
    return filters ? filters.split(',') : [];
  });

  useEffect(() => {
    const filters = searchParams.get('filters');
    setActiveFilters(filters ? filters.split(',') : []);
  }, [searchParams]);

  const toggleFilter = useCallback((type: string) => {
    setActiveFilters(prev => {
      const newFilters = prev.includes(type)
        ? prev.filter(t => t !== type)
        : [...prev, type];
      return newFilters;
    });
  }, []);

  useEffect(() => {
    if (activeFilters.length > 0) {
      setSearchParams({ filters: activeFilters.join(',') });
    } else {
      setSearchParams({});
    }
  }, [activeFilters, setSearchParams]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  const groupedMethods: GroupedMethods = prayerMethods.reduce((acc, method) => {
    if (!acc[method.type]) {
      acc[method.type] = [];
    }
    acc[method.type].push(method);
    return acc;
  }, {} as GroupedMethods);

  const sortedTypes = Object.keys(groupedMethods).sort((a, b) => {
    const orderA = SECTION_CONFIG[a as SectionConfigKey]?.order || Infinity;
    const orderB = SECTION_CONFIG[b as SectionConfigKey]?.order || Infinity;
    return orderA - orderB;
  });

  const typeHeaders: TypeHeaders = {
    'daily-meditation': 'Daily Gospel Meditations',
    'daily-prayer': 'Daily Gospel Prayers',
    'relaxation-prayer': 'Relaxation Prayers',
    'rosary-meditation': 'Rosary Meditations',
    'daily-reflection': 'Daily Reflections',
    'contemplative-meditation': 'Contemplative Meditations',
    'saints-meditation': 'Saints Meditations',
    'series': 'Prayer Series & Journeys',
    'saints': 'Saints & Religious Figures'
  };

  return (
    <div className="prayers-catalog">
      <h1 className="catalog-title">Prayers & Meditations</h1>
      <FilterChips
        items={Object.keys(typeHeaders).map(key => typeHeaders[key])}
        activeItems={activeFilters.map(filter => typeHeaders[filter])}
        onToggle={(displayName: string) => {
          const key = Object.keys(typeHeaders).find(key => typeHeaders[key] === displayName);
          if (key) toggleFilter(key);
        }}
        limit={8}
      />
      {sortedTypes.map(type => (
        activeFilters.length === 0 || activeFilters.includes(type) ? (
          <div key={type} className="prayer-type-section">
            <h2 className="type-header">{typeHeaders[type] || type}</h2>
            <div className="prayer-methods-grid">
              {groupedMethods[type].map(method => (
                <Link 
                  key={method.id} 
                  to={getPrayerMethodRoute(method)}
                  className="prayer-method-card"
                >
                  <img src={method.imageUrl} alt={method.name} className="method-image" />
                  <div className="method-details">
                    <h3 className="method-name">{method.name}</h3>
                    <p className="method-description">{method.description}</p>
                    <span className="method-link">
                      Start Prayer <ArrowRight size={16} className="method-icon" />
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ) : null
      ))}
      {(activeFilters.length === 0 || activeFilters.includes('series')) && (
        <div className="prayer-type-section">
          <h2 className="type-header">{typeHeaders['series']}</h2>
          <div className="prayer-methods-grid">
            {series.map(seriesItem => (
              <Link 
                key={seriesItem.id} 
                to={`/series/${seriesItem.id}`}
                className="prayer-method-card"
              >
                <img src={seriesItem.imageUrl} alt={seriesItem.title} className="method-image" />
                <div className="method-details">
                  <h3 className="method-name">{seriesItem.title}</h3>
                  <p className="method-description">{seriesItem.description}</p>
                  <div className="series-metadata">
                    <span className="duration">{seriesItem.estimatedDuration}</span>
                    {seriesItem.difficulty && (
                      <span className="difficulty">{seriesItem.difficulty}</span>
                    )}
                  </div>
                  <span className="method-link">
                    Start Series <ArrowRight size={16} className="method-icon" />
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
      {(activeFilters.length === 0 || activeFilters.includes('saints')) && (
        <div className="prayer-type-section">
          <h2 className="type-header">{typeHeaders['saints']}</h2>
          <div className="prayer-methods-grid">
            {saints.map(saint => (
              <Link 
                key={saint.id} 
                to={`/saints/${saint.id}`}
                className="prayer-method-card"
              >
                <img src={saint.imageUrl} alt={saint.name} className="method-image" />
                <div className="method-details">
                  <h3 className="method-name">{saint.name}</h3>
                  <p className="method-description">{saint.description}</p>
                  {saint.category && (
                    <div className="series-metadata">
                      {saint.category.map(cat => (
                        <span key={cat} className="difficulty">{cat}</span>
                      ))}
                    </div>
                  )}
                  <span className="method-link">
                    Learn More <ArrowRight size={16} className="method-icon" />
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrayersCatalog;
