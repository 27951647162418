import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { ChevronLeft, ChevronRight, ArrowLeft } from 'lucide-react';
import '../../styles/rosaryMeditation.css';
import LoadingSpinner from '../common/LoadingSpinner';
import rosaryMeditationFactory from '../../utils/rosaryMeditationFactory';
import { FIRESTORE_PATHS } from '../../utils/apiConfig';
import NewStepNavigation from '../common/NewStepNavigation';
import '../../styles/stepNavigation.css';
import Sharing from '../common/Sharing';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';
import { RosaryMystery } from '@shared/types/rosaryMystery';
import { RosaryPrayers } from '@shared/types/rosary';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { PrayerTrackingProvider } from '../../contexts/PrayerTrackingContext';
import RosaryMeditationProgress from './RosaryMeditationProgress';
import { RosaryTrackingData } from '@shared/types/rosary';
import ContentHeader from '../common/ContentHeader';

interface RouteParams extends Record<string, string | undefined> {
  mysteryType: string;
  styleId: string;
}

interface RosaryPrayerMethod extends PrayerMethod {
  type: 'rosary-meditation';
  id: string;
  name: string;
  description: string;
  long_description: string;
  imageUrl: string;
  link: string;
  status: string;
  'api-link': string;
  promptFile: string;
  schemaFile: string;
  displayOrder: number;
}

interface RosaryMeditationContainerProps {
  seriesContext?: {
    seriesId: string;
    itemId: string;
    sectionId: string;
    hideBackLink: boolean;
  };
  mysteryType?: string;
  styleId?: string;
}

const RosaryMeditationContainer: React.FC<RosaryMeditationContainerProps> = ({ 
  seriesContext,
  mysteryType: propMysteryType,
  styleId: propStyleId
}) => {
  const [meditationData, setMeditationData] = useState<RosaryMystery | null>(null);
  const [rosaryPrayers, setRosaryPrayers] = useState<RosaryPrayers | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentMysteryIndex, setCurrentMysteryIndex] = useState<number>(-1);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  
  const params = useParams<RouteParams>();
  const actualMysteryType = propMysteryType || params.mysteryType;
  const actualStyleId = propStyleId || params.styleId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('[RosaryMeditationContainer] Fetching data for mysteryType:', actualMysteryType, 'and styleId:', actualStyleId);
        const meditationDocRef = doc(firestore, FIRESTORE_PATHS.ROSARY_MEDITATIONS.path(actualMysteryType, actualStyleId));
        const prayersDocRef = doc(firestore, 'rosaryPrayers', 'standard');
        
        const [meditationSnap, prayersSnap] = await Promise.all([
          getDoc(meditationDocRef),
          getDoc(prayersDocRef)
        ]);

        if (meditationSnap.exists() && prayersSnap.exists()) {
          setMeditationData(meditationSnap.data() as RosaryMystery);
          setRosaryPrayers(prayersSnap.data() as RosaryPrayers);
        } else {
          throw new Error('Meditation data or prayers not found');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [actualMysteryType, actualStyleId]);

  useEffect(() => {
    setImageLoading(true);
  }, [currentMysteryIndex]);

  const handlePrevMystery = (): void => {
    setCurrentMysteryIndex((prev) => {
      const nextIndex = prev > -1 ? prev - 1 : -1;
      if (nextIndex !== prev) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      return nextIndex;
    });
  };

  const handleNextMystery = (): void => {
    setCurrentMysteryIndex((prev) => {
      const nextIndex = prev < (meditationData?.mysteries.length ?? 0) ? prev + 1 : prev;
      if (nextIndex !== prev) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      return nextIndex;
    });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error-message">Error: {error}</div>;
  if (!meditationData || !rosaryPrayers) return <div>No meditation data available</div>;

  const trackingData: RosaryTrackingData = {
    id: `rosary-${actualMysteryType}-${actualStyleId}-${new Date().toISOString().split('T')[0]}`,
    title: `${meditationData.mysteryType} Mysteries - ${meditationData.style} Style`,
    type: 'rosary-meditation',
    prayerMethodId: meditationData.prayerMethodId,
    prayerMethodTypeId: meditationData.type,
    mysteryType: meditationData.mysteryType,
    style: meditationData.style,
    styleId: meditationData.styleId,
    date: new Date().toISOString().split('T')[0],
    gospelReference: meditationData.mysteries[0]?.scriptureReference ?? '',
    gospelText: meditationData.mysteries[0]?.scripture ?? '',
    gospelId: meditationData.mysteries[0]?.id ?? '',
    steps: meditationData.mysteries.map(mystery => ({
      title: mystery.name,
      description: mystery.textMeditation ?? ''
    }))
  };

  const currentMystery = currentMysteryIndex >= 0 && currentMysteryIndex < meditationData.mysteries.length
    ? meditationData.mysteries[currentMysteryIndex]
    : null;

  return (
    <PrayerTrackingProvider
      prayerMethod={{
        ...meditationData,
        type: 'rosary-meditation'
      }}
      prayerData={trackingData}
      seriesContext={seriesContext}
    >
      <div className="rosary-meditation-container container-fluid px-2 px-md-4 my-3 pt-3">
        <ContentHeader
          backLink={`/rosary/${actualMysteryType}`}
          backText="Back to Styles"
          title={`${meditationData.mysteryType} Mysteries - ${meditationData.style} - Saintly Whispers`}
          description={`Meditate on the ${meditationData.mysteryType} Mysteries of the Rosary with the ${meditationData.style} style on Saintly Whispers.`}
          hideBackLink={seriesContext?.hideBackLink}
        />

        <h2 className="rosary-meditation-title fs-3 fs-md-2">
          {meditationData.mysteryType} Mysteries - {meditationData.style}
        </h2>

        {/* <h3 className="text-center mb-4">
          {currentMysteryIndex === -1 ? "Opening Prayers" : 
           currentMysteryIndex === meditationData.mysteries.length ? "Closing Prayers" :
           currentMystery?.name}
        </h3> */}

        <div className="d-none d-md-block mb-4">
          <NewStepNavigation
            currentStep={currentMysteryIndex + 1}
            totalSteps={meditationData.mysteries.length + 2}
            onPrevious={handlePrevMystery}
            onNext={handleNextMystery}
            onStepClick={(index) => setCurrentMysteryIndex(index - 1)}
            stepTitle={
              currentMysteryIndex === -1 ? "Opening Prayers" : 
              currentMysteryIndex === meditationData.mysteries.length ? "Closing Prayers" :
              currentMystery?.name ?? "Mystery"
            }
            showStepTitles={false}
            scrollToId="rosary-content"
          />
        </div>

        {currentMysteryIndex !== -1 && currentMysteryIndex < meditationData.mysteries.length && (
          <div className="mystery-image-container">
            {imageLoading && <LoadingSpinner />}
            <img
              src={currentMystery?.imageURL}
              alt={currentMystery?.name}
              className={`mystery-image img-fluid ${imageLoading ? 'hidden' : ''}`}
              onLoad={() => setImageLoading(false)}
              onError={() => setImageLoading(false)}
            />
          </div>
        )}

        <div className="rosary-content px-2 p-md-4">
          <div className="read-content">
            {currentMysteryIndex === -1 ? (
              <div className="opening-prayers">
                <h3>Opening Prayers</h3>
                {rosaryPrayers.openingPrayers.map((prayer, index) => (
                  <div key={index} className="prayer">
                    <h4>{prayer.name}</h4>
                    <p>{prayer.text}</p>
                    {prayer.repeat && <p>(Repeat {prayer.repeat} times)</p>}
                  </div>
                ))}
              </div>
            ) : currentMysteryIndex < meditationData.mysteries.length ? (
              currentMystery && React.createElement(
                rosaryMeditationFactory(meditationData.styleId),
                {
                  mysteryMeditationData: currentMystery,
                  allMeditationData: meditationData,
                  rosaryPrayers: rosaryPrayers
                }
              )
            ) : (
              <div className="closing-prayers">
                <h3>Closing Prayers</h3>
                {rosaryPrayers.closingPrayers.map((prayer, index) => (
                  <div key={index} className="prayer">
                    <h4>{prayer.name}</h4>
                    <p>{prayer.text}</p>
                    {prayer.repeat && <p>(Repeat {prayer.repeat} times)</p>}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="d-md-block">
          <NewStepNavigation
            currentStep={currentMysteryIndex + 1}
            totalSteps={meditationData.mysteries.length + 2}
            onPrevious={handlePrevMystery}
            onNext={handleNextMystery}
            onStepClick={(index) => setCurrentMysteryIndex(index - 1)}
            stepTitle={
              currentMysteryIndex === -1 ? "Opening Prayers" : 
              currentMysteryIndex === meditationData.mysteries.length ? "Closing Prayers" :
              currentMystery?.name ?? "Mystery"
            }
            showStepTitles={true}
            scrollToId="rosary-content"
          />
        </div>
      </div>
      <RosaryMeditationProgress 
        currentMysteryIndex={currentMysteryIndex}
        meditationData={meditationData}
      />
    </PrayerTrackingProvider>
  );
};

export default RosaryMeditationContainer; 