import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import { RosaryMeditationStyle } from '@shared/types/rosaryMeditationStyle';

interface UseRosaryMeditationStylesReturn {
  styles: RosaryMeditationStyle[];
  loading: boolean;
  error: string | null;
}

export const useRosaryMeditationStyles = (): UseRosaryMeditationStylesReturn => {
  const [styles, setStyles] = useState<RosaryMeditationStyle[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStyles = async () => {
      try {
        const stylesCollection = collection(firestore, 'rosaryMeditationStyles');
        const stylesSnapshot = await getDocs(stylesCollection);
        const stylesData = stylesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() } as RosaryMeditationStyle))
          .filter(style => style.status === 'published')
          .sort((a, b) => a.displayOrder - b.displayOrder);
        setStyles(stylesData);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchStyles();
  }, []);

  return { styles, loading, error };
};
