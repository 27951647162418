import { useState, useCallback, useEffect, useRef } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import api from '../utils/api';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { DailyPrayer } from '@shared/types/dailyPrayer';

interface AudioScript {
  script: string[];
}

// Track pending requests globally
const pendingRequests = new Map<string, Promise<string[]>>();

export const useAudioPrayer = (
  prayerMethod: PrayerMethod | null,
  prayerData: DailyPrayer | null,
  date: string,
  initialScript: string[] | null
) => {
  const [script, setScript] = useState<string[] | null>(initialScript || null);
  const [isLoading, setIsLoading] = useState(!initialScript);
  const [error, setError] = useState<string | null>(null);
  const requestKeyRef = useRef<string | null>(null);

  const getRequestKey = (methodId: string, date: string) => `${methodId}_${date}`;

  const fetchScript = useCallback(async (
    methodToUse: PrayerMethod | null = prayerMethod,
    dataToUse: DailyPrayer | null = prayerData,
    dateToUse: string = date
  ) => {
    if (!methodToUse || !dataToUse || !dateToUse) {
      throw new Error("Missing required parameters for fetching script");
    }

    const requestKey = getRequestKey(methodToUse.id, dateToUse);
    requestKeyRef.current = requestKey;

    // Return existing request if one is pending
    if (pendingRequests.has(requestKey)) {
      return pendingRequests.get(requestKey);
    }

    setIsLoading(true);
    setError(null);

    const promise = (async () => {
      try {
        const db = getFirestore();
        const scriptRef = doc(db, FIRESTORE_PATHS.DAILY_PRAYERS_SCRIPT.path(methodToUse.id, dateToUse));
        const scriptDoc = await getDoc(scriptRef);

        if (scriptDoc.exists()) {
          const scriptData = scriptDoc.data() as AudioScript;
          return scriptData.script;
        } else {
          const scriptResponse = await api.generateAudioScript({
            prayerMethod: methodToUse,
            prayerContent: dataToUse,
            date: dateToUse || 'default',
            source: 'client'
          });
          return scriptResponse.data.script;
        }
      } finally {
        // Clean up the pending request
        pendingRequests.delete(requestKey);
      }
    })();

    // Store the promise
    pendingRequests.set(requestKey, promise);

    try {
      const result = await promise;
      if (requestKeyRef.current === requestKey) { // Only update state if this is still the current request
        setScript(result);
      }
      return result;
    } catch (err) {
      console.error("Error in fetchScript:", err);
      if (requestKeyRef.current === requestKey) {
        setError(`Error fetching script: ${err instanceof Error ? err.message : 'Unknown error'}`);
      }
      throw err;
    } finally {
      if (requestKeyRef.current === requestKey) {
        setIsLoading(false);
      }
    }
  }, [prayerMethod, prayerData, date]);

  useEffect(() => {
    if (!initialScript && prayerMethod && prayerData) {
      fetchScript().catch(console.error);
    }
  }, [fetchScript, initialScript, prayerMethod, prayerData]);

  return { script, isLoading, error, fetchScript };
};
