import { useState, useCallback, useEffect } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import api from '../utils/api';

export const useSaintsPlayer = (saintId, saintMeditationData, isDiscussion = false) => {
  const [script, setScript] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchScript = useCallback(async () => {
    if (script) return script;

    setIsLoading(true);
    setError(null);

    try {
      if (saintMeditationData && saintMeditationData.audioScript) {
        setScript(saintMeditationData.audioScript);
        return saintMeditationData.audioScript;
      }

      const db = getFirestore();
      const scriptRef = doc(db, FIRESTORE_PATHS.SAINT_MEDITATIONS.path(saintId, isDiscussion));
      const scriptDoc = await getDoc(scriptRef);

      if (scriptDoc.exists()) {
        const fetchedScript = scriptDoc.data().audioScript;
        if (fetchedScript && fetchedScript.length > 0) {
          setScript(fetchedScript);
          return fetchedScript;
        } else {
          throw new Error("Saint meditation script is empty");
        }
      } else {
        throw new Error("Saint meditation script not found");
      }
    } catch (err) {
      setError(`Error fetching script: ${err.message}`);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [script, saintId, saintMeditationData, isDiscussion]);

  const generateAudio = useCallback(async (params) => {
    console.log('isDiscs', isDiscussion)
    return api.generateSaintMeditationAudio({
      saintId: saintId,
      prayerMethod: params.prayerMethod,
      prayerData: params.prayerData,
      script: params.script,
      isDiscussion: isDiscussion,
    });
  }, [saintId, isDiscussion]);

  useEffect(() => {
    fetchScript().catch(console.error);
  }, [fetchScript]);

  return { script, isLoading, error, fetchScript, generateAudio };
};