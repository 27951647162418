import React, { useState } from 'react';
import { format, addDays } from 'date-fns';
import api from '../../utils/api';
import { API_ENDPOINTS } from '../../utils/apiConfig';

const GeneratePostingPlan = ({ publishedMethods, setStatus }) => {
  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(addDays(new Date(), 7), 'yyyy-MM-dd'));
  const [postingPlan, setPostingPlan] = useState(null);
  const [postStatuses, setPostStatuses] = useState({});

  const handleGeneratePostingPlan = async () => {
    if (publishedMethods.length === 0) {
      setStatus('No prayer methods available.');
      return;
    }

    try {
      setStatus('Generating posting plan...');
      const response = await api.generatePostingPlan({
        prayerMethods: publishedMethods,
        startDate,
        endDate
      });
      setPostingPlan(response.data.plan);
      setStatus('Posting plan generated successfully.');
    } catch (error) {
      setStatus(`Error generating posting plan: ${error.message}`);
    }
  };

  const handleApprovePost = async (post) => {
    try {
      setStatus('Scheduling post...');
      await api.post(API_ENDPOINTS.SCHEDULE_SOCIAL_MEDIA_POST, {
        message: post.post.text + '\n\n' + post.post.hashtags.map(tag => `#${tag}`).join(' '),
        link: post.meditationUrl,
        delayUntil: new Date(`${post.date}T${post.time}`).toISOString(),
        imageUrl: post.prayerMethod.imageUrl
      });
      setStatus(`Post scheduled successfully for ${post.date} at ${post.time}`);
      setPostStatuses(prev => ({ ...prev, [`${post.date}-${post.time}`]: 'approved' }));
    } catch (error) {
      setStatus(`Error scheduling post: ${error.message}`);
    }
  };

  const handleRejectPost = (post) => {
    setPostStatuses(prev => ({ ...prev, [`${post.date}-${post.time}`]: 'rejected' }));
    setStatus(`Post for ${post.date} at ${post.time} rejected.`);
  };

  return (
    <div>
      <h3>Generate Posting Plan</h3>
      <div className="mb-3">
        <label className="form-label">
          Start Date:
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
      </div>
      <div className="mb-3">
        <label className="form-label">
          End Date:
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
      </div>
      <button 
        className="btn btn-primary" 
        onClick={handleGeneratePostingPlan}
      >
        Generate Posting Plan
      </button>
      {postingPlan && (
        <div className="mt-4">
          <h4>Generated Posting Plan</h4>
          {postingPlan.map((post, index) => (
            <div key={index} className="card mb-3">
              <div className="card-body">
                <h5 className="card-title">{post.date} at {post.time}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{post.prayerMethod.name}</h6>
                <p className="card-text">{post.post.text}</p>
                <p className="card-text">
                  <small className="text-muted">
                    {post.post.hashtags.map(tag => `#${tag}`).join(' ')}
                  </small>
                </p>
                <p className="card-text">
                  <small className="text-muted">
                    Meditation URL: <a href={post.meditationUrl} target="_blank" rel="noopener noreferrer">{post.meditationUrl}</a>
                  </small>
                </p>
                <button 
                  className="btn btn-primary mr-2" 
                  onClick={() => handleApprovePost(post)}
                  disabled={postStatuses[`${post.date}-${post.time}`]}
                >
                  {postStatuses[`${post.date}-${post.time}`] === 'approved' ? 'Approved' : 'Approve and Schedule'}
                </button>
                <button className="btn btn-secondary"
                  onClick={() => handleRejectPost(post)}
                  disabled={postStatuses[`${post.date}-${post.time}`]}
                >
                  {postStatuses[`${post.date}-${post.time}`] === 'rejected' ? 'Rejected' : 'Reject'}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GeneratePostingPlan;