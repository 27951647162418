import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../styles/about.css';

const Terms: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="about-container">
      <h1 className="text-center my-5">Terms of Service</h1>

      <Row className="mb-5">
        <Col>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using Saintly Whispers, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access the service.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>2. Use of Service</h2>
          <p>
            Saintly Whispers is intended for personal, non-commercial use. You may not use the service for any illegal or unauthorized purpose. You agree to comply with all laws, rules, and regulations applicable to your use of the service.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>3. Content</h2>
          <p>
            Our service allows you to access various prayer methods, meditations, and spiritual content. The content is provided for informational and devotional purposes only and should not be considered as official Catholic doctrine or teaching.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>4. User Accounts</h2>
          <p>
            When you create an account with us, you must provide accurate and complete information. You are solely responsible for the activity that occurs on your account, and you must keep your account password secure.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>5. Intellectual Property</h2>
          <p>
            The service and its original content, features, and functionality are owned by Saintly Whispers and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>6. Termination</h2>
          <p>
            We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>7. Limitation of Liability</h2>
          <p>
            In no event shall Saintly Whispers, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>8. Changes to Terms</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms.
          </p>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at [contact email].
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
