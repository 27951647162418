import React from 'react';
import bibleBooks from '../data/bible_books.json';
import bookNormalization from '../data/book_normalization.json';

// Add type definition for the normalization map
type BookNormalizationMap = {
  [key: string]: string;
};

// Assert the type of the imported JSON
const normalizations = bookNormalization as BookNormalizationMap;

export const parseScriptureCitation = (citation: string) => {
  // Updated regex to handle numbered books like "1 Corinthians"
  const regex = /^(\d*\s*\w+)\s+(\d+):(\d+)(?:-(\d+))?$/;
  const match = citation.match(regex);
  
  if (!match) {
    console.error('Failed to parse citation:', citation);
    throw new Error('Invalid scripture citation format');
  }
  
  const [, book, chapter, startVerse, endVerse = startVerse] = match;
  
  return {
    book: book.trim(),
    chapter,
    startVerse,
    endVerse
  };
};

export const formatVersesWithBrackets = (text: string, startVerse: number, endVerse: number): JSX.Element[] | null => {
  if (!text) {
    console.log('No text provided');
    return null;
  }
  
  // Split by verse numbers in brackets, keeping the delimiters
  const verses = text.split(/(\[\d+\])/).filter(Boolean);
  const formattedVerses: JSX.Element[] = [];
  let currentVerseNumber = 0;
  let currentVerseContent = '';
  
  for (let i = 0; i < verses.length; i++) {
    const part = verses[i].trim();
    const verseNumberMatch = part.match(/\[(\d+)\]/);
    
    if (verseNumberMatch) {
      // If we have content from previous verse, add it
      if (currentVerseNumber > 0 && currentVerseContent) {
        const shouldIncludeVerse = 
          (startVerse === 0 || currentVerseNumber >= startVerse) && 
          (endVerse === 0 || currentVerseNumber <= endVerse);
          
        if (shouldIncludeVerse) {
          formattedVerses.push(
            React.createElement('div', { key: currentVerseNumber, className: 'csm-verse' },
              React.createElement('span', { className: 'csm-verse-number' }, currentVerseNumber),
              React.createElement('span', { className: 'csm-verse-content' }, currentVerseContent.trim())
            )
          );
        }
      }
      currentVerseNumber = parseInt(verseNumberMatch[1]);
      currentVerseContent = '';
    } else {
      currentVerseContent += ' ' + part;
    }
  }
  
  // Add the last verse if it exists
  if (currentVerseNumber > 0 && currentVerseContent) {
    const shouldIncludeVerse = 
      (startVerse === 0 || currentVerseNumber >= startVerse) && 
      (endVerse === 0 || currentVerseNumber <= endVerse);
    
    if (shouldIncludeVerse) {
      formattedVerses.push(
        React.createElement('div', { key: currentVerseNumber, className: 'csm-verse' },
          React.createElement('span', { className: 'csm-verse-number' }, currentVerseNumber),
          React.createElement('span', { className: 'csm-verse-content' }, currentVerseContent.trim())
        )
      );
    }
  }
  
  return formattedVerses;
};

export const formatVersesWithNumbers = (text: string, startVerse: number, endVerse: number): JSX.Element[] | null => {
  if (!text) return null;
  
  // Split text into lines and clean up
  const lines = text.split('\n').map(line => line.trim()).filter(Boolean);
  const formattedVerses: JSX.Element[] = [];
  
  let currentVerseNumber = 0;
  let currentVerseContent = '';
  
  for (let line of lines) {
    // Look for verse numbers at start or within the line
    const verseMatch = line.match(/\s*(\d+)\s+(.+)/);
    
    if (verseMatch) {
      // If we have previous verse content, add it
      if (currentVerseNumber > 0 && currentVerseContent) {
        if (currentVerseNumber >= startVerse && currentVerseNumber <= endVerse) {
          formattedVerses.push(
            React.createElement('div', { key: currentVerseNumber, className: 'csm-verse' },
              React.createElement('span', { className: 'csm-verse-number' }, currentVerseNumber),
              React.createElement('span', { className: 'csm-verse-content' }, currentVerseContent.trim())
            )
          );
        }
      }
      
      currentVerseNumber = parseInt(verseMatch[1]);
      currentVerseContent = verseMatch[2];
    } else {
      // If no verse number found, append to current verse content
      currentVerseContent += ' ' + line;
    }
  }
  
  // Add the last verse if it exists
  if (currentVerseNumber > 0 && currentVerseContent) {
    if (currentVerseNumber >= startVerse && currentVerseNumber <= endVerse) {
      formattedVerses.push(
        React.createElement('div', { key: currentVerseNumber, className: 'csm-verse' },
          React.createElement('span', { className: 'csm-verse-number' }, currentVerseNumber),
          React.createElement('span', { className: 'csm-verse-content' }, currentVerseContent.trim())
        )
      );
    }
  }
  
  return formattedVerses;
};

export const formatVerses = (text: string, selectedChapter: string, startVerse: number, endVerse: number): JSX.Element[] | null => {
  if (!text) return null;
  return text.includes('[') 
    ? formatVersesWithBrackets(text, startVerse, endVerse)
    : formatVersesWithNumbers(text, startVerse, endVerse);
};

export const getVerseCount = (content: string): number => {
  if (!content) return 0;
  const matches = content.match(/\[(\d+)\]/g);
  if (!matches) return 0;
  const verseNumbers = matches.map(m => parseInt(m.replace(/[\[\]]/g, '')));
  return Math.max(...verseNumbers);
};

export const extractVerseRange = (content: string, startVerse: string, endVerse: string): string => {
  if (!content) return '';
  
  // Split by verse markers and filter out empty strings
  const verses = content.split(/(\[\d+\])/).filter(Boolean);
  let scriptureText = '';
  let isCollectingVerse = false;
  let currentVerseNum = 0;

  for (let i = 0; i < verses.length; i++) {
    const part = verses[i];
    const verseMatch = part.match(/\[(\d+)\]/);
    
    if (verseMatch) {
      currentVerseNum = parseInt(verseMatch[1]);
      isCollectingVerse = currentVerseNum >= parseInt(startVerse) && 
                         currentVerseNum <= parseInt(endVerse);
    } else if (isCollectingVerse) {
      scriptureText += `[${currentVerseNum}]${part}`;
    }
  }

  return scriptureText.trim();
};

export const getOfficialBookNames = (): string[] => {
  return bibleBooks.data.map(book => book.name);
};

export const getOfficialBookData = (bookName: string) => {
  return bibleBooks.data.find(book => 
    book.name.toLowerCase() === bookName.toLowerCase() ||
    book.abbreviation.toLowerCase() === bookName.toLowerCase()
  );
};

const normalizeBookNameFallback = (bookName: string): string => {
  const normalized = bookName.toLowerCase().trim();
  return normalizations[normalized] || bookName;
};

export const validateBookName = (bookName: string): string | null => {
  // First try direct match
  let bookData = getOfficialBookData(bookName);
  
  // If not found, try normalizing and checking again
  if (!bookData) {
    const normalizedName = normalizeBookNameFallback(bookName);
    bookData = getOfficialBookData(normalizedName);
  }
  
  return bookData ? bookData.name : null;
};
