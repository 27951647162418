import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface StepNavigationProps {
  currentIndex: number;
  totalSteps: number;
  onPrevious: () => void;
  onNext: () => void;
  onStepClick: (index: number) => void;
  stepTitle: string;
}

const StepNavigation: React.FC<StepNavigationProps> = ({
  currentIndex,
  totalSteps,
  onPrevious,
  onNext,
  onStepClick,
  stepTitle,
}) => {
  return (
    <div className="step-navigation d-flex flex-column align-items-center">
      <h2 className="current-step-title text-center mb-3 fs-4 fs-md-3">
        {stepTitle}
      </h2>
      <div className="d-flex justify-content-between w-100">
        <button onClick={onPrevious} disabled={currentIndex === 0} className="btn btn-primary btn-sm">
          <ChevronLeft /> Previous
        </button>
        <div className="step-progress d-flex justify-content-center flex-wrap">
          {Array.from({ length: totalSteps }, (_, index) => (
            <div
              key={index}
              className={`step-dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => onStepClick(index)}
            />
          ))}
        </div>
        <button onClick={onNext} disabled={currentIndex === totalSteps - 1} className="btn btn-primary btn-sm">
          Next <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export const FlexibleStepNavigation: React.FC<StepNavigationProps> = ({
  currentIndex,
  totalSteps,
  onPrevious,
  onNext,
  onStepClick,
  stepTitle,
}) => {
  return (
    <div className="flexible-step-navigation d-flex flex-column align-items-center">
      <h2 className="current-step-title text-center mb-3 fs-4 fs-md-3">
        {stepTitle}
      </h2>
      <div className="d-flex justify-content-between w-100">
        <button onClick={onPrevious} disabled={currentIndex === 0} className="btn btn-primary btn-sm">
          <ChevronLeft /> Previous
        </button>
        <div className="step-progress d-flex justify-content-center flex-wrap">
          {Array.from({ length: totalSteps }, (_, index) => (
            <div
              key={index}
              className={`step-dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => onStepClick(index)}
            />
          ))}
        </div>
        <button onClick={onNext} disabled={currentIndex === totalSteps - 1} className="btn btn-primary btn-sm">
          Next <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default StepNavigation;
