import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/recommendationDisplay.module.css';
import { RecommendationResponse, UserAnswers } from '@shared/types/recommendation';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import recommendationQuestions from '../../data/recommendation_question.json';
import { getPrayerMethodRoute } from '../../utils/routeHelpers';
import { Lightbulb } from 'lucide-react';
import SignUpPrompt from '../authentication/SignUpPrompt';
import { useUserData } from '../../hooks/useUserData';
import StartButton from '../common/StartButton';
import { usePrayerPlan } from '../../hooks/usePrayerPlan';
import AddToPlan from '../plan/AddToPlan';

// Add interface for the question type
interface Question {
  id: string;
  question: string;
  type: string;
  purpose: string;
  options: Array<{
    id: string;
    label: string;
    themes?: string[];
    suitableMethods?: string[];
  }>;
}

interface RecommendationDisplayProps {
  recommendation: RecommendationResponse | null;
  userAnswers?: UserAnswers;
  onRetake?: () => void;
}

const RecommendationDisplay: React.FC<RecommendationDisplayProps> = ({ 
  recommendation, 
  userAnswers,
  onRetake 
}) => {
  const navigate = useNavigate();
  const { prayerMethods, loading } = usePrayerMethods();
  const { userData } = useUserData();
  const { addToPlan } = usePrayerPlan();

  if (!recommendation) return <div>Loading recommendation...</div>;
  if (!recommendation.recommendation.primaryScripture) return <div>Error: Incomplete recommendation data</div>;

  const { primaryScripture, alternateScriptures, alternatePrayerMethods, personalizedGuidance } = recommendation.recommendation;

  const handleScriptureClick = (scripture: any, methodId: string, methodName: string) => {
    const match = scripture.reference.match(/^(.*?)\s+(\d+):(\d+)(?:-(\d+))?$/);
    if (!match) return;
    const [_, book, chapter, startVerse, endVerse = startVerse] = match;
    navigate(`/scripture/meditation?${new URLSearchParams({
      scripture: scripture.reference,
      prayerMethodId: methodId,
      prayerMethodName: methodName,
      selectedChapter: chapter,
      startVerse: startVerse,
      endVerse: endVerse,
      source: 'recommendations'
    }).toString()}`);
  };

  const getPrayerMethodDetails = (methodId: string) => {
    return prayerMethods.find(method => method.id === methodId);
  };

  const handlePrayerMethodClick = (method: any) => {
    const route = getPrayerMethodRoute(method);
    navigate(route);
  };

  const handleAddToPlan = async (planItem: any) => {
    await addToPlan({
      scriptureReference: planItem.scriptureReference,
      scriptureTheme: planItem.scriptureTheme,
      prayerMethodId: planItem.prayerMethodId,
      prayerMethodName: planItem.prayerMethodName
    });
  };

  return (
    <Container className={styles.recommendationDisplay}>
      <h2 className={styles.title}>Your Prayer Recommendations</h2>
      
      {userAnswers && (
        <div className={styles.answersSummary}>
          <div className={styles.summaryHeader}>
            <h3 className={styles.summaryTitle}>Based on Your Responses</h3>
            <button onClick={onRetake} className={styles.retakeButton}>
              Retake
            </button>
          </div>
          <div className={styles.answersGrid}>
            {Object.entries(userAnswers).map(([key, value]) => {
              const question = recommendationQuestions.questions.find((q: Question) => q.id === key);
              const answer = (question?.options as Array<{ id: string; label: string }>)?.find(opt => opt.id === value);
              if (!question || !answer) return null;
              return (
                <div key={key} className={styles.answerItem}>
                  <span className={styles.answerLabel}>{question.question}:</span>
                  <span className={styles.answerValue}>{answer.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      
      <div className={styles.guidanceSection}>
        <Card className={styles.guidanceCard}>
          <div className={styles.guidanceHeader}>
            <div className={styles.guidanceIcon}>
              <Lightbulb className="text-white" />
            </div>
            <h3 className={styles.guidanceTitle}>Guidance</h3>
          </div>
          <Card.Text>{personalizedGuidance}</Card.Text>
        </Card>
      </div>

      <div className={styles.section}>
        <h3 className={styles.sectionTitle}>Scripture Recommendations</h3>
        <div className={styles.recommendationsGrid}>
          {/* Primary Scripture Card */}
          <Card 
            className={`${styles.card} ${styles.clickable} ${styles.primaryCard}`}
            onClick={() => handleScriptureClick(primaryScripture, primaryScripture.id, primaryScripture.name)}
          >
            {!loading && getPrayerMethodDetails(primaryScripture.id) && (
              <div className={styles.methodImageContainer}>
                <img 
                  src={getPrayerMethodDetails(primaryScripture.id)?.imageUrl} 
                  alt={primaryScripture.name}
                  className={styles.methodImage}
                />
              </div>
            )}
            <Card.Body className={styles.cardContent}>
              <Card.Title className={styles.bold}>{primaryScripture.reference}</Card.Title>
              <Card.Subtitle className={`${styles.italic} mb-2`}>{primaryScripture.theme}</Card.Subtitle>
              <Card.Text>{primaryScripture.relevance}</Card.Text>
              <div className={styles.methodTag}>
                {primaryScripture.name}
              </div>
              <div className={styles.buttonGroup}>
                <StartButton />
                <AddToPlan 
                  onAdd={async (e) => {
                    e?.stopPropagation();  // Stop event from bubbling up
                    await handleAddToPlan({
                      scriptureReference: primaryScripture.reference,
                      scriptureTheme: primaryScripture.theme,
                      prayerMethodId: primaryScripture.id,
                      prayerMethodName: primaryScripture.name
                    });
                  }}
                  variant="outline"
                  size="sm"
                />
              </div>
            </Card.Body>
          </Card>

          {/* Alternative Scriptures */}
          {alternateScriptures?.map((scripture, index) => (
            <Card 
              key={index} 
              className={`${styles.card} ${styles.clickable}`}
              onClick={() => handleScriptureClick(scripture, scripture.id, scripture.name)}
            >
              {!loading && getPrayerMethodDetails(scripture.id) && (
                <div className={styles.methodImageContainer}>
                  <img 
                    src={getPrayerMethodDetails(scripture.id)?.imageUrl} 
                    alt={scripture.name}
                    className={styles.methodImage}
                  />
                </div>
              )}
              <Card.Body className={styles.cardContent}>
                <Card.Title className={styles.bold}>{scripture.reference}</Card.Title>
                <Card.Subtitle className={`${styles.italic} mb-2`}>{scripture.theme}</Card.Subtitle>
                <Card.Text>{scripture.relevance}</Card.Text>
                <div className={styles.methodTag}>
                  {scripture.name}
                </div>
                <div className={styles.buttonGroup}>
                  <StartButton />
                  <AddToPlan 
                    onAdd={async (e) => {
                      e?.stopPropagation();  // Stop event from bubbling up
                      await handleAddToPlan({
                        scriptureReference: scripture.reference,
                        scriptureTheme: scripture.theme,
                        prayerMethodId: scripture.id,
                        prayerMethodName: scripture.name
                      });
                    }}
                    variant="outline"
                    size="sm"
                  />
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>

      {alternatePrayerMethods && alternatePrayerMethods.length > 0 && (
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Additional Prayer Methods You Might Enjoy</h3>
          <div className={styles.methodGrid}>
            {alternatePrayerMethods.map((method, index) => {
              const methodDetails = getPrayerMethodDetails(method.id);
              if (!methodDetails) return null;
              
              return (
                <Card 
                  key={index} 
                  className={`${styles.card} ${styles.methodCard} ${styles.clickable}`}
                  onClick={() => handlePrayerMethodClick({
                    ...methodDetails,
                    link: methodDetails.id // ensure link property exists for routing
                  })}
                >
                  <div className={styles.methodImageContainer}>
                    <img 
                      src={methodDetails.imageUrl} 
                      alt={method.name}
                      className={styles.methodImage}
                    />
                  </div>
                  <Card.Body className={styles['card-body']}>
                    <Card.Title className={styles.bold}>{method.name}</Card.Title>
                    <Card.Text>{method.reason}</Card.Text>
                    <div className={styles.methodTag}>{method.name}</div>
                    <StartButton />
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </div>
      )}

      {!userData || userData.isAnonymous ? (
        <div className={styles.signUpSection}>
          <SignUpPrompt 
            message="Sign up to save your recommendations and access them anytime" 
            variant="button"
          />
        </div>
      ) : null}
    </Container>
  );
};

export default RecommendationDisplay;
