import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GospelDataGeneration from './GospelDataGeneration';
import DailyPrayerDataGeneration from './DailyPrayerDataGeneration';
import StainedGlassImageGeneration from './StainedGlassImageGeneration';
import GenerateSaintsData from './GenerateSaintsData';
import SocialMediaPosts from './SocialMediaPosts';
import styles from '../../styles/adminPage.module.css';

const AdminPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('gospel');

  const tabs = [
    { id: 'gospel', label: 'Gospel Data', component: GospelDataGeneration },
    { id: 'prayer', label: 'Daily Prayer', component: DailyPrayerDataGeneration },
    { id: 'stainedGlass', label: 'Stained Glass', component: StainedGlassImageGeneration },
    { id: 'saints', label: 'Saints Data', component: GenerateSaintsData },
    { id: 'socialMedia', label: 'Social Media', component: SocialMediaPosts },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromUrl = searchParams.get('tab');
    if (tabFromUrl && tabs.some(tab => tab.id === tabFromUrl)) {
      setActiveTab(tabFromUrl);
    }
  }, [location]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    navigate(`?tab=${tabId}`, { replace: true });
  };

  return (
    <div className={styles.adminPage}>
      <h1>Admin Dashboard</h1>
      <div className={styles.tabNavigation}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`${styles.tabButton} ${activeTab === tab.id ? styles.active : ''}`}
            onClick={() => handleTabChange(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${styles.tabPane} ${activeTab === tab.id ? styles.active : ''}`}
          >
            <tab.component />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPage;
