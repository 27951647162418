import React, { useEffect, useRef, useState, useMemo } from 'react';
import '../../styles/audioTranscript.css';
import { estimateSpeakingDuration } from '../../utils/audioUtils';
import { ChevronDown, ChevronUp } from 'lucide-react';

const AudioTranscript = ({ script, currentTime, isPlaying, disableAutoScroll = false, breakDurations }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const transcriptRef = useRef(null);
  const activeElementRef = useRef(null);

  const cumulativeDurations = useMemo(() => {
    let cumulative = 0;
    return script.map((item, index) => {
      const spokenDuration = item.speakingDuration || estimateSpeakingDuration(item.text);
      const breakDuration = breakDurations ? (breakDurations[index] || 0) : parseFloat(item.break.replace('s', '')) || 0;
      cumulative += spokenDuration + breakDuration;
      return cumulative;
    });
  }, [script, breakDurations]);

  useEffect(() => {
    if (!isPlaying || !script.length) return;

    const newActiveIndex = cumulativeDurations.findIndex(duration => currentTime < duration);
    setActiveIndex(newActiveIndex === -1 ? script.length - 1 : newActiveIndex);
  }, [currentTime, cumulativeDurations, isPlaying, script.length]);

  useEffect(() => {
    if (disableAutoScroll || !transcriptRef.current || !activeElementRef.current) return;

    const scrollToActiveElement = () => {
      const containerRect = transcriptRef.current?.getBoundingClientRect();
      const activeRect = activeElementRef.current?.getBoundingClientRect();

      if (!containerRect || !activeRect) return;

      const containerScrollTop = transcriptRef.current.scrollTop;
      const targetScrollTop = containerScrollTop + activeRect.top - containerRect.top - containerRect.height / 2 + activeRect.height / 2;

      transcriptRef.current.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth'
      });
    };

    // Delay the scroll to ensure the DOM has updated
    const timeoutId = setTimeout(scrollToActiveElement, 0);

    return () => clearTimeout(timeoutId);
  }, [activeIndex, disableAutoScroll]);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className={`audio-transcript mt-4 ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="transcript-header d-flex justify-content-between align-items-center p-3" onClick={toggleCollapse}>
        <h4 className="mb-0">Transcript</h4>
        {isCollapsed ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
      </div>
      {!isCollapsed && (
        <div className="transcript-content p-3" ref={transcriptRef}>
          {script.map((item, index) => (
            <p
              key={index}
              ref={index === activeIndex ? activeElementRef : null}
              className={`mb-3 ${!disableAutoScroll && index === activeIndex ? 'active' : ''}`}
            >
              {item.text}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default AudioTranscript;
