import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { ArrowLeft, Calendar, Info, CheckCircle, Circle } from 'lucide-react';
import { useSeriesData } from '../../hooks/useSeriesData';
import { useSeriesProgress } from '../../hooks/tracking/useSeriesProgress';
import { ExpandableSection } from '../common/ExpandableContainer';
import LoadingSpinner from '../common/LoadingSpinner';
import Sharing from '../common/Sharing';
import styles from '../../styles/SeriesOverview.module.css';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import { Series, SeriesItem, SeriesProgress } from '@shared/types/series';
import { useUser } from '../../contexts/UserContext';
import SignUpPrompt from '../authentication/SignUpPrompt';

const SeriesOverviewContainer = () => {
  console.log('[SeriesOverviewContainer] Component rendering');
  
  const { seriesId } = useParams();
  const location = useLocation();
  const [expandedSection, setExpandedSection] = useState<string | undefined>(
    new URLSearchParams(location.search).get('expand') || undefined
  );
  
  const { currentSeries, loading: seriesLoading, error: seriesError, fetchSeriesDetails } = useSeriesData();
  const { progress: seriesProgress, loading: progressLoading, error: progressError, fetchProgress } = useSeriesProgress(seriesId);
  const { prayerMethods, loading: methodsLoading, error: methodsError } = usePrayerMethods();
  const { userData } = useUser();

  // Combine loading and error states
  const loading = seriesLoading || methodsLoading || progressLoading;
  const error = seriesError || methodsError || progressError;

  useEffect(() => {
    console.log('[SeriesOverviewContainer] useEffect triggered', {
      seriesId,
      fetchSeriesDetails: !!fetchSeriesDetails,
      fetchProgress: !!fetchProgress,
      currentSeries: !!currentSeries,
      seriesProgress: !!seriesProgress
    });

    if (seriesId) {
      fetchSeriesDetails(seriesId);
      fetchProgress();
    }
  }, [seriesId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!currentSeries) return <div>Series not found</div>;

  const getItemStatus = (itemId: string, sectionItems: SeriesItem[]) => {
    if (!seriesProgress?.progress.completedItems) return 'pending';
    
    // Check if item is completed
    if (seriesProgress.progress.completedItems.some(
      (item: { itemId: string }) => item.itemId === itemId
    )) {
      return 'completed';
    }

    // Find first incomplete item across all sections
    const allItems = currentSeries.sections?.flatMap(section => section.items) || [];
    const firstIncompleteItem = allItems.find(item => 
      !seriesProgress.progress.completedItems.some(
        completed => completed.itemId === item.id
      )
    );

    return firstIncompleteItem?.id === itemId ? 'next-up' : 'pending';
  };

  const renderItemWithMethod = (item: SeriesItem, sectionItems: SeriesItem[]) => {
    const prayerMethod = prayerMethods.find(method => method.id === item.prayerMethodId);
    const status = getItemStatus(item.id, sectionItems);
    const scriptureReference = item.additionalData?.scripturePassage?.reference;
    
    return (
      <Link 
        key={item.id}
        to={`/series/${seriesId}/items/${item.id}`}
        className={`${styles.item} ${styles[status]}`}
      >
        {status === 'completed' ? 
          <CheckCircle className={styles.itemIcon} /> :
          <Circle className={styles.itemIcon} />
        }
        <div className={styles.itemContent}>
          <h3 className={styles.itemTitle}>{item.customTitle}</h3>
          <p className={styles.itemDescription}>{item.customDescription}</p>
          <div className={styles.tags}>
            {prayerMethod && (
              <div className={styles.methodTag}>
                {prayerMethod.imageUrl && (
                  <img 
                    src={prayerMethod.imageUrl} 
                    alt={prayerMethod.name}
                    className={styles.methodImage}
                  />
                )}
                <span className={styles.methodName}>{prayerMethod.name}</span>
              </div>
            )}
            {scriptureReference && (
              <div className={styles.scriptureTag}>
                {scriptureReference}
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  };

  const renderProgressBar = () => {
    const percentage = seriesProgress?.stats.percentageComplete || 0;
    return (
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
          <div 
            className={styles.progressFill} 
            style={{ width: `${percentage}%` }}
          />
        </div>
        <div className={styles.progressStats}>
          <span>{percentage}% Complete</span>
          <span>{seriesProgress?.stats.completedItems || 0} / {seriesProgress?.stats.totalItems || 0} Items</span>
        </div>
      </div>
    );
  };

  const renderSectionProgress = (sectionId: string) => {
    const sectionProgress = seriesProgress?.progress.sectionProgress[sectionId];
    if (!sectionProgress) return null;

    return (
      <div className={styles.sectionProgress}>
        <span className={styles.sectionStats}>
          {sectionProgress.completedItems} / {sectionProgress.totalItems} completed
        </span>
        {sectionProgress.isCompleted && (
          <span className={styles.completedBadge}>
            <CheckCircle size={16} /> Completed
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to="/series" className={styles.backLink}>
          <ArrowLeft size={18} />
          Back to Series Catalog
        </Link>
        <Sharing 
          url={window.location.href}
          title={`${currentSeries.title} - Saintly Whispers`}
          description={currentSeries.description}
        />
      </div>

      <div className={styles.banner} style={{ backgroundImage: `url(${currentSeries.imageUrl})` }}>
        <h1 className={styles.title}>{currentSeries.title}</h1>
      </div>

      <div className={styles.overview}>
        <ExpandableSection 
          title="About this Series" 
          icon={<Info size={20} />}
          expandedSection={expandedSection}
          sectionId="about"
        >
          <div className={styles.seriesInfo}>
            <p className={styles.description}>{currentSeries.longDescription}</p>
            <div className={styles.metadata}>
              <span className={styles.duration}>{currentSeries.estimatedDuration}</span>
              {currentSeries.difficulty && (
                <span className={styles.difficulty}>{currentSeries.difficulty}</span>
              )}
            </div>
          </div>
        </ExpandableSection>

        <div className={styles.progress}>
          <h2 className={styles.progressTitle}>Your Progress</h2>
          {renderProgressBar()}
          {userData?.isAnonymous && (
            <SignUpPrompt 
              variant="link"
              message="Sign up or login to save your progress"
              className={styles.signUpPrompt}
            />
          )}
        </div>

        <div className={styles.sections}>
          {currentSeries.sections?.map((section, index) => (
            <div key={section.id} className={styles.section}>
              <div className={styles.sectionHeader}>
                <h2 className={styles.sectionTitle}>
                  {currentSeries.sections && currentSeries.sections.length > 1 && (
                    <span className={styles.sectionNumber}>
                      Part {index + 1}
                    </span>
                  )}
                  {section.title}
                </h2>
                {renderSectionProgress(section.id)}
              </div>
              <p className={styles.sectionDescription}>{section.description}</p>
              
              <div className={styles.items}>
                {section.items.map(item => renderItemWithMethod(item, section.items))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeriesOverviewContainer;
