// API Endpoints
export const API_ENDPOINTS = {
    GENERATE_AUDIO_PRAYER: '/generate-audio-prayer',
    GENERATE_AUDIO_SCRIPT: '/generate-audio-script',
    GENERATE_ROSARY_AUDIO: '/generate-rosary-audio',
    GENERATE_DISCUSSION_SCRIPT: '/generate-discussion-script',
    GENERATE_DISCUSSION_AUDIO: '/generate-discussion-audio',
    FETCH_GOSPEL_READING: (date) => `/gospel/${date}`,
    FETCH_USCCB_GOSPEL: '/usccb-gospel',
    FETCH_GOSPEL_ANALYSIS: (date) => `/gospel-analysis/${date}`,
    PRAYER_DATA: '/prayer-data',
    GENERIC_PRAYER: (methodApiLink, date) => `/${methodApiLink}/${date}`,
    GENERATE_CONTEMPLATION_AUDIO: '/generate-contemplation-audio',
    GENERATE_SOCIAL_MEDIA_POST: '/generate-social-media-post',
    GENERATE_POSTING_PLAN: '/generate-posting-plan',
    SCHEDULE_SOCIAL_MEDIA_POST: '/schedule-social-media-post',
    GENERATE_STAINED_GLASS_IMAGE: '/generate-stained-glass-image',
    SAVE_STAINED_GLASS_IMAGE: '/save-stained-glass-image',
    GENERATE_SAINT_MEDITATION: '/generate-saint-meditation',
    SAVE_SAINT_DATA: '/save-saint-data',
    GENERATE_SAINT_IMAGE: '/generate-saint-image',
    SAINT_AUDIO_MEDITATIONS: (saintId, stepIndex) => `/audio/saint-meditations/${saintId}/step_${stepIndex}`,
    GENERATE_SAINT_MEDITATION_AUDIO: '/generate-saint-meditation-audio',
    UPDATE_SAINT_DATA: '/update-saint-data',
    FETCH_BOOK_CONTENT: (book) => `/custom-scripture/book/${encodeURIComponent(book)}`,
    CUSTOM_SCRIPTURE_MEDITATION: '/custom-scripture-meditation',
    FETCH_CUSTOM_SCRIPTURE: '/custom-scripture',
    GENERATE_CUSTOM_MEDITATION_SCRIPT: '/generate-custom-meditation-script',
    GENERATE_CUSTOM_MEDITATION_AUDIO: '/generate-custom-meditation-audio',
    GENERATE_RECOMMENDATION: '/generate-recommendation',
    PRAYER_HISTORY: {
        LOG: '/prayer-history/log',
        GET: (userId) => `/prayer-history/${userId}`,
        DELETE: (userId, prayerId) => `/prayer-history/${userId}/${prayerId}`,
    },
    RECOMMENDATION: {
        GENERATE: '/generate-recommendation',
        SAVE: '/recommendation/save',
        GET: (userId) => `/recommendation/${userId}`,
    },
    PRAYER_PLAN: {
        GET: (userId) => `/prayer-plan/${userId}`,
        ADD: '/prayer-plan/add',
        REORDER: (userId) => `/prayer-plan/${userId}/reorder`,
        COMPLETE: (userId) => `/prayer-plan/${userId}/complete`,
        DELETE: (userId, itemId) => `/prayer-plan/${userId}/${itemId}`,
    },
    SERIES_PROGRESS: {
        UPDATE: '/series-progress/update',
        GET: (userId, seriesId) => `/series-progress/${userId}/${seriesId}`,
        LIST: (userId) => `/series-progress/${userId}`,
    },
    DAILY_HOMILIES: {
        GET: (date) => `/homilies/${date}`,
        GENERATE: '/generate-homily',
        GENERATE_WITH_STYLE: (styleId) => `/generate-homily/${styleId}`,
        GENERATE_INSPIRATION: '/generate-homily-inspiration'
    },
    FIX_SCRIPT_DURATIONS: '/fix-script-durations',
};

// Storage Paths
export const STORAGE_PATHS = {
    DAILY_AUDIO_PRAYERS: (prayerMethodId, date) => `/audio/daily-prayers/${prayerMethodId}/${date}`,
    RELAXATION_AUDIO_PRAYERS: (prayerMethodId) => `/audio/relaxation-prayers/${prayerMethodId}`,
    ROSARY_AUDIO_MEDITATIONS: (rosaryMeditationId, mysteryId) => `/audio/rosary-meditations/${rosaryMeditationId}/${mysteryId}`,
    DAILY_AUDIO_DISCUSSIONS: (prayerMethodId, date) => `/audio/daily-discussions/${prayerMethodId}/${date}`,
    CONTEMPLATION_AUDIO_MEDITATIONS: (prayerMethodId) => `/audio/contemplation-meditations/${prayerMethodId}`,
    DAILY_REFLECTIONS_AUDIO: (prayerMethodId, stepIndex) => `/audio/daily-reflections/${prayerMethodId}/step_${stepIndex}`,
    SAINT_AUDIO_MEDITATIONS: (saintId) => `/audio/saint-meditations/${saintId}/meditation`,
    SAINT_AUDIO_DISCUSSIONS: (saintId) => `/audio/saint-meditations/${saintId}/discussion`,
    CUSTOM_SCRIPTURE_AUDIO_MEDITATIONS: (prayerMethodId, citation) => 
      `audio/custom_meditations/${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}`,
};

// Firestore Paths
export const FIRESTORE_PATHS = {
    DAILY_GOSPEL_READINGS: {
        collection: 'gospelReadings',
        doc: (date) => date,
        path: (date) => `gospelReadings/${date}`,
    },
    PRAYER_METHODS: {
        collection: 'prayerMethods',
        doc: (prayerMethodId) => prayerMethodId,
        path: (prayerMethodId) => `prayerMethods/${prayerMethodId}`,
    },
    DAILY_PRAYERS_TEXT: {
        collection: 'dailyPrayers',
        doc: (prayerMethodId, date) => `${prayerMethodId}/${date}/text_prayer`,
        path: (prayerMethodId, date) => `dailyPrayers/${prayerMethodId}/${date}/text_prayer`,
    },
    DAILY_PRAYERS_SCRIPT: {
        collection: 'dailyPrayers',
        doc: (prayerMethodId, date) => `${prayerMethodId}/${date}/audio_prayer`,
        path: (prayerMethodId, date) => `dailyPrayers/${prayerMethodId}/${date}/audio_prayer`,
    },
    RELAXATION_PRAYERS: {
        collection: 'relaxationPrayers',
        doc: (prayerMethodId) => prayerMethodId,
        path: (prayerMethodId) => `relaxationPrayers/${prayerMethodId}`,
    },
    DAILY_DISCUSSIONS: {
        collection: 'dailyDiscussions',
        doc: (prayerMethodId, date) => `${prayerMethodId}/${date}/audio_discussion`,
        path: (prayerMethodId, date) => `dailyDiscussions/${prayerMethodId}/${date}/audio_discussion`,
    },
    ROSARY_MEDITATIONS: {
        collection: 'rosaryMeditations',
        doc: (prayerMethodId, rosaryMeditationStyleId) => `${prayerMethodId}_${rosaryMeditationStyleId}`,
        path: (prayerMethodId, rosaryMeditationStyleId) => `rosaryMeditations/${prayerMethodId}_${rosaryMeditationStyleId}`,
    },
    DAILY_REFLECTIONS: {
        collection: 'dailyReflections',
        doc: (prayerMethodId) => prayerMethodId,
        path: (prayerMethodId) => `dailyReflections/${prayerMethodId}`,
    },
    CONTEMPLATION_MEDITATIONS: {
        collection: 'contemplationMeditations',
        doc: (prayerMethodId) => prayerMethodId,
        path: (prayerMethodId) => `contemplationMeditations/${prayerMethodId}`,
    },
    USERS: {
        collection: 'users',
        doc: (userId) => userId,
        path: (userId) => `users/${userId}`,
    },
    SAINTS_DATA: {
        collection: 'saintsData',
        doc: (saintId) => saintId,
        path: (saintId) => `saintsData/${saintId}`,
    },
    SAINT_MEDITATIONS: {
        collection: 'saintMeditations',
        doc: (saintId) => saintId,
        path: (saintId) => `saintMeditations/${saintId}`,
    },
    CUSTOM_MEDITATION_TEXT: {
        collection: 'customMeditations',
        doc: (prayerMethodId, citation) => `${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}`,
        path: (prayerMethodId, citation) => `customMeditations/${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}/text`,
    },
    CUSTOM_MEDITATION_SCRIPTS: {
        collection: 'customMeditations',
        doc: (prayerMethodId, citation) => `${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}`,
        path: (prayerMethodId, citation) => `customMeditations/${prayerMethodId}/${encodeURIComponent(citation.replace(/\s+/g, '_'))}/audio_script`,
    },
    DAILY_HOMILIES: {
        collection: 'dailyHomilies',
        doc: (date) => date,
        path: (date) => `dailyHomilies/${date}`,
    },
};
