import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import styles from '../../styles/prayerMethods.module.css';
import { getPrayerMethodRoute } from '../../utils/routeHelpers';
import { PrayerMethod } from '@shared/types/prayerMethod';

interface PrayerMethodsListProps {
  prayerMethods: PrayerMethod[];
  selectedDate: Date;
  isSidebar?: boolean;
  types?: string[];
}

const PrayerMethodsList: React.FC<PrayerMethodsListProps> = ({ 
  prayerMethods, 
  selectedDate, 
  isSidebar = false, 
  types 
}) => {
  const navigate = useNavigate();

  const handlePrayerMethodClick = (method: PrayerMethod, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(getPrayerMethodRoute(method, selectedDate));
  };

  const sortedPrayerMethods = [...prayerMethods].sort((a, b) => {
    const orderA = a.displayOrder || Infinity;
    const orderB = b.displayOrder || Infinity;
    return orderA - orderB;
  });

  const filteredPrayerMethods = types
    ? sortedPrayerMethods.filter(method => types.includes(method.type) && method.status === 'published')
    : sortedPrayerMethods.filter(method => method.status === 'published');

  return (
    <div className={`row ${isSidebar ? styles.g3 : `${styles.rowCols1} ${styles.rowColsMd2} ${styles.rowColsLg3} ${styles.g4}`}`}>
      {filteredPrayerMethods.map((method, index) => (
        <div key={method.id || index} className={isSidebar ? styles.col12 : styles.col}>
          <a 
            href={getPrayerMethodRoute(method, selectedDate)}
            className={styles.prayerMethodCardLink}
            onClick={(e) => handlePrayerMethodClick(method, e)}
          >
            <div className={styles.prayerMethodCard}>
              <div className={styles.prayerMethodImageContainer}>
                <img src={method.imageUrl} alt={method.name} className={styles.prayerMethodImage} />
              </div>
              <div className={styles.prayerMethodDetails}>
                <h4 className={styles.prayerMethodName}>{method.name}</h4>
                <p className={styles.prayerMethodDescription}>{method.description}</p>
                <span className={styles.prayerMethodLink}>
                  Start Prayer <ArrowRight size={16} className={styles.prayerMethodIcon} />
                </span>
              </div>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default PrayerMethodsList; 