import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AltCustomScriptureSelector from './AltCustomScriptureSelector';
import LoadingSpinner from '../common/LoadingSpinner';
import { useCustomScripture } from '../../hooks/useCustomScripture';
import { useCustomScriptureMeditation } from '../../hooks/useCustomScriptureMeditation';
import '../../styles/customScripturePage.css';
import { ScriptureData, PrayerMethod } from '@shared/types/customScripture';

const CustomScripturePage: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    customScripture,
    loading: scriptureLoading,
    error: scriptureError,
    handleScriptureSelection,
    handlePrayerMethodSelection,
    selectedScripture,
    selectedPrayerMethod,
    bookContent,
    fetchBookContent
  } = useCustomScripture();

  const {
    loading: meditationLoading,
    error: meditationError,
    generateMeditation
  } = useCustomScriptureMeditation();

  const loading = scriptureLoading || meditationLoading;
  const error = scriptureError || meditationError;

  const navigate = useNavigate();

  const handleGenerateMeditation = (scriptureData: ScriptureData, prayerMethod: PrayerMethod) => {
    console.log("CustomScripturePage - handleGenerateMeditation called");
    
    if (scriptureData && prayerMethod) {
      // Create URL parameters
      const queryParams = new URLSearchParams();
      queryParams.set('scripture', scriptureData.citation);
      queryParams.set('prayerMethodId', prayerMethod.id);
      queryParams.set('selectedChapter', scriptureData.selectedChapter);
      queryParams.set('startVerse', scriptureData.startVerse.toString());
      queryParams.set('endVerse', scriptureData.endVerse.toString());

      const navigationPath = `/scripture/meditation?${queryParams.toString()}`;
      console.log("Navigating to:", navigationPath);

      // Create a serializable version of the state
      const serializableState = {
        scripture: scriptureData.citation,
        prayerMethod: {
          id: prayerMethod.id,
          name: prayerMethod.name,
          description: prayerMethod.description,
          imageUrl: prayerMethod.imageUrl,
          type: prayerMethod.type,
          link: prayerMethod.link,
          status: prayerMethod.status,
          "api-link": prayerMethod["api-link"],
          long_description: prayerMethod.long_description,
          promptFile: prayerMethod.promptFile,
          schemaFile: prayerMethod.schemaFile,
          displayOrder: prayerMethod.displayOrder
        },
        scriptureText: scriptureData.text,
        bookContent: JSON.parse(JSON.stringify({
          data: scriptureData.bookContent?.data || [],
          selectedChapter: scriptureData.selectedChapter,
          startVerse: scriptureData.startVerse,
          endVerse: scriptureData.endVerse
        })),
        selectedChapter: scriptureData.selectedChapter,
        startVerse: scriptureData.startVerse,
        endVerse: scriptureData.endVerse
      };

      // Navigate with serializable state
      navigate(navigationPath, { state: serializableState });
    } else {
      console.error("Missing required data:", { scriptureData, prayerMethod });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div ref={containerRef} id="custom-scripture-container" className="custom-scripture-container container">
      <h1 className="custom-scripture-title">Scripture Meditations</h1>
      <AltCustomScriptureSelector 
        onScriptureSelect={handleScriptureSelection} 
        onSelectPrayerMethod={handlePrayerMethodSelection} 
        handleGenerateMeditation={handleGenerateMeditation}
        scrollToTop={scrollToTop}
        fetchBookContent={fetchBookContent}
        bookContent={bookContent}
      />
      {loading && <LoadingSpinner />}
      {error && <div className="alert alert-danger mt-3" role="alert">Error: {error}</div>}
    </div>
  );
};

export default CustomScripturePage;
