import React, { createContext, useContext, useState } from 'react';
import { PrayerPlanItem } from '@shared/types/prayerPlan';

interface PrayerPlanContextType {
  planItems: PrayerPlanItem[] | null;
  setPlanItems: React.Dispatch<React.SetStateAction<PrayerPlanItem[] | null>>;
  reorderItems: (itemId: string, newOrder: number) => void;
}

const PrayerPlanContext = createContext<PrayerPlanContextType | undefined>(undefined);

export const PrayerPlanProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [planItems, setPlanItems] = useState<PrayerPlanItem[] | null>(null);

  const reorderItems = (itemId: string, newOrder: number) => {
    if (!planItems) return;
    console.log('Reordering items in context:', { itemId, newOrder, currentItems: planItems });
    
    const movingItem = planItems.find(item => item.id === itemId);
    if (!movingItem) return;

    const reorderedItems = planItems
      .filter(item => item.id !== itemId)
      .map((item, index) => ({
        ...item,
        order: index < newOrder ? index : index + 1
      }));

    reorderedItems.splice(newOrder, 0, { ...movingItem, order: newOrder });
    
    console.log('New order:', reorderedItems);
    setPlanItems(reorderedItems);
  };

  return (
    <PrayerPlanContext.Provider value={{ planItems, setPlanItems, reorderItems }}>
      {children}
    </PrayerPlanContext.Provider>
  );
};

export const usePrayerPlanContext = () => {
  const context = useContext(PrayerPlanContext);
  if (!context) {
    throw new Error('usePrayerPlanContext must be used within a PrayerPlanProvider');
  }
  return context;
};
