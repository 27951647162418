import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import { PrayerMethod } from '../../shared/types/prayerMethod';
import { RelaxationPrayer } from '../../shared/types/relaxationPrayer';

export const useRelaxationPrayer = (prayerMethod: PrayerMethod | null) => {
  const [relaxationPrayer, setRelaxationPrayer] = useState<RelaxationPrayer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRelaxationPrayer = async () => {
      if (!prayerMethod || !prayerMethod.id) {
        setLoading(false);
        return;
      }

      try {
        const relaxationPrayerRef = doc(firestore, FIRESTORE_PATHS.RELAXATION_PRAYERS.path(prayerMethod.id));
        const relaxationPrayerSnap = await getDoc(relaxationPrayerRef);

        if (relaxationPrayerSnap.exists()) {
          const data = relaxationPrayerSnap.data() as RelaxationPrayer;
          setRelaxationPrayer(data);
        } else {
          throw new Error('Relaxation prayer not found');
        }
      } catch (err) {
        console.error('Error fetching relaxation prayer:', err);
        setError(`Error fetching relaxation prayer: ${err instanceof Error ? err.message : 'Unknown error'}`);
      } finally {
        setLoading(false);
      }
    };

    fetchRelaxationPrayer();
  }, [prayerMethod]);

  return { relaxationPrayer, loading, error };
};
