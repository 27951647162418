import { useState, useCallback, useEffect } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import api from '../utils/api';

export const useDiscussionPlayer = (prayerMethod, gospelContent, date) => {
  const [script, setScript] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchScript = useCallback(async () => {
    if (script) return script;

    setIsLoading(true);
    setError(null);

    try {
      const db = getFirestore();
      const scriptRef = doc(db, FIRESTORE_PATHS.DAILY_DISCUSSIONS.path(prayerMethod.id, date));
      const scriptDoc = await getDoc(scriptRef);
      
      if (scriptDoc.exists()) {
        const fetchedScript = scriptDoc.data().script;
        setScript(fetchedScript);
        return fetchedScript;
      } else {
        const scriptResponse = await api.generateDiscussionScript({
          prayerMethod,
          gospelContent,
          date
        });
        setScript(scriptResponse.data.script);
        return scriptResponse.data.script;
      }
    } catch (err) {
      setError(`Error fetching script: ${err.message}`);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [script, prayerMethod, gospelContent, date]);

  const generateAudio = useCallback(async (scriptData) => {
    return api.generateDiscussionAudio({
      prayerMethod,
      gospelContent,
      date,
      script: scriptData
    });
  }, [prayerMethod, gospelContent, date]);

  useEffect(() => {
    if (!script) {
      fetchScript().catch(console.error);
    }
  }, [fetchScript, script]);

  return { script, isLoading, error, fetchScript, generateAudio };
};