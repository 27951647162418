import React, { useEffect } from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import { useCustomScriptureMeditationPlayer } from '../../hooks/useCustomScriptureMeditationPlayer';
import LoadingSpinner from '../common/LoadingSpinner';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';

const CustomScriptureMeditationPlayer = ({
  scriptureData,
  meditationData,
  fetchScript,
  seriesContext
}) => {
  const { prayerMethod, prayerData } = usePrayerTracking();

  console.log('[CustomScriptureMeditationPlayer] Props:', {
    hasSeriesContext: !!seriesContext,
    seriesContextData: seriesContext,
    prayerMethodId: prayerMethod?.id
  });

  const { script, isLoading, error, generateAudio } = useCustomScriptureMeditationPlayer(prayerMethod, scriptureData, meditationData);

  useEffect(() => {
  }, [scriptureData, prayerMethod, meditationData]);

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
    />
  );

  const title = `${prayerMethod.name} - ${scriptureData.citation}`;
  const storagePath = STORAGE_PATHS.CUSTOM_SCRIPTURE_AUDIO_MEDITATIONS(prayerMethod.id, scriptureData.citation);

  const enhancedPrayerData = {
    ...scriptureData,
    title: title,
    scriptureReference: scriptureData.citation,
    prayerType: prayerMethod.type,
    completionType: 'listen'
  };

  console.log('[CustomScriptureMeditationPlayer] Enhanced prayer data:', {
    enhancedPrayerData,
    originalScriptureData: scriptureData,
    citation: scriptureData.citation
  });

  if (isLoading) return <LoadingSpinner message="Loading your audio..." />;
  if (error) return <div>Error: {error}</div>;

  return (
    <BaseAudioPlayer
      fetchScript={fetchScript}
      generateAudioEndpoint={generateAudio}
      renderTranscript={renderTranscript}
      title={title}
      date={null}
      storagePath={storagePath}
      seriesContext={seriesContext}
    />
  );
};

export default CustomScriptureMeditationPlayer;
