import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import api from '../utils/api';
import { PrayerPlanItem } from '@shared/types/prayerPlan';
import { usePrayerPlanContext } from '../contexts/PrayerPlanContext';

export const usePrayerPlan = () => {
  const { planItems, setPlanItems, reorderItems } = usePrayerPlanContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPlan = async () => {
    if (!auth.currentUser) return;
    setLoading(true);
    try {
      const response = await api.get(`/prayer-plan/${auth.currentUser.uid}`);
      setPlanItems(response.data);
    } catch (err) {
      setError('Failed to fetch prayer plan');
    } finally {
      setLoading(false);
    }
  };

  const addToPlan = async (item: Omit<PrayerPlanItem, 'id' | 'userId' | 'order' | 'completed' | 'addedAt'>) => {
    if (!auth.currentUser) return;
    try {
      await api.post('/prayer-plan/add', {
        userId: auth.currentUser.uid,
        ...item
      });
      await fetchPlan();
    } catch (err) {
      setError('Failed to add to plan');
    }
  };

  const reorderPlan = async (itemId: string, newOrder: number) => {
    if (!auth.currentUser) return;
    
    console.log('Starting reorderPlan:', { itemId, newOrder });
    
    // Optimistically update the UI
    reorderItems(itemId, newOrder);
    
    try {
      console.log('Sending reorder request to API');
      await api.put(`/prayer-plan/${auth.currentUser.uid}/reorder`, {
        itemId,
        newOrder
      });
      console.log('Reorder API request successful');
    } catch (err) {
      console.error('Reorder API request failed:', err);
      await fetchPlan();
      setError('Failed to reorder plan');
    }
  };

  const markCompleted = async (itemId: string) => {
    if (!auth.currentUser) return;
    try {
      await api.put(`/prayer-plan/${auth.currentUser.uid}/complete`, {
        itemId
      });
      await fetchPlan();
    } catch (err) {
      setError('Failed to mark as completed');
    }
  };

  const deleteItem = async (itemId: string) => {
    if (!auth.currentUser) return;
    try {
      await api.delete(`/prayer-plan/${auth.currentUser.uid}/${itemId}`);
      await fetchPlan();
    } catch (err) {
      setError('Failed to delete item');
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchPlan();
    }
  }, []);

  return {
    plan: planItems,
    loading,
    error,
    addToPlan,
    reorderPlan,
    markCompleted,
    deleteItem
  };
};
