import { useState, useCallback, useEffect, useRef } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import api from '../utils/api';
import { adjustBreakDurations } from '../utils/audioUtils';
import { 
  PrayerMethod, 
  ScriptureData, 
  MeditationData, 
  CustomScriptureMeditationPlayerHook,
  AudioScript,
  GenerateAudioParams
} from '@shared/types/customScripture';

export const useCustomScriptureMeditationPlayer = (
  prayerMethod: PrayerMethod, 
  scriptureData: ScriptureData, 
  meditationData: MeditationData | null, 
  duration: string
): CustomScriptureMeditationPlayerHook => {
  const [script, setScript] = useState<AudioScript[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const isFetchingRef = useRef<boolean>(false);
  const fetchPromiseRef = useRef<Promise<AudioScript[] | null> | null>(null);

  const validateScriptDurations = (script: AudioScript[]): boolean => {
    return script.every(section => 
      section.speakingDuration && 
      typeof section.speakingDuration === 'number' && 
      section.speakingDuration > 0
    );
  };

  const fetchScript = useCallback(async (): Promise<AudioScript[] | null> => {
    if (hasFetched && script) {
      return script;
    }

    if (isFetchingRef.current) {
      return fetchPromiseRef.current as Promise<AudioScript[] | null>;
    }

    isFetchingRef.current = true;
    setIsLoading(true);
    setError(null);

    fetchPromiseRef.current = (async () => {
      try {
        if (!meditationData) {
          return null;
        }

        const db = getFirestore();
        const scriptRef = doc(db, FIRESTORE_PATHS.CUSTOM_MEDITATION_SCRIPTS.path(
          prayerMethod.id, 
          scriptureData.citation
        ));
        const scriptDoc = await getDoc(scriptRef);

        let fetchedScript: AudioScript[];
        if (scriptDoc.exists()) {
          console.log('Script found in Firestore');
          fetchedScript = scriptDoc.data().script as AudioScript[];
          
          if (!validateScriptDurations(fetchedScript)) {
            console.log('Script missing durations, attempting to fix...');
            try {
              const response = await api.fixScriptDurations({
                prayerMethod,
                scriptureData,
                script: fetchedScript
              });
              
              if (response.data.wasUpdated) {
                console.log('Script durations fixed successfully');
                fetchedScript = response.data.script;
              }
            } catch (err) {
              console.warn('Failed to fix script durations:', err);
            }
          }
        } else {
          fetchedScript = await generateNewScript();
        }

        if (duration) {
          const targetDuration = parseInt(duration) * 60;
          const { adjustedScript } = adjustBreakDurations(fetchedScript, targetDuration);
          fetchedScript = adjustedScript;
        }

        setScript(fetchedScript);
        setHasFetched(true);
        setIsLoading(false);
        return fetchedScript;
      } catch (err: any) {
        console.error('Error in fetchScript:', err);
        setError(`Error fetching script: ${err.message}`);
        throw err;
      } finally {
        isFetchingRef.current = false;
        fetchPromiseRef.current = null;
      }
    })();

    return fetchPromiseRef.current;
  }, [prayerMethod, scriptureData, meditationData, duration, script, hasFetched]);

  const generateNewScript = async (): Promise<AudioScript[]> => {
    try {
      if (!meditationData || !meditationData.prayer || !Array.isArray(meditationData.prayer.steps)) {
        console.error('Invalid meditation data structure:', meditationData);
        throw new Error('Invalid meditation data structure: missing steps array');
      }

      const response = await api.generateCustomMeditationScript({
        prayerMethod,
        scriptureData,
        meditationData: { steps: meditationData.prayer.steps }
      });
      return response.data.script;
    } catch (err: any) {
      console.error('Error in generateNewScript:', err);
      setError(`Error generating new script: ${err.message}`);
      throw err;
    }
  };

  const generateAudio = useCallback(async (params: GenerateAudioParams): Promise<any> => {
    try {
      const response = await api.generateCustomMeditationAudio({
        prayerMethod: params.prayerMethod,
        scriptureData: params.prayerData,
        script: params.script
      });
      return response.data;
    } catch (err: any) {
      console.error('Error in generateAudio:', err);
      setError(`Error generating audio: ${err.message}`);
      throw err;
    }
  }, []);

  useEffect(() => {
    if (!hasFetched || !script || duration) {
      fetchScript().catch(console.error);
    }
  }, [fetchScript, script, hasFetched, duration]);

  return { script, isLoading, error, fetchScript, generateAudio };
};

