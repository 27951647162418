import React from 'react';
import { DailyReflectionData } from '@shared/types/dailyReflection';

interface DailyReflectionIntroProps {
  reflectionData: DailyReflectionData;
}

const DailyReflectionIntro: React.FC<DailyReflectionIntroProps> = ({ reflectionData }) => {
  return (
    <div className="daily-reflection-intro">
      <p>{reflectionData.context}</p>
      <h3>Core Principles</h3>
      <ul>
        {reflectionData.core_principles.map((principle, index) => (
          <li key={index}>
            <strong>{principle.title}:</strong> {principle.explanation}
          </li>
        ))}
      </ul>
      <h3>Benefits</h3>
      <ul>
        {reflectionData.benefits.map((benefit, index) => (
          <li key={index}>{benefit}</li>
        ))}
      </ul>
      <h3>General Structure</h3>
      <p>{reflectionData.general_structure}</p>
      <h3>Recommended Frequency</h3>
      <p>{reflectionData.recommended_frequency}</p>
      <h3>Typical Duration</h3>
      <p>{reflectionData.typical_duration}</p>
      <h3>Tips for Beginners</h3>
      <ul>
        {reflectionData.tips_for_beginners.map((tip, index) => (
          <li key={index}>{tip}</li>
        ))}
      </ul>
    </div>
  );
};

export default DailyReflectionIntro;
