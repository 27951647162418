import React from 'react';

const DecadePrayers = ({ decadePrayers, mysteryName }) => {
  const updatedDecadePrayers = decadePrayers.map(prayer => 
    prayer.name === "Announce the Mystery" 
      ? { ...prayer, text: `Let us contemplate the ${mysteryName}` }
      : prayer
  );

  return (
    <div className="rosary-prayers">
      <h3>Decade Prayers</h3>
      {updatedDecadePrayers.map((prayer, index) => (
        <div key={index} className="prayer">
          <h4>{prayer.name}</h4>
          <p>{prayer.text}</p>
          {prayer.repeat && <p>(Repeat {prayer.repeat} times)</p>}
        </div>
      ))}
    </div>
  );
};

export default DecadePrayers;