import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import bibleStructure from '../../data/bible_structure.json';
import { fetchBookContent } from '../../utils/api';
import CustomPrayerMethodSelector from './CustomPrayerMethodSelector';
import LoadingSpinner from '../common/LoadingSpinner';
import '../../styles/altCustomScriptureSelector.css';
import ScriptureContent from './ScriptureContent';
import BibleRecommendations from './BibleRecommendations';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import { ScriptureData } from '@shared/types/customScripture';
import { PrayerMethod } from '@shared/types/prayerMethod';
import RecommendationPromo from '../advisor/RecommendationPromo';
import AddToPlan from '../plan/AddToPlan';
import { usePrayerPlan } from '../../hooks/usePrayerPlan';
import { getVerseCount, extractVerseRange, getOfficialBookNames, validateBookName } from '../../utils/scriptureUtils';

interface AltCustomScriptureSelectorProps {
  onScriptureSelect: (scripture: ScriptureData) => void;
  onSelectPrayerMethod: (prayerMethod: PrayerMethod) => void;
  handleGenerateMeditation: (scriptureData: ScriptureData, prayerMethod: PrayerMethod) => void;
  scrollToTop: () => void;
  fetchBookContent: (book: string) => Promise<void>;
  bookContent: any;
}

const AltCustomScriptureSelector: React.FC<AltCustomScriptureSelectorProps> = ({ 
  onScriptureSelect, 
  onSelectPrayerMethod, 
  handleGenerateMeditation, 
  scrollToTop,
  fetchBookContent,
  bookContent
}) => {
  const [book, setBook] = useState<string>('');
  const [chapter, setChapter] = useState<string>('');
  const [startVerse, setStartVerse] = useState<string>('');
  const [endVerse, setEndVerse] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedPrayerMethod, setSelectedPrayerMethod] = useState<PrayerMethod | null>(null);
  const [verseOptions, setVerseOptions] = useState<string[]>([]);

  const { prayerMethods, loading: prayerMethodsLoading, error: prayerMethodsError } = usePrayerMethods();
  const { addToPlan } = usePrayerPlan();

  useEffect(() => {
    if (book) {
      fetchBookContent(book);
    }
  }, [book, fetchBookContent]);

  useEffect(() => {
    if (bookContent?.data && chapter) {
      const chapterData = bookContent.data.find((c: any) => c.number === chapter);
      if (chapterData) {
        const totalVerses = getVerseCount(chapterData.content);
        const verseOptions = Array.from({ length: totalVerses }, (_, i) => (i + 1).toString());
        setStartVerse('');
        setEndVerse('');
        setVerseOptions(verseOptions);
      }
    }
  }, [bookContent, chapter]);

  if (prayerMethodsLoading) return <LoadingSpinner />;
  if (prayerMethodsError) return <p className="error-message">{prayerMethodsError}</p>;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const scripture = `${book} ${chapter}:${startVerse}-${endVerse}`;
    
    if (selectedPrayerMethod && bookContent) {
      const selectedContent = bookContent.data.find((c: any) => c.number === chapter);
      if (selectedContent) {
        const scriptureText = extractVerseRange(selectedContent.content, startVerse, endVerse);

        const scriptureData: ScriptureData = {
          citation: scripture,
          text: scriptureText,
          bookContent: bookContent,
          selectedChapter: chapter,
          startVerse: parseInt(startVerse),
          endVerse: parseInt(endVerse)
        };

        onScriptureSelect(scriptureData);
        onSelectPrayerMethod(selectedPrayerMethod);
        handleGenerateMeditation(scriptureData, selectedPrayerMethod);
      }
    }
    scrollToTop();
  };

  const handleRecommendationSelect = (recommendation: any) => {
    setBook(recommendation.book);
    setChapter(recommendation.chapter.toString());
    const [start, end] = recommendation.verses.split('-');
    setStartVerse(start);
    setEndVerse(end);

    const scripture = `${recommendation.book} ${recommendation.chapter}:${recommendation.verses}`;
    const scriptureData: ScriptureData = { 
      citation: scripture, 
      text: recommendation.text,
      bookContent: null,
      selectedChapter: recommendation.chapter.toString(),
      startVerse: parseInt(start),
      endVerse: parseInt(end)
    };
    onScriptureSelect(scriptureData);
    
    const prayerMethod = prayerMethods.find(method => method.id === recommendation.suitablePrayerMethods[0]);
    if (prayerMethod) {
      setSelectedPrayerMethod(prayerMethod);
      onSelectPrayerMethod(prayerMethod);
    }
    
    scrollToTop();
  };

  const isFormComplete = book && chapter && startVerse && endVerse && selectedPrayerMethod;

  return (
    <div className="alt-custom-scripture-selector-wrapper">
      <Container className="alt-custom-scripture-selector">
        <h2 className="selector-title">Select Scripture Passage</h2>
        <Form onSubmit={handleSubmit}>
          <Row className="selector-row">
            <Col xs={12} sm={6} md={3}>
              <Form.Group controlId="bookSelect">
                <Form.Label>Book</Form.Label>
                <Form.Control
                  as="select"
                  value={book}
                  onChange={(e: React.ChangeEvent<HTMLElement>) => {
                    if (e.target instanceof HTMLSelectElement) {
                      const validatedBook = validateBookName(e.target.value);
                      if (validatedBook) {
                        setBook(validatedBook);
                        setChapter('');
                        setStartVerse('');
                        setEndVerse('');
                      }
                    }
                  }}
                  className="selector-input"
                >
                  <option value="">Select a book</option>
                  {getOfficialBookNames().map((bookName) => (
                    <option key={bookName} value={bookName}>{bookName}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Form.Group controlId="chapterSelect">
                <Form.Label>Chapter</Form.Label>
                <Form.Control
                  as="select"
                  value={chapter}
                  onChange={(e: React.ChangeEvent<HTMLElement>) => {
                    if (e.target instanceof HTMLSelectElement) {
                      setChapter(e.target.value);
                      setStartVerse('');
                      setEndVerse('');
                    }
                  }}
                  className="selector-input"
                  disabled={!book}
                >
                  <option value="">Select a chapter</option>
                  {book && Object.keys(bibleStructure[book as keyof typeof bibleStructure] || {}).map((chapterNum) => (
                    <option key={chapterNum} value={chapterNum}>{chapterNum}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Form.Group controlId="startVerseSelect">
                <Form.Label>Start Verse</Form.Label>
                <Form.Control
                  as="select"
                  value={startVerse}
                  onChange={(e: React.ChangeEvent<HTMLElement>) => {
                    if (e.target instanceof HTMLSelectElement) {
                      setStartVerse(e.target.value);
                    }
                  }}
                  className="selector-input"
                  disabled={!chapter}
                >
                  <option value="">Start verse</option>
                  {verseOptions.map((verse) => (
                    <option key={verse} value={verse}>{verse}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <Form.Group controlId="endVerseSelect">
                <Form.Label>End Verse</Form.Label>
                <Form.Control
                  as="select"
                  value={endVerse}
                  onChange={(e: React.ChangeEvent<HTMLElement>) => {
                    if (e.target instanceof HTMLSelectElement) {
                      setEndVerse(e.target.value);
                    }
                  }}
                  className="selector-input"
                  disabled={!startVerse}
                >
                  <option value="">End verse</option>
                  {verseOptions
                    .filter(v => parseInt(v) >= parseInt(startVerse || '0'))
                    .map((verse) => (
                      <option key={verse} value={verse}>{verse}</option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {startVerse && endVerse && (
            <div className="prayer-method-selection">
              <CustomPrayerMethodSelector
                onSelectPrayerMethod={(method: PrayerMethod) => {
                  setSelectedPrayerMethod(method);
                  onSelectPrayerMethod(method);
                }}
                selectedPrayerMethod={selectedPrayerMethod}
                prayerMethods={prayerMethods}
                loading={prayerMethodsLoading}
                error={prayerMethodsError}
                scrollToTop={scrollToTop}
              />
            </div>
          )}
          <div className="button-group">
            <Button
              variant="primary"
              type="submit"
              className={`generate-button ${isFormComplete ? 'active' : 'inactive'}`}
              disabled={!isFormComplete}
            >
              Start Meditation
            </Button>
            
            {isFormComplete && (
              <AddToPlan
                onAdd={async () => {
                  if (selectedPrayerMethod && book && chapter && startVerse && endVerse) {
                    await addToPlan({
                      scriptureReference: `${book} ${chapter}:${startVerse}-${endVerse}`,
                      prayerMethodId: selectedPrayerMethod.id,
                      prayerMethodName: selectedPrayerMethod.name,
                      scriptureTheme: 'Custom Selection'
                    });
                  }
                }}
                variant="outline"
                size="lg"
                className="add-plan-button"
              />
            )}
          </div>
          {!isFormComplete && (
            <p className="form-hint">
              Please select a book, chapter, start verse, end verse, and then a prayer method.
            </p>
          )}
        </Form>
      </Container>
      {loading && <LoadingSpinner />}
      {error && <p className="error-message">{error}</p>}
      {bookContent && (
        <ScriptureContent 
          bookContent={bookContent} 
          selectedChapter={chapter}
          startVerse={startVerse ? parseInt(startVerse) : undefined}
          endVerse={endVerse ? parseInt(endVerse) : undefined}
          fullBookName={book}
        />
      )}
      <hr className="divider" />
      <div className="recommendation-promo-wrapper">
        <RecommendationPromo size="mini" />
      </div>
      <div className="bible-recommendations-wrapper">
        <BibleRecommendations 
          onRecommendationSelect={handleRecommendationSelect} 
          onSelectPrayerMethod={onSelectPrayerMethod} 
          prayerMethods={prayerMethods}
          scrollToTop={scrollToTop}
        />
      </div>
    </div>
  );
};

export default AltCustomScriptureSelector;
