import React from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import { useRosaryPlayer } from '../../hooks/useRosaryPlayer';

const RosaryPlayer = ({ prayerMethod, mysteryMeditationData, allMeditationData }) => {
  const { script, isLoading, error, fetchScript, generateAudio } = useRosaryPlayer(prayerMethod, mysteryMeditationData, allMeditationData);

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
    />
  );

  const title = `Rosary Meditation: ${mysteryMeditationData.name}`;
  const storagePath = STORAGE_PATHS.ROSARY_AUDIO_MEDITATIONS(allMeditationData.id, mysteryMeditationData.id);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <BaseAudioPlayer
      fetchScript={fetchScript}
      generateAudioEndpoint={generateAudio}
      renderTranscript={renderTranscript}
      title={title}
      prayerMethod={prayerMethod}
      prayerData={mysteryMeditationData}
      date={null}
      storagePath={storagePath}
      disableCompletionLogging={true}
      isRosaryMeditation={true}
    />
  );
};

export default RosaryPlayer;