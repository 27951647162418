import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Book, ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { format, addDays, subDays, parseISO, isValid } from 'date-fns';
import '../styles/homepage.css';
import '../styles/dailyReading.css';
import PrayerMethodsList from './catalog/PrayerMethodsList';
import PrayerMethodsCarousel from './catalog/PrayerMethodsCarousel';
import '../styles/gospelAnalysis.css';
import { ExpandableContainer } from './common/ExpandableContainer';
import '../styles/expandableContainer.css';
import { useGospelData } from '../hooks/useGospelData';
import { usePrayerMethods } from '../hooks/usePrayerMethods';
import { useGospelAnalysis } from '../hooks/useGospelAnalysis';
import SaintsPromoCard from './catalog/SaintsPromoCard';
import { Link } from 'react-router-dom';
import LoadingSpinner from './common/LoadingSpinner';
import ErrorMessage from './common/ErrorMessage';
import RecommendationPromo from './advisor/RecommendationPromo';
import DateNavigation from './common/DateNavigation';
import SeriesCarousel from './series/SeriesCarousel';

const HomePage = () => {
  const navigate = useNavigate();
  const { date } = useParams();
  
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { dailyReading, loading: gospelLoading, error: gospelError } = useGospelData(selectedDate);
  const [filteredReading, setFilteredReading] = useState(null);
  const { prayerMethods, loading: prayerMethodsLoading, error: prayerMethodsError } = usePrayerMethods();
  const [isPrayerMethodsExpanded, setIsPrayerMethodsExpanded] = useState(true);
  const [searchParams] = useSearchParams();
  const expandedSection = searchParams.get('expand');
  const expandedSectionRef = useRef(null);

  useEffect(() => {
    if (date) {
      const parsedDate = parseISO(date);
      if (isValid(parsedDate)) {
        setSelectedDate(parsedDate);
      } else {
        const today = new Date();
        setSelectedDate(today);
        navigate(`/${format(today, 'yyyy-MM-dd')}`, { replace: true });
      }
    } else {
      const today = new Date();
      setSelectedDate(today);
      navigate(`/${format(today, 'yyyy-MM-dd')}`, { replace: true });
    }
  }, [date, navigate]);

  useEffect(() => {
    if (dailyReading && dailyReading.readings) {
      const gospelReading = dailyReading.readings.find(reading => reading.name === 'Gospel');
      setFilteredReading(gospelReading);
    }
  }, [dailyReading]);

  useEffect(() => {
    if (expandedSection && expandedSectionRef.current) {
      expandedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [expandedSection]);

  const handlePreviousDay = () => {
    const newDate = subDays(selectedDate, 1);
    navigate(`/${format(newDate, 'yyyy-MM-dd')}`);
  };
  
  const handleNextDay = () => {
    const newDate = addDays(selectedDate, 1);
    navigate(`/${format(newDate, 'yyyy-MM-dd')}`);
  };

  const togglePrayerMethodsExpansion = () => {
    if (window.innerWidth >= 992) {
      setIsPrayerMethodsExpanded(prevState => !prevState);
    }
  };

  const renderErrorMessage = (message) => (
    <ErrorMessage message={message} />
  );

  const formattedSelectedDate = format(selectedDate, "EEE. MMM do, yyyy");

  const saintsMeditation = prayerMethods.find(method => method.id === 'saintsMeditation');

  const { gospelAnalysis, loading: analysisLoading, error: analysisError } = useGospelAnalysis(selectedDate);

  return (
    <>
      <div className="header-banner mb-4">
        <h1 className="homepage-title">Daily Scripture Meditations Inspired by Saints</h1>
      </div>      
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-body">
                <h2 className="todays-gospel-title mb-md-4 mb-2">Today's Gospel & Meditations</h2>
                <div className="row">
                  <div className="col-lg-7">
                    <DateNavigation
                      selectedDate={selectedDate}
                      onPreviousDay={handlePreviousDay}
                      onNextDay={handleNextDay}
                      showChangePassage={true}
                    />
                    {gospelLoading ? (
                      <LoadingSpinner message="Loading Gospel..." />
                    ) : gospelError ? (
                      renderErrorMessage(gospelError)
                    ) : filteredReading ? (
                      <div className="daily-reading">
                        <h3 className="card-title daily-reading-title">
                          <span className="daily-reading-title-text">
                            <Book size={24} className="daily-reading-icon" />
                            Reading: {filteredReading.citation}
                          </span>
                          <Link to="/scripture" className="custom-scripture-link">
                            Change Passage
                          </Link>
                        </h3>
                        <div className="bible-text-container px-md-3 mt-1.5">
                          <p className="card-text daily-reading-text" style={{ whiteSpace: 'pre-wrap' }}>
                            {filteredReading.text.replace(/<br\s*\/?>/gi, '\n')}
                          </p>
                        </div>
                        {analysisLoading ? (
                          <LoadingSpinner />
                        ) : (
                          <ExpandableContainer
                            gospelContent={filteredReading}
                            date={format(selectedDate, 'yyyy-MM-dd')}
                            analysis={gospelAnalysis}
                            expandedSection={expandedSection}
                            ref={expandedSectionRef}
                          />
                        )}
                      </div>
                    ) : (
                      <p>No gospel reading available for this date.</p>
                    )}
                  </div>
                  <div className="col-lg-5">
                    <div className="choose-meditation-container">
                      <h3 className="mb-3">Choose Gospel Meditation</h3>
                      {prayerMethodsLoading ? (
                        <LoadingSpinner />
                      ) : prayerMethodsError ? (
                        renderErrorMessage(prayerMethodsError)
                      ) : (
                        <div className={`prayer-methods-list-wrapper ${isPrayerMethodsExpanded ? 'expanded' : ''}`}>
                          <PrayerMethodsList 
                            prayerMethods={prayerMethods} 
                            selectedDate={selectedDate} 
                            isSidebar={true} 
                            types={['daily-meditation', 'daily-prayer']} 
                          />
                        </div>
                      )}
                      <button className="expand-button d-none d-lg-block" onClick={togglePrayerMethodsExpansion}>
                        {isPrayerMethodsExpanded ? 'Show More' : 'Show Less'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecommendationPromo size="mini" />
        <div className="row">
          <div className="col-12">
            <div className="card my-4">
              <div className="card-body">
                <h2 className="prayer-methods-title">Other Meditations and Prayers</h2>
                {prayerMethodsLoading ? (
                  <LoadingSpinner />
                ) : prayerMethodsError ? (
                  renderErrorMessage(prayerMethodsError)
                ) : (
                  <PrayerMethodsCarousel 
                    prayerMethods={prayerMethods} 
                    selectedDate={selectedDate} 
                    types={['relaxation-prayer', 'rosary-meditation', 'daily-reflection','contemplative-meditation']} 
                    showAllSections={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card my-4">
              <div className="card-body">
                <SeriesCarousel />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card my-4">
              <div className="card-body">
                {prayerMethodsLoading ? (
                  <LoadingSpinner />
                ) : prayerMethodsError ? (
                  <div>Error: {prayerMethodsError}</div>
                ) : saintsMeditation ? (
                  <SaintsPromoCard saintsMeditation={saintsMeditation} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card my-4" style={{ border: 'none' }}>
              <RecommendationPromo />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
