import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import recommendationQuestions from '../../data/recommendation_question.json';
import RecommendationDisplay from './RecommendationDisplay';
import styles from '../../styles/recommendationForm.module.css';
import LoadingSpinner from '../common/LoadingSpinner';
import { UserAnswers } from '@shared/types/recommendation';
import { useRecommendation } from '../../hooks/useRecommendation';

interface Question {
  id: string;
  question: string;
  type: string;
  purpose: string;
  options: Array<{
    id: string;
    label: string;
    themes?: string[];
    suitableMethods?: string[];
  }>;
}

const RecommendationForm: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [answers, setAnswers] = useState<UserAnswers>({});
  const { recommendation, loading: isLoading, getRecommendation } = useRecommendation();

  const questions: Question[] = recommendationQuestions.questions;

  const handleAnswerChange = (questionId: string, answer: string) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer
    }));
    
    setTimeout(() => {
      const navigationButtons = document.querySelector(`.${styles.navigationButtons}`);
      if (navigationButtons) {
        navigationButtons.scrollIntoView({ 
          behavior: 'smooth',
          block: 'center'
        });
      }
    }, 100);
  };

  const handleNext = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      window.scrollTo(0, 0);
    } else {
      await getRecommendation(answers);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      window.scrollTo(0, 0);
    }
  };

  const currentQuestion = questions[currentQuestionIndex];

  if (isLoading) {
    return <LoadingSpinner message="Loading your scripture and meditation recommendations..." />;
  }

  if (recommendation) {
    return (
      <RecommendationDisplay 
        recommendation={recommendation} 
        userAnswers={answers}
        onRetake={() => {
          setCurrentQuestionIndex(0);
          setAnswers({});
        }}
      />
    );
  }

  return (
    <Container className={styles.formContainer} style={{ marginTop: '1rem' }}>
      <h2 className={styles.questionTitle}>{currentQuestion.question}</h2>
      <Form>
        {currentQuestion.type === 'single-select' && (
          <div className={styles.optionsContainer}>
            {currentQuestion.options.map(option => (
              <Form.Check
                key={option.id}
                type="radio"
                id={option.id}
                name={currentQuestion.id}
                label={option.label}
                checked={answers[currentQuestion.id as keyof UserAnswers] === option.id}
                onChange={() => handleAnswerChange(currentQuestion.id, option.id)}
                className={styles.radioInput}
              />
            ))}
          </div>
        )}
        <div className={styles.navigationButtons}>
          <Button 
            variant="secondary"
            onClick={handlePrevious} 
            disabled={currentQuestionIndex === 0}
            className={`${styles.navButton} ${currentQuestionIndex === 0 ? styles.disabled : ''}`}
          >
            Previous
          </Button>
          <Button 
            variant="primary"
            onClick={handleNext}
            className={styles.navButton}
          >
            {currentQuestionIndex < questions.length - 1 ? 'Next' : 'Submit'}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default RecommendationForm;
