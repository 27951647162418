import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';

export const useBibleRecommendations = () => {
  const [recommendations, setRecommendations] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const bibleRecommendationsRef = collection(firestore, 'bibleRecommendations');
        const snapshot = await getDocs(bibleRecommendationsRef);
        const data = {};
        snapshot.forEach((doc) => {
          data[doc.id] = doc.data().recommendations;
        });
        setRecommendations(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch Bible recommendations');
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  return { recommendations, loading, error };
};
