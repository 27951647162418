import { PrayerMethod } from '@shared/types/prayerMethod';
import { PrayerActivityData } from '@shared/types/prayerHistory';

export type PrayerMethodType = 
  | 'daily-meditation'
  | 'daily-prayer'
  | 'relaxation-prayer'
  | 'rosary-meditation'
  | 'daily-reflection'
  | 'contemplative-meditation'
  | 'saints-meditation'
  | 'daily-discussion';

export interface BasePrayerRequirements {
  title: string;
  prayerType: PrayerMethodType;
  completionType: 'read' | 'listen' | 'reflection';
  date: string; // completion date in YYYY-MM-DD format
  metadata?: {
    totalSteps?: number;
    [key: string]: any;
  };
}

export interface DailyGospelRequirements extends BasePrayerRequirements {
  prayerType: 'daily-meditation' | 'daily-prayer';
  scriptureReference: string;
  gospelDate: string;
  gospelReference?: string;
  metadata?: {
    citation?: string;
    [key: string]: any;
  };
  uniqueKey: `${string}_${string}`; // ${prayerMethodId}_${gospelDate}
}

export interface CustomScriptureRequirements extends BasePrayerRequirements {
  prayerType: 'daily-meditation' | 'daily-prayer';
  scriptureReference: string;
  metadata: {
    customScripture: true;
    [key: string]: any;
  };
  uniqueKey: `${string}_${string}`; // ${prayerMethodId}_${scriptureReference}
}

export interface RosaryMeditationRequirements extends BasePrayerRequirements {
  prayerType: 'rosary-meditation';
  metadata: {
    styleId: string;
    mysteryType: 'Joyful' | 'Luminous' | 'Sorrowful' | 'Glorious';
    [key: string]: any;
  };
  uniqueKey: `${string}_${string}_${string}`; // ${prayerMethodId}_${styleId}_${completionDate}
}

export interface DailyReflectionRequirements extends BasePrayerRequirements {
  prayerType: 'daily-reflection';
  completionType: 'reflection';
  uniqueKey: `${string}_${string}`; // ${prayerMethodId}_${completionDate}
}

export interface SimpleTrackingRequirements extends BasePrayerRequirements {
  prayerType: 'relaxation-prayer' | 'contemplative-meditation';
  uniqueKey: `${string}_${string}`; // ${prayerMethodId}_${completionDate}
}

export interface SaintsMeditationRequirements extends BasePrayerRequirements {
  prayerType: 'saints-meditation';
  completionType: 'reflection';
  metadata: {
    saintId: string;
    saintMeditationType: 'life' | 'meditation';
    [key: string]: any;
  };
  uniqueKey: `${string}_${string}_${string}`; // ${prayerMethodId}_${saintId}_${completionDate}
}

export interface DiscussionTrackingRequirements extends BasePrayerRequirements {
  prayerType: 'daily-discussion';
  uniqueKey: `${string}_${string}`; // ${prayerMethodId}_${date}
  skipTracking?: boolean;
}

export interface BasePrayerActivityInput {
  userId?: string;
  title: string;
  date?: string;
  metadata?: {
    [key: string]: any;
  };
  scriptureReference?: string;
  gospelReference?: string;
  gospelDate?: string;
}

// Type guard functions
export const isGospelPrayer = (
  method: PrayerMethod,
  data: BasePrayerActivityInput
): boolean => {
  return !!(
    (method.type === 'daily-meditation' || method.type === 'daily-prayer') &&
    (data.gospelDate || data.metadata?.gospelDate) &&
    !data.metadata?.isCustomScripture
  );
};

export const isPrayerType = (
  method: PrayerMethod,
  type: PrayerMethodType
): boolean => {
  return method.type === type;
};

export const isCustomScripturePrayer = (
  data: Partial<PrayerActivityData>
): data is CustomScriptureRequirements => {
  return !!(
    data.metadata?.isCustomScripture ||
    (data.scriptureReference && !data.gospelDate)
  );
};

export const isRosaryPrayer = (
  data: Partial<PrayerActivityData>
): data is RosaryMeditationRequirements => {
  return !!(data.metadata?.styleId && data.metadata?.mysteryType);
};

export const isSaintsMeditation = (
  data: Partial<PrayerActivityData>
): data is SaintsMeditationRequirements => {
  return !!(
    data.prayerType === 'saints-meditation' &&
    data.metadata?.saintId &&
    data.metadata?.saintMeditationType
  );
};