import React from 'react';
import { Feather } from 'lucide-react';
import SaintsPlayer from '../audio/SaintsPlayer';
import SaintMeditationSteps from './SaintMeditationSteps';

const SaintMeditation = ({ saintData, meditationData }) => {
  return (
    <div>
      <h2 className="saint-meditation-title">Meditation with {saintData.name}</h2>

      <SaintsPlayer
        saintData={saintData}
        saintMeditationData={meditationData}
        isDiscussion={false}
      />
      <hr className="divider" />
      <section className="meditation-steps-section">
        <h3><Feather size={24} className="section-icon" /> Meditation Steps</h3>
        <SaintMeditationSteps 
          steps={meditationData.meditationSteps} 
          saintData={saintData}
        />
      </section>
    </div>
  );
};

export default SaintMeditation;
