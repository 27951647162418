import React, { useState, useEffect } from 'react';
import { FlexibleStepNavigation } from '../common/StepNavigation';
import { ContemplationStepsProps } from '@shared/types/contemplation';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';

const ContemplationSteps: React.FC<ContemplationStepsProps> = ({ steps, prayerMethod }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const { hasCompleted, markComplete } = usePrayerProgress({
    prayerMethod,
    prayerData: {
      title: `Contemplation Meditation: ${prayerMethod.name}`,
      date: new Date().toISOString().split('T')[0],
      metadata: {
        totalSteps: steps.length
      }
    },
    completionType: 'read',
    trigger: {
      currentStep,
      totalSteps: steps.length
    }
  });

  useEffect(() => {
    if (currentStep === steps.length - 1 && !hasCompleted) {
      markComplete();
    }
  }, [currentStep, steps.length, hasCompleted, markComplete]);

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const currentStepData = steps[currentStep];

  return (
    <div className="contemplation-steps">
      <h3>{currentStepData.title}</h3>
      <p>{currentStepData.description}</p>
      <h4>Guidance</h4>
      <p>{currentStepData.guidance}</p>
      <h4>Reflection</h4>
      <p>{currentStepData.reflection}</p>

      <FlexibleStepNavigation
        currentIndex={currentStep}
        totalSteps={steps.length}
        onPrevious={prevStep}
        onNext={nextStep}
        onStepClick={setCurrentStep}
        stepTitle={`Step ${currentStep + 1} of ${steps.length}`}
      />
    </div>
  );
};

export default ContemplationSteps;