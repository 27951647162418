import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { auth, getAuthToken } from '../firebase';
import { API_ENDPOINTS } from './apiConfig';
import { PrayerMethod, ScriptureData, AudioScript } from '@shared/types/customScripture';
import { PrayerActivityData } from '@shared/types/prayerHistory';
import { UserAnswers, RecommendationResponse } from '@shared/types/recommendation';

const getApiBaseUrl = (): string => {
  if (process.env.REACT_APP_NODE_ENV === 'development') {
    if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
      return `http://localhost:5001/${process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID}/us-central1/api`;
    }
    return `https://us-central1-${process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID}.cloudfunctions.net/api`;
  }
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    if (typeof window !== 'undefined' && window.location.hostname === 'localhost' && process.env.REACT_APP_USE_EMULATOR_FOR_LOCAL_DEV === 'TRUE') {
      return `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api`;
    }
    return process.env.REACT_APP_FIREBASE_FUNCTIONS_URL || '';
  }
  return '';
};

const API_BASE_URL = getApiBaseUrl();

const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await getAuthToken();
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getApiUrl = (endpoint: string): string => `${API_BASE_URL}/${endpoint}`;

export const generateAudioPrayer = (data: any) => api.post(API_ENDPOINTS.GENERATE_AUDIO_PRAYER, data);
export const generateAudioScript = (data: any) => api.post(API_ENDPOINTS.GENERATE_AUDIO_SCRIPT, data);
export const generateDiscussionScript = (data: any) => api.post(API_ENDPOINTS.GENERATE_DISCUSSION_SCRIPT, data);
export const generateDiscussionAudio = (data: any) => api.post(API_ENDPOINTS.GENERATE_DISCUSSION_AUDIO, data);
export const generateRosaryAudio = (data: any) => api.post(API_ENDPOINTS.GENERATE_ROSARY_AUDIO, data);
export const generateContemplationAudio = (data: any) => api.post(API_ENDPOINTS.GENERATE_CONTEMPLATION_AUDIO, data);
export const generatePostingPlan = (data: any) => api.post(API_ENDPOINTS.GENERATE_POSTING_PLAN, data);
export const generateStainedGlassImage = (data: any) => api.post(API_ENDPOINTS.GENERATE_STAINED_GLASS_IMAGE, data);
export const saveStainedGlassImage = (data: any) => api.post(API_ENDPOINTS.SAVE_STAINED_GLASS_IMAGE, data);
export const generateSaintMeditation = (data: any) => api.post(API_ENDPOINTS.GENERATE_SAINT_MEDITATION, data);
export const saveSaintData = (data: any) => api.post(API_ENDPOINTS.SAVE_SAINT_DATA, data);
export const generateSaintImage = (data: any) => api.post(API_ENDPOINTS.GENERATE_SAINT_IMAGE, data);
export const generateSaintMeditationAudio = (data: any) => api.post(API_ENDPOINTS.GENERATE_SAINT_MEDITATION_AUDIO, data);
export const updateSaintData = (data: any) => api.put(API_ENDPOINTS.UPDATE_SAINT_DATA, data);
export const fetchBookContent = (book: string) => api.get(API_ENDPOINTS.FETCH_BOOK_CONTENT(book));

export const generateCustomScriptureMeditation = (data: any) => {
  console.log('Sending request to generate custom scripture meditation:', data);
  return api.post(API_ENDPOINTS.CUSTOM_SCRIPTURE_MEDITATION, data)
    .then(response => {
      console.log('Custom scripture meditation response:', response);
      return response;
    })
    .catch(error => {
      console.error('Error generating custom scripture meditation:', error);
      throw error;
    });
};

export const fetchCustomScripture = (scripture: string) => api.get(API_ENDPOINTS.FETCH_CUSTOM_SCRIPTURE, { params: { scripture } });
export const generateCustomMeditationScript = (data: any) => api.post(API_ENDPOINTS.GENERATE_CUSTOM_MEDITATION_SCRIPT, data);
export const generateCustomMeditationAudio = (data: any) => api.post(API_ENDPOINTS.GENERATE_CUSTOM_MEDITATION_AUDIO, data);

export const generateRecommendation = (data: any) => api.post(API_ENDPOINTS.GENERATE_RECOMMENDATION, data);

interface LogPrayerActivityParams {
  userId: string;
  prayerMethod: PrayerMethod;
  prayerData: PrayerActivityData;
}

export const logPrayerActivity = ({ 
  userId, 
  prayerMethod, 
  prayerData 
}: LogPrayerActivityParams) => {
  const normalizedPrayerData = {
    ...prayerData,
    scriptureReference: prayerData.gospelReference || prayerData.scriptureReference || null,
    gospelReference: prayerData.gospelReference || prayerData.scriptureReference || null
  };

  console.log('logPrayerActivity', { userId, prayerMethod, normalizedPrayerData });
  return api.post(API_ENDPOINTS.PRAYER_HISTORY.LOG, {
    userId,
    prayerMethod,
    prayerData: normalizedPrayerData
  });
};

export const fetchPrayerHistory = (userId: string, limit?: number) => {
  return api.get(API_ENDPOINTS.PRAYER_HISTORY.GET(userId), { 
    params: { limit } 
  });
};

export const deletePrayerHistoryItem = (userId: string, prayerId: string) => {
  return api.delete(API_ENDPOINTS.PRAYER_HISTORY.DELETE(userId, prayerId));
};

export const saveRecommendation = ({ 
  userId, 
  userAnswers, 
  recommendation 
}: { 
  userId: string;
  userAnswers: UserAnswers;
  recommendation: RecommendationResponse['recommendation'];
}) => {
  return api.post(API_ENDPOINTS.RECOMMENDATION.SAVE, {
    userId,
    userAnswers,
    recommendation
  });
};

export const fetchPrayerPlan = (userId: string) => {
  return api.get(API_ENDPOINTS.PRAYER_PLAN.GET(userId));
};

export const addToPrayerPlan = (data: {
  userId: string;
  scriptureReference: string;
  scriptureTheme: string;
  prayerMethodId: string;
  prayerMethodName: string;
}) => {
  return api.post(API_ENDPOINTS.PRAYER_PLAN.ADD, data);
};

export const reorderPrayerPlan = (userId: string, itemId: string, newOrder: number) => {
  return api.put(API_ENDPOINTS.PRAYER_PLAN.REORDER(userId), { itemId, newOrder });
};

export const completePrayerPlanItem = (userId: string, itemId: string) => {
  return api.put(API_ENDPOINTS.PRAYER_PLAN.COMPLETE(userId), { itemId });
};

export const deletePrayerPlanItem = (userId: string, itemId: string) => {
  return api.delete(API_ENDPOINTS.PRAYER_PLAN.DELETE(userId, itemId));
};

export const updateSeriesProgress = async (data: {
  seriesId: string;
  itemId: string;
  prayerHistoryId: string;
}) => {
  console.log('[API] Calling updateSeriesProgress:', data);
  try {
    const response = await api.post(API_ENDPOINTS.SERIES_PROGRESS.UPDATE, data);
    console.log('[API] Series progress update response:', response);
    return response;
  } catch (error) {
    console.error('[API] Series progress update error:', error);
    throw error;
  }
};

export const getSeriesProgress = (userId: string, seriesId: string) => {
  return api.get(API_ENDPOINTS.SERIES_PROGRESS.GET(userId, seriesId));
};

export const listSeriesProgress = (userId: string) => {
  return api.get(API_ENDPOINTS.SERIES_PROGRESS.LIST(userId));
};

export const generateHomily = (data: { gospelContent: string, date: string, styleId?: string }) => 
  data.styleId ? 
    api.post(API_ENDPOINTS.DAILY_HOMILIES.GENERATE_WITH_STYLE(data.styleId), data) :
    api.post(API_ENDPOINTS.DAILY_HOMILIES.GENERATE, data);

export const generateHomilyInspiration = (data: { gospelContent: string, date: string }) => 
  api.post(API_ENDPOINTS.DAILY_HOMILIES.GENERATE_INSPIRATION, data);

export const fixScriptDurations = (data: {
  prayerMethod: PrayerMethod;
  scriptureData: ScriptureData;
  script: AudioScript[];
}) => api.post(API_ENDPOINTS.FIX_SCRIPT_DURATIONS, data);

const apiWithMethods = {
  ...api,
  get: api.get,
  post: api.post,
  put: api.put,
  delete: api.delete,
  generateAudioPrayer,
  generateAudioScript,
  generateDiscussionScript,
  generateDiscussionAudio,
  generateRosaryAudio,
  generateContemplationAudio,
  generatePostingPlan,
  generateStainedGlassImage,
  saveStainedGlassImage,
  generateSaintMeditation,
  saveSaintData,
  generateSaintImage,
  generateSaintMeditationAudio,
  updateSaintData,
  generateCustomScriptureMeditation,
  fetchBookContent,
  generateCustomMeditationScript,
  generateCustomMeditationAudio,
  generateRecommendation,
  fetchCustomScripture,
  logPrayerActivity,
  fetchPrayerHistory,
  deletePrayerHistoryItem,
  saveRecommendation,
  fetchPrayerPlan,
  addToPrayerPlan,
  reorderPrayerPlan,
  completePrayerPlanItem,
  deletePrayerPlanItem,
  updateSeriesProgress,
  getSeriesProgress,
  listSeriesProgress,
  generateHomily,
  generateHomilyInspiration,
  fixScriptDurations
};

export default apiWithMethods;
