import { useState, useEffect, useRef, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import api from '../utils/api';
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import { HomilyResponse, Homily } from '@shared/types/homily';

export const useHomilies = (gospelContent: string | null, date: string) => {
  const [homilyData, setHomilyData] = useState<HomilyResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const previousDateRef = useRef<string>(date);

  const fetchHomily = useCallback(async () => {
    if (!gospelContent || !date) return;
    
    setLoading(true);
    setError(null);
    setHomilyData(null);

    try {
      const formattedDate = format(parseISO(date), 'yyyy-MM-dd');
      console.log('Fetching homily for date:', formattedDate);
      
      const docRef = doc(firestore, FIRESTORE_PATHS.DAILY_HOMILIES.path(formattedDate));
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Found existing homily');
        const docData = docSnap.data();
        
        const stylesCollectionRef = collection(docRef, 'styles');
        const stylesSnapshot = await getDocs(stylesCollectionRef);
        
        const styles: Record<string, Homily> = {};
        stylesSnapshot.forEach((styleDoc) => {
          styles[styleDoc.id] = styleDoc.data() as Homily;
        });
        
        setHomilyData({
          ...docData,
          styles,
          timestamp: docData.timestamp || new Date()
        } as HomilyResponse);
      } else {
        console.log('Generating new homily');
        await api.generateHomily({ 
          gospelContent, 
          date: formattedDate 
        });
        
        // Poll for the result every 5 seconds for up to 1 minute
        let attempts = 0;
        const maxAttempts = 12; // 12 attempts * 5 seconds = 1 minute max

        const checkForHomily = async () => {
          const updatedDocSnap = await getDoc(docRef);
          if (updatedDocSnap.exists()) {
            const updatedData = updatedDocSnap.data();
            const stylesCollectionRef = collection(docRef, 'styles');
            const stylesSnapshot = await getDocs(stylesCollectionRef);
            
            const styles: Record<string, Homily> = {};
            stylesSnapshot.forEach((styleDoc) => {
              styles[styleDoc.id] = styleDoc.data() as Homily;
            });
            
            setHomilyData({
              ...updatedData,
              styles,
              timestamp: updatedData.timestamp || new Date()
            } as HomilyResponse);
            return true;
          }
          return false;
        };

        while (attempts < maxAttempts) {
          const found = await checkForHomily();
          if (found) break;
          await new Promise(resolve => setTimeout(resolve, 5000));
          attempts++;
        }

        if (attempts >= maxAttempts) {
          throw new Error('Timeout waiting for homily generation');
        }
      }
    } catch (err) {
      console.error('Error in fetchHomily:', err);
      setError(`Error fetching homily: ${err instanceof Error ? err.message : 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  }, [gospelContent, date]);

  useEffect(() => {
    if (date !== previousDateRef.current) {
      previousDateRef.current = date;
      if (gospelContent) {
        fetchHomily();
      }
    } else if (gospelContent && !homilyData) {
      fetchHomily();
    }
  }, [gospelContent, date, fetchHomily, homilyData]);

  return { homilyData, loading, error };
};