import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { format, parseISO, addDays, subDays } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import PrayerComponent from './PrayerComponent';
import LoadingSpinner from '../common/LoadingSpinner';
import { usePrayerMethod } from '../../hooks/usePrayerMethod';
import { getPrayerMethodRoute } from '../../utils/routeHelpers';
import { useDailyPrayer } from '../../hooks/useDailyPrayer';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import { PrayerTrackingProvider } from '../../contexts/PrayerTrackingContext';

const DailyPrayerMethodContainer = () => {
  const { methodLink, date } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // If date is not provided, use the current date
  const currentDate = date ? parseISO(date) : new Date();
  const formattedDate = format(currentDate, 'yyyy-MM-dd');

  const { prayerMethod, loading: methodLoading, error: methodError } = usePrayerMethod(methodLink);
  const { prayerData, loading, error } = useDailyPrayer(prayerMethod, formattedDate);
  const { prayerMethods, loading: prayerMethodsLoading, error: prayerMethodsError } = usePrayerMethods();

  const handleDateChange = (direction) => {
    const newDate = direction === 'prev' ? subDays(currentDate, 1) : addDays(currentDate, 1);
    const newRoute = getPrayerMethodRoute(prayerMethod, newDate);
    navigate(newRoute + location.search);
  };

  useEffect(() => {
    console.log('Container Data:', {
      prayerData,
      prayerMethod,
      loading,
      methodLoading,
      error,
      methodError
    });
  }, [prayerData, prayerMethod, loading, methodLoading, error, methodError]);

  if (methodLoading || loading) return <LoadingSpinner message="Preparing your meditation..." />;
  if (methodError || error) return (
    <div className="error-container">
      <h2>Oops! Something went wrong</h2>
      <p>We encountered an error while loading your prayer:</p>
      <div className="error-message mt-3">{methodError || error}</div>
      <p className="mt-3">Please try refreshing the page. If the problem persists, contact support.</p>
    </div>
  );
  if (!prayerMethod || !prayerData) return <div>No data available</div>;

  const displayDate = format(currentDate, 'MMMM d, yyyy');

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{`${prayerMethod.name} - ${displayDate} | Saintly Whispers`}</title>
        <meta name="description" content={prayerMethod.description} />
        <meta property="og:title" content={`${prayerMethod.name} - ${displayDate} | Saintly Whispers`} />
        <meta property="og:description" content={prayerMethod.description} />
        <meta property="og:image" content={prayerMethod.imageUrl} />
        <meta property="og:url" content={`https://saintlywhispers.com${location.pathname}`} />
        <meta property="og:type" content="article" />
      </Helmet>
      <PrayerTrackingProvider 
        prayerData={prayerData} 
        prayerMethod={prayerMethod}
      >
        <PrayerComponent
          prayerData={prayerData}
          prayerMethod={prayerMethod}
          loading={loading || methodLoading}
          error={error || methodError}
          onDateChange={handleDateChange}
          currentDate={formattedDate}
          allPrayerMethods={prayerMethods}
          prayerMethodsLoading={prayerMethodsLoading}
          prayerMethodsError={prayerMethodsError}
        />
      </PrayerTrackingProvider>
    </>
  );
};

export default DailyPrayerMethodContainer;
