import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import { PrayerMethod } from '@shared/types/prayerMethod';

type MethodIdentifier = string | { id: string } | null;

export const usePrayerMethod = (methodIdentifier: MethodIdentifier) => {
  const [prayerMethod, setPrayerMethod] = useState<PrayerMethod | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrayerMethod = async () => {
      if (!methodIdentifier) {
        setLoading(false);
        return;
      }

      try {
        const prayerMethodsRef = collection(firestore, 'prayerMethods');
        const idQuery = query(prayerMethodsRef, where("id", "==", methodIdentifier));
        const linkQuery = query(prayerMethodsRef, where("link", "==", methodIdentifier));

        const [idSnapshot, linkSnapshot] = await Promise.all([
          getDocs(idQuery),
          getDocs(linkQuery)
        ]);

        const combinedResults = [...idSnapshot.docs, ...linkSnapshot.docs];

        if (combinedResults.length === 0) {
          setPrayerMethod(null);
          setLoading(false);
          return;
        }

        const prayerMethodData = combinedResults[0].data() as PrayerMethod;
        setPrayerMethod(prayerMethodData);
      } catch (err) {
        setError(`Error fetching prayer method: ${err instanceof Error ? err.message : 'Unknown error'}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPrayerMethod();
  }, [methodIdentifier]);

  return { prayerMethod, loading, error };
};
