import React from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import { useSaintsPlayer } from '../../hooks/useSaintsPlayer';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';

const SaintsPlayer = ({ saintData, saintMeditationData, isDiscussion = false }) => {
  const { script, isLoading, error, fetchScript, generateAudio } = useSaintsPlayer(saintData.id, saintMeditationData, isDiscussion);
  const { prayerMethod, prayerData } = usePrayerTracking();

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
    />
  );

  const title = isDiscussion 
    ? `Saint Discussion: ${saintData.name}`
    : `Saint Meditation: ${saintData.name}`;
  const storagePath = isDiscussion
    ? STORAGE_PATHS.SAINT_AUDIO_DISCUSSIONS(saintData.id)
    : STORAGE_PATHS.SAINT_AUDIO_MEDITATIONS(saintData.id);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <BaseAudioPlayer
      fetchScript={fetchScript}
      generateAudioEndpoint={generateAudio}
      renderTranscript={renderTranscript}
      title={title}
      date={null}
      storagePath={storagePath}
      completionThreshold={5}
    />
  );
};

export default SaintsPlayer;