import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/RecommendationPromo.module.css';
import { Sparkles } from 'lucide-react';

interface RecommendationPromoProps {
  size?: 'mini' | 'large';
}

const RecommendationPromo: React.FC<RecommendationPromoProps> = ({ size = 'large' }) => {
  const navigate = useNavigate();

  if (size === 'mini') {
    return (
      <div className={`${styles.promoCard} ${styles.miniCard}`} onClick={() => navigate('/advisor')}>
        <div className={styles.promoContent}>
          <div className={styles.iconWrapper}>
            <Sparkles size={20} />
          </div>
          <div className={styles.textContent}>
            <h3 className={styles.promoTitle}>Get Personalized Scripture & Prayer Recommendations</h3>
          </div>
          <button className={`${styles.promoButton} ${styles.miniButton}`}>Get Started</button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.promoCard} onClick={() => navigate('/advisor')}>
      <div className={styles.promoContent}>
        <div className={styles.iconWrapper}>
          <Sparkles size={24} />
        </div>
        <div className={styles.textContent}>
          <h3 className={styles.promoTitle}>Get Personalized Scripture & Prayer Recommendations</h3>
          <p className={styles.promoDescription}>
            Answer a few questions to receive scripture passages and prayer methods tailored to your spiritual journey
          </p>
        </div>
      </div>
      <button className={styles.promoButton}>Get Started</button>
    </div>
  );
};

export default RecommendationPromo;
