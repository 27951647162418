import React, { useState, useCallback, useEffect } from 'react';
import StepperProgress from '../common/StepperProgress';
import NewStepNavigation from '../common/NewStepNavigation';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';
import { DailyPrayer } from '@shared/types/dailyPrayer';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { SinglePrayerInstructionComponentProps } from '@shared/types/dailyPrayer';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';

const SinglePrayerInstructionComponent: React.FC<SinglePrayerInstructionComponentProps> = ({
  prayerData,
  prayerMethod,
  isCustomScripture,
  seriesContext
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { 
    prayerData: prayerDataTracking, 
    prayerMethod: prayerMethodTracking,
    seriesProgress 
  } = usePrayerTracking();

  // Type guard function
  const isDailyPrayer = (data: any): data is DailyPrayer => {
    return data && 'steps' in data && Array.isArray(data.steps);
  };

  const defaultPrayerMethod: PrayerMethod = {
    id: '',
    name: '',
    type: 'daily-prayer',
    description: '',
    long_description: '',
    imageUrl: '',
    link: '',
    status: 'active',
    'api-link': '',
    promptFile: '',
    schemaFile: '',
    displayOrder: 0
  };

  const { hasCompleted, markComplete } = usePrayerProgress({
    prayerMethod: prayerMethod || defaultPrayerMethod,
    prayerData: {
      title: prayerData && 'title' in prayerData ? prayerData.title : `Prayer: ${prayerMethod?.name || 'Unknown'}`,
      scriptureReference: prayerData && 'scriptureReference' in prayerData ? prayerData.scriptureReference : '',
      date: prayerData && 'date' in prayerData ? prayerData.date : new Date().toISOString().split('T')[0],
      metadata: {
        isCustomScripture,
        totalSteps: isDailyPrayer(prayerData) ? prayerData.steps.length : 0
      }
    },
    completionType: 'read',
    seriesContext: seriesProgress?.currentProgress?.currentItemId ? {
      seriesId: seriesProgress.currentProgress.seriesId,
      itemId: seriesProgress.currentProgress.currentItemId
    } : undefined,
    trigger: isDailyPrayer(prayerData) ? {
      currentStep,
      totalSteps: prayerData.steps.length
    } : undefined
  });

  const handleCompletion = useCallback(async () => {
    if (!prayerData || !isDailyPrayer(prayerData)) return;
    if (currentStep === prayerData.steps.length - 1 && !hasCompleted) {
      await markComplete();
    }
  }, [currentStep, prayerData, hasCompleted, markComplete]);

  useEffect(() => {
    handleCompletion();
  }, [handleCompletion]);

  // Now we can do our conditional returns
  if (!prayerData || !prayerMethod) {
    return <div>Loading prayer data...</div>;
  }

  if (!isDailyPrayer(prayerData)) {
    return <div>Invalid prayer data format</div>;
  }

  const { steps, prayer, affirmation, gospelTheme } = prayerData;

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  if (!steps.length) {
    return <div>No meditation steps available</div>;
  }

  return (
    <div className="prayer-instructions mt-4">
      {gospelTheme && (
        <div className="mb-4">
          <h2 className="h4 mb-3">Gospel Theme</h2>
          <p>{gospelTheme}</p>
        </div>
      )}

      {prayer && (
        <div className="mb-4">
          <h2 className="h4 mb-3">Prayer</h2>
          <p>{prayer}</p>
        </div>
      )}

      {affirmation && (
        <div className="mb-4">
          <h2 className="h4 mb-3">Affirmation</h2>
          <p>{affirmation}</p>
        </div>
      )}

      <StepperProgress
        steps={steps}
        currentStep={currentStep}
        onStepClick={setCurrentStep}
      />

      <div className="step-content mb-4" id="step-content">
        <h2 className="h4 mb-3">{steps[currentStep].title}</h2>
        <p className="mb-3">{steps[currentStep].description}</p>
        <h3 className="h5 mb-2">Affirmation:</h3>
        <p>{steps[currentStep].affirmation}</p>
        <h3 className="h5 mb-2">Reflection Question:</h3>
        <p>{steps[currentStep].reflectionQuestion}</p>
      </div>

      <NewStepNavigation
        currentStep={currentStep}
        totalSteps={steps.length}
        onPrevious={prevStep}
        onNext={nextStep}
        showStepTitles={false}
        scrollToId="step-content"
      />
    </div>
  );
};

export default SinglePrayerInstructionComponent;