import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import { ContemplationData, UseContemplationDataReturn } from '@shared/types/contemplation';
import { PrayerMethod } from '@shared/types/prayerMethod';

export const useContemplationData = (prayerMethod: PrayerMethod | null): UseContemplationDataReturn => {
  const [contemplationData, setContemplationData] = useState<ContemplationData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchContemplationData = async () => {
      if (!prayerMethod || !prayerMethod.id) {
        setLoading(false);
        return;
      }

      try {
        const contemplationRef = doc(firestore, FIRESTORE_PATHS.CONTEMPLATION_MEDITATIONS.path(prayerMethod.id));
        const contemplationSnap = await getDoc(contemplationRef);

        if (contemplationSnap.exists()) {
          setContemplationData(contemplationSnap.data() as ContemplationData);
        } else {
          throw new Error('Contemplation data not found');
        }
      } catch (err) {
        console.error('Error fetching contemplation data:', err);
        setError(`Error fetching contemplation data: ${err instanceof Error ? err.message : 'Unknown error'}`);
      } finally {
        setLoading(false);
      }
    };

    fetchContemplationData();
  }, [prayerMethod]);

  return { contemplationData, loading, error };
};