import React, { useState, useMemo } from 'react';
import { useBibleRecommendations } from '../../hooks/useBibleRecommendations';
import LoadingSpinner from '../common/LoadingSpinner';
import { Card } from 'react-bootstrap';
import styles from '../../styles/bibleRecommendations.module.css';
import FilterChips from '../common/FilterChips';
import { PrayerMethod, ScriptureData } from '@shared/types/customScripture';

interface Recommendation {
  book: string;
  chapter: number;
  verses: string;
  theme: string;
  meditationTheme: string;
  relevance: number;
  suitablePrayerMethods: string[];
  text: string;
}

interface BibleRecommendationsProps {
  onRecommendationSelect: (recommendation: Recommendation) => void;
  onSelectPrayerMethod: (prayerMethod: PrayerMethod) => void;
  prayerMethods: PrayerMethod[];
  scrollToTop: () => void;
  selectedScripture?: ScriptureData;
}

const BibleRecommendations: React.FC<BibleRecommendationsProps> = ({ 
  onRecommendationSelect, 
  onSelectPrayerMethod, 
  prayerMethods, 
  scrollToTop,
  selectedScripture
}) => {
  const { recommendations, loading, error } = useBibleRecommendations();
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);

  const sortedRecommendations = useMemo(() => {
    return Object.entries(recommendations || {})
      .flatMap(([book, recs]) => 
        (recs as Recommendation[]).map(rec => ({ ...rec, book }))
      )
      .sort((a, b) => b.relevance - a.relevance);
  }, [recommendations]);

  const meditationThemes = useMemo(() => {
    const themesWithRelevance = sortedRecommendations.reduce((acc: Record<string, number>, rec) => {
      if (!acc[rec.meditationTheme]) {
        acc[rec.meditationTheme] = rec.relevance;
      } else {
        acc[rec.meditationTheme] = Math.max(acc[rec.meditationTheme], rec.relevance);
      }
      return acc;
    }, {});
    return Object.entries(themesWithRelevance)
      .sort((a, b) => b[1] - a[1])
      .map(([theme]) => theme);
  }, [sortedRecommendations]);

  const filteredRecommendations = useMemo(() => {
    if (selectedThemes.length === 0) return sortedRecommendations;
    return sortedRecommendations.filter(rec => selectedThemes.includes(rec.meditationTheme));
  }, [sortedRecommendations, selectedThemes]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="alert alert-danger mt-3" role="alert">Error: {error}</div>;

  const handleThemeToggle = (theme: string) => {
    setSelectedThemes(prev => 
      prev.includes(theme) ? prev.filter(t => t !== theme) : [...prev, theme]
    );
  };

  return (
    <div className={styles.bibleRecommendationsContainer}>
      <h2 className={styles.recommendationsTitle}>Popular Passages</h2>
      <div className={styles.filterSection}>
        <label className={styles.filterLabel}>Filter passages by theme:</label>
        <FilterChips
          items={meditationThemes}
          activeItems={selectedThemes}
          onToggle={handleThemeToggle}
          limit={7}
        />
      </div>
      <div className={styles.recommendationsList}>
        {filteredRecommendations.map((rec, index) => {
          const prayerMethod = prayerMethods.find(method => method.id === rec.suitablePrayerMethods[0]);
          return (
            <Card key={index} className={styles.recommendationCard} onClick={() => onRecommendationSelect(rec)}>
              <Card.Body>
                <Card.Title className={styles.recommendationBook}>{rec.book}</Card.Title>
                <Card.Subtitle className={styles.recommendationCitation}>
                  <strong>Chapter {rec.chapter}, Verses {rec.verses}</strong>
                </Card.Subtitle>
                <Card.Text className={styles.recommendationTheme}>
                  <strong>Theme:</strong> {rec.theme}
                </Card.Text>
                <Card.Text className={styles.recommendationPrayerMethod}>
                  <strong>Prayer Method:</strong> {prayerMethod ? prayerMethod.name : 'Not specified'}
                </Card.Text>
              </Card.Body>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default BibleRecommendations;
