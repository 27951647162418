import React from 'react';
import { Card, Container, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { useRecommendation } from '../../hooks/useRecommendation';
import styles from '../../styles/recommendationHistory.module.css';
import { RecommendationHistoryItem } from '@shared/types/recommendation';
import { useNavigate } from 'react-router-dom';

interface RecommendationHistoryProps {
  variant?: 'full' | 'compact';
}

const RecommendationHistory: React.FC<RecommendationHistoryProps> = ({ variant = 'full' }) => {
  const containerClassName = `${styles.container} ${variant === 'compact' ? styles.compact : ''}`;
  const { recommendationHistory } = useRecommendation();
  const navigate = useNavigate();

  if (!recommendationHistory.length) {
    return (
      <Container className={styles.container}>
        <p>No recommendation history yet. Try getting a recommendation!</p>
      </Container>
    );
  }

  const handleCardClick = (historyItem: RecommendationHistoryItem) => {
    navigate(`/advisor?recommendationId=${historyItem.id}`);
  };

  const renderHistoryItems = (historyItems: RecommendationHistoryItem[]) => {
    return (
      <div className={styles.historyGrid}>
        {historyItems.map((item) => (
          <Card 
            key={item.id} 
            className={`${styles.historyCard} ${styles.clickable}`}
            onClick={() => handleCardClick(item)}
          >
            <Card.Body>
              <Card.Title>
                {format(new Date(item.timestamp), 'MMM d, yyyy h:mm a')}
              </Card.Title>
              <div className={styles.recommendationDetails}>
                {/* <h4>Primary Scripture</h4>
                <p>{item.recommendation.primaryScripture.reference}</p> */}
                <p className={styles.theme}>{item.recommendation.primaryScripture.theme}</p>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className={containerClassName}>
      {variant === 'full' ? (
        <h2 className={styles.title}>Recent Recommendations</h2>
      ) : (
        <h3 className={styles.compactTitle}>Recent Recommendations</h3>
      )}
      
      {variant === 'compact' ? 
        renderHistoryItems(recommendationHistory.slice(0, 3)) : 
        renderHistoryItems(recommendationHistory)
      }
      
      {variant === 'compact' && recommendationHistory.length > 3 && (
        <Button 
          variant="link" 
          className={styles.viewAllButton}
          onClick={() => navigate('/advisor?tab=history')}
        >
          View All History
        </Button>
      )}
    </div>
  );
};

export default RecommendationHistory; 