import React from 'react';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <div className="error-container d-flex flex-column justify-content-center align-items-center vh-100">
      <h2>Oops! Something went wrong</h2>
      {message ? (
        <>
          <p>We encountered an error:</p>
          <div className="error-message mt-3">{message}</div>
        </>
      ) : (
        <p>An unexpected error occurred.</p>
      )}
      <p className="mt-3">Please try refreshing the page. If the problem persists, contact support.</p>
    </div>
  );
};

export default ErrorMessage;
