import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import styles from '../../styles/StepNavigation.module.css';

interface StepNavigationProps {
  currentStep: number;
  totalSteps: number;
  onPrevious: () => void;
  onNext: () => void;
  onStepClick?: (index: number) => void;
  stepTitle?: string;
  showStepTitles?: boolean;
  scrollToId?: string;  // New prop
}

const NewStepNavigation: React.FC<StepNavigationProps> = ({
  currentStep,
  totalSteps,
  onPrevious,
  onNext,
  onStepClick,
  stepTitle,
  showStepTitles = true,
  scrollToId
}) => {
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === totalSteps - 1;

  const handleNavigation = (action: () => void) => {
    action();
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // Get the next and previous step titles
  const getNextStepTitle = () => {
    if (!showStepTitles || !stepTitle) return null;
    return "Next Step";
  };

  const getPreviousStepTitle = () => {
    if (!showStepTitles || !stepTitle) return null;
    return "Previous Step";
  };

  return (
    <div className={styles.navigationContainer}>
      {stepTitle && (
        <h2 className={styles.stepTitle}>{stepTitle}</h2>
      )}
      <div className={styles.navigationControls}>
        <div className={styles.buttonContainer}>
          {!isFirstStep && (
            <button 
              className={styles.previousButton}
              onClick={() => handleNavigation(onPrevious)}
            >
              <ChevronLeft size={18} />
              <span className={styles.buttonText}>
                Previous
                {/* {showStepTitles && stepTitle && (
                  <small className={styles.previousStepTitle}>Previous Step</small>
                )} */}
              </span>
            </button>
          )}
        </div>

        <div className={styles.stepProgress}>
          {Array.from({ length: totalSteps }, (_, index) => (
            <div
              key={index}
              className={`${styles.stepDot} ${index === currentStep ? styles.active : ''}`}
              onClick={() => onStepClick?.(index)}
            />
          ))}
        </div>

        <div className={styles.buttonContainer}>
          {!isLastStep && (
            <button 
              className={styles.nextButton}
              onClick={() => handleNavigation(onNext)}
            >
              <span className={styles.nextButtonText}>
                Next
                {/* {showStepTitles && stepTitle && !isLastStep && (
                  <small className={styles.nextStepTitle}>Next Step</small>
                )} */}
              </span>
              <ChevronRight size={18} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewStepNavigation;
