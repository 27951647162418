import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectStorageEmulator } from 'firebase/storage';
import { connectAuthEmulator } from 'firebase/auth';

// const PROD_PROJECT_ID = "lofh-dbbbb";
// const PROJECT_ID = process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID || process.env.REACT_APP_FIREBASE_PROJECT_ID;
// const isProduction = PROJECT_ID === PROD_PROJECT_ID;

const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

// console.log("Project ID:", PROJECT_ID);
// console.log("REACT_APP_FIREBASE_PROJECT_ID:", process.env.REACT_APP_FIREBASE_PROJECT_ID);
// console.log("REACT_APP_DEV_FIREBASE_PROJECT_ID:", process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID);
// console.log('Current NODE_ENV:', process.env.NODE_ENV);
// console.log('REACT_APP_NODE_ENV:', process.env.REACT_APP_NODE_ENV);
console.log('Is Production:', isProduction);

const firebaseConfig = isProduction
  ? {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID
    }
  : {
      apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID
    };

const app = initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
}

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export const signInAnonymouslyIfNeeded = async () => {
  if (!auth.currentUser) {
    try {
      const userCredential = await signInAnonymously(auth);
      console.log('Signed in anonymously');
      return userCredential.user;
    } catch (error) {
      console.error('Error signing in anonymously:', error);
      throw error;
    }
  } else {
    console.log('User already signed in:', auth.currentUser.isAnonymous ? 'anonymously' : 'with account');
    return auth.currentUser;
  }
};

export const getAuthToken = () => auth.currentUser ? auth.currentUser.getIdToken() : null;

export default app;