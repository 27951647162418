import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { useSaintsData } from '../../hooks/useSaintsData';
import '../../styles/saintsPromoCard.css';

const SaintsPromoCard = ({ saintsMeditation }) => {
  const { saints, loading, error } = useSaintsData();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const topSaints = saints
    .filter(saint => saint.status === 'published')
    .sort((a, b) => (a.displayOrder || Infinity) - (b.displayOrder || Infinity))
    .slice(0, 3);

  return (
    <div className="saints-promo-card">
      <div className="saints-promo-header">
        <h2>Lives of the Saints</h2>
        <p>{saintsMeditation.description}</p>
        <Link to="/saints" replace className="saints-promo-link">
          Try Saints Meditation <ArrowRight size={16} />
        </Link>
      </div>
      <div className="saints-promo-grid">
        {topSaints.map(saint => (
          <Link key={saint.id} to={`/saints/${saint.id}`} className="saint-promo-item">
            <img src={saint.imageUrl} alt={saint.name} className="saint-promo-image" />
            <p className="saint-promo-name">{saint.name}</p>
          </Link>
        ))}
        <Link to="/saints" replace className="saint-promo-item see-all">
          <div className="saint-promo-image see-all-image">
            <ArrowRight size={32} />
          </div>
          <p className="saint-promo-name">See All Saints</p>
        </Link>
      </div>
    </div>
  );
};

export default SaintsPromoCard;
