import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { Play, Pause, Loader } from 'lucide-react';
import '../../styles/audioPrayerComponent.css';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';

const BaseAudioPlayer = ({ 
  fetchScript, 
  generateAudioEndpoint, 
  renderTranscript, 
  title,
  date,
  storagePath,
  audioKey,
  customBreakDurations,
  initialDuration,
  script: initialScript,
  completionThreshold = 5,
  isRosaryMeditation = false,
  prayerData: propPrayerData
}) => {
  const trackingContext = usePrayerTracking();
  
  const effectivePrayerData = isRosaryMeditation 
    ? propPrayerData 
    : trackingContext.prayerData;

  const [currentTime, setCurrentTime] = useState(0);
  const [hasTriggeredCompletion, setHasTriggeredCompletion] = useState(false);

  const { hasCompleted, markComplete } = usePrayerProgress({
    prayerMethod: trackingContext.prayerMethod,
    prayerData: {
      ...effectivePrayerData,
      title: title || effectivePrayerData.title,
      completionType: 'listen'
    },
    completionType: 'listen',
    seriesContext: trackingContext.seriesProgress,
    trigger: hasTriggeredCompletion ? null : {
      currentTime,
      threshold: completionThreshold
    }
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [script, setScript] = useState(initialScript || []);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(() => {
    if (initialDuration && initialDuration > 0) {
      return initialDuration;
    } else if (initialScript) {
      return initialScript.reduce((total, item) => {
        const speakingDuration = item.speakingDuration || 0;
        const breakDuration = parseFloat(item.break?.replace('s', '') || '0');
        return total + speakingDuration + breakDuration;
      }, 0);
    }
    return 0;
  });
  const [audioReady, setAudioReady] = useState(false);
  const [error, setError] = useState(null);

  const [audioBuffers, setAudioBuffers] = useState([]);
  const [breakDurations, setBreakDurations] = useState(null);

  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const startTimeRef = useRef(0);
  const animationFrameRef = useRef(null);
  const initialFetchMadeRef = useRef(false);
  const audioElementRef = useRef(null);
  const wakeLockRef = useRef(null);

  const [hasLoggedActivity, setHasLoggedActivity] = useState(false);
  const [hasReachedThreshold, setHasReachedThreshold] = useState(false);

  useEffect(() => {
    console.log('[BaseAudioPlayer] Initializing with props:', {
      // seriesContext,
      prayerMethod: trackingContext.prayerMethod
    });
    console.log('[BaseAudioPlayer] completionThreshold:', completionThreshold);
  }, [trackingContext.prayerMethod]);

  const memoizedAudio = useMemo(() => {
    return async (scriptData) => {
      if (audioReady) return;
      setIsAudioLoading(true);
      setError(null);
      let totalDuration = 0;
      try {
        const storage = getStorage();

        const listRef = ref(storage, storagePath);
        let audioFiles = await listAll(listRef);

        if (audioFiles.items.length === 0) {
          console.log("No audio files found. Generating...");
          console.log('BASE AUDIO PLAYER PRAYER METHOD', trackingContext.prayerMethod);
          console.log('BASE AUDIO PLAYER GENERATE AUDIO PARAMS', {
            prayerMethod: trackingContext.prayerMethod,
            prayerData: effectivePrayerData,
            script: scriptData,
            date: date,
            currentMysteryId: trackingContext.prayerMethod.type === 'rosary-meditation' ? effectivePrayerData.id : null,
            rosaryMeditationId: trackingContext.prayerMethod.type === 'rosary-meditation' ? effectivePrayerData.rosaryMeditationId : null
          });
          await generateAudioEndpoint({
            prayerMethod: trackingContext.prayerMethod,
            prayerData: effectivePrayerData,
            script: scriptData,
            date: date,
            currentMysteryId: trackingContext.prayerMethod.type === 'rosary-meditation' ? effectivePrayerData.id : null,
            rosaryMeditationId: trackingContext.prayerMethod.type === 'rosary-meditation' ? effectivePrayerData.rosaryMeditationId : null
          });
          await new Promise(resolve => setTimeout(resolve, 2000));
          audioFiles = await listAll(listRef);
        }

        if (audioFiles.items.length === 0) {
          throw new Error('No audio files found after generation attempt');
        }

        // Sort audio files
        const sortedAudioFiles = audioFiles.items.sort((a, b) => {
          const aIndex = parseInt(a.name.split('_')[1]);
          const bIndex = parseInt(b.name.split('_')[1]);
          return aIndex - bIndex;
        });

        // Close existing AudioContext if it exists
        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
          await audioContextRef.current.close();
        }

        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const buffers = await Promise.all(sortedAudioFiles.map(async (item) => {
          const url = await getDownloadURL(item);
          return fetchAudioBuffer(url, audioContextRef.current);
        }));


        setAudioBuffers(buffers);

        let breaks;
        if (customBreakDurations) {
          breaks = customBreakDurations;
        } else {
          breaks = scriptData.map(item => {
            return parseFloat(item.break.replace('s', ''));
          });
        }

        setBreakDurations(breaks);

        const { finalBuffer, calculatedTotalDuration } = processAudioBuffers(buffers, breaks);
        audioBufferRef.current = finalBuffer;
        totalDuration = calculatedTotalDuration;

        // Create an audio element for iOS compatibility
        const audioBlob = new Blob([exportWAV(finalBuffer)], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioElementRef.current = new Audio(audioUrl);
        audioElementRef.current.loop = false;

        setDuration(totalDuration);
        setAudioReady(true);
        console.log("Audio prepared successfully");
      } catch (error) {
        console.error("Error preparing audio:", error);
        setError(`Failed to prepare audio. Please try again. Error: ${error.message}`);
      } finally {
        setIsAudioLoading(false);
      }
      return { totalDuration };
    };
  }, [audioKey, storagePath, generateAudioEndpoint, trackingContext.prayerMethod, effectivePrayerData, date, customBreakDurations]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    const prepareAudio = async () => {
      if (audioReady) return;
      setIsLoading(true);
      setIsAudioLoading(true);
      try {
        const fetchedScript = await fetchScript();
        setScript(fetchedScript);
        const { totalDuration } = await memoizedAudio(fetchedScript);
        setAudioReady(true);
        setDuration(prevDuration => prevDuration > 0 ? prevDuration : totalDuration);
      } catch (error) {
        console.error('Error preparing audio:', error);
        setError('Failed to prepare audio. Please try again.');
      } finally {
        setIsLoading(false);
        setIsAudioLoading(false);
      }
    };

    if (!initialFetchMadeRef.current && !audioReady) {
      prepareAudio();
      initialFetchMadeRef.current = true;
    }
  }, [audioKey, fetchScript, memoizedAudio]);

  useEffect(() => {
    console.log('Total audio duration:', duration);
  }, [duration]);

  useEffect(() => {
    setScript(initialScript || []);
    if (customBreakDurations) {
      setBreakDurations(customBreakDurations);
    } else if (initialScript) {
      setBreakDurations(initialScript.map(item => parseFloat(item.break.replace('s', '')) || 0));
    }
  }, [initialScript, customBreakDurations]);

  useEffect(() => {
    if (!hasCompleted && !hasTriggeredCompletion && currentTime >= completionThreshold) {
      setHasTriggeredCompletion(true);
      markComplete();
    }
  }, [currentTime, completionThreshold, hasCompleted, hasTriggeredCompletion, markComplete]);

  const cleanup = () => {
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close().catch(e => console.error('Error closing AudioContext:', e));
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    if (sourceNodeRef.current) {
      sourceNodeRef.current.stop();
    }
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      audioElementRef.current.src = '';
      audioElementRef.current = null;
    }
    setIsPlaying(false);
    setCurrentTime(0);
    setDuration(0);
    setAudioReady(false);
    releaseWakeLock();
  };

  const processAudioBuffers = (audioBuffers, breaks) => {
    if (audioBuffers.length === 0) {
      console.error('No audio buffers to process');
      return { finalBuffer: null, calculatedTotalDuration: 0 };
    }

    let calculatedTotalDuration = 0;
    audioBuffers.forEach((buffer, index) => {
      calculatedTotalDuration += buffer.duration;
      if (index < breaks.length) {
        calculatedTotalDuration += breaks[index];
      }
    });

    const finalBuffer = audioContextRef.current.createBuffer(
      audioBuffers[0].numberOfChannels,
      Math.ceil(audioContextRef.current.sampleRate * calculatedTotalDuration),
      audioContextRef.current.sampleRate
    );

    let offset = 0;
    for (let i = 0; i < audioBuffers.length; i++) {
      const buffer = audioBuffers[i];
      for (let channel = 0; channel < finalBuffer.numberOfChannels; channel++) {
        const finalChannelData = finalBuffer.getChannelData(channel);
        const bufferChannelData = buffer.getChannelData(channel);
        finalChannelData.set(bufferChannelData, offset);
      }
      offset += buffer.length;
      
      if (i < breaks.length) {
        const silenceSamples = Math.ceil(breaks[i] * audioContextRef.current.sampleRate);
        offset += silenceSamples;
      }
    }
    
    return { finalBuffer, calculatedTotalDuration };
  };

  const playAudio = () => {
    if (audioElementRef.current) {
      audioElementRef.current.play();
      setIsPlaying(true);
      updatePlaybackTime();
      requestWakeLock();
    }
  };

  const pauseAudio = () => {
    if (audioElementRef.current) {
      audioElementRef.current.pause();
      setIsPlaying(false);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      releaseWakeLock();
    }
  };

  const updatePlaybackTime = () => {
    if (audioElementRef.current) {
      setCurrentTime(audioElementRef.current.currentTime);
      if (audioElementRef.current.currentTime >= duration) {
        setIsPlaying(false);
        setCurrentTime(0);
        releaseWakeLock();
      } else {
        animationFrameRef.current = requestAnimationFrame(updatePlaybackTime);
      }
    }
  };

  const togglePlayPause = () => {
    if (!audioReady) {
      if (!isAudioLoading) {
        memoizedAudio(script);
      }
      return;
    }

    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const handleSeek = (e) => {
    const seekTime = (parseFloat(e.target.value) / 100) * duration;
    setCurrentTime(seekTime);
    if (audioElementRef.current) {
      audioElementRef.current.currentTime = seekTime;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const fetchAudioBuffer = async (url, audioContext) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return await audioContext.decodeAudioData(arrayBuffer);
  };

  const exportWAV = (audioBuffer) => {
    const numOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numOfChannels * 2;
    const buffer = new ArrayBuffer(44 + length);
    const view = new DataView(buffer);

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + length, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChannels, true);
    view.setUint32(24, audioBuffer.sampleRate, true);
    view.setUint32(28, audioBuffer.sampleRate * numOfChannels * 2, true);
    view.setUint16(32, numOfChannels * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, length, true);

    const data = new Float32Array(audioBuffer.length * numOfChannels);
    let offset = 0;
    for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
      data.set(audioBuffer.getChannelData(i), offset);
      offset += audioBuffer.length;
    }

    floatTo16BitPCM(view, 44, data);

    return buffer;
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const floatTo16BitPCM = (output, offset, input) => {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  };

  const requestWakeLock = async () => {
    if ('wakeLock' in navigator) {
      try {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
      } catch (err) {
        console.error(`Failed to request wake lock: ${err.name}, ${err.message}`);
      }
    }
  };

  const releaseWakeLock = () => {
    if (wakeLockRef.current) {
      wakeLockRef.current.release()
        .then(() => {
          wakeLockRef.current = null;
        })
        .catch((err) => {
          console.error(`Failed to release wake lock: ${err.name}, ${err.message}`);
        });
    }
  };

  const handleTimeUpdate = useCallback(() => {
    if (!audioElementRef.current) return;
    const newTime = audioElementRef.current.currentTime;
    setCurrentTime(newTime);
  }, []);

  useEffect(() => {
    const audioElement = audioElementRef.current;
    if (!audioElement || !handleTimeUpdate) return;
    
    audioElement.addEventListener('timeupdate', handleTimeUpdate);
    
    return () => {
      audioElement?.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [audioElementRef.current, handleTimeUpdate]);

  const handleEnded = useCallback(() => {
    setIsPlaying(false);
    // Only stop playback, don't log completion
  }, []);

  return (
    <div className="audio-player mt-md-4 mb-3 px-md-2 px-1">
      <div className="row align-items-center mb-3">
        <div className="col-auto">
          <button
            className={`play-button ${isPlaying ? 'playing' : ''}`}
            onClick={togglePlayPause}
            disabled={isLoading || isAudioLoading}
          >
            {isLoading || isAudioLoading ? (
              <Loader className="icon-spin" />
            ) : isPlaying ? (
              <Pause />
            ) : (
              <Play />
            )}
          </button>
        </div>
        <div className="col">
          <div className="audio-title">{title}</div>
        </div>
      </div>
      {audioReady && (
        <div className="progress-container">
          <input
            type="range"
            min="0"
            max="100"
            value={(currentTime / duration) * 100 || 0}
            onChange={handleSeek}
            className="progress-bar w-100"
          />
          <div className="d-flex justify-content-between mt-2">
            <span className="time-display">{formatTime(currentTime)}</span>
            <span className="time-display">{formatTime(duration)}</span>
          </div>
        </div>
      )}
      {error && <div className="error-message mt-3">{error}</div>}
      {script.length > 0 && renderTranscript(script, currentTime, isPlaying)}
    </div>
  );
};


export default BaseAudioPlayer;
