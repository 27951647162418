import React, { useState, useEffect } from 'react';
import { FlexibleStepNavigation } from '../common/StepNavigation';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';

const SaintMeditationSteps = ({ steps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { prayerMethod, prayerData } = usePrayerTracking();

  const { hasCompleted, markComplete } = usePrayerProgress({
    completionType: 'reflection',
    trigger: {
      currentStep,
      totalSteps: steps.length
    },
    disabled: currentStep < steps.length - 1
  });

  useEffect(() => {
    if (currentStep === steps.length - 1 && !hasCompleted) {
      markComplete();
    }
  }, [currentStep, steps.length, hasCompleted, markComplete]);

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const currentStepData = steps[currentStep];

  return (
    <div className="saint-meditation-steps">
      <h3>{currentStepData.title}</h3>
      {currentStepData.context && <p className="context">{currentStepData.context}</p>}
      <p>{currentStepData.description}</p>
      {currentStepData.questions && currentStepData.questions.length > 0 && (
        <>
          <h4>Reflection Questions</h4>
          <ul>
            {currentStepData.questions.map((question, index) => (
              <li key={index}>{question}</li>
            ))}
          </ul>
        </>
      )}

      <FlexibleStepNavigation
        currentIndex={currentStep}
        totalSteps={steps.length}
        onPrevious={prevStep}
        onNext={nextStep}
        onStepClick={setCurrentStep}
        stepTitle={`Step ${currentStep + 1} of ${steps.length}`}
      />
    </div>
  );
};

export default SaintMeditationSteps;