import React, { useMemo } from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { usePrayerPlan } from '../../hooks/usePrayerPlan';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import { useUserPrayerHistory } from '../../hooks/useUserPrayerHistory';
import { useUserData } from '../../hooks/useUserData';
import { useNavigate } from 'react-router-dom';
import { Check, Trash2, GripVertical, ChevronDown, ChevronUp } from 'lucide-react';
import styles from '../../styles/prayerPlan.module.css';
import SignUpPrompt from '../authentication/SignUpPrompt';
import { PrayerPlanItem } from '@shared/types/prayerPlan';
import StartButton from '../common/StartButton';
import { PrayerHistoryItem } from '@shared/types/prayerHistory';

interface PrayerPlanProps {
  variant?: 'full' | 'compact';
}

interface CompletedItem {
  scriptureRef: string;
  methodId: string;
  titleScripture: string;
}

const PrayerPlan: React.FC<PrayerPlanProps> = ({ variant = 'full' }) => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const { plan, loading, error, reorderPlan, markCompleted, deleteItem } = usePrayerPlan();
  const { prayerMethods, loading: methodsLoading } = usePrayerMethods();
  const { history } = useUserPrayerHistory();
  const [showCompleted, setShowCompleted] = React.useState(false);

  const { activePlanItems, completedPlanItems } = useMemo(() => {
    if (!plan || !history) return { activePlanItems: [], completedPlanItems: [] };

    const completedItems = new Set<CompletedItem>(
      history.map((historyItem: PrayerHistoryItem) => ({
        scriptureRef: historyItem.scriptureReference || '',
        methodId: historyItem.prayerMethod,
        titleScripture: historyItem.prayerTitle.split(' Meditation')[0],
      }))
    );

    return plan.reduce<{ activePlanItems: PrayerPlanItem[]; completedPlanItems: PrayerPlanItem[] }>(
      (acc, planItem) => {
        const isCompleted = Array.from(completedItems).some(
          (completed: CompletedItem) => 
            (completed.scriptureRef === planItem.scriptureReference && 
             completed.methodId === planItem.prayerMethodId) ||
            (completed.titleScripture === planItem.scriptureReference && 
             completed.methodId === planItem.prayerMethodId)
        );

        if (isCompleted) {
          acc.completedPlanItems.push(planItem);
        } else {
          acc.activePlanItems.push(planItem);
        }
        return acc;
      },
      { activePlanItems: [], completedPlanItems: [] }
    );
  }, [plan, history]);

  const getPrayerMethodDetails = (methodId: string) => {
    return prayerMethods.find(method => method.id === methodId);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    console.log('Drag ended:', {
      itemId: result.draggableId,
      newIndex: result.destination.index,
      sourceIndex: result.source.index
    });
    reorderPlan(result.draggableId, result.destination.index);
  };

  const handleStartPrayer = (item: PrayerPlanItem) => {
    navigate(`/scripture/meditation?${new URLSearchParams({
      scripture: item.scriptureReference,
      prayerMethodId: item.prayerMethodId,
      prayerMethodName: item.prayerMethodName
    }).toString()}`);
  };

  const renderPlanItems = (items: PrayerPlanItem[], isDraggable = true) => {
    return items.map((item, index) => {
      const methodDetails = getPrayerMethodDetails(item.prayerMethodId);
      
      return (
        <Draggable
          key={item.id}
          draggableId={item.id}
          index={index}
          isDragDisabled={!isDraggable}
        >
          {(provided) => (
            <Card 
              ref={provided.innerRef}
              {...provided.draggableProps}
              className={styles.planItem}
            >
              <Card.Body className={styles.planItemBody}>
                <div className={styles.planItemMain}>
                  {isDraggable && (
                    <div {...provided.dragHandleProps} className={styles.dragHandle}>
                      <GripVertical size={20} />
                    </div>
                  )}
                  
                  {methodDetails?.imageUrl && (
                    <div className={styles.methodImageContainer}>
                      <img
                        src={methodDetails.imageUrl}
                        alt={methodDetails.name}
                        className={styles.methodImage}
                      />
                    </div>
                  )}
                  
                  <div className={styles.planItemInfo}>
                    <Card.Title>{item.scriptureReference}</Card.Title>
                    <div className={styles.methodInfo}>
                      <span className={styles.methodTag}>{item.prayerMethodName}</span>
                      {item.scriptureTheme && (
                        <span className={styles.theme}>{item.scriptureTheme}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.planItemActions}>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleStartPrayer(item)}
                    className={styles.startButton}
                  >
                    Start
                  </Button>
                  <button
                    onClick={() => deleteItem(item.id)}
                    className={styles.deleteButton}
                  >
                    <Trash2 size={18} />
                  </button>
                </div>
              </Card.Body>
            </Card>
          )}
        </Draggable>
      );
    });
  };

  const containerClassName = `${styles.container} ${variant === 'compact' ? styles.compact : ''}`;

  const renderCompactPlan = () => (
    <div className={styles.compactContainer}>
      <h3 className={styles.compactTitle}>Your Prayer Plan</h3>
      {(!activePlanItems || activePlanItems.length === 0) ? (
        <Card className={`${styles.emptyCard} ${styles.compactEmptyCard}`}>
          <Card.Body>
            <p className={styles.compactEmptyText}>Create your prayer plan:</p>
            <Button 
              variant="outline-primary"
              size="sm"
              onClick={() => navigate('/advisor?tab=advisor')}
              className={styles.compactEmptyButton}
            >
              Get Recommendations
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <>
          {activePlanItems.slice(0, 3).map((item) => {
            const methodDetails = getPrayerMethodDetails(item.prayerMethodId);
            return (
              <Card key={item.id} className={styles.planItem}>
                <Card.Body>
                  <Row className="align-items-center g-2">
                    {methodDetails && (
                      <Col xs="auto">
                        <div className={styles.methodImageContainer}>
                          <img 
                            src={methodDetails.imageUrl} 
                            alt={item.prayerMethodName}
                            className={styles.methodImage}
                          />
                        </div>
                      </Col>
                    )}
                    <Col>
                      <Card.Title>{item.scriptureReference}</Card.Title>
                      <div className={styles.methodTag}>{item.prayerMethodName}</div>
                    </Col>
                    <Col xs="auto">
                      <Button 
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleStartPrayer(item)}
                      >
                        <StartButton text="Start" />
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
          <Button 
            variant="link" 
            className={styles.viewAllButton}
            onClick={() => navigate('/advisor?tab=plan')}
          >
            Manage Prayer Plan
          </Button>
        </>
      )}
    </div>
  );

  if (!userData) {
    return (
      <Container className={containerClassName}>
        <SignUpPrompt 
          message="Sign up to create and manage your personalized prayer plan"
          variant="button"
        />
      </Container>
    );
  }

  if (loading || methodsLoading) {
    return <div className={styles.loading}>Loading your prayer plan...</div>;
  }

  return (
    <Container className={containerClassName}>
      {variant === 'compact' ? (
        renderCompactPlan()
      ) : (
        <>
          <h2 className={styles.title}>Your Prayer Plan</h2>
          
          {(!plan || plan.length === 0) ? (
            <Card className={styles.emptyCard}>
              <Card.Body>
                <p>Your prayer plan is empty. Add items in one of two ways:</p>
                <div className={styles.addOptionsContainer}>
                  <div className={styles.addOption}>
                    <Button onClick={() => navigate('/advisor?tab=advisor')}>Get Personalized Recommendations</Button>
                    <p className={styles.addOptionDescription}>
                      Get scripture recommendations tailored to your needs and preferences
                    </p>
                  </div>
                  <div className={styles.addOption}>
                    <Button onClick={() => navigate('/scripture')} variant="outline-primary">
                      Choose Any Scripture
                    </Button>
                    <p className={styles.addOptionDescription}>
                      Select any passage from the Bible to add to your plan
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="prayer-plan">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {renderPlanItems(activePlanItems)}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {completedPlanItems.length > 0 && (
                <div className={styles.completedSection}>
                  <Button 
                    variant="link" 
                    className={styles.toggleButton}
                    onClick={() => setShowCompleted(!showCompleted)}
                  >
                    {showCompleted ? <ChevronUp /> : <ChevronDown />}
                    Completed Items ({completedPlanItems.length})
                  </Button>
                  
                  {showCompleted && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="completed-plan">
                        {(provided) => (
                          <div 
                            {...provided.droppableProps} 
                            ref={provided.innerRef}
                            className={styles.completedItems}
                          >
                            {renderPlanItems(completedPlanItems, false)}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
      {plan && plan.length > 0 && variant === 'full' && (
        <div className={styles.addMoreSection}>
          <p className={styles.addMoreText}>
            Add more items to your plan:
          </p>
          <div className={styles.addMoreButtons}>
            <Button 
              variant="outline-primary" 
              size="sm"
              onClick={() => navigate('/advisor?tab=advisor')}
            >
              Get Personalized Recommendations
            </Button>
            <Button 
              variant="outline-primary" 
              size="sm"
              onClick={() => navigate('/scripture')}
            >
              Choose Any Scripture
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default PrayerPlan;
