import { useState, useCallback } from 'react';
import api from '../utils/api';
import { API_ENDPOINTS } from '../utils/apiConfig';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { 
  CustomScripture, 
  Scripture, 
  CustomScriptureHook, 
  ScriptureData,
  BookContent,
  ExtendedScripture
} from '@shared/types/customScripture';
import { parseScriptureCitation } from '../utils/scriptureUtils';
import { validateBookName } from '../utils/scriptureUtils';

export const useCustomScripture = (): CustomScriptureHook => {
  const [customScripture, setCustomScripture] = useState<CustomScripture | null>(null);
  const [scriptureLoading, setScriptureLoading] = useState<boolean>(false);
  const [bookLoading, setBookLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedPrayerMethod, setSelectedPrayerMethod] = useState<PrayerMethod | null>(null);
  const [selectedScripture, setSelectedScripture] = useState<ScriptureData | null>(null);
  const [bookContent, setBookContent] = useState<BookContent | null>(null);

  // For meditation container - just get scripture passage
  const fetchScripturePassage = useCallback(async (citation: string): Promise<ExtendedScripture | null> => {
    console.log('fetchScripturePassage called with citation:', citation);
    setScriptureLoading(true);
    try {
      const parsedCitation = parseScriptureCitation(citation);
      const validatedBook = validateBookName(parsedCitation.book);
      
      if (!validatedBook) {
        throw new Error(`Invalid book name: ${parsedCitation.book}`);
      }
      
      parsedCitation.book = validatedBook; // Use validated book name
      console.log('Parsed and validated citation:', parsedCitation);
      
      const [bookContentResponse, passageResponse] = await Promise.all([
        api.fetchBookContent(parsedCitation.book),
        api.fetchCustomScripture(citation)
      ]);

      console.log('API responses received:', {
        bookContent: bookContentResponse?.data,
        passage: passageResponse?.data
      });

      // Create scripture data from direct API response
      const scriptureData = {
        readings: [{
          citation: passageResponse.data.citation,
          text: passageResponse.data.text
        }],
        bookContent: bookContentResponse.data,
        selectedChapter: parsedCitation.chapter,
        startVerse: parseInt(parsedCitation.startVerse),
        endVerse: parseInt(parsedCitation.endVerse),
        text: passageResponse.data.text
      };

      // Update local state
      setCustomScripture({
        readings: scriptureData.readings,
        text: scriptureData.text
      });
      setBookContent(scriptureData.bookContent);

      return scriptureData;
    } catch (err) {
      console.error('Error in fetchScripturePassage:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch scripture passage');
      return null;
    } finally {
      setScriptureLoading(false);
    }
  }, []);

  // For scripture selector - get full book content
  const fetchBookContent = useCallback(async (book: string) => {
    setBookLoading(true);
    try {
      const response = await api.fetchBookContent(book);
      const content = response.data?.data ? response.data : response;
      setBookContent(content);
      return content;
    } catch (err) {
      setError('Failed to fetch book content');
      return null;
    } finally {
      setBookLoading(false);
    }
  }, []);

  const handleScriptureSelection = (scripture: ScriptureData) => {
    setSelectedScripture(scripture);
    setCustomScripture({ readings: [{ citation: scripture.citation, text: scripture.text }] });
  };

  const handlePrayerMethodSelection = (prayerMethod: PrayerMethod) => {
    setSelectedPrayerMethod(prayerMethod);
  };

  console.log("Custom scripture: ", customScripture);
  
  return {
    customScripture,
    loading: scriptureLoading || bookLoading,
    scriptureLoading,
    bookLoading,
    error,
    selectedPrayerMethod,
    selectedScripture,
    bookContent,
    handleScriptureSelection,
    handlePrayerMethodSelection,
    fetchScripturePassage,
    fetchBookContent
  };
};
