import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card, Nav, Button, Image } from 'react-bootstrap';
import AdjustableCustomScriptureMeditationPlayer from '../audio/AdjustableCustomScriptureMeditationPlayer';
import LoadingSpinner from '../common/LoadingSpinner';
import { useCustomScriptureMeditation } from '../../hooks/useCustomScriptureMeditation';
import { useCustomScriptureMeditationPlayer } from '../../hooks/useCustomScriptureMeditationPlayer';
import { useCustomScripture } from '../../hooks/useCustomScripture';
import { usePrayerMethod } from '../../hooks/usePrayerMethod';
import SinglePrayerInstructionComponent from '../daily-prayers/SinglePrayerInstructionComponent';
import PrayerInstructionComponent from '../daily-prayers/PrayerInstructionComponent';
import { Book, Headphones, ArrowLeft } from 'lucide-react';
import '../../styles/customScriptureMeditationContainer.css';
import ErrorMessage from '../common/ErrorMessage';
import { PrayerMethod, ScriptureData, MeditationData } from '@shared/types/customScripture';
import Sharing from '../common/Sharing';
import ExpandableText from '../common/ExpandableText';
import ScriptureDisplay from './ScriptureDisplay';
import { CustomScripturePrayer, DailyPrayer, PrayerStep } from '@shared/types/dailyPrayer';
import { PrayerTrackingProvider } from '../../contexts/PrayerTrackingContext';
import ContentHeader from '../common/ContentHeader';

interface LocationState {
  scripture: string;
  prayerMethod: PrayerMethod;
  scriptureText: string;
  bookContent: any; // You might want to create a more specific type for this
  selectedChapter: string;
  startVerse: number;
  endVerse: number;
}

interface CustomScriptureMeditationContainerProps {
  prayerMethod: PrayerMethod;
  scripturePassage: ScriptureData;
  customTitle?: string;
  seriesContext?: {
    seriesId: string;
    sectionId: string;
    itemId: string;
    hideBackLink: boolean;
  };
  onComplete?: (prayerHistoryId: string) => void;
}

const CustomScriptureMeditationContainer: React.FC<CustomScriptureMeditationContainerProps> = ({
  prayerMethod: initialPrayerMethod,
  scripturePassage,
  customTitle,
  seriesContext,
  onComplete
}) => {
  console.log('CustomScriptureMeditationContainer mounted with props:', {
    initialPrayerMethod,
    scripturePassage,
    customTitle,
    seriesContext
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [scripture, setScripture] = useState<string>('');
  const [prayerMethod, setPrayerMethod] = useState<PrayerMethod | null>(initialPrayerMethod);
  const [scriptureText, setScriptureText] = useState<string>('');
  const [bookContent, setBookContent] = useState<any>(null);
  const [selectedChapter, setSelectedChapter] = useState<string>('');
  const [startVerse, setStartVerse] = useState<number>(0);
  const [endVerse, setEndVerse] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<'listen' | 'read'>('listen');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [scriptureLoaded, setScriptureLoaded] = useState<boolean>(false);
  const [isStateNavigation, setIsStateNavigation] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const scriptureParam = searchParams.get('scripture');
  const prayerMethodIdParam = searchParams.get('prayerMethodId');
  const selectedChapterParam = searchParams.get('selectedChapter');
  const startVerseParam = searchParams.get('startVerse');
  const endVerseParam = searchParams.get('endVerse');
  const source = searchParams.get('source');

  const { meditationData, loading: meditationLoading, error: meditationError, fetchMeditation } = useCustomScriptureMeditation();
  const { 
    fetchScripturePassage,
    scriptureLoading,
    error: scriptureError,
    customScripture
  } = useCustomScripture();
  const { prayerMethod: fetchedPrayerMethod, loading: prayerMethodLoading, error: prayerMethodError } = usePrayerMethod(prayerMethodIdParam || null);

  useEffect(() => {
    console.log('Component mounted/updated with props:', {
      scripturePassage,
      customTitle,
      seriesContext,
      initialPrayerMethod
    });

    if (seriesContext?.seriesId) {
      console.log('Setting scripture data from series context:', {
        citation: scripturePassage.citation,
        text: scripturePassage.text,
        bookContent: scripturePassage.bookContent,
        selectedChapter: scripturePassage.selectedChapter,
        startVerse: scripturePassage.startVerse,
        endVerse: scripturePassage.endVerse
      });
      
      setScripture(scripturePassage.citation);
      setScriptureText(scripturePassage.text);
      setBookContent(scripturePassage.bookContent);
      setSelectedChapter(scripturePassage.selectedChapter);
      setStartVerse(scripturePassage.startVerse);
      setEndVerse(scripturePassage.endVerse);
      setPrayerMethod(initialPrayerMethod);
      setScriptureLoaded(true);
    }
  }, [scripturePassage, customTitle, seriesContext, initialPrayerMethod]);

  useEffect(() => {
    // Delay scroll to ensure content is loaded
    const timeoutId = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const initializeFromParams = async () => {
      // If we have state from navigation, use that
      if (location.state) {
        const state = location.state as LocationState;
        setScripture(state.scripture);
        setPrayerMethod(state.prayerMethod);
        setScriptureText(state.scriptureText);
        setBookContent(state.bookContent);
        setSelectedChapter(state.selectedChapter);
        setStartVerse(state.startVerse);
        setEndVerse(state.endVerse);
        setScriptureLoaded(true);
        return;
      }

      // Otherwise, fetch everything from URL params
      if (scriptureParam && prayerMethodIdParam) {
        try {
          // Fetch scripture content
          const scriptureData = await fetchScripturePassage(scriptureParam);
          if (scriptureData) {
            setScripture(scriptureParam);
            setScriptureText(scriptureData.text || '');
            setBookContent(scriptureData.bookContent);
            setSelectedChapter(selectedChapterParam || '');
            setStartVerse(startVerseParam ? parseInt(startVerseParam) : 0);
            setEndVerse(endVerseParam ? parseInt(endVerseParam) : 0);
            setScriptureLoaded(true);
          }
        } catch (error) {
          console.error('Error initializing from params:', error);
        }
      }
    };

    initializeFromParams();
  }, [location, scriptureParam, prayerMethodIdParam, selectedChapterParam, startVerseParam, endVerseParam, fetchScripturePassage]);

  useEffect(() => {
    if (fetchedPrayerMethod && !prayerMethod) {
      setPrayerMethod(fetchedPrayerMethod);
    }
  }, [fetchedPrayerMethod, prayerMethod]);

  useEffect(() => {
    if (!isStateNavigation && customScripture && !scriptureText) {
      const text = customScripture?.readings?.[0]?.text || customScripture?.text || '';
      if (text) {
        setScriptureText(text);
        setScriptureLoaded(true);
      }
    }
  }, [customScripture, scriptureText, isStateNavigation]);

  useEffect(() => {
    if (scriptureLoaded && prayerMethod?.id && scriptureText && !meditationData) {
      fetchMeditation(scripture, prayerMethod.id, scriptureText);
    }
  }, [scriptureLoaded, prayerMethod, meditationData, fetchMeditation, scripture, scriptureText]);

  const durationOptions = [
    { value: '10', label: '10 minutes' },
    { value: '15', label: '15 minutes' },
    { value: '20', label: '20 minutes' },
    { value: '30', label: '30 minutes' }
  ];

  const defaultDuration = '10';

  const { script, isLoading: scriptLoading, error: scriptError, fetchScript } = useCustomScriptureMeditationPlayer(prayerMethod!, { citation: scripture, text: scriptureText, bookContent, selectedChapter, startVerse, endVerse }, meditationData, defaultDuration);

  const renderScriptureSection = () => {
    return (
      <ScriptureDisplay
        scripture={scripture}
        scriptureText={scriptureText}
        selectedChapter={selectedChapter}
        startVerse={startVerse}
        endVerse={endVerse}
        isLoading={scriptureLoading}
      />
    );
  };

  const renderMeditationSection = () => {
    console.log('[CustomScriptureMeditationContainer] Rendering meditation section:', {
      activeTab,
      meditationData,
      prayerMethod,
      seriesContext
    });

    if (prayerMethodLoading) {
      return <LoadingSpinner message="Loading meditation..." />;
    }

    // Only show error if we're not in a loading state and prayerMethod is still null
    if (!prayerMethodLoading && !prayerMethod) {
      return <ErrorMessage message="Prayer method not found" />;
    }

    return (
      <Card>
        <div 
          className="csm-guided-meditation-banner"
          style={{ backgroundImage: `url(${prayerMethod?.imageUrl})` }}
        >
          <h3 className="csm-guided-meditation-title">Guided Meditation</h3>
        </div>
        <Card.Header className="bg-white">
          <Nav variant="tabs" className="card-header-tabs mt-2">
            <Nav.Item>
              <Nav.Link
                active={activeTab === 'listen'}
                onClick={() => handleTabChange('listen')}
              >
                <Headphones size={18} className="me-2" />
                Listen
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                active={activeTab === 'read'}
                onClick={() => handleTabChange('read')}
              >
                <Book size={18} className="me-2" />
                Read
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body className="csm-meditation-card-body">
          {activeTab === 'listen' && renderAudioSection()}
          {activeTab === 'read' && renderReadSection()}
        </Card.Body>
      </Card>
    );
  };

  const renderHeader = () => {
    if (!prayerMethod) return <LoadingSpinner message="Loading prayer method..." />;

    const title = customTitle || `${scripture} Meditation`;

    return (
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center mb-3">
        <Image src={prayerMethod.imageUrl} alt={prayerMethod.name} className="csm-prayer-method-image mb-2 mb-md-0 me-md-3" />
        <div>
          <h1 className="csm-title mb-1">{title} Meditation Inspired by {prayerMethod.name}</h1>
          <p className="text-muted mb-0">{prayerMethod.description}</p>
        </div>
      </div>
    );
  };

  const handleTabChange = (tab: 'listen' | 'read') => {
    setActiveTab(tab);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getBackLink = () => {
    switch (source) {
      case 'recommendations':
        return '/advisor';
      // Add more cases as needed
      default:
        return '/scripture';
    }
  };

  const getBackText = () => {
    switch (source) {
      case 'recommendations':
        return 'Back to Recommendations';
      // Add more cases as needed
      default:
        return 'Back to Scripture Selection';
    }
  };

  useEffect(() => {
    console.log('Loading states:', {
      prayerMethodLoading,
      scriptureLoading,
      meditationLoading
    });
    
    console.log('Current data:', {
      scripture,
      scriptureText,
      bookContent,
      selectedChapter,
      startVerse,
      endVerse,
      scriptureLoaded
    });

    if (seriesContext?.seriesId) {
      console.log('Setting scripture data from series context:', scripturePassage);
      setScripture(scripturePassage.citation);
      setScriptureText(scripturePassage.text);
      setBookContent(scripturePassage.bookContent);
      setSelectedChapter(scripturePassage.selectedChapter);
      setStartVerse(scripturePassage.startVerse);
      setEndVerse(scripturePassage.endVerse);
      setPrayerMethod(initialPrayerMethod);
      setScriptureLoaded(true);
    }
  }, [scripturePassage, seriesContext, initialPrayerMethod, prayerMethodLoading, scriptureLoading, meditationLoading]);

  const renderReadSection = () => {
    if (meditationLoading || !prayerMethod) {
      return <LoadingSpinner message="Preparing text meditation..." />;
    }

    if (!meditationData?.prayer) {
      return <LoadingSpinner message="Generating meditation content..." />;
    }

    // Check if the prayer method type is 'daily-prayer'
    const isDailyPrayer = prayerMethod.type === 'daily-prayer';

    // Prepare the prayer data structure that matches what the instruction components expect
    const preparedPrayerData: CustomScripturePrayer = {
      id: `custom-${formatScriptureReference(scripture)}`,
      title: `${scripture} Meditation`,
      scriptureReference: scripture,
      scriptureText: scriptureText,
      date: new Date().toISOString().split('T')[0],
      steps: meditationData.prayer.steps || [],
      prayerMethodTypeId: prayerMethod.type,
      metadata: {
        isCustomScripture: true,
        citation: scripture,
        selectedChapter,
        startVerse,
        endVerse
      }
    };

    return isDailyPrayer ? (
      <SinglePrayerInstructionComponent 
        prayerData={preparedPrayerData}
        prayerMethod={prayerMethod}
        isCustomScripture={true}
        seriesContext={seriesContext}
      />
    ) : (
      <PrayerInstructionComponent
        steps={meditationData.prayer.steps || []}
        prayerMethod={prayerMethod}
        prayerData={preparedPrayerData}
        isCustomScripture={true}
        seriesContext={seriesContext}
      />
    );
  };

  const renderAudioSection = () => {
    if (meditationLoading) {
      return <LoadingSpinner message="Preparing audio meditation..." />;
    }

    if (!meditationData?.prayer) {
      return <LoadingSpinner message="Generating meditation content..." />;
    }

    return (
      <AdjustableCustomScriptureMeditationPlayer
        scriptureData={{ citation: scripture, text: scriptureText }}
        prayerMethod={prayerMethod!}
        meditationData={meditationData?.prayer?.steps || []}
        durationOptions={durationOptions}
        defaultDuration={defaultDuration}
        fetchScript={fetchScript}
        seriesContext={seriesContext}
        onComplete={onComplete}
      />
    );
  };

  useEffect(() => {
    // Cleanup function to prevent memory leaks and stale callbacks
    return () => {
      // Any cleanup needed
    };
  }, []);

  // Format scripture reference for tracking
  const formatScriptureReference = (citation: string) => {
    return citation.replace(/\s+/g, '-').replace(/:/g, '_');
  };

  // Prepare tracking data
  const getPrayerTrackingData = () => ({
    id: `custom-${formatScriptureReference(scripture)}`,
    title: `${scripture} Meditation`,
    date: new Date().toISOString().split('T')[0],
    prayerMethodTypeId: prayerMethod?.type || 'daily-meditation',
    scriptureReference: scripture,
    scriptureText: scriptureText,
    steps: meditationData?.prayer?.steps || [],
    metadata: {
      isCustomScripture: true,
      citation: scripture,
      selectedChapter,
      startVerse,
      endVerse
    }
  } as CustomScripturePrayer);

  return (
    <>
      <Helmet>
        <title>{`${scripture} Meditation`}</title>
        <meta property="og:title" content={`${scripture} Meditation`} />
        <meta property="og:url" content={`https://saintlywhispers.com${location.pathname}${location.search}`} />
        <meta property="og:type" content="article" />
      </Helmet>
      {prayerMethod && (
        <PrayerTrackingProvider
          prayerMethod={prayerMethod}
          prayerData={getPrayerTrackingData()}
          seriesContext={seriesContext}
        >
          <Container className="csm-container">
            {(prayerMethodLoading || scriptureLoading) ? (
              <LoadingSpinner message="Loading meditation content..." />
            ) : (
              <>
                <ContentHeader
                  backLink={getBackLink()}
                  backText={getBackText()}
                  title={`${scripture} Meditation - ${prayerMethod?.name} - Saintly Whispers`}
                  description={`Meditate on ${scripture} with ${prayerMethod?.name} on Saintly Whispers.`}
                  hideBackLink={seriesContext?.hideBackLink}
                  className="mb-3"
                />
                <Row>
                  <Col xs={12}>
                    {renderHeader()}
                    {renderScriptureSection()}
                    {renderMeditationSection()}
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </PrayerTrackingProvider>
      )}
    </>
  );
};

export default CustomScriptureMeditationContainer;
