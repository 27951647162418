import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../../utils/authentication';
import { useUserData } from '../../hooks/useUserData';
import '../../styles/accountPage.css';
import PrayerHistory from '../activity/PrayerHistory';
import { UserData, UseUserDataReturn } from '@shared/types/auth';

const AccountPage: React.FC = () => {
  const { userData, loading, error } = useUserData() as UseUserDataReturn;
  const navigate = useNavigate();

  const handleSignOut = async (): Promise<void> => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (loading) return <div className="account-container">Loading...</div>;
  if (error) return <div className="account-container">Error: {error}</div>;
  if (!userData) return <div className="account-container">No user data available</div>;

  return (
    <div className="account-container">
      <h1 className="account-title">Your Account</h1>
      <div className="account-card">
        <h2 className="account-card-title">Account Details</h2>
        <p className="account-info"><strong>Email:</strong> {userData.email}</p>
        <p className="account-info">
          <strong>Account Created:</strong> {userData.createdAt?.toDate().toLocaleDateString()}
        </p>
        <p className="account-info">
          <strong>Last Login:</strong> {userData.lastLogin?.toDate().toLocaleDateString()}
        </p>
        <button onClick={handleSignOut} className="btn btn-danger logout-button">
          Log Out
        </button>
      </div>
      <PrayerHistory />
    </div>
  );
};

export default AccountPage;