import { useState, useEffect, useCallback, useRef } from 'react';
import { format } from 'date-fns';
import api from '../utils/api';
import { API_ENDPOINTS } from '../utils/apiConfig';
import { GospelAnalysis } from '@shared/types/gospelAnalysis';

export const useGospelAnalysis = (selectedDate: Date) => {
  const [gospelAnalysis, setGospelAnalysis] = useState<GospelAnalysis | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (gospelAnalysis) {
      console.log('Gospel analysis updated:', gospelAnalysis);
    }
  }, [gospelAnalysis]);

  const fetchGospelAnalysis = useCallback(async () => {
    if (!selectedDate) return;

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Cancel any in-flight request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new AbortController for this request
    abortControllerRef.current = new AbortController();

    timeoutRef.current = setTimeout(async () => {
      setLoading(true);
      try {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        const response = await api.get<GospelAnalysis>(
          API_ENDPOINTS.FETCH_GOSPEL_ANALYSIS(formattedDate),
          { signal: abortControllerRef.current?.signal }
        );
        setGospelAnalysis(response.data);
        setError(null);
      } catch (err) {
        if (err instanceof Error && err.name === 'AbortError') {
          return;
        }
        setError(`Error fetching gospel analysis: ${err instanceof Error ? err.message : 'Unknown error'}`);
      } finally {
        setLoading(false);
      }
    }, 300);
  }, [selectedDate]);

  useEffect(() => {
    fetchGospelAnalysis();
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (abortControllerRef.current) abortControllerRef.current.abort();
    };
  }, [fetchGospelAnalysis]);

  return { gospelAnalysis, loading, error };
};
