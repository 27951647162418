import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { FIRESTORE_PATHS } from '../utils/apiConfig';

export const useSaintMeditation = (saintId) => {
  const [saintMeditation, setSaintMeditation] = useState(null);
  const [saintData, setSaintData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [meditationExists, setMeditationExists] = useState(false);

  useEffect(() => {
    const fetchSaintData = async () => {
      if (!saintId) {
        setLoading(false);
        return;
      }

      try {
        const meditationRef = doc(firestore, FIRESTORE_PATHS.SAINT_MEDITATIONS.path(saintId));
        const saintRef = doc(firestore, FIRESTORE_PATHS.SAINTS_DATA.path(saintId));

        const [meditationSnap, saintSnap] = await Promise.all([
          getDoc(meditationRef),
          getDoc(saintRef)
        ]);

        if (meditationSnap.exists() && saintSnap.exists()) {
          const meditationData = meditationSnap.data();
          const saintData = saintSnap.data();
          //console.log('Saint meditation found:', meditationData);
          //console.log('Saint data found:', saintData);
          setSaintMeditation({
            ...meditationData,
            audioScript: meditationData.audioScript || []
          });
          setSaintData(saintData);
          setMeditationExists(true);
        } else if (saintSnap.exists()) {
          setSaintData(saintSnap.data());
          setMeditationExists(false);
        } else {
          throw new Error('Saint data not found');
        }
      } catch (err) {
        console.error('Error fetching saint data:', err);
        setError(`Error fetching saint data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSaintData();
  }, [saintId]);

  return { saintMeditation, saintData, loading, error, meditationExists };
};
