import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthModalProvider } from './contexts/AuthModalContext';
import { PrayerPlanProvider } from './contexts/PrayerPlanContext';
import { UserProvider } from './contexts/UserContext';

if (process.env.REACT_APP_REMOVE_CONSOLE === 'true') {
  console.log = () => {};
  console.info = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <AuthModalProvider>
      <PrayerPlanProvider>
        <App />
      </PrayerPlanProvider>
    </AuthModalProvider>
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
