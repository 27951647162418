import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DateNavigationProps } from '@shared/types/dateNavigation';
import styles from '../../styles/DateNavigation.module.css';
import { Link } from 'react-router-dom';

const DateNavigation: React.FC<DateNavigationProps> = ({
  selectedDate,
  onPreviousDay,
  onNextDay,
  formatString = "EEE. MMM do, yyyy",
  className = '',
  showChangePassage = false,
  onChangePassage,
  align = 'center'
}) => {
  const formattedDate = useMemo(() => 
    format(selectedDate, formatString)
  , [selectedDate, formatString]);

  return (
    <div className={`${styles.dateNavigation} ${styles[align]} ${className}`}>
      <button 
        className={styles.dateNavBtn} 
        onClick={onPreviousDay}
        aria-label="Previous day"
      >
        <ChevronLeft size={20} />
      </button>
      <span className={styles.selectedDate}>{formattedDate}</span>
      <button 
        className={styles.dateNavBtn} 
        onClick={onNextDay}
        aria-label="Next day"
      >
        <ChevronRight size={20} />
      </button>
      {showChangePassage && onChangePassage && (
        <Link 
          to="/scripture" 
          className="custom-scripture-link ms-3"
          onClick={onChangePassage}
        >
          Change Passage
        </Link>
      )}
    </div>
  );
};

export default DateNavigation;
