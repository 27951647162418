import React, { useState } from 'react';
import styles from '../../styles/filterChips.module.css';

interface FilterChipsProps {
  items: string[];
  activeItems: string[];
  onToggle: (item: string) => void;
  limit?: number;
}

const FilterChips: React.FC<FilterChipsProps> = ({ 
  items, 
  activeItems, 
  onToggle, 
  limit = 7 
}) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const visibleItems = showAll ? items : items.slice(0, limit);

  return (
    <div className={styles.filterChipsContainer}>
      <div className={styles.filterChips}>
        {visibleItems.map((item, index) => (
          <button
            key={`${item}-${index}`}
            className={`${styles.filterChip} ${activeItems.includes(item) ? styles.active : ''}`}
            onClick={() => onToggle(item)}
          >
            {item}
          </button>
        ))}
        {items.length > limit && (
          <a
            href="#"
            className={styles.showMoreButton}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              setShowAll(!showAll);
            }}
          >
            {showAll ? 'Show Less' : 'Show More'}
          </a>
        )}
      </div>
    </div>
  );
};

export default FilterChips;
