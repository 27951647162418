import React, { useState, useCallback, useEffect } from 'react';
import StepperProgress from '../common/StepperProgress';
import NewStepNavigation from '../common/NewStepNavigation';
import { usePrayerTracking } from '../../contexts/PrayerTrackingContext';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';
import { DailyPrayer, PrayerInstructionComponentProps } from '@shared/types/dailyPrayer';
import '../../styles/prayerInstructions.css';

const PrayerInstructionComponent: React.FC<PrayerInstructionComponentProps> = ({
  steps,
  prayerMethod,
  prayerData,
  isCustomScripture,
  seriesContext
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { prayerData: prayerTrackingData, prayerMethod: prayerTrackingMethod } = usePrayerTracking();

  const { hasCompleted, markComplete } = usePrayerProgress({
    prayerMethod: prayerTrackingMethod || prayerMethod,
    prayerData: {
      title: prayerData?.title || prayerTrackingMethod?.name || prayerMethod.name,
      scriptureReference: prayerData?.scriptureReference || '',
      date: prayerData?.date,
      metadata: {
        scriptureText: prayerData?.scriptureText,
        isCustomScripture
      }
    },
    completionType: 'read',
    seriesContext,
    trigger: {
      currentStep,
      totalSteps: steps.length
    }
  });

  const handleCompletion = useCallback(async () => {
    if (!steps.length || hasCompleted) return;
    
    if (currentStep === steps.length - 1) {
      await markComplete();
    }
  }, [currentStep, steps.length, hasCompleted, markComplete]);

  useEffect(() => {
    handleCompletion();
  }, [handleCompletion]);

  if (!prayerTrackingData || !prayerTrackingMethod) {
    return <div>Loading meditation steps...</div>;
  }

  const currentStepData = steps[currentStep];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="prayer-instructions" id="prayer-step-content">
      <StepperProgress
        steps={steps}
        currentStep={currentStep}
        onStepClick={setCurrentStep}
      />

      <div className="step-content mb-4">
        <h2 className="h4 mb-3">{currentStepData.title}</h2>
        <p className="mb-3">{currentStepData.description}</p>
        
        {currentStepData.questions && currentStepData.questions.length > 0 && (
          <>
            <h3 className="h5 mb-2">Reflection Questions:</h3>
            <ul className="reflection-questions mb-3">
              {currentStepData.questions.map((question: string, index: number) => (
                <li key={index}>{question}</li>
              ))}
            </ul>
          </>
        )}
        
        {currentStepData.context && (
          <div className="context-box p-3 rounded">
            <h3 className="h6 mb-2">Context:</h3>
            <p className="mb-0">{currentStepData.context}</p>
          </div>
        )}
        
        {currentStepData.tips && (
          <div className="tips-box p-3 rounded mt-3">
            <h3 className="h6 mb-2">Tips:</h3>
            <ul className="tips-list mb-0">
              {Array.isArray(currentStepData.tips) ? 
                currentStepData.tips.map((tip: string, index: number) => (
                  <li key={index}>{tip}</li>
                )) : 
                <li>{currentStepData.tips}</li>
              }
            </ul>
          </div>
        )}
      </div>
      
      <NewStepNavigation
        currentStep={currentStep}
        totalSteps={steps.length}
        onPrevious={prevStep}
        onNext={nextStep}
        showStepTitles={false}
        scrollToId="prayer-step-content"
      />
    </div>
  );
};

export default PrayerInstructionComponent;