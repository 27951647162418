import React, { useState, useEffect, useCallback } from 'react';
import AudioPrayer from './AudioPrayer';
import { adjustBreakDurations, estimateSpeakingDuration } from '../../utils/audioUtils';
import LoadingSpinner from '../common/LoadingSpinner';

const AdjustableAudioPrayerWithDuration = ({ 
  prayerMethod, 
  prayerData, 
  date, 
  script: initialScript,
  durationOptions,
  defaultDuration,
  fetchScript: parentFetchScript,
  isCustomScripture
}) => {
  const [selectedDuration, setSelectedDuration] = useState(defaultDuration);
  const [adjustedScript, setAdjustedScript] = useState(null);
  const [key, setKey] = useState(0);

  const normalizeScript = (script) => {
    return script.map(item => ({
      ...item,
      break: typeof item.break === 'number' ? `${item.break}s` : item.break,
      speakingDuration: item.speakingDuration || estimateSpeakingDuration(item.text)
    }));
  };

  const updateScript = useCallback(async (duration) => {
    console.log('Updating script with duration:', duration);
    const originalScript = initialScript || await parentFetchScript();
    const normalizedScript = normalizeScript(originalScript);
    console.log('Normalized script:', normalizedScript);
    const targetDuration = parseInt(duration) * 60; // Convert minutes to seconds
    const { adjustedScript, breakDurations, totalAdjustedDuration } = adjustBreakDurations(normalizedScript, targetDuration);
    console.log('Adjusted script:', adjustedScript);
    console.log('Break durations:', breakDurations);
    console.log('Total adjusted duration:', totalAdjustedDuration);
    setAdjustedScript(adjustedScript);
  }, [initialScript, parentFetchScript]);

  useEffect(() => {
    console.log('Selected duration changed:', selectedDuration);
    updateScript(selectedDuration);
    setKey(prevKey => prevKey + 1); // Force re-render of AudioPrayer
  }, [selectedDuration, updateScript]);

  const handleDurationChange = (e) => {
    const newDuration = e.target.value;
    console.log('Duration changed to:', newDuration);
    setSelectedDuration(newDuration);
  };

  if (!adjustedScript) {
    return <LoadingSpinner message="Preparing your audio prayer..." />;
  }

  console.log('Rendering AudioPrayer with duration:', selectedDuration);

  return (
    <div>
      <div className="duration-select-container">
        <select 
          className="duration-select"
          value={selectedDuration} 
          onChange={handleDurationChange}
        >
          {durationOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
        ))}
        </select>
      </div>
      <AudioPrayer
        key={key}
        prayerMethod={prayerMethod}
        prayerData={prayerData}
        date={date}
        script={adjustedScript}
        duration={selectedDuration}
        fetchScript={() => Promise.resolve(adjustedScript)}
        customBreakDurations={adjustedScript.map(item => parseFloat(item.break.replace('s', '')) || 0)}
        isCustomScripture={isCustomScripture}
      />
    </div>
  );
};

export default AdjustableAudioPrayerWithDuration;
