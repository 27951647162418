import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import api from '../utils/api';

export const useSaintsData = () => {
  const [saints, setSaints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSaints = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, FIRESTORE_PATHS.SAINTS_DATA.collection));
        const saintsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        //console.log('Fetched saints data:', saintsData);
        setSaints(saintsData);
      } catch (err) {
        console.error('Error fetching saints data:', err);
        setError('Error fetching saints data');
      } finally {
        setLoading(false);
      }
    };

    fetchSaints();
  }, []);

  const generateSaintMeditation = async (saintData) => {
    try {
      const response = await api.generateSaintMeditation(saintData);
      return response.data;
    } catch (err) {
      console.error('Error generating saint meditation:', err);
      throw new Error('Error generating saint meditation');
    }
  };

  const saveSaintsData = async (saintsData) => {
    try {
      const response = await api.saveSaintsData(saintsData);
      return response.data;
    } catch (err) {
      console.error('Error saving saints data:', err);
      throw new Error('Error saving saints data');
    }
  };

  const generateSaintImage = async (saintData) => {
    try {
      const response = await api.generateSaintImage(saintData);
      return response.data;
    } catch (err) {
      console.error('Error generating saint image:', err);
      throw new Error('Error generating saint image');
    }
  };

  return { 
    saints, 
    loading, 
    error, 
    generateSaintMeditation, 
    saveSaintsData, 
    generateSaintImage 
  };
};