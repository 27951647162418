import { format } from 'date-fns';

export const getPrayerMethodRoute = (method, date = new Date()) => {
  const formattedDate = format(date, 'yyyy-MM-dd');

  switch (method.type) {
    case 'rosary-meditation':
      return `/rosary/${method.id}`;
    case 'relaxation-prayer':
      return `/relaxation/${method.link}`;
    case 'daily-reflection':
      return `/reflections/${method.link}`;
    case 'contemplative-meditation':
      return `/contemplation/${method.link}`;
    case 'daily-meditation':
    case 'daily-prayer':
      return `/daily/${method.link}/${formattedDate}`;
    case 'saints-meditation':
      return '/saints';
    default:
      return `/${method.link}/${formattedDate}`;
  }
};
