import React, { useEffect } from 'react';
import ReflectionPlayer from '../audio/ReflectionPlayer';
import { Step } from '@shared/types/dailyReflection';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { usePrayerProgress } from '../../hooks/tracking/usePrayerProgress';

interface DailyReflectionStepsProps {
  steps: Step[];
  currentStepIndex: number;
  prayerMethod: PrayerMethod;
  reflectionName?: string;
  setCurrentStepIndex: (index: number) => void;
}

const DailyReflectionSteps: React.FC<DailyReflectionStepsProps> = ({ 
  steps, 
  currentStepIndex, 
  prayerMethod, 
  reflectionName 
}) => {
  const { hasCompleted, markComplete } = usePrayerProgress({
    prayerMethod,
    prayerData: {
      title: `${prayerMethod.name} Reflection`,
      date: new Date().toISOString().split('T')[0],
      metadata: {
        totalSteps: steps.length
      }
    },
    completionType: 'reflection',
    trigger: {
      currentStep: currentStepIndex,
      totalSteps: steps.length
    }
  });

  useEffect(() => {
    if (currentStepIndex === steps.length - 1 && !hasCompleted) {
      markComplete();
    }
  }, [currentStepIndex, steps.length, hasCompleted, markComplete]);

  if (!steps || !steps.length) return null;

  const step = steps[currentStepIndex];
  if (!step) return null;

  return (
    <div className="daily-reflection-steps">
      <div className="step-content">
        <ReflectionPlayer 
          prayerMethod={prayerMethod}
          stepIndex={currentStepIndex}
          stepData={step}
        />
        <hr className="divider" />
        <h3>Instructions</h3>
        <p>{step.description}</p>
        <h4>Questions to Consider:</h4>
        <ul>
          {step.questions?.map((question, index) => (
            <li key={index}>{question}</li>
          ))}
        </ul>
        <p><strong>Context:</strong> {step.context}</p>
        
        {step.practicalSteps && step.practicalSteps.length > 0 && (
          <>
            <h4>Practical Steps:</h4>
            <ol>
              {step.practicalSteps.map((practicalStep, index) => (
                <li key={index}>{practicalStep}</li>
              ))}
            </ol>
          </>
        )}
        
        {step.tips && (
          <>
            <h4>Tips:</h4>
            <ul className="tips-list">
              {Array.isArray(step.tips) ? 
                step.tips.map((tip, index) => (
                  <li key={index}>{tip}</li>
                )) : 
                <li>{step.tips}</li>
              }
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default DailyReflectionSteps;
