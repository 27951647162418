import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Menu } from 'lucide-react';
import { SeriesItem } from '@shared/types/series';
import styles from '../../styles/SeriesNavigation.module.css';

interface SeriesNavigationProps {
  seriesId: string;
  seriesTitle: string;
  sectionTitle?: string;
  prevItem: SeriesItem | null;
  nextItem: SeriesItem | null;
}

const SeriesNavigation: React.FC<SeriesNavigationProps> = ({
  seriesId,
  seriesTitle,
  sectionTitle,
  prevItem,
  nextItem
}) => {
  return (
    <nav className={styles.navigation}>
      <div className={styles.topBar}>
        <Link to={`/series/${seriesId}`} className={styles.backLink}>
          <ArrowLeft size={16} />
          <span className={styles.breadcrumb}>
            <span className={styles.seriesTitle}>{seriesTitle}</span>
            {sectionTitle && (
              <>
                <span className={styles.separator}>/</span>
                <span className={styles.sectionTitle}>{sectionTitle}</span>
              </>
            )}
          </span>
        </Link>
      </div>

      <div className={styles.navButtons}>
        {prevItem ? (
          <Link 
            to={`/series/${seriesId}/items/${prevItem.id}`}
            className={`${styles.navButton} ${styles.prevButton}`}
          >
            <ArrowLeft size={16} />
            <span className={styles.buttonText}>{prevItem.customTitle}</span>
          </Link>
        ) : (
          <span className={`${styles.navButton} ${styles.disabled}`}>
            <ArrowLeft size={16} />
            <span className={styles.buttonText}>Previous</span>
          </span>
        )}
        
        {nextItem ? (
          <Link 
            to={`/series/${seriesId}/items/${nextItem.id}`}
            className={`${styles.navButton} ${styles.nextButton}`}
          >
            <span className={styles.buttonText}>{nextItem.customTitle}</span>
            <ArrowRight size={16} />
          </Link>
        ) : (
          <span className={`${styles.navButton} ${styles.disabled}`}>
            <span className={styles.buttonText}>Next</span>
            <ArrowRight size={16} />
          </span>
        )}
      </div>
    </nav>
  );
};

export default SeriesNavigation;
