import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import { PrayerMethod } from '@shared/types/prayerMethod';

type PrayerMethods = PrayerMethod[];

export const usePrayerMethods = () => {
  const [prayerMethods, setPrayerMethods] = useState<PrayerMethods>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrayerMethods = async () => {
      try {
        const prayerMethodsRef = collection(firestore, 'prayerMethods');
        const querySnapshot = await getDocs(prayerMethodsRef);
        const filteredMethods = querySnapshot.docs.filter(doc => doc.data().status === 'published');
        const methods = filteredMethods.map(doc => ({ id: doc.id, ...doc.data() } as PrayerMethod));
        setPrayerMethods(methods);
      } catch (err) {
        setError(`Error fetching prayer methods: ${err instanceof Error ? err.message : 'Unknown error'}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPrayerMethods();
  }, []);

  return { prayerMethods, loading, error };
};
