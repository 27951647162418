import React from 'react';
import '../../styles/scripture.css';
import { BookContent } from '@shared/types/customScripture';
import { formatScriptureVerses } from '../../utils/gospelUtils';

interface ScriptureContentProps {
  bookContent: BookContent;
  selectedChapter: string;
  startVerse?: number;
  endVerse?: number;
  fullBookName: string;
}

const ScriptureContent: React.FC<ScriptureContentProps> = ({
  bookContent,
  selectedChapter,
  startVerse,
  endVerse,
  fullBookName
}) => {
  console.log('ScriptureContent props:', {
    bookContent,
    selectedChapter,
    startVerse,
    endVerse,
    fullBookName
  });

  if (!bookContent?.data || !Array.isArray(bookContent.data)) {
    console.error('Invalid bookContent structure:', bookContent);
    return null;
  }

  const formatVerses = (content: string, chapterNumber: string) => {
    if (!content) return null;
    
    const formattedVerses = formatScriptureVerses(
      content,
      selectedChapter ? startVerse : 1,
      selectedChapter ? endVerse : Infinity
    );
    
    return formattedVerses.map(verse => (
      <div key={`${chapterNumber}-${verse.number}`} className="verse">
        <span className="verse-number">{verse.number}</span>
        <span className="verse-text">{verse.content}</span>
      </div>
    ));
  };

  const title = selectedChapter 
    ? `${fullBookName} ${selectedChapter}${startVerse && endVerse ? `:${startVerse}-${endVerse}` : ''}`
    : fullBookName;

  const chaptersToShow = selectedChapter 
    ? bookContent.data.filter(chapter => chapter.number === selectedChapter)
    : bookContent.data;

  console.log('Chapters to show:', chaptersToShow);

  return (
    <div className="scripture-content">
      <h2 className="book-title">{title}</h2>
      {Array.isArray(chaptersToShow) && chaptersToShow.map((chapter) => (
        <div key={`chapter-${chapter.number}`} className="scripture-chapter">
          {(!selectedChapter || bookContent.data.length > 1) && (
            <h3 className="chapter-title">Chapter {chapter.number}</h3>
          )}
          <div className="chapter-content">
            {formatVerses(chapter.content, chapter.number)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScriptureContent;
