import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import { useSeriesData } from '../../hooks/useSeriesData';
import '../../styles/prayerMethodsCarousel.css';

const SeriesCarousel = () => {
  const navigate = useNavigate();
  const { seriesCatalog, loading, error } = useSeriesData();
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const updateArrows = () => {
    if (carouselRef.current) {
      setShowLeftArrow(carouselRef.current.scrollLeft > 0);
      setShowRightArrow(
        carouselRef.current.scrollLeft < carouselRef.current.scrollWidth - carouselRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    const currentRef = carouselRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', updateArrows);
      const resizeObserver = new ResizeObserver(() => {
        updateArrows();
      });
      resizeObserver.observe(currentRef);

      setTimeout(updateArrows, 100);

      return () => {
        if (currentRef) {
          currentRef.removeEventListener('scroll', updateArrows);
        }
        resizeObserver.disconnect();
      };
    }
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    const container = carouselRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const targetScroll = direction === 'left' 
        ? container.scrollLeft - scrollAmount 
        : container.scrollLeft + scrollAmount;
      
      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  const handleSeriesClick = (seriesId: string) => {
    navigate(`/series/${seriesId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="prayer-methods-carousel">
      <div className="prayer-methods-carousel-header">
        <h3 className="prayer-methods-carousel-title">
          Spiritual Journey Series
          <Link to="/series" className="see-all-link subtle">
            See All <ArrowRight size={14} />
          </Link>
        </h3>
      </div>
      <p className="prayer-methods-carousel-subtitle">
        Embark on guided multi-day journeys to deepen your prayer life and explore the rich traditions of Christian spirituality
      </p>
      <div className="prayer-methods-carousel-container">
        {showLeftArrow && (
          <button className="carousel-arrow left" onClick={() => scroll('left')}>
            <ChevronLeft size={24} />
          </button>
        )}
        <div className="prayer-methods-carousel-list" ref={carouselRef}>
          {seriesCatalog.map(series => (
            <div key={series.id} className="prayer-method-carousel-item">
              <div 
                className="prayer-method-carousel-card"
                onClick={() => handleSeriesClick(series.id)}
              >
                <img src={series.imageUrl} alt={series.title} className="prayer-method-carousel-image" />
                <div className="prayer-methods-carousel-details">
                  <h4 className="prayer-method-carousel-name">{series.title}</h4>
                  <div className="series-metadata">
                    <span className="series-duration">{series.metadata?.duration || 'Multiple days'}</span>
                    <span className="series-difficulty">
                      {series.metadata?.difficulty || 'All levels'}
                    </span>
                  </div>
                  <p className="prayer-method-carousel-description">{series.description}</p>
                  <span className="prayer-method-carousel-link">
                    Start Series <ArrowRight size={16} className="prayer-method-carousel-icon" />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <button className="carousel-arrow right" onClick={() => scroll('right')}>
            <ChevronRight size={24} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SeriesCarousel;