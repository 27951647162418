import React, { useState } from 'react';
import api from '../../utils/api';
import { API_ENDPOINTS } from '../../utils/apiConfig';

const StainedGlassImageGeneration = () => {
  const [prayerMethodDescription, setPrayerMethodDescription] = useState('');
  const [generatedImageUrl, setGeneratedImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [storagePath, setStoragePath] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setGeneratedImageUrl('');

    try {
      const response = await api.generateStainedGlassImage({ prayerMethodDescription });
      setGeneratedImageUrl(response.data.imageUrl);
    } catch (err) {
      setError('Failed to generate stained glass image. Please try again.');
      console.error('Error generating stained glass image:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveImage = async () => {
    if (!generatedImageUrl || !storagePath) return;
    setIsSaving(true);
    setSaveError('');
    try {
      await api.saveStainedGlassImage({ imageUrl: generatedImageUrl, storagePath });
      alert('Image saved successfully!');
    } catch (err) {
      setSaveError('Failed to save the image. Please try again.');
      console.error('Error saving stained glass image:', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDownloadImage = async () => {
    if (!generatedImageUrl) return;
    
    try {
      const response = await fetch(generatedImageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'stained-glass-image.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error downloading image:', err);
      alert('Failed to download the image. Please try again.');
    }
  };

  const handleTryAgain = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await api.generateStainedGlassImage({ prayerMethodDescription });
      setGeneratedImageUrl(response.data.imageUrl);
    } catch (err) {
      setError('Failed to generate a new stained glass image. Please try again.');
      console.error('Error generating new stained glass image:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Generate Stained Glass Image</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="prayerMethodDescription" className="form-label">Prayer Method Description:</label>
          <textarea
            id="prayerMethodDescription"
            className="form-control"
            value={prayerMethodDescription}
            onChange={(e) => setPrayerMethodDescription(e.target.value)}
            rows="4"
            placeholder="Enter prayer method description..."
            required
          />
        </div>
        <div className="d-flex gap-2">
          <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Generating...' : 'Generate Image'}
          </button>
          {generatedImageUrl && (
            <button type="button" className="btn btn-secondary" onClick={handleTryAgain} disabled={isLoading}>
              Try Again
            </button>
          )}
        </div>
      </form>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
      {generatedImageUrl && (
        <div className="mt-4">
          <h3>Generated Stained Glass Image:</h3>
          <img src={generatedImageUrl} alt="Generated Stained Glass" className="img-fluid" />
        </div>
      )}
      {generatedImageUrl && (
        <div className="mt-4">
          <h3>Save Generated Image:</h3>
          <div className="mb-3">
            <label htmlFor="storagePath" className="form-label">Storage Path:</label>
            <input
              type="text"
              id="storagePath"
              className="form-control"
              value={storagePath}
              onChange={(e) => setStoragePath(e.target.value)}
              placeholder="Enter storage path (e.g., img/prayer-method-images/newImage.jpg)"
              required
            />
          </div>
          <div className="d-flex gap-2">
            <button onClick={handleSaveImage} className="btn btn-success" disabled={isSaving || !storagePath}>
              {isSaving ? 'Saving...' : 'Save Image'}
            </button>
            <button onClick={handleDownloadImage} className="btn btn-info" disabled={!generatedImageUrl}>
              Download Image
            </button>
          </div>
          {saveError && <div className="alert alert-danger mt-3">{saveError}</div>}
        </div>
      )}
    </div>
  );
};

export default StainedGlassImageGeneration;
