import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import { doc, onSnapshot, setDoc, Timestamp, DocumentSnapshot } from 'firebase/firestore';
import { UserData } from '@shared/types/auth';

interface UserContextType {
  userData: UserData | null;
  loading: boolean;
  error: string | null;
  isAuthChecked: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    console.log('[UserProvider] Initializing auth listener');
    let unsubscribe = () => {};

    const authUnsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('[UserProvider] Auth state changed:', { user: user?.uid });
      
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        unsubscribe = onSnapshot(userDocRef, 
          async (doc: DocumentSnapshot) => {
            if (doc.exists()) {
              const rawData = doc.data();
              const data: UserData = {
                uid: doc.id,
                email: rawData?.email || '',
                isAnonymous: rawData?.isAnonymous || false,
                isAdmin: rawData?.isAdmin || false,
                createdAt: rawData?.createdAt,
                lastLogin: rawData?.lastLogin,
                displayName: rawData?.displayName,
                photoURL: rawData?.photoURL
              };
              console.log('[UserProvider] User data loaded:', { uid: data.uid });
              setUserData(data);
            } else {
              const newUserData: UserData = {
                uid: user.uid,
                email: user.email || '',
                isAnonymous: user.isAnonymous,
                isAdmin: false,
                createdAt: Timestamp.now(),
                lastLogin: Timestamp.now()
              };
              console.log('[UserProvider] Creating new user data:', { uid: newUserData.uid });
              await setDoc(userDocRef, newUserData);
              setUserData(newUserData);
            }
            setLoading(false);
            setIsAuthChecked(true);
          },
          (err) => {
            console.error('[UserProvider] Error fetching user data:', err);
            setError(err.message);
            setLoading(false);
            setIsAuthChecked(true);
          }
        );
      } else {
        console.log('[UserProvider] No user signed in');
        setUserData(null);
        setLoading(false);
        setIsAuthChecked(true);
      }
    });

    return () => {
      console.log('[UserProvider] Cleaning up listeners');
      authUnsubscribe();
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ userData, loading, error, isAuthChecked }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};