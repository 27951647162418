import React from 'react';
import { useAuthModal } from '../../contexts/AuthModalContext';
import { useLocation } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';
import styles from './SignUpPrompt.module.css';

interface SignUpPromptProps {
  message?: string;
  className?: string;
  variant?: 'button' | 'link';
}

const SignUpPrompt: React.FC<SignUpPromptProps> = ({ 
  message = 'Sign up to access more features', 
  className = '',
  variant = 'button'
}) => {
  const { showAuthModal } = useAuthModal();
  const location = useLocation();

  const handleClick = () => {
    showAuthModal(location.pathname);
  };

  if (variant === 'link') {
    return (
      <div className={`${styles.linkContainer} ${className}`}>
        <AlertTriangle className={styles.warningIcon} />
       <span className={styles.message}>{message}</span>
        <button onClick={handleClick} className={styles.link}>
          Sign up / Login
        </button>
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <AlertTriangle className={styles.warningIcon} />
      <span className={styles.message}>{message}</span>
      <button onClick={handleClick} className={styles.button}>
        Sign up / Login
      </button>
    </div>
  );
};

export default SignUpPrompt;