import React, { useState, useEffect } from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import LoadingSpinner from '../common/LoadingSpinner';

const ReflectionPlayer = ({ prayerMethod, stepIndex, stepData }) => {
  const [audioScript, setAudioScript] = useState(null);

  useEffect(() => {
    if (stepData && stepData.audioScript) {
      setAudioScript(stepData.audioScript);
    }
  }, [stepIndex, stepData]);

  const fetchScript = async () => {
    if (stepData.audioScript) {
      return stepData.audioScript;
    }
    throw new Error("Audio script not found for this step.");
  };

  const fakeGenerateAudioEndpoint = async () => {
    throw new Error("Audio generation is not available for Daily Examen. Please contact support if audio is missing.");
  };

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
    />
  );

  const title = `Step ${stepIndex + 1}: ${stepData.title}`;
  const storagePath = STORAGE_PATHS.DAILY_REFLECTIONS_AUDIO(prayerMethod.id, stepIndex);

  if (!audioScript) return <LoadingSpinner message="Preparing your reflection audio..." />;
  console.log('storagePath', storagePath);
  return (
    <BaseAudioPlayer
      key={stepIndex}
      fetchScript={fetchScript}
      generateAudioEndpoint={fakeGenerateAudioEndpoint}
      renderTranscript={renderTranscript}
      title={title}
      prayerMethod={prayerMethod}
      prayerData={stepData}
      date={null}
      storagePath={storagePath}
      audioKey={stepIndex}
      disableCompletionLogging={true}
    />
  );
};

export default ReflectionPlayer;
