import { useState, useEffect, useCallback } from 'react';
import { generateRecommendation, saveRecommendation as saveRecommendationApi } from '../utils/api';
import { UseRecommendationHook, UserAnswers, RecommendationResponse, RecommendationHistoryItem } from '@shared/types/recommendation';
import { auth } from '../firebase';
import api from '../utils/api';
import { API_ENDPOINTS } from '../utils/apiConfig';

export const useRecommendation = (): UseRecommendationHook => {
  const [recommendation, setRecommendation] = useState<RecommendationResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [recommendationHistory, setRecommendationHistory] = useState<RecommendationHistoryItem[]>([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyLoaded, setHistoryLoaded] = useState(false);

  const setSelectedRecommendation = (selectedRecommendation: RecommendationResponse) => {
    setRecommendation(selectedRecommendation);
  };

  const getRecommendation = async (answers: UserAnswers): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      const result = await generateRecommendation(answers);
      setRecommendation(result.data);
      
      // Auto-save recommendation if user is authenticated
      console.log('Auto-saving recommendation', auth.currentUser);
      if (auth.currentUser) {
        await saveRecommendation(answers, result.data.recommendation);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  const saveRecommendation = async (answers: UserAnswers, recommendationData: RecommendationResponse['recommendation']): Promise<void> => {
    if (!auth.currentUser) return;
    console.log('Saving recommendation');
    try {
      await saveRecommendationApi({
        userId: auth.currentUser.uid,
        userAnswers: answers,
        recommendation: recommendationData
      });
      await fetchRecommendationHistory();
    } catch (error) {
      console.error('Error saving recommendation:', error);
    }
  };

  const fetchRecommendationHistory = useCallback(async () => {
    if (!auth.currentUser || historyLoading || historyLoaded) return;
    
    setHistoryLoading(true);
    try {
      const response = await api.get(API_ENDPOINTS.RECOMMENDATION.GET(auth.currentUser.uid));
      setRecommendationHistory(response.data);
      setHistoryLoaded(true);
    } catch (error) {
      console.error('Error fetching recommendation history:', error);
    } finally {
      setHistoryLoading(false);
    }
  }, [historyLoading, historyLoaded]);

  useEffect(() => {
    if (auth.currentUser && !historyLoaded) {
      fetchRecommendationHistory();
    }
  }, [auth.currentUser, historyLoaded, fetchRecommendationHistory]);

  return { 
    recommendation, 
    loading, 
    error, 
    getRecommendation,
    saveRecommendation,
    recommendationHistory,
    setSelectedRecommendation
  };
};
