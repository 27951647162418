import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { useSeriesData } from '../../hooks/useSeriesData';
import styles from '../../styles/SeriesCatalog.module.css';

const SeriesCatalog = () => {
  const { seriesCatalog, loading, error } = useSeriesData();

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.seriesCatalog}>
      <h1 className={styles.catalogTitle}>Prayer Series</h1>
      <div className={styles.seriesGrid}>
        {seriesCatalog.map(series => (
          <Link 
            key={series.id} 
            to={`/series/${series.id}`}
            className={styles.seriesCard}
          >
            <img src={series.imageUrl} alt={series.title} className={styles.seriesImage} />
            <div className={styles.seriesDetails}>
              <h3 className={styles.seriesName}>{series.title}</h3>
              <p className={styles.seriesDescription}>{series.description}</p>
              <div className={styles.seriesMetadata}>
                <span className={styles.duration}>{series.metadata?.duration}</span>
                {series.difficulty && (
                  <span className={styles.difficulty}>{series.difficulty}</span>
                )}
              </div>
              <span className={styles.seriesLink}>
                Start Series <ArrowRight size={16} className={styles.seriesIcon} />
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SeriesCatalog;
