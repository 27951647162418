import { FC } from 'react';
import TraditionalRosaryMeditation from '../components/rosary/TraditionalRosaryMeditation';
import ScripturalRosaryMeditation from '../components/rosary/ScripturalRosaryMeditation';
import SurrenderRosaryMeditation from '../components/rosary/SurrenderRosaryMeditation';
import IgnatianRosaryMeditation from '../components/rosary/IgnatianRosaryMeditation';
import { RosaryMystery } from '@shared/types/rosaryMystery';
import { RosaryPrayers } from '@shared/types/rosary';

export interface RosaryMeditationProps {
  mysteryMeditationData: RosaryMystery['mysteries'][0];
  allMeditationData: RosaryMystery;
  rosaryPrayers: RosaryPrayers;
}

type RosaryMeditationComponent = FC<RosaryMeditationProps>;

const rosaryMeditationFactory = (style: string): RosaryMeditationComponent => {
  switch (style.toLowerCase()) {
    case 'traditional':
      return TraditionalRosaryMeditation;
    case 'scriptural':
      return ScripturalRosaryMeditation;
    case 'surrender':
      return SurrenderRosaryMeditation;
    case 'ignatian':
      return IgnatianRosaryMeditation;
    default:
      return TraditionalRosaryMeditation;
  }
};

export default rosaryMeditationFactory;
