import React from 'react';
import { Link, useParams, Navigate } from 'react-router-dom';
import { ArrowLeft, Info } from 'lucide-react';
import LoadingSpinner from '../common/LoadingSpinner';
import ContemplationSteps from './ContemplationSteps';
import { usePrayerMethod } from '../../hooks/usePrayerMethod';
import { ExpandableSection } from '../common/ExpandableContainer';
import ContemplationPlayer from '../audio/ContemplationPlayer';
import { useContemplationData } from '../../hooks/useContemplationData';
import Sharing from '../common/Sharing';
import { ContemplationMeditationProps } from '@shared/types/contemplation';
import '../../styles/contemplationMeditation.css';
import { PrayerTrackingProvider } from '../../contexts/PrayerTrackingContext';
import { ContemplationTrackingData } from '@shared/types/contemplation';
import ContentHeader from '../common/ContentHeader';

const ContemplationMeditationContainer: React.FC<ContemplationMeditationProps> = ({ 
  methodLink,
  seriesContext 
}) => {
  const params = useParams<{ methodLink: string }>();
  const actualMethodLink = methodLink || params.methodLink;

  const { prayerMethod, loading: methodLoading, error: methodError } = usePrayerMethod(actualMethodLink || '');
  const { contemplationData, loading, error } = useContemplationData(prayerMethod);

  if (!actualMethodLink) {
    return <Navigate to="/" replace />;
  }

  if (methodLoading || loading) return <LoadingSpinner />;
  if (methodError || error) return <div className="error-message">{methodError || error}</div>;
  if (!prayerMethod || !contemplationData) return <div>No data available</div>;

  const trackingData: ContemplationTrackingData = {
    id: `${prayerMethod.id}-${new Date().toISOString().split('T')[0]}`,
    title: `Contemplation Meditation: ${prayerMethod.name}`,
    type: 'contemplation-meditation',
    prayerMethodId: prayerMethod.id,
    prayerMethodTypeId: prayerMethod.type,
    date: new Date().toISOString().split('T')[0],
    gospelReference: '',
    gospelText: '',
    gospelId: '',
    steps: contemplationData.steps
  };

  return (
    <PrayerTrackingProvider
      prayerMethod={prayerMethod}
      prayerData={trackingData}
      seriesContext={seriesContext && {
        seriesId: seriesContext.seriesId,
        itemId: seriesContext.itemId,
        sectionId: seriesContext.sectionId
      }}
    >
      <div className="contemplation-container">
        <ContentHeader
          backLink="/"
          backText="Back to Home"
          title={`${prayerMethod.name} - Saintly Whispers`}
          description={`Explore ${prayerMethod.name}, a contemplative prayer method on Saintly Whispers.`}
          hideBackLink={seriesContext?.hideBackLink}
          className="mb-3"
        />
        
        <div className="contemplation-banner" style={{ backgroundImage: `url(${prayerMethod.imageUrl})` }}>
          <h1 className="contemplation-title">
            <span className="title-background">{prayerMethod.name}</span>
          </h1>
        </div>

        <ExpandableSection title={`Introduction to ${prayerMethod.name}`} icon={<Info size={20} />}>
          <p>{contemplationData.context}</p>
          <h3>Core Principles</h3>
          <ul>
            {contemplationData.core_principles.map((principle, index) => (
              <li key={index}>
                <strong>{principle.title}:</strong> {principle.explanation}
              </li>
            ))}
          </ul>
          <h3>Benefits</h3>
          <ul>
            {contemplationData.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
          <p><strong>Recommended Frequency:</strong> {contemplationData.recommended_frequency}</p>
          <p><strong>Typical Duration:</strong> {contemplationData.typical_duration}</p>
          <h3>Tips for Beginners</h3>
          <ul>
            {contemplationData.tips_for_beginners.map((tip, index) => (
              <li key={index}>{tip}</li>
            ))}
          </ul>
        </ExpandableSection>

        <hr className="contemplation-divider" />
        <ContemplationPlayer prayerMethod={prayerMethod} contemplationData={contemplationData} />
        <hr className="contemplation-divider" />
        <h2 className="contemplation-steps-title">{prayerMethod.name} Instructions</h2>
        <ContemplationSteps steps={contemplationData.steps} prayerMethod={prayerMethod} />
      </div>
    </PrayerTrackingProvider>
  );
};

export default ContemplationMeditationContainer;