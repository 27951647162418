import { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import { PrayerHistoryItem } from '@shared/types/prayerHistory';
import { fetchPrayerHistory as fetchPrayerHistoryApi, deletePrayerHistoryItem } from '../utils/api';

export const useUserPrayerHistory = (limitCount: number = 50) => {
  const [history, setHistory] = useState<PrayerHistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPrayerHistory = useCallback(async (): Promise<void> => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetchPrayerHistoryApi(currentUser.uid, limitCount);
      setHistory(response.data);
    } catch (error) {
      console.error('Error fetching prayer history:', error);
      setError('Failed to fetch prayer history');
    } finally {
      setLoading(false);
    }
  }, [limitCount]);

  const deletePrayerItem = useCallback(async (prayerId: string): Promise<void> => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('User not authenticated');
      return;
    }

    try {
      await deletePrayerHistoryItem(currentUser.uid, prayerId);
      setHistory(prev => prev.filter(item => item.id !== prayerId));
    } catch (error) {
      console.error('Error deleting prayer history item:', error);
      setError('Failed to delete prayer history item');
    }
  }, []);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      fetchPrayerHistory();
    } else {
      setLoading(false);
    }
  }, [fetchPrayerHistory]);

  return { 
    history, 
    loading, 
    error,
    refreshHistory: fetchPrayerHistory,
    deletePrayerItem
  };
};
