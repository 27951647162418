import React, { useState } from 'react';
import { format, isSameDay, differenceInDays } from 'date-fns';
import { Book, Trash2 } from 'lucide-react';
import { Timestamp } from 'firebase/firestore';
import { useUserPrayerHistory } from '../../hooks/useUserPrayerHistory';
import { usePrayerMethods } from '../../hooks/usePrayerMethods';
import { PrayerHistoryItem } from '@shared/types/prayerHistory';
import LoadingSpinner from '../common/LoadingSpinner';
import styles from '../../styles/prayerHistory.module.css';
import StatsOverview from './StatsOverview';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface PrayerHistoryProps {
  variant?: 'full' | 'compact';
}

const PrayerHistory: React.FC<PrayerHistoryProps> = ({ variant = 'full' }) => {
  const containerClassName = `${styles.container} ${variant === 'compact' ? styles.compact : ''}`;
  const navigate = useNavigate();
  const { history, loading, error, refreshHistory, deletePrayerItem } = useUserPrayerHistory();
  const { prayerMethods, loading: methodsLoading } = usePrayerMethods();
  const [deletingId, setDeletingId] = useState<string | null>(null);

  const formatTimestamp = (timestamp: string | Timestamp): string => {
    if (timestamp instanceof Timestamp) {
      return format(timestamp.toDate(), 'MMM d, yyyy h:mm a');
    } else if (typeof timestamp === 'string') {
      const date = new Date(timestamp);
      if (!isNaN(date.getTime())) {
        return format(date, 'MMM d, yyyy h:mm a');
      }
    }
    return 'Unknown date';
  };

  const getPrayerMethodDetails = (methodId: string) => {
    return prayerMethods.find(method => method.id === methodId);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this prayer history item?')) {
      setDeletingId(id);
      try {
        await deletePrayerItem(id);
      } finally {
        setDeletingId(null);
      }
    }
  };

  if (loading || methodsLoading) return <LoadingSpinner message="Loading prayer history..." />;
  if (error) return <div className={styles.message}>Error: {error}</div>;
  if (!history) return <div className={styles.message}>Please log in to view your prayer history.</div>;

  return (
    <div className={containerClassName}>
      {variant === 'full' ? (
        <h2>Your Prayer Journey</h2>
      ) : (
        <h3 className={styles.compactTitle}>Recent Activity</h3>
      )}
      
      <StatsOverview history={history} />
      
      {history.length === 0 ? (
        <p className={styles.message}>You haven't completed any prayers yet. Start your journey today!</p>
      ) : (
        <ul className={styles.list}>
          {(variant === 'compact' ? history.slice(0, 5) : history).map((prayer: PrayerHistoryItem) => {
            const methodDetails = getPrayerMethodDetails(prayer.prayerMethod);
            return (
              <li key={prayer.id} className={styles.item}>
                {methodDetails?.imageUrl ? (
                  <img 
                    src={methodDetails.imageUrl} 
                    alt={methodDetails.name} 
                    className={styles.methodImage}
                  />
                ) : (
                  <div className={styles.methodImage}>
                    <Book size={24} />
                  </div>
                )}
                <div className={styles.details}>
                  <strong className={styles.title}>{prayer.prayerTitle}</strong>
                  <div className={styles.metadata}>
                    <span className={styles.methodName}>
                      {methodDetails?.name || 'Prayer Method'}
                    </span>
                    <span className={styles.timestamp}>
                      {formatTimestamp(prayer.timestamp)}
                    </span>
                    {prayer.completionType && (
                      <span className={styles.completionType}>
                        {prayer.completionType === 'listen' ? 'Audio' : 'Reading'}
                      </span>
                    )}
                  </div>
                </div>
                <button 
                  onClick={() => handleDelete(prayer.id)}
                  className={styles.deleteButton}
                  disabled={deletingId === prayer.id}
                >
                  <Trash2 size={18} />
                </button>
              </li>
            );
          })}
        </ul>
      )}
      
      {variant === 'compact' && history.length > 5 && (
        <Button 
          variant="link" 
          className={styles.viewAllButton}
          onClick={() => navigate('/advisor?tab=history')}
        >
          View Full History
        </Button>
      )}
    </div>
  );
};

export default PrayerHistory;