import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Plus, Check } from 'lucide-react';
import styles from './addToPlan.module.css';

interface AddToPlanProps {
  onAdd: (e?: React.MouseEvent) => Promise<void>;
  variant?: 'primary' | 'outline' | 'minimal';
  size?: 'sm' | 'lg';
  className?: string;
}

const AddToPlan: React.FC<AddToPlanProps> = ({ 
  onAdd, 
  variant = 'primary',
  size,
  className = ''
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (showConfirmation) return;
    
    setIsAdding(true);
    try {
      await onAdd(e);
      setShowConfirmation(true);
    } catch (error) {
      // Handle error if needed
    } finally {
      setIsAdding(false);
    }
  };

  const buttonClassName = `
    ${styles.addButton} 
    ${styles[variant]} 
    ${size ? styles[size] : ''}
    ${showConfirmation ? styles.success : ''}
    ${className}
  `.trim();

  return (
    <div className={styles.container}>
      <Button 
        variant={variant === 'outline' ? 'outline-primary' : 'primary'}
        size={size}
        className={buttonClassName}
        onClick={handleClick}
        disabled={isAdding}
        style={{ 
          whiteSpace: 'nowrap',
          fontSize: '1rem'
        }}
      >
        {showConfirmation ? (
          <>
            <Check size={16} /> Added
          </>
        ) : (
          <>
            <Plus size={16} />
            {variant !== 'minimal' && 'Add to Plan'}
          </>
        )}
      </Button>
    </div>
  );
};

export default AddToPlan;
