import React from 'react';
import RosaryPlayer from '../audio/RosaryPlayer';
import DecadePrayers from './DecadePrayers';

const ScripturalRosaryMeditation = ({ mysteryMeditationData, allMeditationData, rosaryPrayers }) => {
  return (
    <>
      <h3>{mysteryMeditationData.name}</h3>
      <div className="scripture-passage">
        <h4>Scripture Passage</h4>
        <p>{mysteryMeditationData.scripture}</p>
      </div>
      <div className="meditation-text">
        <h4>Meditation</h4>
        <p>{mysteryMeditationData.textMeditation}</p>
      </div>
      <RosaryPlayer
        key={mysteryMeditationData.id}
        prayerMethod={{
          type: 'rosary-meditation',
          id: allMeditationData.prayerMethodId,
        }}
        mysteryMeditationData={mysteryMeditationData}
        allMeditationData={allMeditationData}
      />
      <div className="hail-mary-scriptures mt-4">
        <h4>Scriptures for Hail Mary</h4>
        <ol>
          {mysteryMeditationData.hailMaryScriptures.map((scripture, index) => (
            <li key={index}>{scripture}</li>
          ))}
        </ol>
      </div>
      <hr className="divider" />
      <DecadePrayers decadePrayers={rosaryPrayers.decadePrayers} mysteryName={mysteryMeditationData.name} />
    </>
  );
};

export default ScripturalRosaryMeditation;