import { useState, useCallback, useEffect } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import api from '../utils/api';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { RelaxationPrayer } from '@shared/types/relaxationPrayer';

interface AudioScript {
  script: string[];
}

export const useRelaxationPrayer = (prayerMethod: PrayerMethod, relaxationPrayer: RelaxationPrayer) => {
  const [script, setScript] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchScript = useCallback(async () => {
    if (script) return script;

    setIsLoading(true);
    setError(null);

    try {
      if (!prayerMethod || !relaxationPrayer) {
        throw new Error("Missing required parameters for fetching script");
      }

      const db = getFirestore();
      const scriptRef = doc(db, FIRESTORE_PATHS.RELAXATION_PRAYERS.path(prayerMethod.id));
      
      const scriptDoc = await getDoc(scriptRef);
      
      if (scriptDoc.exists()) {
        const scriptData = scriptDoc.data() as AudioScript;
        setScript(scriptData.script);
        return scriptData.script;
      } else {
        const scriptResponse = await api.generateAudioScript({
          prayerMethod,
          prayerContent: relaxationPrayer,
          date: 'default',
          source: 'client'
        });
        setScript(scriptResponse.data.script);
        return scriptResponse.data.script;
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Unknown error';
      setError(`Error fetching script: ${errorMessage}`);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [script, prayerMethod, relaxationPrayer]);

  useEffect(() => {
    if (prayerMethod && relaxationPrayer) {
      fetchScript().catch(console.error);
    }
  }, [fetchScript, prayerMethod, relaxationPrayer]);

  return { script, isLoading, error, fetchScript };
};
