import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { FIRESTORE_PATHS } from '../utils/apiConfig';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { DailyReflectionData } from '@shared/types/dailyReflection';

export const useDailyReflectionData = (prayerMethod: PrayerMethod | null) => {
  const [reflectionData, setReflectionData] = useState<DailyReflectionData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReflectionData = async () => {
      if (!prayerMethod || !prayerMethod.id) {
        console.log('No prayer method or prayer method ID provided');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching reflection data for prayer method:', prayerMethod.id);
        const reflectionRef = doc(firestore, FIRESTORE_PATHS.DAILY_REFLECTIONS.path(prayerMethod.id));
        const reflectionSnap = await getDoc(reflectionRef);

        if (reflectionSnap.exists()) {
          const data = reflectionSnap.data() as DailyReflectionData;
          console.log('Found reflection data:', data);
          setReflectionData(data);
        } else {
          console.log('No reflection data found, creating default data');
          // Create default reflection data
          const defaultData: DailyReflectionData = {
            context: prayerMethod.long_description || '',
            core_principles: [],
            benefits: [],
            general_structure: '',
            recommended_frequency: 'Daily',
            typical_duration: '15-20 minutes',
            tips_for_beginners: [],
            steps: [
              {
                title: 'Begin Your Reflection',
                description: 'Take a moment to center yourself and begin your reflection.',
                questions: ['What stands out to you today?'],
                context: prayerMethod.description || '',
                audioScript: [],
                practicalSteps: ['Find a quiet space', 'Take a deep breath'],
                tips: ['Start with a short prayer']
              }
            ]
          };
          setReflectionData(defaultData);
        }
      } catch (err) {
        console.error('Error fetching daily reflection data:', err);
        setError(`Error fetching daily reflection data: ${err instanceof Error ? err.message : 'Unknown error'}`);
      } finally {
        setLoading(false);
        console.log('Current reflection data state:', { 
          reflectionData, 
          loading: false, 
          error,
          prayerMethod: prayerMethod?.id 
        });
      }
    };

    fetchReflectionData();
  }, [prayerMethod]);

  return { reflectionData, loading, error };
};
