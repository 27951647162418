import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { collection, getDocs, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { firestore, auth, signInAnonymouslyIfNeeded } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { FIRESTORE_PATHS } from './utils/apiConfig';
import HomePage from './components/HomePage';
import PrayersCatalog from './components/catalog/PrayersCatalog';
import Navbar from './components/nav/Navbar';
import DailyPrayerMethodContainer from './components/daily-prayers/DailyPrayerMethodContainer';
import RelaxationPrayerContainer from './components/relaxation/RelaxationPrayerContainer.tsx';
import RosaryMeditationStyleSelector from './components/rosary/RosaryMeditationStyleSelector';
import RosaryMeditationContainer from './components/rosary/RosaryMeditationContainer';
import ContemplationMeditationContainer from './components/contemplation/ContemplationMeditationContainer';
import LoadingSpinner from './components/common/LoadingSpinner';
import DailyReflectionContainer from './components/daily-reflections/DailyReflectionContainer';
import CustomScripturePage from './components/scripture/CustomScripturePage';
import CustomScriptureMeditationContainer from './components/scripture/CustomScriptureMeditationContainer';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom-bootstrap.css';
import './styles/shared.css';
import './styles/components.css';
import AuthComponent from './components/authentication/AuthComponent';
import AdminPage from './components/admin/AdminPage';
import SocialMediaPosts from './components/admin/SocialMediaPosts';
import { useUserData } from './hooks/useUserData';
import AccountPage from './components/authentication/AccountPage';
import SaintsCatalog from './components/saints/SaintsCatalog';
import SaintMeditationContainer from './components/saints/SaintMeditationContainer';
import AboutPage from './components/about/AboutPage';
import Footer from './components/nav/Footer';
import Terms from './components/about/Terms';
import RecommendationForm from './components/advisor/RecommendationForm';
import LogoutSuccess from './components/authentication/LogoutSuccess';
import AuthModal from './components/authentication/AuthModal';
import { useAuthModal, AuthModalProvider } from './contexts/AuthModalContext';
import RecommendationContainer from './components/advisor/RecommendationContainer';
import PrayerPlan from './components/plan/PrayerPlan';
import SeriesCatalog from './components/series/SeriesCatalog';
import SeriesOverviewContainer from './components/series/SeriesOverviewContainer';
import SeriesItemContainer from './components/series/SeriesItemContainer';

import HomilyContainer from './components/homilies/HomilyContainer';

const ProtectedRoute = ({ children, isAdminRoute = false }) => {
  const { userData, loading } = useUserData();
  const { showAuthModal } = useAuthModal();
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!loading) {
      if (!userData || userData.isAnonymous) {
        showAuthModal(location.pathname);
      } else if (isAdminRoute && !userData.isAdmin) {
        // Redirect non-admin users to home page immediately
        navigate('/', { replace: true });
      }
    }
  }, [userData, loading, isAdminRoute, location.pathname, showAuthModal, navigate]);
  
  if (loading) {
    return <LoadingSpinner />;
  }
  
  // Don't render anything for non-authenticated or non-admin users
  if (!userData || userData.isAnonymous || (isAdminRoute && !userData.isAdmin)) {
    return null;
  }
  
  return children;
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prayerMethods, setPrayerMethods] = useState([]);
  const [error, setError] = useState(null);

  const { userData, loading: userLoading, error: userError } = useUserData();
  const { isAuthModalVisible, hideAuthModal, returnUrl } = useAuthModal();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.isAnonymous) {
          console.log('User is signed in anonymously');
        } else {
          console.log('User is signed in with an account:', user.email);
        }
        setUser(user);
      } else {
        try {
          const anonymousUser = await signInAnonymouslyIfNeeded();
          const userDocRef = doc(firestore, FIRESTORE_PATHS.USERS.path(anonymousUser.uid));
          await setDoc(userDocRef, {
            isAnonymous: true,
            createdAt: serverTimestamp(),
            lastLogin: serverTimestamp(),
          }, { merge: true });
          setUser(anonymousUser);
        } catch (error) {
          console.error('Error signing in anonymously:', error);
          setError('Failed to sign in anonymously. Please try again later.');
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPrayerMethods = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'prayerMethods'));
        const methods = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPrayerMethods(methods);
      } catch (error) {
        console.error("Error fetching prayer methods:", error);
        setError("Failed to load prayer methods. Please try again later.");
      }
    };

    if (user) {
      fetchPrayerMethods();
    }
  }, [user]);

  if (loading || userLoading) {
    return <LoadingSpinner />;
  }

  if (error || userError) {
    return <div className="error-message">{error || userError}</div>;
  }

  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Navbar user={user} userData={userData} />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/:date" element={<HomePage />} />
              <Route path="/rosary/:mysteryType" element={<RosaryMeditationStyleSelector />} />
              <Route path="/rosary/:mysteryType/:styleId" element={<RosaryMeditationContainer />} />
              <Route path="/reflections/:methodLink" element={<DailyReflectionContainer />} />
              <Route path="/prayers" element={<PrayersCatalog />} />
              <Route path="/signup" element={<AuthComponent />} />
              <Route path="/login" element={<AuthComponent />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/daily/:methodLink/:date?" element={<DailyPrayerMethodContainer />} />
              <Route path="/relaxation/:methodLink" element={<RelaxationPrayerContainer />} />
              <Route path="/contemplation/:methodLink" element={<ContemplationMeditationContainer />} />
              <Route path="/admin/data-generation" element={<ProtectedRoute isAdminRoute><AdminPage /></ProtectedRoute>} />
              <Route path="/admin/social-media-posts" element={<ProtectedRoute isAdminRoute><SocialMediaPosts /></ProtectedRoute>} />
              <Route path="/account" element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
              <Route path="/saints" element={<SaintsCatalog />} />
              <Route path="/saints/:saintId" element={<SaintMeditationContainer />} />
              <Route path="/scripture" element={<CustomScripturePage />} />
              <Route path="/scripture/meditation" element={<CustomScriptureMeditationContainer />} />
              <Route path="/advisor" element={<RecommendationContainer />} />
              <Route path="/advisor/plan" element={<PrayerPlan />} />
              <Route path="/logout-success" element={<LogoutSuccess />} />
              <Route path="/series" element={<SeriesCatalog />} />
              <Route path="/series/:seriesId" element={<SeriesOverviewContainer />} />
              <Route path="/series/:seriesId/items/:itemId" element={<SeriesItemContainer />} />
              <Route path="/homily/:date?" element={<HomilyContainer />} />
            </Routes> 
          </main>
          <AuthModal 
            show={isAuthModalVisible}
            onHide={hideAuthModal}
            returnUrl={returnUrl}
          />
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
