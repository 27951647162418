import React from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import api from '../../utils/api';
import { useAudioPrayer } from '../../hooks/useAudioPrayer';
import LoadingSpinner from '../common/LoadingSpinner';

const AudioPrayer = ({ 
  prayerMethod, 
  prayerData, 
  date, 
  script: initialScript, 
  duration, 
  fetchScript: parentFetchScript, 
  customBreakDurations, 
  isCustomScripture, 
  completionThreshold = 5 
}) => {
  const { script, isLoading, error } = useAudioPrayer(
    prayerMethod, 
    prayerData, 
    date, 
    initialScript, 
    duration, 
    parentFetchScript
  );

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      key={JSON.stringify(script)}
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
      breakDurations={customBreakDurations}
    />
  );

  const title = prayerData && prayerData.gospelReference
    ? `Guided ${prayerMethod.name} for ${prayerData.gospelReference}`
    : `Guided Meditation: ${prayerMethod.name}`;

  if (isLoading) return <LoadingSpinner message="Preparing your audio prayer..." />;
  if (error) return <div className="error-container">
    <h2>Oops! Something went wrong</h2>
    <p>We encountered an error while loading your audio:</p>
    <div className="error-message mt-3">{error}</div>
    <p className="mt-3">Please try refreshing the page.</p>
  </div>;

  return (
    <div>
      <BaseAudioPlayer
        fetchScript={parentFetchScript}
        generateAudioEndpoint={api.generateAudioPrayer}
        renderTranscript={renderTranscript}
        title={title}
        date={date}
        storagePath={
          prayerMethod.type === 'rosary-meditation'
            ? STORAGE_PATHS.ROSARY_AUDIO_MEDITATIONS(prayerMethod.id, prayerMethod.currentMysteryId)
            : prayerMethod.type === 'relaxation-prayer'
              ? STORAGE_PATHS.RELAXATION_AUDIO_PRAYERS(prayerMethod.id)
              : STORAGE_PATHS.DAILY_AUDIO_PRAYERS(prayerMethod.id, date)
        }
        initialDuration={duration}
        script={script}
        customBreakDurations={customBreakDurations}
        completionThreshold={completionThreshold}
      />
    </div>
  );  
};

export default AudioPrayer;
