import React from 'react';
import RosaryPlayer from '../audio/RosaryPlayer';
import DecadePrayers from './DecadePrayers';


const TraditionalRosaryMeditation = ({ mysteryMeditationData, allMeditationData, rosaryPrayers }) => {
    return (
    <>
      <h3>{mysteryMeditationData.name}</h3>
      <p className="meditation-text">{mysteryMeditationData.textMeditation}</p>
      <RosaryPlayer
        key={mysteryMeditationData.id}
        prayerMethod={{
          type: 'rosary-meditation',
          id: allMeditationData.prayerMethodId,
        }}
        mysteryMeditationData={mysteryMeditationData}
        allMeditationData={allMeditationData}
      />
      <hr className="divider" />
      <DecadePrayers decadePrayers={rosaryPrayers.decadePrayers} mysteryName={mysteryMeditationData.name} />
    </>
  );
};

export default TraditionalRosaryMeditation;