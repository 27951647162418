import React from 'react';
import ExpandableText from '../common/ExpandableText';
import { formatVerses } from '../../utils/scriptureUtils';

interface ScriptureDisplayProps {
  scripture: string;
  scriptureText: string;
  selectedChapter: string;
  startVerse: number;
  endVerse: number;
  isLoading: boolean;
}

const ScriptureDisplay: React.FC<ScriptureDisplayProps> = ({
  scripture,
  scriptureText,
  selectedChapter,
  startVerse,
  endVerse,
  isLoading
}) => {
  const formattedVerses = formatVerses(
    scriptureText,
    selectedChapter,
    startVerse,
    endVerse
  );

  return (
    <div className="scripture-section mb-4">
      <ExpandableText
        title={scripture}
        content={formattedVerses}
        maxHeight="200px"
        variant="verses"
        startVerse={startVerse}
        endVerse={endVerse}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ScriptureDisplay;
