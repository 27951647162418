import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import api from '../utils/api';
import { API_ENDPOINTS } from '../utils/apiConfig';
import { DailyReading } from '@shared/types/gospel';
import { AxiosError } from 'axios';

interface ErrorResponse {
  message?: string;
}

interface UseGospelDataOptions {
  forceFetch?: boolean;
}

interface GospelDataHookResult {
  dailyReading: DailyReading | null;
  loading: boolean;
  error: string | null;
}

interface GospelDataGenerationResult extends GospelDataHookResult {
  isComplete: boolean;
  setDailyReading: (reading: DailyReading | null) => void;
  setIsComplete: (complete: boolean) => void;
  setError: (error: string | null) => void;
}

// For HomePage.js - Simple data fetching
export const useGospelData = (
  selectedDate: Date | null,
  options: UseGospelDataOptions = {}
): GospelDataHookResult => {
  const { forceFetch = false } = options;
  const [dailyReading, setDailyReading] = useState<DailyReading | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedDate) return;

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    let isMounted = true;
    const controller = new AbortController();

    const fetchGospelData = async () => {
      setLoading(true);
      try {
        const response = await api.get<DailyReading>(
          API_ENDPOINTS.FETCH_GOSPEL_READING(formattedDate),
          { signal: controller.signal }
        );
        
        if (isMounted) {
          setDailyReading(response.data);
          setError(null);
        }
      } catch (error: unknown) {
        if (isMounted && !(error instanceof Error && error.name === 'AbortError')) {
          const axiosError = error as AxiosError<ErrorResponse>;
          const errorMessage = axiosError.response?.data?.message 
            || (typeof axiosError.response?.data === 'string' ? axiosError.response.data : null)
            || axiosError.message 
            || 'Unknown server error';
          setError(`Error fetching gospel data: ${errorMessage}`);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchGospelData();
    
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [selectedDate?.getTime()]);

  return { dailyReading, loading, error };
};

// For GospelDataGeneration.tsx - Generation with completion status
export const useGospelDataGeneration = (
  selectedDate: Date | null,
  options: UseGospelDataOptions = {}
): GospelDataGenerationResult => {
  const { forceFetch = false } = options;
  const [dailyReading, setDailyReading] = useState<DailyReading | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedDate) return;

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    let isMounted = true;
    const controller = new AbortController();

    const fetchGospelData = async () => {
      if (isMounted) {
        setLoading(true);
        setIsComplete(false);
        setError(null);
        setDailyReading(null);
      }
      
      try {
        const response = await api.get<DailyReading>(
          API_ENDPOINTS.FETCH_GOSPEL_READING(formattedDate),
          { 
            params: { forceFetch },
            signal: controller.signal,
            timeout: 30000 // Add timeout of 30 seconds
          }
        );
        
        if (isMounted) {
          setDailyReading(response.data);
          setError(null);
          setIsComplete(true);
        }
      } catch (error: unknown) {
        if (isMounted && !(error instanceof Error && error.name === 'AbortError')) {
          const axiosError = error as AxiosError<ErrorResponse>;
          const errorMessage = axiosError.response?.data?.message 
            || (typeof axiosError.response?.data === 'string' ? axiosError.response.data : null)
            || axiosError.message 
            || 'Unknown server error';
          setError(`Error fetching gospel data: ${errorMessage}`);
          setIsComplete(true);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchGospelData();
    
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [selectedDate?.getTime(), forceFetch]);

  return {
    dailyReading,
    loading,
    error,
    isComplete,
    setDailyReading,
    setIsComplete,
    setError
  };
};

