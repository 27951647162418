import React, { useState, useCallback, useRef } from 'react';
import { format, parseISO, isValid, addDays } from 'date-fns';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { FIRESTORE_PATHS } from '../../utils/apiConfig';
import { useGospelDataGeneration } from '../../hooks/useGospelData';
import { DailyReading } from '@shared/types/gospel';

interface DateRange {
  startDate: string;
  endDate: string;
}

interface ProcessingStatus {
  message: string;
  type: 'info' | 'success' | 'error';
  timestamp: number;
}

const GospelDataGeneration: React.FC = () => {
  const [dateRange, setDateRange] = useState<DateRange>({ startDate: '', endDate: '' });
  const [statusLog, setStatusLog] = useState<ProcessingStatus[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentDate, setCurrentDate] = useState<string | null>(null);
  const processingRef = useRef<boolean>(false);

  const currentDateObj = currentDate ? parseISO(currentDate) : null;
  const { 
    dailyReading, 
    loading, 
    error: gospelError, 
    isComplete,
    setDailyReading,
    setIsComplete 
  } = useGospelDataGeneration(currentDateObj, {
    forceFetch: true
  });

  const addStatus = useCallback((message: string, type: ProcessingStatus['type'] = 'info') => {
    setStatusLog(prev => [...prev, { message, type, timestamp: Date.now() }]);
  }, []);

  const processDate = useCallback(async (date: Date, docRef: any): Promise<boolean> => {
    const dateStr = format(date, 'yyyy-MM-dd');
    
    console.log('processDate - Processing:', { dateStr });
    
    setCurrentDate(dateStr);
    setIsComplete(false);
    
    return new Promise((resolve, reject) => {
      const TIMEOUT = 60000;
      let isResolved = false;

      const timeoutId = setTimeout(() => {
        if (!isResolved) {
          isResolved = true;
          setCurrentDate(null);
          reject(new Error('Timeout waiting for gospel data generation'));
        }
      }, TIMEOUT);

      const checkInterval = setInterval(() => {
        if (isResolved) {
          clearInterval(checkInterval);
          return;
        }

        if (isComplete) {
          isResolved = true;
          clearInterval(checkInterval);
          clearTimeout(timeoutId);

          if (dailyReading) {
            setDoc(docRef, dailyReading)
              .then(() => resolve(true))
              .catch(reject)
              .finally(() => {
                setCurrentDate(null);
                setDailyReading(null);
                setIsComplete(false);
              });
          } else if (gospelError) {
            setCurrentDate(null);
            reject(new Error(gospelError));
          }
        }
      }, 500);

      return () => {
        clearInterval(checkInterval);
        clearTimeout(timeoutId);
        if (!isResolved) {
          setCurrentDate(null);
          setIsComplete(false);
        }
      };
    });
  }, [dailyReading, gospelError, isComplete, setIsComplete]);

  const generateGospelData = useCallback(async () => {
    if (processingRef.current) return;
    processingRef.current = true;
    setIsProcessing(true);
    setStatusLog([]);

    try {
      // Create dates at UTC noon
      const start = new Date(Date.UTC(
        parseInt(dateRange.startDate.slice(0, 4)),
        parseInt(dateRange.startDate.slice(5, 7)) - 1,
        parseInt(dateRange.startDate.slice(8, 10)),
        12, 0, 0, 0
      ));
      
      const end = new Date(Date.UTC(
        parseInt(dateRange.endDate.slice(0, 4)),
        parseInt(dateRange.endDate.slice(5, 7)) - 1,
        parseInt(dateRange.endDate.slice(8, 10)),
        12, 0, 0, 0
      ));

      console.log('generateGospelData - Date range:', {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        parsedStart: start.toISOString(),
        parsedEnd: end.toISOString()
      });

      if (!isValid(start) || !isValid(end)) {
        throw new Error('Invalid date range');
      }

      const dates: Date[] = [];
      let current = start;
      while (current <= end) {
        dates.push(new Date(current));
        current = addDays(current, 1);
      }

      addStatus(`Starting processing for ${dates.length} dates...`);

      for (const date of dates) {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const docRef = doc(
          firestore, 
          FIRESTORE_PATHS.DAILY_GOSPEL_READINGS.collection,
          FIRESTORE_PATHS.DAILY_GOSPEL_READINGS.doc(formattedDate)
        );

        try {
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists()) {
            addStatus(`Processing ${formattedDate}...`);
            await processDate(date, docRef);
            addStatus(`Successfully processed ${formattedDate}`, 'success');
          } else {
            addStatus(`Skipping ${formattedDate} - already exists`, 'info');
          }
        } catch (error) {
          addStatus(
            `Error processing ${formattedDate}: ${error instanceof Error ? error.message : 'Unknown error'}`,
            'error'
          );
        }
      }
    } catch (error) {
      addStatus(
        `Generation failed: ${error instanceof Error ? error.message : 'Unknown error'}`,
        'error'
      );
    } finally {
      setCurrentDate(null);
      setIsProcessing(false);
      processingRef.current = false;
      addStatus('Processing complete', 'info');
    }
  }, [dateRange, processDate, addStatus]);

  return (
    <div className="container mt-5">
      <h2>Generate Daily Gospel Data</h2>
      <div className="row g-3">
        <div className="col-md-6">
          <label className="form-label">Start Date</label>
          <input
            type="date"
            className="form-control"
            value={dateRange.startDate}
            onChange={(e) => setDateRange(prev => ({ ...prev, startDate: e.target.value }))}
            disabled={isProcessing}
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-control"
            value={dateRange.endDate}
            onChange={(e) => setDateRange(prev => ({ ...prev, endDate: e.target.value }))}
            disabled={isProcessing}
          />
        </div>
      </div>

      <button
        className="btn btn-primary mt-3"
        onClick={generateGospelData}
        disabled={isProcessing || !dateRange.startDate || !dateRange.endDate}
      >
        {isProcessing ? 'Generating...' : 'Generate Data'}
      </button>

      <div className="mt-4">
        <h3>Processing Log</h3>
        <div className="log-container border rounded p-3" style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {statusLog.map((status, index) => (
            <div
              key={`${status.timestamp}-${index}`}
              className={`log-entry mb-1 ${status.type === 'error' ? 'text-danger' : 
                status.type === 'success' ? 'text-success' : 'text-info'}`}
            >
              {status.message}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GospelDataGeneration;