import React, { useState, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { PrayerMethod } from '@shared/types/prayerMethod';
import styles from '../../styles/customPrayerMethodSelector.module.css';

interface CustomPrayerMethodSelectorProps {
  prayerMethods: PrayerMethod[];
  selectedPrayerMethod: PrayerMethod | null;
  onSelectPrayerMethod: (prayerMethod: PrayerMethod) => void;
  loading: boolean;
  error: string | null;
  scrollToTop: () => void;
}

const CustomPrayerMethodSelector: React.FC<CustomPrayerMethodSelectorProps> = ({
  prayerMethods,
  selectedPrayerMethod,
  onSelectPrayerMethod,
  loading,
  error,
  scrollToTop
}) => {
  const [showAllMethods, setShowAllMethods] = useState(false);

  const filteredPrayerMethods = useMemo(() => {
    return prayerMethods
      .filter(method => 
        (method.type === 'daily-meditation' || method.type === 'daily-prayer') && 
        method.status === 'published'
      )
      .sort((a, b) => {
        const orderA = a.displayOrder || Infinity;
        const orderB = b.displayOrder || Infinity;
        return orderA - orderB;
      });
  }, [prayerMethods]);

  if (loading) return <p>Loading prayer methods...</p>;
  if (error) return <p>Error loading prayer methods: {error}</p>;
  if (filteredPrayerMethods.length === 0) return <p>No suitable prayer methods found.</p>;

  return (
    <div className={styles.customPrayerMethodSelector}>
      <h3 className={styles.selectorTitle}>Choose a Prayer Method</h3>
      {selectedPrayerMethod && !showAllMethods ? (
        <div>
          <a href="#" className={styles.chooseDifferentMethod} onClick={() => setShowAllMethods(true)}>
            Change prayer method
          </a>
          <div className={`${styles.prayerMethodCard} ${styles.selected}`} onClick={() => onSelectPrayerMethod(selectedPrayerMethod)}>
            <div className={styles.prayerMethodImageContainer}>
              <img src={selectedPrayerMethod.imageUrl} alt={selectedPrayerMethod.name} className={styles.prayerMethodImage} />
            </div>
            <div className={styles.prayerMethodDetails}>
              <h4 className={styles.prayerMethodName}>{selectedPrayerMethod.name}</h4>
              <p className={styles.prayerMethodDescription}>{selectedPrayerMethod.description}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.prayerMethodList}>
          {filteredPrayerMethods.map((method) => (
            <div
              key={method.id}
              className={`${styles.prayerMethodCard} ${selectedPrayerMethod?.id === method.id ? styles.selected : ''}`}
              onClick={() => {
                onSelectPrayerMethod(method);
                setShowAllMethods(false);
                scrollToTop();
              }}
            >
              <div className={styles.prayerMethodImageContainer}>
                <img src={method.imageUrl} alt={method.name} className={styles.prayerMethodImage} />
              </div>
              <div className={styles.prayerMethodDetails}>
                <h4 className={styles.prayerMethodName}>{method.name}</h4>
                <p className={styles.prayerMethodDescription}>{method.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomPrayerMethodSelector;
