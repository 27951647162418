import React from 'react';
import DecadePrayers from './DecadePrayers';
import RosaryPlayer from '../audio/RosaryPlayer';

const SurrenderRosaryMeditation = ({ mysteryMeditationData, allMeditationData, rosaryPrayers }) => {
  return (
    <>
      <h3>{mysteryMeditationData.name}</h3>
      <h4>{mysteryMeditationData.surrenderFocus}</h4>
      <p className="reflection">{mysteryMeditationData.reflection}</p>
      <p className="surrender-prayer">{mysteryMeditationData.surrenderPrayer}</p>
      <div className="practical-application">
        <h4>Practical Application</h4>
        <p>{mysteryMeditationData.practicalApplication}</p>
      </div>
      <div className="scripture-verse">
        <h4>Scripture Verse</h4>
        <p>{mysteryMeditationData.scriptureVerse}</p>
      </div>
      <RosaryPlayer
        key={mysteryMeditationData.id}
        prayerMethod={{
          type: 'rosary-meditation',
          id: allMeditationData.prayerMethodId,
        }}
        mysteryMeditationData={mysteryMeditationData}
        allMeditationData={allMeditationData}
      />
      <hr className="divider" />
      <DecadePrayers decadePrayers={rosaryPrayers.decadePrayers} mysteryName={mysteryMeditationData.name} />
    </>
  );
};

export default SurrenderRosaryMeditation;