import React, { useState, useEffect, useCallback } from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import { adjustContemplationBreakDurations } from '../../utils/audioUtils';

const AudioPlayerWithDuration = ({ 
  fetchScript, 
  generateAudioEndpoint, 
  renderTranscript, 
  title, 
  prayerMethod, 
  prayerData, 
  date, 
  storagePath, 
  audioKey,
  durationOptions,
  defaultDuration
}) => {
  const [selectedDuration, setSelectedDuration] = useState(defaultDuration);
  const [adjustedScript, setAdjustedScript] = useState(null);
  const [customBreakDurations, setCustomBreakDurations] = useState([]);
  const [key, setKey] = useState(0);

  const updateScript = useCallback(async (duration) => {
    console.log('Updating script with duration:', duration);
    const originalScript = await fetchScript();
    if (!originalScript || !Array.isArray(originalScript)) {
      console.error('Invalid script:', originalScript);
      return;
    }
    const targetDuration = parseInt(duration) * 60; // Convert minutes to seconds

    const { adjustedScript, breakDurations } = adjustContemplationBreakDurations(originalScript, targetDuration);
    console.log('Adjusted script:', adjustedScript);
    console.log('Break durations:', breakDurations);
    setAdjustedScript(adjustedScript);
    setCustomBreakDurations(breakDurations);
  }, [fetchScript]);

  useEffect(() => {
    console.log('Selected duration changed:', selectedDuration);
    updateScript(selectedDuration);
    setKey(prevKey => prevKey + 1); // Force re-render of BaseAudioPlayer
  }, [selectedDuration, updateScript]);

  const handleDurationChange = (e) => {
    const newDuration = e.target.value;
    console.log('Duration changed to:', newDuration);
    setSelectedDuration(newDuration);
  };

  if (!adjustedScript) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <select 
        className="duration-select"
        value={selectedDuration} 
        onChange={handleDurationChange}
      >
        {durationOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      <BaseAudioPlayer
        key={key}
        fetchScript={() => Promise.resolve(adjustedScript)}
        generateAudioEndpoint={generateAudioEndpoint}
        renderTranscript={renderTranscript}
        title={title}
        prayerMethod={prayerMethod}
        prayerData={prayerData}
        date={date}
        storagePath={storagePath}
        audioKey={`${audioKey}-${selectedDuration}`}
        customBreakDurations={customBreakDurations}
      />
    </div>
  );
};

export default AudioPlayerWithDuration;
