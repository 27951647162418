import React, { createContext, useContext, ReactNode } from 'react';
import { PrayerMethod } from '@shared/types/prayerMethod';
import { useSeriesProgress } from '../hooks/tracking/useSeriesProgress';
import { PrayerActivityData, BasePrayerActivityData } from '@shared/types/prayerHistory';
import { SeriesProgress } from '@shared/types/series';
import { DailyPrayer } from '@shared/types/dailyPrayer';
import { RelaxationPrayer } from '@shared/types/relaxationPrayer';
import { RosaryMystery } from '@shared/types/rosaryMystery';
import { CompletionParams, PrayerTrackingContextType } from '@shared/types/prayerTracking';
import { logPrayerActivity as logPrayerActivityApi } from '../utils/api';
import { useUser } from '../contexts/UserContext';
import { LogPrayerActivityResponse, LogPrayerActivityApiResponse } from '@shared/types/api';
import { 
  DailyGospelRequirements, 
  CustomScriptureRequirements,
  RosaryMeditationRequirements,
  DailyReflectionRequirements,
  SimpleTrackingRequirements,
  SaintsMeditationRequirements,
  DiscussionTrackingRequirements,
  PrayerMethodType,
  isCustomScripturePrayer,
  isRosaryPrayer,
  isGospelPrayer,
  BasePrayerActivityInput
} from '../types/prayerMethodTracking';

export const PrayerTrackingContext = createContext<PrayerTrackingContextType | undefined>(undefined);

interface SeriesContextInfo {
  currentProgress: SeriesProgress;
  percentageComplete: number;
  isSeriesComplete: boolean;
  currentItemId?: string;
}

interface PrayerTrackingProviderProps {
  children: ReactNode;
  seriesContext?: {
    seriesId: string;
    itemId: string;
    sectionId?: string;
  };
  prayerData?: DailyPrayer | RelaxationPrayer | null;
  prayerMethod?: PrayerMethod | null;
}

export const PrayerTrackingProvider: React.FC<PrayerTrackingProviderProps> = ({ 
  children, 
  seriesContext,
  prayerData = null, 
  prayerMethod = null 
}) => {
  const { userData } = useUser();
  const { 
    progress: seriesProgress, 
    loading: seriesLoading, 
    error: seriesError,
    updateProgress: updateSeriesProgress,
    fetchProgress 
  } = useSeriesProgress(seriesContext?.seriesId);

  const seriesProgressInfo = seriesContext?.seriesId && seriesProgress ? {
    currentProgress: seriesProgress,
    percentageComplete: seriesProgress.stats.percentageComplete,
    isSeriesComplete: !!seriesProgress.completedAt,
    currentItemId: seriesContext.itemId
  } : undefined;

  const createActivityData = (
    prayerMethod: PrayerMethod,
    inputData: BasePrayerActivityInput,
    completionType: 'read' | 'listen' | 'reflection'
  ): PrayerActivityData => {
    const date = inputData.date || new Date().toISOString().split('T')[0];
    
    const baseActivityData: BasePrayerActivityData = {
      userId: inputData.userId,
      title: inputData.title,
      date,
      metadata: inputData.metadata,
      scriptureReference: inputData.scriptureReference,
      gospelReference: inputData.gospelReference,
      gospelDate: inputData.gospelDate
    };

    if (isCustomScripturePrayer(inputData)) {
      const sanitizedReference = inputData.scriptureReference
        ?.replace(/[\s:,;]/g, '-')
        .toLowerCase();
      
      const uniqueKey = `${prayerMethod.id}_${sanitizedReference}`;
      return {
        ...inputData,
        prayerType: 'daily-meditation',
        completionType,
        scriptureReference: inputData.scriptureReference,
        metadata: {
          ...inputData.metadata,
          isCustomScripture: true
        },
        uniqueKey
      } as CustomScriptureRequirements;
    }

    if (isGospelPrayer(prayerMethod, inputData)) {
      const uniqueKey = `${prayerMethod.id}_${inputData.gospelDate}`;
      const scriptureRef = inputData.scriptureReference || inputData.gospelReference || '';
      
      return {
        ...baseActivityData,
        prayerType: prayerMethod.type as 'daily-meditation' | 'daily-prayer',
        completionType,
        scriptureReference: scriptureRef,
        gospelDate: inputData.gospelDate || '',
        uniqueKey
      } as DailyGospelRequirements;
    }

    switch (prayerMethod.type as PrayerMethodType) {
      case 'daily-meditation':
      case 'daily-prayer': {
        const uniqueKey = `${prayerMethod.id}_${date}`;
        return {
          ...baseActivityData,
          prayerType: prayerMethod.type,
          completionType,
          uniqueKey
        } as SimpleTrackingRequirements;
      }

      case 'rosary-meditation': {
        const uniqueKey = `${prayerMethod.id}_${inputData.metadata?.styleId || ''}_${date}`;
        return {
          ...baseActivityData,
          prayerType: 'rosary-meditation',
          completionType,
          metadata: {
            ...inputData.metadata,
            styleId: inputData.metadata?.styleId || '',
            mysteryType: inputData.metadata?.mysteryType || 'Joyful',
            style: inputData.metadata?.style || ''
          },
          uniqueKey
        } as RosaryMeditationRequirements;
      }

      case 'daily-reflection': {
        const uniqueKey = `${prayerMethod.id}_${date}`;
        return {
          ...baseActivityData,
          prayerType: 'daily-reflection',
          completionType: 'reflection',
          uniqueKey
        } as DailyReflectionRequirements;
      }

      case 'relaxation-prayer':
      case 'contemplative-meditation': {
        const uniqueKey = `${prayerMethod.id}_${date}`;
        return {
          ...baseActivityData,
          prayerType: prayerMethod.type as 'relaxation-prayer' | 'contemplative-meditation',
          completionType,
          uniqueKey
        } as SimpleTrackingRequirements;
      }

      case 'saints-meditation': {
        const uniqueKey = `${prayerMethod.id}_${inputData.metadata?.saintId}_${date}`;
        
        // Determine meditation type based on section
        const saintMeditationType = inputData.metadata?.section === 'story' ? 'life' : 'meditation';
        
        return {
          ...baseActivityData,
          prayerType: 'saints-meditation',
          completionType: 'reflection', // Always 'reflection' for saints meditation
          metadata: {
            ...inputData.metadata,
            saintId: inputData.metadata?.saintId || '',
            saintMeditationType,
          },
          uniqueKey
        } as SaintsMeditationRequirements;
      }

      case 'daily-discussion': {
        const uniqueKey = `${prayerMethod.id}_${date}`;
        return {
          ...baseActivityData,
          prayerType: 'daily-discussion',
          completionType,
          uniqueKey,
          skipTracking: true
        } as DiscussionTrackingRequirements;
      }

      default:
        throw new Error(`Unsupported prayer method type: ${prayerMethod.type}`);
    }
  };

  const logCompletion = async ({ 
    prayerMethod, 
    prayerData: inputPrayerData, 
    completionType 
  }: CompletionParams) => {
    console.log('[PrayerTrackingContext] Starting logCompletion with context:', {
      prayerMethod,
      prayerData: inputPrayerData,
      completionType,
      seriesContext
    });

    if (!userData?.uid) {
      throw new Error('User not authenticated');
    }

    const activityData = createActivityData(
      prayerMethod,
      {
        ...inputPrayerData,
        userId: userData.uid,
        metadata: {
          ...inputPrayerData.metadata,
          ...(seriesContext && {
            seriesId: seriesContext.seriesId,
            itemId: seriesContext.itemId
          })
        }
      },
      completionType
    );

    const response = await logPrayerActivityApi({
      userId: userData.uid,
      prayerMethod,
      prayerData: activityData
    }) as LogPrayerActivityApiResponse;

    console.log('[PrayerTrackingContext] Prayer activity logged:', response);
    console.log('[PrayerTrackingContext] Series context:', seriesContext);

    if (seriesContext) {
      console.log('[PrayerTrackingContext] Updating series progress:', seriesContext);
      try {
        await updateSeriesProgress({
          seriesId: seriesContext.seriesId,
          itemId: seriesContext.itemId,
          prayerHistoryId: response.data.id
        });
        console.log('[PrayerTrackingContext] Series progress updated successfully');
      } catch (error) {
        console.error('[PrayerTrackingContext] Error updating series progress:', error);
        throw error;
      }
    }

    return response.data.id;
  };

  const contextValue = {
    logCompletion: (params: CompletionParams) => logCompletion({
      ...params,
      seriesContext: seriesContext
    }),
    loading: seriesLoading,
    error: seriesError,
    prayerData,
    prayerMethod,
    seriesProgress: seriesProgressInfo
  };

  return (
    <PrayerTrackingContext.Provider value={contextValue}>
      {children}
    </PrayerTrackingContext.Provider>
  );
};

export const usePrayerTracking = () => {
  const context = useContext(PrayerTrackingContext);
  if (!context) {
    throw new Error('usePrayerTracking must be used within PrayerTrackingProvider');
  }
  return context;
}; 