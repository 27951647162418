import React from 'react';
import AdjustableAudioPlayerWithDuration from './AdjustableAudioPlayerWithDuration';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import { useContemplationPlayer } from '../../hooks/useContemplationPlayer';
import LoadingSpinner from '../common/LoadingSpinner';

const ContemplationPlayer = ({ prayerMethod, contemplationData }) => {
  const { script, isLoading, error, fetchScript, generateAudio } = useContemplationPlayer(prayerMethod, contemplationData);

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
    />
  );

  const title = `Contemplation Meditation: ${prayerMethod.name}`;

  const durationOptions = [
    { value: '5', label: '5 minutes' },
    { value: '10', label: '10 minutes' },
    { value: '15', label: '15 minutes' },
    { value: '20', label: '20 minutes' },
    { value: '30', label: '30 minutes' }
  ];

  const defaultDuration = '10';

  if (isLoading) return <LoadingSpinner message="Preparing your contemplation meditation..." />;
  if (error) return <div>Error: {error}</div>;

  return (
    <AdjustableAudioPlayerWithDuration
      fetchScript={() => Promise.resolve(script)}
      generateAudioEndpoint={generateAudio}
      renderTranscript={renderTranscript}
      title={title}
      prayerMethod={prayerMethod}
      prayerData={{
        title: title,
        type: 'contemplation-meditation',
        completionType: 'listen'
      }}
      date={new Date().toISOString().split('T')[0]}
      storagePath={STORAGE_PATHS.CONTEMPLATION_AUDIO_MEDITATIONS(contemplationData.prayerMethodId)}
      audioKey={contemplationData.prayerMethodId}
      durationOptions={durationOptions}
      defaultDuration={defaultDuration}
      customBreakDurations={script ? script.map(item => {
        const breakDuration = item.break ? parseFloat(item.break.replace('s', '')) : 0;
        return isNaN(breakDuration) ? 0 : breakDuration;
      }) : null}
    />
  );
};

export default ContemplationPlayer;
