import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import api from './api';
import { format } from 'date-fns';
import { API_ENDPOINTS, FIRESTORE_PATHS } from './apiConfig';

export const fetchDailyGospel = async (date) => {
  const formattedDate = format(date, 'yyyy-MM-dd');
  const docRef = doc(firestore, FIRESTORE_PATHS.DAILY_GOSPEL_READINGS.collection, FIRESTORE_PATHS.DAILY_GOSPEL_READINGS.doc(formattedDate));

  try {
    // Check if the document exists in Firestore
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log(`Gospel data not found in Firestore for date: ${formattedDate}. Fetching from API.`);
      const response = await api.get(API_ENDPOINTS.FETCH_GOSPEL_READING(formattedDate));
      const gospelData = response.data;

      return gospelData;
    }
  } catch (error) {
    console.error(`Error fetching daily gospel for date ${formattedDate}:`, error);
    console.error('Error details:', error.message);
    if (error.response) {
      console.error('API response status:', error.response.status);
      console.error('API response data:', error.response.data);
    }
    throw error;
  }
};

export const formatScriptureVerses = (text, startVerse = 1, endVerse = Infinity) => {
  if (!text) return [];
  
  const hasBrackets = text.includes('[');
  const verses = hasBrackets 
    ? text.split(/(\[\d+\])/).filter(Boolean)
    : text.split(/(\d+)[\s\n]+/).filter(Boolean);
  
  const formattedVerses = [];
  let currentVerseNumber = 0;
  let currentVerseContent = '';
  
  for (let i = 0; i < verses.length; i++) {
    const part = verses[i].trim();
    
    const verseNumberMatch = hasBrackets 
      ? part.match(/\[(\d+)\]/)
      : !isNaN(parseInt(part)) ? [null, part] : null;
    
    if (verseNumberMatch) {
      if (currentVerseNumber > 0 && currentVerseContent) {
        const shouldIncludeVerse = 
          currentVerseNumber >= startVerse && 
          currentVerseNumber <= endVerse;
          
        if (shouldIncludeVerse) {
          formattedVerses.push({
            number: currentVerseNumber,
            content: currentVerseContent.trim()
          });
        }
      }
      currentVerseNumber = parseInt(verseNumberMatch[1]);
      currentVerseContent = '';
    } else {
      currentVerseContent += ' ' + part;
    }
  }
  
  // Add the last verse if it exists
  if (currentVerseNumber > 0 && currentVerseContent) {
    const shouldIncludeVerse = 
      currentVerseNumber >= startVerse && 
      currentVerseNumber <= endVerse;
    
    if (shouldIncludeVerse) {
      formattedVerses.push({
        number: currentVerseNumber,
        content: currentVerseContent.trim()
      });
    }
  }
  
  return formattedVerses;
};