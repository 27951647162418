import React from 'react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/startButton.module.css';

interface StartButtonProps {
  scripture?: string;
  methodId?: string;
  text?: string;
  icon?: React.ReactNode;
  className?: string;
  variant?: 'link' | 'classic' | 'outline';
}

const StartButton: React.FC<StartButtonProps> = ({ 
  scripture,
  methodId,
  text = 'Start Prayer',
  icon = <ArrowRight size={16} />,
  className = '',
  variant = 'link'
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (scripture && methodId) {
      navigate(`/meditation?scripture=${encodeURIComponent(scripture)}&prayerMethodId=${methodId}`);
    }
  };

  return (
    <span 
      className={`${styles.startButton} ${styles[variant]} ${className}`}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      {text} {icon}
    </span>
  );
};

export default StartButton;