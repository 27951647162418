import React from 'react';
import BaseAudioPlayer from './BaseAudioPlayer';
import AudioTranscript from './AudioTranscript';
import { STORAGE_PATHS } from '../../utils/apiConfig';
import { useDiscussionPlayer } from '../../hooks/useDiscussionPlayer';
import LoadingSpinner from '../common/LoadingSpinner';

const DiscussionPlayer = ({ prayerMethod, gospelContent, date }) => {
  const { script, isLoading, error, fetchScript, generateAudio } = useDiscussionPlayer(prayerMethod, gospelContent, date);

  const renderTranscript = (script, currentTime, isPlaying) => (
    <AudioTranscript
      script={script}
      currentTime={currentTime}
      isPlaying={isPlaying}
    />
  );

  const title = `Gospel Discussion: ${prayerMethod.name}`;

  if (isLoading) return <LoadingSpinner message="Preparing your discussion audio..." />;
  if (error) return <div>Error: {error}</div>;

  return (
    <BaseAudioPlayer
      fetchScript={fetchScript}
      generateAudioEndpoint={generateAudio}
      prayerMethod={prayerMethod}
      prayerData={gospelContent}
      date={date}
      storagePath={STORAGE_PATHS.DAILY_AUDIO_DISCUSSIONS(prayerMethod.id, date)}
      renderTranscript={renderTranscript}
      title={title}
    />
  );
};

export default DiscussionPlayer;