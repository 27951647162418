import React from 'react';
import RosaryPlayer from '../audio/RosaryPlayer';
import DecadePrayers from './DecadePrayers';

const IgnatianRosaryMeditation = ({ mysteryMeditationData, allMeditationData, rosaryPrayers }) => {
  return (
    <div className="ignatian-meditation">
      <h3>{mysteryMeditationData.name}</h3>
      <p className="scripture-reference">{mysteryMeditationData.scriptureReference}</p>
      <div className="meditation-content">
        <p className="meditation-text">{mysteryMeditationData.textMeditation}</p>
        <RosaryPlayer
          key={mysteryMeditationData.id}
          prayerMethod={{
            type: 'rosary-meditation',
            id: allMeditationData.prayerMethodId,
          }}
          mysteryMeditationData={mysteryMeditationData}
          allMeditationData={allMeditationData}
        />
      </div>
      <hr className="divider" />
      <DecadePrayers decadePrayers={rosaryPrayers.decadePrayers} mysteryName={mysteryMeditationData.name} />
    </div>
  );
};

export default IgnatianRosaryMeditation;