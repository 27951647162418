import React, { useState, useEffect, useCallback } from 'react';
import CustomScriptureMeditationPlayer from './CustomScriptureMeditationPlayer';
import { adjustBreakDurations, estimateSpeakingDuration } from '../../utils/audioUtils';
import LoadingSpinner from '../common/LoadingSpinner';

interface AdjustableCustomScriptureMeditationPlayerProps {
  prayerMethod: PrayerMethod;
  scriptureData: {
    citation: string;
    text: string;
  };
  meditationData: PrayerStep[];
  durationOptions: Array<{ value: string; label: string }>;
  defaultDuration: string;
  fetchScript: () => Promise<any>;
  seriesContext?: {
    seriesId: string;
    itemId: string;
    sectionId: string;
    hideBackLink?: boolean;
  };
  onComplete: () => void;
  prayerData?: {
    title: string;
    scriptureReference: string;
  };
}

const AdjustableCustomScriptureMeditationPlayer = ({ 
  prayerMethod, 
  scriptureData, 
  meditationData, 
  durationOptions, 
  defaultDuration, 
  fetchScript: parentFetchScript,
  seriesContext,
  onComplete
}) => {
  const [selectedDuration, setSelectedDuration] = useState(defaultDuration);
  const [adjustedScript, setAdjustedScript] = useState(null);
  const [key, setKey] = useState(0);

  console.log('[AdjustablePlayer] Initializing with props:', {
    seriesContext,
    prayerMethod: prayerMethod?.id,
    hasOnComplete: !!onComplete
  });

  const normalizeScript = (script) => {
    if (!script || !Array.isArray(script)) {
      console.error('Invalid script:', script);
      return [];
    }
    
    return script.map(item => {
      // Parse break duration to number
      let breakDuration = 5; // Default duration
      if (item.break) {
        if (typeof item.break === 'string') {
          breakDuration = parseFloat(item.break.replace('s', ''));
        } else if (typeof item.break === 'number') {
          breakDuration = item.break;
        }
      }
      
      // Ensure break duration is valid
      if (isNaN(breakDuration)) {
        console.warn('Invalid break duration found:', item.break);
        breakDuration = 5; // Fallback to default
      }
      
      return {
        ...item,
        break: `${breakDuration}s`,
        speakingDuration: item.speakingDuration || estimateSpeakingDuration(item.text)
      };
    });
  };

  const updateScript = useCallback(async (duration) => {
    // console.group('Script Update');
    // console.log('Original duration:', duration);
    const originalScript = await parentFetchScript();
    // console.log('Original script:', originalScript);
    const normalizedScript = normalizeScript(originalScript);
    // console.log('Normalized script:', normalizedScript);
    const targetDuration = parseInt(duration) * 60;
    // console.log('Target duration (seconds):', targetDuration);
    const { adjustedScript, breakDurations, totalAdjustedDuration } = adjustBreakDurations(normalizedScript, targetDuration);
    // console.log('Break durations:', breakDurations);
    // console.log('Total adjusted duration:', totalAdjustedDuration);
    // console.log('Final adjusted script:', adjustedScript);
    // console.groupEnd();
    
    // Validate breaks before setting
    const hasInvalidBreaks = adjustedScript.some(item => 
      typeof item.break === 'string' && item.break.includes('NaN')
    );
    
    if (hasInvalidBreaks) {
      console.error('Invalid breaks detected in adjusted script');
      return;
    }
    
    setAdjustedScript(adjustedScript);
  }, [parentFetchScript]);

  useEffect(() => {
    console.log('Selected duration changed:', selectedDuration);
    updateScript(selectedDuration);
    setKey(prevKey => prevKey + 1); // Force re-render of CustomScriptureMeditationPlayer
  }, [selectedDuration, updateScript]);

  const handleDurationChange = (e) => {
    const newDuration = e.target.value;
    console.log('Duration changed to:', newDuration);
    setSelectedDuration(newDuration);
  };

  // Create prayer data object for tracking
  const prayerTrackingData = {
    title: `${prayerMethod.name} - ${scriptureData.citation}`,
    scriptureReference: scriptureData.citation,
    completionType: 'listen',
    prayerType: prayerMethod.type,
    metadata: {
      isCustomScripture: true,
      citation: scriptureData.citation,
      text: scriptureData.text
    }
  };

  console.log('[AdjustablePlayer] Created tracking data:', {
    prayerTrackingData,
    scriptureData,
    citation: scriptureData.citation
  });

  if (!adjustedScript) {
    return <LoadingSpinner message="Preparing your meditation..." />;
  }

  return (
    <div>
      <div className="duration-select-container">
        <select 
          className="duration-select"
          value={selectedDuration} 
          onChange={handleDurationChange}
        >
          {durationOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      <CustomScriptureMeditationPlayer
        key={key}
        prayerMethod={prayerMethod}
        scriptureData={scriptureData}
        meditationData={meditationData}
        fetchScript={() => Promise.resolve(adjustedScript)}
        seriesContext={seriesContext}
        prayerData={prayerTrackingData}
        onComplete={(id) => {
          console.log('[AdjustablePlayer] Completion callback:', {
            prayerHistoryId: id,
            seriesContext,
            scriptureReference: scriptureData.citation
          });
          onComplete?.(id);
        }}
      />
    </div>
  );
};

export default AdjustableCustomScriptureMeditationPlayer;
