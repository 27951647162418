import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useHomilies } from '../../hooks/useHomilies';
import { useGospelData } from '../../hooks/useGospelData';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorMessage from '../common/ErrorMessage';
import DateNavigation from '../common/DateNavigation';
import ExpandableText from '../common/ExpandableText';
import styles from '../../styles/Homily.module.css';
import { parseISO, isValid, format, addDays, subDays } from 'date-fns';
import { Homily } from '@shared/types/homily';
import HomilyInspiration from './HomilyInspiration';

interface HomilyContainerProps {
  initialDate?: string;
}

const HomilyContainer: React.FC<HomilyContainerProps> = ({ initialDate }) => {
  const navigate = useNavigate();
  const { date } = useParams();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [isGospelExpanded, setIsGospelExpanded] = useState(false);

  // Handle date initialization and navigation
  useEffect(() => {
    if (date) {
      const parsedDate = parseISO(date);
      if (isValid(parsedDate)) {
        setSelectedDate(parsedDate);
      } else {
        const today = new Date();
        setSelectedDate(today);
        navigate(`/homily/${format(today, 'yyyy-MM-dd')}`, { replace: true });
      }
    } else {
      const today = new Date();
      setSelectedDate(today);
      navigate(`/homily/${format(today, 'yyyy-MM-dd')}`, { replace: true });
    }
  }, [date, navigate]);

  // Fetch gospel data
  const { dailyReading, loading: gospelLoading, error: gospelError } = useGospelData(selectedDate);
  const gospelContent = dailyReading?.readings?.find(r => r.name === 'Gospel');

  // Fetch homily data
  const { homilyData, loading: homilyLoading, error: homilyError } = useHomilies(
    gospelContent?.text || null,
    format(selectedDate, 'yyyy-MM-dd')
  );

  // Date navigation handlers
  const handlePreviousDay = () => {
    const newDate = subDays(selectedDate, 1);
    navigate(`/homily/${format(newDate, 'yyyy-MM-dd')}`);
  };

  const handleNextDay = () => {
    const newDate = addDays(selectedDate, 1);
    navigate(`/homily/${format(newDate, 'yyyy-MM-dd')}`);
  };

  // Existing homily rendering logic
  const parseHomilyContent = (content: string) => {
    try {
      if (content.startsWith('{')) {
        const parsed = JSON.parse(content);
        return parsed.homily || content;
      }
      return content;
    } catch (e) {
      return content;
    }
  };

  const renderHomily = (homily: Homily, styleName: string) => (
    <div className={styles.homilyCard}>
      <h4 className={styles.styleTitle}>{styleName}</h4>
      <div className={styles.homilyContent}>
        {parseHomilyContent(homily.content)
          .split('\n')
          .filter((para: string) => para.trim() !== '')
          .map((paragraph: string, index: number) => (
            <p key={index} className={styles.paragraph}>
              {paragraph.trim()}
            </p>
          ))}
      </div>
    </div>
  );

  if (gospelLoading || homilyLoading) return <LoadingSpinner />;
  if (gospelError) return <ErrorMessage message={gospelError} />;
  if (homilyError) return <ErrorMessage message={homilyError} />;
  if (!gospelContent) return <ErrorMessage message="No gospel reading found for this date" />;
  if (!homilyData || !homilyData.styles) return null;

  const formattedDate = format(selectedDate, 'EEEE, MMMM do, yyyy');

  return (
    <div className={styles.homilyContainer}>
      {/* Date Navigation */}
      <DateNavigation
        selectedDate={selectedDate}
        onPreviousDay={handlePreviousDay}
        onNextDay={handleNextDay}
        formatString="EEE, MMM do, yyyy"
        className={styles.dateNavigation}
        align="left"
      />

      {/* Gospel Content */}
      <div className={styles.gospelSection}>
        <ExpandableText
          title={`Gospel Reading: ${gospelContent.citation}`}
          content={gospelContent.text.replace(/<br\s*\/?>/gi, '\n')}
          isExpanded={isGospelExpanded}
          onToggleExpand={() => setIsGospelExpanded(!isGospelExpanded)}
          maxHeight="300px"
          className={styles.gospelText}
          variant="plain"
        />
      </div>

      {/* Homilies Section */}
      {homilyData && homilyData.styles && (
        <>
          {/* Main Fr. Brendan Homily */}
          {homilyData.styles['frBrendan'] && (
            <div className={styles.mainHomilyCard}>
              <h3 className={styles.homilyTitle}>
                Homily for {formattedDate}
              </h3>
              {renderHomily(homilyData.styles['frBrendan'], 'Fr. Brendan McGuire Style')}
              {homilyData.inspiration && (
                <HomilyInspiration inspiration={homilyData.inspiration} />
              )}
            </div>
          )}

          {/* Other Homily Styles */}
          {Object.entries(homilyData.styles)
            .filter(([styleId]) => styleId !== 'frBrendan')
            .map(([styleId, homily]) => (
              <div key={styleId} className={styles.alternateVersion}>
                {renderHomily(homily, styleId)}
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default HomilyContainer;
